import React, { useEffect, useState } from "react";
import "./SingeRecordedClassVideoPage.scss";
//import ReactPlayer from "react-player";
// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Typography,
// } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import AOS from "aos";
// import "aos/dist/aos.css";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ListOfAllClassVideos from "../ClassVideosComponents/components/ListOfAllClassVideos";
import Loading from "../../../../../../../LoadingComponent/Loading";
import {
  setemrolledRecodedCourseModuleVideos,
  setemrolledRecodedCourseTopicVideos,
} from "../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import {
  getLessonTopicVides,
  getTopicVideos,
} from "../../../../../../../Redux/Actions/MyCourseActions";
const SingeRecordedClassVideoPage = () => {
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { topic_id } = useParams();

  const emrolledRecodedCourseModuleVideos = useSelector(
    (state) => state.mycourseData.emrolledRecodedCourseModuleVideos
  );
  const emrolledRecodedCourseTopicVideos = useSelector(
    (state) => state.mycourseData.emrolledRecodedCourseTopicVideos
  );

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getLessonTopicVides(moduleId);
      dispatch(setemrolledRecodedCourseModuleVideos(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, moduleId]);
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getTopicVideos(topic_id);
      dispatch(setemrolledRecodedCourseTopicVideos(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, topic_id]);

  let video = {};

  if (emrolledRecodedCourseTopicVideos) {
    const videoData = emrolledRecodedCourseTopicVideos?.filter((val) => {
      return val.active_Status === "A";
    });

    if (videoData) {
      video = videoData[0];
      console.log(video?.video_URL);
    }
  }

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="SingleLiveClassRecodedVideoPage-main-container">
      <div className="SingleLiveClassRecodedVideoPage-container">
        <div className="SingleLiveClassRecodedVideoPage-singleVideo-Content">

        <video
        src="https://d7p5gncpfqoge.cloudfront.net/WhatsApp%20Video%202023-12-18%20at%207.14.42%20PM.mp4"
        width={"100%"}
        height={"500px"}
        controls
      ></video>

          {/* <video
            src={video?.video_URL}
            width={"100%"}
            height={"100%"}
            controls
          ></video> */}
          {/* <ReactPlayer
            url={video?.video_URL}
            width={"100%"}
            height={"100%"}
            controls={true}
          ></ReactPlayer> */}
          <div className="video-topic-details">
            <h5>{video?.topic_Video_Heading}</h5>
          </div>
        </div>

        <div className="allLessonWithVideos">
          <ListOfAllClassVideos videoData={emrolledRecodedCourseModuleVideos} />{" "}
        </div>
      </div>
    </div>
  );
};
export default SingeRecordedClassVideoPage;
