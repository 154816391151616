import React, { useEffect, useState } from "react";
import "./JobDetails.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  addJuzzItJobApplycants,
  getJobDetails,
} from "../../../../../../Redux/Actions/EventAction";
import { useDispatch, useSelector } from "react-redux";
import { setJobDetails } from "../../../../../../Redux/RedusersCompo/EventReducer";

import logo from "../../../../../../img/logo/juzz-it_logo.jpg";
import { Button, TextField } from "@mui/material";
import Loading from "../../../../../../LoadingComponent/Loading";
const JobDetails = () => {
  const { jobId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const jobDetails = useSelector((state) => state.EventData.jobDetails);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
  });

  const [formError, setFormError] = useState({
    name: "",
    email: "",
    mobileNumber: "",
  });

  const handleOnchange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    // Clear errors when user starts typing
    setFormError({ ...formError, [event.target.name]: "" });
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getJobDetails(jobId);
      dispatch(setJobDetails(data));
    };
    getData();
  }, [dispatch, jobId]);

  const validateForm = () => {
    let newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = "Invalid mobile number (10 digits)";
    }

    setFormError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmition = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const isValid = validateForm();
    if (isValid) {
      const data = {
        name: formData.name,
        email: formData.email,
        mobile_number: Number(formData.mobileNumber),
      };

      setLoading(true);
      const responce = await addJuzzItJobApplycants(jobId, data);

      setTimeout(() => {
        setLoading(false);
        if (responce?.status === true) {
          navigate("/page/success");
        }
      }, 4000);
    }
  };

  if (loading) {
    return <Loading />;
  }

let image;
  if(jobDetails &&
    jobDetails.length > 0 &&
    jobDetails[0]?.imageData){

      image= jobDetails[0]?.imageData.filter((a)=>{
return a.active_status === "A";
      })[0]


      

    }


  return (
    <div className="JobDetails-main-container">
      <div className="JobDetails-container">
        <div className="JobDetails-main-contant">
          <div className="JobDetails-header-content">
            <div className="JobDetails-img-content">
              {
                image?<img src={image.image_URL} alt="sdcd"/>:
              <img src={logo} alt="sdcd" />
              }
            </div>

            <div className="JobDetails-job-contnet">
              <h1>
                {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0]?.company_name}
              </h1>
              <ul>
                {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].JobLocationData.map((val, key) => {
                    return <li>{val.LOCATION}</li>;
                  })}
              </ul>
              <p>
                {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0]?.company_description}
              </p>
            </div>
          </div>

          <div className="JobDetails-main-content">
            <div className="JobDetails-content">
              <h2>job role</h2>
              <ul>
                <li>
                  <i class="fa-solid fa-circle"></i>{" "}
                  {jobDetails &&
                    jobDetails.length > 0 &&
                    jobDetails[0].categury_or_role}
                </li>
              </ul>
            </div>
            <div className="JobDetails-content">
           { jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].skillsRequired && <h2>tool and technologys</h2>}
              <ul>
                {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].skillsRequired.map((val, key) => {
                    return (
                      <li>
                        {" "}
                        <i class="fa-solid fa-circle"></i>
                        {val.SKILL_REQUIRED}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="JobDetails-content">
            {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].requirement && <h2>requirement</h2>}
              <ul>
                {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].requirement.map((val, key) => {
                    return (
                      <li>
                        {" "}
                        <i class="fa-solid fa-circle"></i>
                        {val.REQUIREMENT}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="JobDetails-content">
            {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].required_qualification &&  <h2>required qualification</h2>}
              <ul>
                {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].required_qualification.map((val, key) => {
                    return (
                      <li>
                        {" "}
                        <i class="fa-solid fa-circle"></i>
                        {val.REQUIRED_QUALIFICATION}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="JobDetails-content">
            {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].responciability && <h2>responciability</h2>}
              <ul>
                {jobDetails &&
                  jobDetails.length > 0 &&
                  jobDetails[0].responciability.map((val, key) => {
                    return (
                      <li>
                        {" "}
                        <i class="fa-solid fa-circle"></i>
                        {val.RESPNCIBILITY}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="JobDetails-apply-main-content">
          <form onSubmit={handleFormSubmition}>
            <div className="header-data">Appy Now</div>
            {/* <h3>Appy Now</h3> */}
            <div className="form-element">
              <TextField
                value={formData.name}
                name="name"
                label="Enter Name"
                type="text"
                variant="standard"
                onChange={handleOnchange}
                error={!!formError.name}
                helperText={formError.name}
              />
            </div>
            <div className="form-element">
              <TextField
                value={formData.email}
                name="email"
                label="Enter Email"
                type="text"
                variant="standard"
                onChange={handleOnchange}
                error={!!formError.email}
                helperText={formError.email}
              />
            </div>
            <div className="form-element">
              <TextField
                value={formData.mobileNumber}
                name="mobileNumber"
                label="Enter Mobile Number"
                type="number"
                variant="standard"
                onChange={handleOnchange}
                error={!!formError.mobileNumber}
                helperText={formError.mobileNumber}
              />
            </div>
            <Button variant="contained" type="submit" className="button">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
