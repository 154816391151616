import React, { useEffect, useState } from "react";
//import PageHeader from "../../PageHeader";
import "./OurTeam.scss";
// import Employee from "./Components/Employee";

// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/effect-cards";
// import "swiper/css/effect-coverflow";
// import "swiper/css/pagination";
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
// import "swiper/css/autoplay";

// // import required modules
// import { EffectCards } from "swiper/modules";
// // import required modules
// import { EffectCoverflow } from "swiper/modules";

import AOS from "aos";
import "aos/dist/aos.css";
import Member from "./Components/Member";
import {
  getAllCoreTeamMember,
  getAllMainTeamMember,
} from "../../../../Redux/Actions/TeamMemberActions";
import { useDispatch, useSelector } from "react-redux";
import {
  setcoreTeamMember,
  setmainTeamMember,
} from "../../../../Redux/RedusersCompo/TeamMemberReducer";
// import img from "../../../../img/ourTeamImage.png";
import Loading from "../../../../LoadingComponent/Loading";

const OurTeam = () => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  // const contants = () => {
  //   return (
  //     <>
  //       <h1>OurTeam</h1>
  //     </>
  //   );
  // };

  const mainTeamMember = useSelector(
    (state) => state.teamMemberData.mainTeamMember
  );
  const coreTeamMember = useSelector(
    (state) => state.teamMemberData.coreTeamMember
  );

  console.log(mainTeamMember);
  console.log(coreTeamMember);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getAllMainTeamMember();
      dispatch(setmainTeamMember(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch]);
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getAllCoreTeamMember();
      dispatch(setcoreTeamMember(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch]);
  if (isLoading) {
    <Loading />;
  }
  return (
    <div className="OurTeam-main-container">
      {/* <PageHeader content={contants()} heading={"OurTeam"} url={img} /> */}
      <h1>Our Team</h1>

      <div className="OurTeam-asckjasdck">
        {mainTeamMember &&
          mainTeamMember?.map((val, key) => {
            return <Member key={key} memberData={val} />;
          })}
        {/* <Swiper
          effect={"cards"}
          grabCursor={true}
          modules={[EffectCards, Navigation, Pagination, Autoplay]}
          autoplay={{ delay: 2500 }}
          className="mySwiper"
        >
          {mainTeamMember &&
            mainTeamMember?.map((val, key) => {
              return (
                <SwiperSlide key={key}>
                  <Member memberData={val} />
                </SwiperSlide>
              );
            })}
        
        </Swiper> */}
      </div>

      <div className="team-mksncvjisdns">
        {coreTeamMember &&
          coreTeamMember?.map((val, key) => {
            return <Member key={key} memberData={val} />;
          })}
        {/* <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          autoplay={{ delay: 2500 }}
          className="mySwiper"
        >
          {coreTeamMember &&
            coreTeamMember?.map((val, key) => {
              return (
                <SwiperSlide key={key}>
                  <Member memberData={val} />
                </SwiperSlide>
              );
            })}
        </Swiper> */}
      </div>
    </div>
  );
};

export default OurTeam;
