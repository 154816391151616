import React, { useRef } from "react";
import "./RecentPlacements.scss";
import SingleStudentPlacement from "./Components/SingleStudentPlacement";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";

// Import Swiper modules

import { useState } from "react";
import { useEffect } from "react";
import { getRecentPlacementData } from "../../../../../Redux/Actions/HomePageActions";
import { useDispatch, useSelector } from "react-redux";
import { setrecentPlacements } from "../../../../../Redux/RedusersCompo/HomePageReducer";
import { Key } from "@mui/icons-material";
import DemoForm from "../../../DemoFormCompo/DemoForm";
import { useNavigate } from "react-router-dom";

// Install Swiper modules

const RecentPlacements = () => {
  const [showForm, setshowForm] = useState(false);

  const recentPlacements = useSelector(
    (state) => state.HomePageData.recentPlacements
  );

  const navigate = useNavigate();

  const [slidesPerView, setSlidesPerView] = useState(5);
  const dispach = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getRecentPlacementData();
      dispach(setrecentPlacements(data));
    };

    getData();
  }, [dispach]);

  useEffect(() => {
    const updateSlidesPerView = () => {
      // Adjust the logic based on your design requirements
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1200) {
        setSlidesPerView(4);
      } else if (screenWidth >= 992) {
        setSlidesPerView(3);
      } else if (screenWidth >= 768) {
        setSlidesPerView(3);
      } else if (screenWidth >= 600) {
        setSlidesPerView(2);
      } else if (screenWidth >= 500) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(1);
      }
    };

    // Update slides per view on initial load and window resize
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  let sortedRecentPlacements;

  if (recentPlacements && recentPlacements.length > 0) {
    sortedRecentPlacements = [...recentPlacements].sort((a, b) => {
      return a.ORDER - b.ORDER;
    });
  }

  return (
    <div className="RecentPlacements-main-container">
      <div className={`demo-form ${showForm ? "desplayDemoform" : ""}`}>
        <DemoForm
          SETDisplay={() => setshowForm(!showForm)}
          setNavigate={() => {
            navigate("/OurAlumini");
          }}
        />
      </div>
      <div className="RecentPlacements-container">
        <h1 className="All-components-heading">Our Alumni</h1>
        <div className="RecentPlacements-content">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-navigation-size": "15px",

              "--swiper-navigation-padding": "15px",
              "--swiper-navigation-right": "15px",
            }}
            slidesPerView={slidesPerView}
            spaceBetween={20}
            modules={[Navigation ,Autoplay]}
            autoplay={{
              delay:2000  
            }}
            className="mySwiper"
            navigation={{
              nextEl: navigationNextRef.current,
              prevEl: navigationPrevRef.current,
            }}
          >
            {sortedRecentPlacements &&
              sortedRecentPlacements?.map((val, key) => {
                return (
                  <SwiperSlide key={Key}>
                    <SingleStudentPlacement PLACEMENTdATA={val} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
        <div className="navigation-sliders">
          <div ref={navigationPrevRef} className="left-slider">
            {/* Replace with your SVG ICON for Previous Slide */}
            <i class="fa-solid fa-left-long"></i>
          </div>
          <div ref={navigationNextRef} className="right-slider">
            {/* Replace with your SVG ICON for Next Slide */}
            <i class="fa-solid fa-right-long"></i>
          </div>
        </div>
        <div
          className="book-form-button-home"
          onClick={() => setshowForm(true)}
          style={{ width: "150px" }}
        >
          <span style={{ width: "100%", textAlign: "center" }}>
            {" "}
            All Students
          </span>
        </div>
      </div>
    </div>
  );
};

export default RecentPlacements;
