import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainTeamMember: [],
  coreTeamMember: [],
};

const TeamMemberReducer = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setmainTeamMember: (state, action) => {
      state.mainTeamMember = action.payload;
    },
    setcoreTeamMember: (state, action) => {
      state.coreTeamMember = action.payload;
    },
  },
});

export const { setmainTeamMember, setcoreTeamMember } =
  TeamMemberReducer.actions;

export default TeamMemberReducer.reducer;
