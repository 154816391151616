import React, { useState } from "react";
import "./BeacomeTrainer.scss";
import { Button, Snackbar, SnackbarContent, TextField } from "@mui/material";
import { sendTrainerRequest } from "../../../../../Redux/Actions/AuthAction";
import Loading from "../../../../../LoadingComponent/Loading";

import img from "../../../../../img/Home/trainer/pngtree-male-teacher-cartoon-character-holding-a-textbook-and-speak-picture-image_8778257-removebg-preview.png";

const BeacomeTrainer = () => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openApplyForm, setOpenApplyForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    highestQualification: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    highestQualification: "",
  });

  const handleChanges = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmition = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        NAME: formData.name,
        EMAIL: formData.email,
        MOBILE_NUMBER: formData.mobileNumber,
        HIGHEST_QUALIFICATION: formData.highestQualification,
      };
      setLoading(true);
      const responce = await sendTrainerRequest(data);
      setLoading(false);
      if (responce?.status === true) {
        setSuccessMessage(
          "We Received Your application . We Will Be Reaching You Soon"
        );
        setOpenSnackbar(true);
        setOpenApplyForm(false);
        setFormData({
          name: "",
          email: "",
          mobileNumber: "",
          highestQualification: "",
        });
      } else {
        setSuccessMessage("something went wrong please try again");
        setOpenSnackbar(true);
      }
    }
  };

  const validateForm = () => {
    const newError = {
      name: formData.name ? "" : "Name is required",
      email: /\S+@\S+\.\S+/.test(formData.email) ? "" : "Invalid email address",
      mobileNumber: /^\d{10}$/.test(formData.mobileNumber)
        ? ""
        : "Mobile must be 10 digits",
      highestQualification: formData.highestQualification
        ? ""
        : "qualification is required",
    };

    setErrors(newError);
    return Object.values(errors).every((e) => e === "");
  };

  if (loading) {
    return <Loading />;
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="BeacomeTrainer-main--container">
      <div className="BeacomeTrainer-container">
        <div className="img-contnat">
          <img src={img} alt="triner" />
        </div>
        <div className="apply-contant">
          <h3 className="All-components-heading" style={{ color: "#fff" }}>
            Apply now for the opportunity to become a trainer with us.
          </h3>
          <Button
            variant="contained"
            className="button"
            onClick={() => {
              setOpenApplyForm(true);
            }}
          >
            Apply Now{" "}
          </Button>
        </div>
      </div>

      <div
        className={`BeacomeTrainer-formcontainer ${
          openApplyForm ? "openApplyForm" : ""
        }`}
      >
        <form onSubmit={handleSubmition}>
          <i
            className="fa-solid fa-x"
            onClick={() => {
              setOpenApplyForm(false);
            }}
          ></i>
          <div className="BeacomeTrainer-form-contnet">
            <TextField
              variant="standard"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChanges}
              error={!!errors.name}
              helperText={errors.name}
              type="text"
            />
            <TextField
              value={formData.email}
              variant="standard"
              type="text"
              name="email"
              onChange={handleChanges}
              error={!!errors.email}
              helperText={errors.email}
              label="Email"
            ></TextField>
            <TextField
              type="number"
              label="Mobile number"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChanges}
              error={!!errors.mobileNumber}
              helperText={errors.mobileNumber}
              variant="standard"
            />
            <TextField
              variant="standard"
              type="text"
              label="Highest Qualification"
              name="highestQualification"
              value={formData.highestQualification}
              onChange={handleChanges}
              error={!!errors.highestQualification}
              helperText={errors.highestQualification}
            />
          </div>

          <Button className="button" variant="contained" type="submit">
            apply
          </Button>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={5000} // Auto-hide after 5 seconds
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          style={{ backgroundColor: "#040841" }}
          message={successMessage}
        />
      </Snackbar>
    </div>
  );
};

export default BeacomeTrainer;
