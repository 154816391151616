import React from "react";
//import PageHeader from "../../PageHeader";
import Aboutbody from "./Components/Aboutbody";
import AboutHeader from "./Components/AboutHeader";
import StartOutlinedIcon from "@mui/icons-material/StartOutlined";

import TrendingFlatOutlinedIcon from "@mui/icons-material/TrendingFlatOutlined";
import "./About.scss";
//import aboutImage from "../../../../img/AboutUsImage.png";
// import OurTeam from "../OurTeamPhage/OurTeam";
import { useNavigate } from "react-router-dom";
const About = () => {
  const navigate = useNavigate();
  return (
    <div className="About-main-container">
      {/* <PageHeader heading={"About"} url={aboutImage} /> */}
      <Aboutbody />
      <AboutHeader />
      {/* <OurTeam /> */}

      <div className="About-main-footer">
        <p>
          {" "}
          Unlock your full potential and shape a brighter future with our
          transformative courses. Join our community of eager learners today and
          embark on a journey of knowledge, growth, and limitless possibilities.
        </p>
        <StartOutlinedIcon />
        <button onClick={() => navigate("/Contact")}>
          Join Now !! <TrendingFlatOutlinedIcon />
        </button>
      </div>
      <iframe
        src="https://widgets.sociablekit.com/google-reviews/iframe/240761"
        frameborder="0"
        width="100%"
        height="400"
        title="google-reviews"
      ></iframe>
    </div>
  );
};

export default About;
