import React, { useEffect, useState } from "react";
import "./OfflineWorkShop.scss";
import ModalVideo from "react-modal-video";

// import popupbagroungImge from "../../../../../img/Course/homePopUpBagroundImage.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";

import webinarIcon from "../../../../img/Home/webinar.png";
import learningIcon from "../../../../img/Home/elearning.png";
import DemoForm from "../../DemoFormCompo/DemoForm";
const OfflineWorkShop = () => {
  const [showForm, setshowForm] = useState(false);
  const [successSubmition, setsuccessSubmition] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  const [isOpen, setOpen] = useState(false);

  return (
    <div className="OfflineWorkShop-main-container">
      <div
        className={`demo-form ${
          showForm ? "OfflineWorkShopdesplayDemoform" : ""
        }`}
      >
        <DemoForm
          SETDisplay={() => setshowForm(!showForm)}
          setsuccessSubmition={() => setsuccessSubmition(true)}
        />
      </div>

      <div
        className={`sucessfully-submited-Request-main ${
          successSubmition ? "successSubmition" : ""
        }`}
      >
        <div className="sucessfully-submited-Request">
          <h1>We received your request. We will reach you soon.</h1>
          <Button
            variant="contained"
            onClick={() => setsuccessSubmition(false)}
          >
            Ok
          </Button>
        </div>
      </div>

      <div className="OfflineWorkShop-container">
        <div className="OfflineWorkShop-content">
          <div className="OfflineWorkShop-left-contant">
            <div className="OfflineWorkShop-img-contant">
              <img src={webinarIcon} alt="dcsd"></img>
            </div>
            <span>
              <h1 className="componets-itam-heading">
                Online Internships for Students
              </h1>
              <p>
                We provides the online internship programmes for students and
                graduates for their accademics certificates.
              </p>
            </span>
          </div>
          <div className="OfflineWorkShop-left-contant">
            <div className="OfflineWorkShop-img-contant">
              <img src={learningIcon} alt="dvd" className="img"></img>
            </div>
            <span>
              <h1 className="componets-itam-heading">
                2023-24 Job Ready Online Internships
              </h1>
              <p>
                We have a range of job ready courses Online with certificate
                recognized by all Universities/Colleges/Companies in Inida.
              </p>
            </span>
          </div>
        </div>
        <div className="aplay-button-contant">
          <ul>
            <li>
              <i class="fa-solid fa-angles-right"></i>Authorized Certificate
              recognized by all Colleges/Universities
            </li>
            <li>
              <i class="fa-solid fa-angles-right"></i>Courses are 100% Online
            </li>
            <li>
              <i class="fa-solid fa-angles-right"></i>Course Duration
              2-3-4-5-6-8 Weeks
            </li>
            <li>
              <i class="fa-solid fa-angles-right"></i>You will get 2
              certificates (1. Internship Certificate 2. Course Completion
              Certificate)
            </li>
            <li>
              <i class="fa-solid fa-angles-right"></i>Learn Anytime & Anywhere
              conveniently
            </li>
            <li>
              <i class="fa-solid fa-angles-right"></i>Learn through Web or
              Mobile
            </li>
          </ul>
          <Button
            onClick={() => setshowForm(true)}
            className="button"
            variant="contained"
          >
            APPLY NOW
          </Button>
        </div>
        {/* <div className="OfflineWorkShop-img"> */}
        {/* <img src={popupbagroungImge} className="img" alt="xsj"></img> */}
        {/* <section onClick={() => setOpen(true)} data-aos="fade-left">
            <div class="video-buttom-animation">
              <span style={{ "--i": 0 }}></span>
              <span style={{ "--i": 1 }}></span>
              <span style={{ "--i": 2 }}></span>
              <span style={{ "--i": 3 }}></span>
              <h1>WATCH NOW</h1>
            </div>
          </section> */}

        {/* <img onClick={() => setOpen(true)} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3Ts4LSTPdCbXqefwEhd5qQZwr041JwagBT3S2eWiIqQ&s' ></img> */}
        {/* </div> */}
      </div>
      <ModalVideo
        channel="youtube"
        youtube={{ mute: 0, autoplay: 0 }}
        isOpen={isOpen}
        videoId="alK9sZuUGrU?si=PMIGadQ6uBexTKIf"
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default OfflineWorkShop;
