import React, { useState } from "react";
import "./Refere.scss";
import { Button, TextField, Select, MenuItem } from "@mui/material";
import { addNewStudentrefreal } from "../../../../../Redux/Actions/MyCourseActions";
import { useParams } from "react-router-dom";
import Loading from "../../../../../LoadingComponent/Loading";
import CourseDetailsBar from "../Components/EnrolledLiveCoursesComponents/Components/CourseDetailsBar";
import img from "../../../../../img/referral/1_sHmcjWgHgjigFBfOwArJJg.jpg";
const Refere = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    courseType: "",
    course: "",
  });

  const [loading, setLoading] = useState(false);

  const [showsuccessfully, setsucessfull] = useState(false);
  const { studentBatchId } = useParams();

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    courseType: "",
    course: "",
  });

  const refertypes = [
    {
      typeid: 12,
      type: "Professional",
      typePrices: 15000,
    },
    {
      typeid: 13,
      type: "Internship",
      typePrices: 500,
    },
  ];

  const courses = [
    "Full Stack Web Development",
    "Data Science",
    "Cloud Computing And DevOps",
    "Software Testing",
    "Hybrid Electric Vehicle Design And Analysis.",
    "Embedded Electric Vehicle Design And Analysis.",
  ];

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobileNumber = (mobileNumber) => {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobileNumber);
  };

  const handleChanges = async (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let formIsValid = true;

    // Validation for name
    if (formData.name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      formIsValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }

    // Validation for email
    if (!validateEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      formIsValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    // Validation for mobile number
    if (!validateMobileNumber(formData.mobileNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: "mobile number must contain 10 digits",
      }));
      formIsValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: "",
      }));
    }

    if (formData.courseType === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        courseType: "Course type is required",
      }));
      formIsValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        courseType: "",
      }));
    }

    // Validation for course
    // Validation for course
    if (formData.course === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        course: "Course is required",
      }));
      formIsValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        course: "",
      }));
    }

    // If all validations pass, you can proceed with your form submission logic
    if (formIsValid) {
      console.log("Form submitted:", formData);
      const data = {
        name: formData.name,
        email: formData.email,
        mobile_number: Number(formData.mobileNumber),
        course: formData.course,
        courseType: formData.courseType,
      };
      setLoading(true);
      const responce = await addNewStudentrefreal(studentBatchId, data);
      setLoading(false);
      if (responce?.status === true) {
        setsucessfull(true);
      }

      setFormData({
        name: "",
        email: "",
        mobileNumber: "",
        courseType: "",
        course: "",
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="Refere-main-container">
      <CourseDetailsBar />
      <div
        className={`Refereforsuccessfully-submition ${
          showsuccessfully ? "showsuccessfully" : ""
        }`}
      >
        <div className="successfull-contnat">
          <h3>
            Thank you for the referral! your referral amount will be reached
            after completion of ather process
          </h3>
          <Button
            onClick={() => {
              setsucessfull(false);
            }}
            variant="contained"
          >
            ok
          </Button>
        </div>
      </div>

      <div className="Refere-container">
        <div className="Refere-img-contant">
          <div className="Refere-left-img-container">
            <h1>
              Unlock Rewards: Refer and Earn Your Way to Exciting Benefits!
            </h1>
          </div>
          <div className="Refere-right-img-contanier">
            <img src={img} alt="referralImg" />
          </div>
        </div>

        <div className="Refere-type-content">
          <ul>
            {refertypes &&
              refertypes.map((referel) => (
                <li key={referel.typeid}>
                  <h1>{referel.type}</h1>
                  <p>
                    <i className="fa-solid fa-indian-rupee-sign"></i>
                    {referel.typePrices} /-
                  </p>
                </li>
              ))}
          </ul>
        </div>
        <div className="Refere-form-conatent">
          <form onSubmit={handleFormSubmit}>
            <h4>Referral Student Details</h4>
            <div className="Refere-form-conatent">
              <TextField
                label="Referral student Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChanges}
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
                sx={{ mt: 1, mb: 1 }}
              />
            </div>
            <div className="Refere-form-conatent">
              <TextField
                label="Referral student Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChanges}
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email}
                sx={{ mt: 1, mb: 1, color: "red" }}
              />
            </div>{" "}
            <div className="Refere-form-conatent">
              <TextField
                label="Referral student Mobile Number"
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChanges}
                variant="outlined"
                error={!!errors.mobileNumber}
                helperText={errors.mobileNumber}
                sx={{ mt: 1, mb: 1 }}
              />
            </div>{" "}
            <div className="Refere-form-conatent">
              <label id="course-label">Course Type</label>
              <Select
                labelId="courseType-label"
                label="xs"
                name="courseType"
                value={formData.courseType}
                color="secondary"
                onChange={handleChanges}
                variant="outlined"
                error={!!errors.courseType}
                sx={{ mt: 1, mb: 1 }}
              >
                <MenuItem value={""}>select</MenuItem>
                {refertypes.map((referel) => (
                  <MenuItem key={referel.typeid} value={referel.type}>
                    {referel.type}
                  </MenuItem>
                ))}
              </Select>
            </div>{" "}
            <div className="Refere-form-conatent">
              <label id="course-label">Course</label>
              <Select
                labelId="course-label"
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChanges}
                variant="outlined"
                error={!!errors.course}
                sx={{ mt: 1, mb: 1 }}
              >
                <MenuItem value={""}>select</MenuItem>
                {courses.map((course) => (
                  <MenuItem key={course} value={course}>
                    {course}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Button className="button" variant="contained" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Refere;
