import React from "react";
import "./FastForworYourCareerWithUs.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const FastForworYourCareerWithUs = () => {
  const navigate = useNavigate();
  return (
    <div className="FastForworYourCareerWithUs-main-container">
      <div className="FastForworYourCareerWithUs-container">
        <div className="FastForworYourCareerWithUs-left-contnat">
          <i class="fa-solid fa-dove"></i>
          <p>
            JUZZ-IT boasts the finest online curriculum, offering an engaging,
            comprehensive, and student-centered program. For those genuinely
            passionate about Advanced Engineering Program, JUZZ-IT stands out as
            the ultimate platform, providing unparalleled quality.
          </p>
        </div>
        <div className="FastForworYourCareerWithUs-right-contontent">
          <div className="FastForworYourCareerWithUs-right-contant">
            <h1>Fast forword your career in tech with JUZZ-IT</h1>
            <Button
              className="button"
              variant="contained"
              onClick={() => {
                navigate("/Cources");
              }}
            >
              EXPLORE COURSES
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FastForworYourCareerWithUs;
