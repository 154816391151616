import React, { useEffect, useState } from "react";
import "./AllClassVideos.scss";
import { setemrolledRecodedCourseModuleVideos } from "../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import { getLessonTopicVides } from "../../../../../../../Redux/Actions/MyCourseActions";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ListOfAllClassVideos from "./components/ListOfAllClassVideos";
import Loading from "../../../../../../../LoadingComponent/Loading";
const AllClassVideos = () => {
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const { courseId, studentBatchId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const emrolledRecodedCourseModuleVideos = useSelector(
    (state) => state.mycourseData.emrolledRecodedCourseModuleVideos
  );

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getLessonTopicVides(moduleId);
      dispatch(setemrolledRecodedCourseModuleVideos(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, moduleId]);

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="AllClassVideos-main-container">
      <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/sessionLinks`}
              className={"link"}
            >
              {" "}
              session links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="AllClassVideos-container">
        <ListOfAllClassVideos videoData={emrolledRecodedCourseModuleVideos} />
      </div>
    </div>
  );
};

export default AllClassVideos;
