import React, { useState, useEffect } from "react";
import "./ScrollToTopButton.scss";
const ScrollToTopButton = () => {
  const [isShow, setIsShow] = useState(false);

  const scrollButtonHandler = () => {
    const scroll = window.scrollY;

    if (scroll > 200) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollButtonHandler);
  }, []);

  const scrollhandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`ScrollToTopButton-main-container  ${isShow ? "active" : ""}`}
      onClick={scrollhandler}
    >
      <i class="fa-solid fa-arrow-up"></i>
    </div>
  );
};

export default ScrollToTopButton;
