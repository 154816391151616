import React, { useEffect, useState } from "react";
import "./BlogDetails.scss";
import PageHeader from "../../PageHeader";
import {
  addCommentsToBlog,
  getAllBlogComments,
  getBlogDetailsByBlogId,
} from "../../../../Redux/Actions/BlogActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setblogComments,
  setblogDetails,
} from "../../../../Redux/RedusersCompo/BlogReducer";
import img from "../../../../img/blogDetailImage.png";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import { getStudentDataByToken } from "../../../../Redux/Actions/AuthAction";
import { setStudentDataToStore } from "../../../../Redux/RedusersCompo/AuthReducer";

const BlogDetails = () => {
  const { blogId } = useParams();
  const dispatch = useDispatch();
  const blogDetails = useSelector((state) => state.blogData.blogDetails);
  const blogComments = useSelector((state) => state.blogData.blogComments);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [comment, setComment] = useState("");
  const [notLogedIn, setNotLoggedIn] = useState(false);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    const getData = async () => {
      const data = await getBlogDetailsByBlogId(blogId);
      dispatch(setblogDetails(data));
    };
    getData();
  }, [dispatch, blogId]);

  // const userData = localStorage.getItem("userData");
  // let user = {};
  // if (userData) {
  //   user = JSON.parse(userData);
  // }

  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllBlogComments(blogId);
      dispatch(setblogComments(data));
    };
    getData();
  }, [dispatch, blogId]);

  let image = {};

  if (blogDetails) {
    const blogImagdATA = blogDetails[0]?.blog_Image;

    if (blogImagdATA) {
      image = blogImagdATA.filter((img) => {
        return img.active_Status === "A";
      })[0];
    }
  }

  const contants = () => {
    return (
      <>
        <h1>{blogDetails && blogDetails[0]?.blog_heading}</h1>
      </>
    );
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const postComment = async (event) => {
    event.preventDefault();
    setEmailError("");
    if (!userName || !userEmail || !comment) {
      return;
    }
    if (!isEmailValid(userEmail)) {
      setEmailError("Invalid email address");
      return;
    }
    const commentData = {
      user_Name: userName,
      user_Email: userEmail,
      comment: comment,
    };

    if (userDetails && userDetails.id) {
      const responce = await addCommentsToBlog(
        userDetails?.id,
        blogId,
        commentData
      );

      if (responce?.status === true) {
        const data = await getAllBlogComments(blogId);
        dispatch(setblogComments(data));
      }
    } else {
      setNotLoggedIn(true);
    }
  };

  let BlogDetailsData = [];

  if (
    blogDetails &&
    blogDetails[0]?.blog_Details &&
    blogDetails[0]?.blog_Details.length > 0
  ) {
    BlogDetailsData = [...blogDetails[0]?.blog_Details].sort(
      (a, b) => a.BLOG_DETAILS_ORDER - b.BLOG_DETAILS_ORDER
    );
  }

  return (
    <div className="BlogDetails-main-container">
      <div
        className={`notLogged-in-main-request-Submited ${
          notLogedIn ? "logInerrorSubmited" : ""
        }`}
      >
        <div className="notLogged-in-request-Submited" data-aos="zoom-in">
          <h1>please login to continue</h1>
          <Button
            variant="contained"
            onClick={() => {
              setNotLoggedIn(false);
            }}
          >
            ok
          </Button>
        </div>
      </div>

      <PageHeader
        content={contants()}
        heading={blogDetails && blogDetails[0]?.blog_heading}
        url={img}
      />

      <div className="BlogDetails-main-content-container">
        <div className="BlogDetails-main-content">
          <div className="BlogDetails-img-content">
            <img src={image?.image_URL} alt="ssxa"></img>
          </div>

          <div className="blog_details">
            <div className="BlogDetails-content">
              <h1>Intruduction</h1>
              <p>{blogDetails && blogDetails[0]?.blog_Intruduction}</p>
            </div>
            {BlogDetailsData.map((val, key) => {
              return (
                <div className="BlogDetails-content" key={key}>
                  <h1>{val.BLOG_DETAILS_HEADING}</h1>
                  <p>{val.BLOG_DETAILS_DESC}</p>
                </div>
              );
            })}
          </div>
          <div className="blog-comment-MAIN-details">
            <h1 className="h1-commemt">Comment</h1>
            <div className="blog-comment-details">
              {blogComments &&
                blogComments?.map((val, key) => {
                  if (val?.student_Data?.STUDENT_ID === userDetails?.id) {
                    return (
                      <div className="right-blog-comment" key={key}>
                        <h1>{val.student_Data.STUDENT_NAME}</h1>
                        <p>{val.blog_Comment}</p>
                      </div>
                    );
                  }
                  return (
                    <div className="left-blog-comment" key={key}>
                      <h1>{val.student_Data.STUDENT_NAME}</h1>
                      <p>{val.blog_Comment}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="BlogDetails-form-main-content">
            <h1>Leave a Comment</h1>

            <div className="BlogDetails-form-content">
              <form onSubmit={postComment}>
                <div className="form-top-content">
                  <div></div>
                  <div className="form-top-content-elemant">
                    <TextField
                      value={userName}
                      variant="outlined"
                      label="NAME"
                      onChange={(e) => setUserName(e.target.value)}
                      type="text"
                      className="input"
                      required // Add required attribute for validation
                    />
                  </div>
                  <div className="form-top-content-elemant">
                    <TextField
                      value={userEmail}
                      label="EMAIL"
                      onChange={(e) => setUserEmail(e.target.value)}
                      type="text"
                      variant="outlined"
                      className="input"
                      required // Add required attribute for validation
                      error={!!emailError}
                      helperText={emailError}
                    />
                  </div>
                </div>
                <div className="form-center-content">
                  <TextareaAutosize
                    value={comment}
                    placeholder="COMMENT"
                    onChange={(e) => setComment(e.target.value)}
                    type="text"
                    className="textarea"
                    minRows={10}
                    maxRows={15}
                    style={{
                      width: "98%",
                      padding: "8px",
                      fontSize: "16px",
                    }}
                    required // Add required attribute for validation
                  />
                </div>
                <Button type="submit" variant="contained" color="primary">
                  Post
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
