import React, { useState } from "react";
import "./ListOfAllClassVideos.scss";
// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Typography,
// } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useParams } from "react-router-dom";
const ListOfAllClassVideos = ({ videoData }) => {
  const { courseId, batchId, studentBatchId, moduleId } = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="ListOfAllClassVideos-main-container">
      <div className="accordion">
        {videoData &&
          videoData.map((val, index) => {
            if (val.active_lesson === "D") {
              return null;
            }
            return (
              <div
                key={index}
                className={`accordion-item ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <div
                  className="accordion-header"
                  onClick={() => toggleAccordion(index)}
                >
                  {val.lesson_Completion_percentage?.completed_percentage >
                  90 ? (
                    <i class="fas fa-check-square"></i>
                  ) : (
                    <i class="fa-regular fa-square"></i>
                  )}
                  {"   "} {index + 1}
                  {".   "}
                  {val.lesson_Tites}
                </div>
                {activeIndex === index && (
                  <div className="accordion-content">
                    <ul>
                      {val.lesson_topic &&
                        val.lesson_topic?.map((val1, key1) => {
                          if (val1.active_Topic === "D") {
                            return null;
                          }
                          return (
                            <li
                              onClick={() =>
                                navigate(
                                  `/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classes/module/${moduleId}/topic/${val1.topic_id}`
                                )
                              }
                            >
                              {" "}
                              {<i class="fa-regular fa-hand-point-right"></i>}
                              {val1.topic_completion_percentage
                                ?.Topic_Completion_Percentage > 90 ? (
                                <i class="fas fa-check-square"></i>
                              ) : (
                                <i class="fa-regular fa-square"></i>
                              )}
                              {key1 + 1}
                              {". "}
                              {val1.topic_title}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {/* {videoData &&
        videoData?.map((val, key) => {
          return (
            <Accordion key={key} className="Accordion">
              <AccordionSummary
                id={`panel${key}-header`}
                aria-controls={`panel${key}-content`}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h6" className="Typography-head">
                  {val.lesson_Completion_percentage?.completed_percentage >
                  90 ? (
                    <i class="fas fa-check-square"></i>
                  ) : (
                    <i class="fa-regular fa-square"></i>
                  )}
                  {"   "} {key + 1}
                  {".   "}
                 
                  {val.lesson_Tites}
                </Typography>
              </AccordionSummary>

              {val.lesson_topic &&
                val.lesson_topic?.map((val1, key1) => {
                  return (
                    <AccordionDetails
                      key={key1}
                      //   onClick={() => setSingleVideoData(val1)}
                    >
                      <span
                        onClick={() =>
                          navigate(
                            `/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classes/module/${moduleId}/topic/${val1.topic_id}`
                          )
                        }
                      >
                        <Typography variant="h6" className="Typography-desc1">
                          {val1.topic_completion_percentage
                            ?.Topic_Completion_Percentage > 90 ? (
                            <i class="fas fa-check-square"></i>
                          ) : (
                            <i class="fa-regular fa-square"></i>
                          )}
                          {key1 + 1}
                          {". "}
                          {val1.topic_title}
                        </Typography>
                      </span>
                    </AccordionDetails>
                  );
                })}
            </Accordion>
          );
        })} */}
    </div>
  );
};

export default ListOfAllClassVideos;
