import React from "react";
import "./SingleCourseCompo.scss";
import { NavLink } from "react-router-dom";
const SingleCourseCompo = (props) => {
  const imageData = props.img;

  const image = imageData?.filter((image) => {
    return image.active_Status === "A";
  })[0];

  return (
    <div className="SingleCourseCompo-main-container">
      <NavLink
        to={`${props.courseId}/${props.batchId}/${props.studentBatchId}/details`}
        className={"nav-link"}
      >
        <img src={image?.image_URL} alt="courimage"></img>
        <h2>{props.heading}</h2>
        <div className="percentage-of-completion">
          <p>{props.percentage?.completed_percentage}%</p>
          <div className="percentage-main-strip">
            <div
              style={{ width: `${props?.percentage?.completed_percentage}` }}
              className="percentage-strip"
            ></div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default SingleCourseCompo;
