import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cource from "../CourcesComponents/AllCourcesComponent/Components/Cource";
import { getAllCourseAndCourseType } from "../../../Redux/Actions/CourcesAction";
import { addAllCourses } from "../../../Redux/RedusersCompo/CourcesReducer";
import Loading from "../../../LoadingComponent/Loading";
import "./Internships.scss";
const Internships = () => {
  const courses = useSelector((store) => store.courseData.allCourse);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getAllCourseAndCourseType();
      dispatch(addAllCourses(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="Internships-main-container">
      <div className="Internships-container">
        <h1>Internships</h1>
        <div className="Internships-contents">
          {courses &&
            courses?.map((course, key) => {
              const courseName = course.course_name;
              if (course.course_active === "A") {
                return (
                  <>
                    {course.courseType?.map((courseType, key) => {
                      const heading = `${courseType.course_typeName} Certification in  ${courseName}`;
                      if (
                        courseType.active_courseType === "A" &&
                        courseType.course_typeName === "Internship"
                      ) {
                        return (
                          <Cource
                            heading={heading}
                            courseTypeLevel={courseType.course_level}
                            courseTypeId={courseType.course_typeId}
                            courseTypeImage={courseType.courseTypeImage}
                            imageURL="https://juzzit.in/wp-content/uploads/2022/01/Html-system-for-website-concept.png"
                            key={key}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default Internships;
