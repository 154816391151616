import React from "react";
import "./JobList.scss";
import SingleJobList from "./Components/SingleJobList";
const JobList = ({ data }) => {
  // const [count,setCount]=useState(2);
  return (
    <div className="JobList-main-container">
      <div className="JobList-container">
        {data &&
          data?.map((val, key) => {
            return val.jobData?.map((val1, key) => {
              return <SingleJobList jobData={val1} />;
            });
          })}

        <SingleJobList />
        <SingleJobList />
      </div>
    </div>
  );
};

export default JobList;
