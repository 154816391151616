import React from "react";
import "./TermsConditions.scss";
const TermsConditions = () => {
  return (
    <div className="TermsConditions-main-container">
      <div className="TermsConditions-container">
        <h3>Introduction</h3>
        <p>
          These Terms & Conditions (“Terms” or “ToU”) are between Juzz-IT (along
          with its successors, subsidiaries, permitted assignees, management,
          support/sales team, employees, consultants and all persons claiming
          through them and under them and referred to as the “Company”,
          JUZZ-IT”, “us”, “we”, or “our”) and its Users and/or its Minor Users
          (referred to as “you” or “yourself”). These Terms define the
          relationship between you and Juzz-IT in accessing our Services via our
          Platform and/or at our Offline Centres. By clicking on the “Agree”
          button you (on behalf of yourself and/or a Minor User) agree to these
          Terms which will bind you and/or the Minor User. If you do not agree
          to these Terms you and/or the Minor User must cease to continue to
          purchase any Services from us. These Terms apply to all Users and/or
          Minor Users of our Services including on our Website, and without
          limitation also apply to Users and/or Minor users who are browsers,
          vendors, and/or Users, and our employees and consultants. The
          expression “YOU” shall mean the Users/beneficiaries and/or Minor Users
          of our Services, including, without limitation all persons including
          our employees and consultants who create and/or make available Course
          / Course Material on the Platform and users who access the Platform to
          purchase any Service or users who access the Platform otherwise such
          as the users who are browsers, vendors, Users, and/or Minor Users, and
          our employees and consultants. Any new feature or tool which is added
          to the current Website or Services shall also be subject to these
        </p>
        <h3>Terms.</h3>
        <p>
          We also provide details regarding our processing of the personal data
          of our Users and/ or Minor Users, and employees and consultants in our
          Privacy Policy. In all cases, you (on behalf of yourself and/or a
          Minor User) agree that no separate act is required to make the Privacy
          Policy, the Placement Policy, or the Terms binding on you and/or a
          Minor User and that your and/or a Minor User’s act of downloading,
          registering, accessing, purchasing or using any part of the Website or
          Services constitutes your (on behalf of yourself and/or a Minor
          User’s) full and final acceptance of the Privacy Policy, the Placement
          Policy, and the Terms. You (on behalf of yourself and/or a Minor User)
          agree and acknowledge to be bound by, and liable under, the Privacy
          Policy, the Placement Policy, and the Terms. If you (on behalf of
          yourself and/or a Minor User) do not agree with the Privacy Policy,
          the Placement Policy, and/or the Terms, please do not use the
          Website/Services.
        </p>
        <p>
          These Terms constitute an electronic record in accordance with the
          provisions of the Information Technology Act, 2000 and the Information
          Technology (Intermediaries Guidelines and Digital Media Ethics Code)
          Rules, 2021 thereunder, as amended from time to time. Please read the
          Terms, Placement Policy, and Privacy Policy carefully before using,
          registering, or accessing our Website and Services. In the event of
          any discrepancy between the Terms and any other policies with respect
          to our Website or Services, the provisions of these Terms shall
          prevail.
        </p>
        <h3>Definitions</h3>
        <p>
          In these Terms, the following words and expressions, unless
          inconsistent with the context, shall bear the meanings assigned
          hereto: Confidential Information – means all information provided by
          one party to the other in written, graphic, recorded,
          machine-readable, or another form concerning the business, clients,
          suppliers, finances and other areas of the other party’s business or
          products, including, without limitation, the Course Materials, except
          information that is publicly known through no wrongful act or omission
          of the User and/or the Minor User or any information already in the
          possession or control of the disclosing party.
        </p>
        <p>
          <span>Course –</span> means our programs are offered remotely through
          either our Platform, pre-recorded multimedia, and audio-visual
          content, live-streamed classes on third-party applications, and/or our
          programs offered physically at our Offline Centres.
        </p>
        <p>
          <span>Course Material – </span>means the information provided by
          Juzz-IT to accompany a Course as part of the Services which is
          available on our Platform and includes assessment tests and
          examinations.
        </p>
        <p>
          <span>Intellectual Property Rights –</span> means copyright, rights in
          or relating to databases, patent rights, performers’ rights, designs,
          registered designs, trademarks, rights in or relating to Confidential
          Information, and other intellectual property rights (registered or
          unregistered) throughout the world.
        </p>
        <p>
          <span>Job Assistance Program –</span> means the Service described in
          Clause 13(b) of these Terms. Legal Actions and Notices – means any
          notice or other communication to be given hereunder will be in writing
          and given by registered post only.
        </p>
        <p>
          <span>Minor User – </span>means a User of our Services, who is a child
          or a minor (i.e. a natural person below the age of 18 (eighteen)
          years).
        </p>
        <p>
          <span>Offline Centre –</span> means physical premises operated by
          Juzz-IT to deliver offline classes.
        </p>
        <p>
          <span>Placement Policy – </span>means Juzz-IT’s placement policy as
          set out in Clause 13 of these Terms.
        </p>
        <p>
          <span>Platform –</span> means the Website and any other feature or
          tool provided and operated by Juzz-IT, including any application that
          may be launched by Juzz-IT.
        </p>
        <p>
          <span>Pricing and Promotional Policy –</span> means the policy
          governing the pricing of and promotions related to the Courses offered
          on the Platform.
        </p>
        <p>
          <span>Privacy Policy – </span>means Juzz-IT’s privacy policy available
          at [https://juzzit/privacy].
        </p>
        <p>
          <span>Services – </span>means the provision of the Course and/or the
          Course Material together with such other services as agreed from time
          to time and purchased by you (on behalf of yourself and/or the Minor
          User) and availed remotely through either our Platform, pre-recorded
          multimedia and audio-visual content, live-streamed classes on third
          party application and/or physically at our Offline Centres.
        </p>
        <p>
          <span>Support System –</span> refers to the different processes that
          Juzz-IT follows to resolve User and/or Minor User queries and includes
          but are not limited to one-on-one and group doubt-clearing sessions
          through phone calls, e-mail, third-party text, audio, and video
          communication applications, or physical at our Offline Centres.
        </p>
        <p>
          <span>Terms – </span>means these Terms and Conditions of Juzz-IT.
        </p>
        <p>
          <span>User (or “you”, “your”, “yourself”) –</span> refers to any
          individual who is a natural person aged 18 (eighteen) years or above,
          and who is competent and capable of contracting within the meaning of
          the Indian Contract Act, 1872 and accesses our Website and/or
          purchases the Services. Such an individual, where applicable, includes
          the parent/legal guardian or any other authorized individual who
          facilitates the use of the Services for a Minor User.
        </p>
        <p>
          <span>Website –</span> means http://www.juzzit.in/
        </p>
        <h3>General Terms and Conditions</h3>
        <h4>Course Enrolment</h4>
        <p>
          When you and/or a Minor User enroll in our Course, you and/or the
          Minor User get a license from us to view/access it, depending on the
          specific program via the Platform, through live-streamed classes on
          third-party applications and/or physically at our Offline Centres and
          for no other use. You and/or a Minor User are hereby expressly barred
          from transferring or reselling Courses in any way.
        </p>
        <p>
          Our Course Material is only licensed to you and/or a Minor User and is
          not sold to you and/or a Minor User. The license granted to you does
          not give you and/or the Minor User any right to transfer or resell the
          Course Material in any manner (including by sharing account
          information with a purchaser or downloading the Course Material and
          sharing it on third-party sources).
        </p>
        <p>
          Juzz-IT grants you as a User and/or a Minor User, a limited,
          non-exclusive, non-transferable license to access and view the Course
          Material and associated content for which you (on behalf of yourself
          and/or the Minor User) have paid all required fees, solely for your
          personal, non-commercial, educational purposes through the Services,
          in accordance with these Terms and any conditions or restrictions
          associated with a particular Course or feature of our Services.
        </p>
        <p>
          All other uses are expressly prohibited. You and/or the Minor User may
          not reproduce, redistribute, transmit, assign, sell, broadcast, rent,
          share, lend, modify, adapt, edit, create derivative works of,
          sub-license, or otherwise transfer or use any Course Material unless
          we give you (for yourself or on behalf of a Minor User) explicit
          permission to do so in a written agreement signed by a Juzz-IT’s
          authorized representative.
        </p>
        <p>
          The Course Material may be accessed by you and/or the Minor User
          through our Platform only irrespective of the Course being delivered
          remotely or physically. We reserve our right to revoke any license to
          access and use Course Material at any point in time, in the event that
          we decide or are obligated to disable access to a Course due to legal
          or policy reasons, for example, if the Course you and/or the Minor
          User enrolled in is the object of a copyright complaint, or if we
          determine its content violates our Terms and policies. The lifetime
          access is not applicable to add-on features and Services associated
          with a Course, for example, translation captions of Courses may be
          disabled by Juzz-IT at any time, and Juzz-IT may decide at any time to
          no longer provide Support System in association with a Course. To be
          clear, the lifetime access is only to the Course content and not to
          add-on features and Services associated with a Course.
        </p>
        <h3>Accounts</h3>
        <p>
          You and/or a Minor User will need an account to purchase and enroll in
          a Course. You (on behalf of yourself or the Minor User) shall be
          solely responsible for all activity associated with your and/or the
          Minor User’s account either done by yourself, the Minor User, or
          someone using your and/or the Minor User’s account without your
          permission. If you suspect someone else is using your and/or the Minor
          User’s account, it is your duty (on behalf of yourself or the Minor
          User) to inform us by contacting our support team at
          education@juzzit.in. We may request some information from you (on
          behalf of yourself and/or the Minor User) to confirm that you and/or a
          Minor User are indeed the owner of your account.
        </p>
        <p>
          When registering, setting up, and maintaining your account or that of
          a Minor User, you agree and acknowledge to provide and continue to
          provide accurate and complete information, including a valid email
          address and the Company does not have the responsibility to ensure or
          verify that you and/or the Minor User conform to eligibility criteria,
          wherever applicable. You (on behalf of yourself and/or the Minor User)
          shall not transfer or assign your and/or the Minor User’s account to
          someone else or use someone else’s account without their permission.
          If you (on behalf of yourself and/or the Minor User) contact us to
          request access to an account, we will not grant you such access unless
          you can provide us with the login credential information for that
          account. In the event of the death of a User and/or the Minor User,
          the account of that User and/or a Minor User will be closed.
        </p>
        <h3>Important Note to Minor Users</h3>
        <p>
          Every Minor User should seek the prior consent and agreement of their
          parents/legal guardians to these Terms, the Placement Policy, and the
          Privacy Policy before providing any information about themselves,
          their parents, and/or other family members and before accessing or
          using the Platform/Website/Services. If you as a Minor User, are using
          our Services without a parent or legal guardian’s consent, please
          immediately stop using our Services until one of your parents or legal
          guardians has approved and provided their informed consent to your
          usage of our Services in accordance with the Terms, the Placement
          Policy and the Privacy Policy.
        </p>
        <h3>Important note to parents and legal guardians</h3>
        <p>
          If you have the ability to enter into a legally binding contract under
          applicable laws and you register, purchase, approve, and/or consent to
          the usage of the Services by a Minor User or facilitate the use of our
          Services for a Minor User, you represent and warrant that you are such
          Minor User’s parent or legal guardian, and you agree to be bound by
          these Terms, the Placement Policy and the Privacy Policy on behalf of
          such Minor User, including without limitation, being liable for all
          use of the Services by the Minor User and that no separate act is
          required to make these Terms, the Placement Policy and the Privacy
          Policy binding on you, and that you and/or the Minor User’s act of
          downloading, registering, purchasing, and/or using the Services
          constitutes your complete acceptance of these Terms, the Placement
          Policy and the Privacy Policy, on behalf of such Minor User.
        </p>
        <p>
          You agree and acknowledge that the use of the Services by a Minor User
          will be undertaken pursuant to your consent and knowledge and under
          your supervision. In other words, wherever applicable, in the event a
          Minor User utilizes the Services, it is assumed that such Minor User
          has obtained your consent, i.e. the consent of their parent/legal
          guardian.
        </p>
        <p>
          Further, you hereby covenant that you will cause the Minor User to use
          the Services strictly in accordance with these Terms, Placement
          Policy, and Privacy Policy, and all terms contained herein shall be
          enforceable against you and you shall be responsible for all losses or
          liabilities incurred by the Company due to any breach of these Terms,
          Placement Policy, and Privacy Policy by the Minor User. You hereby
          acknowledge that the Company may choose, but is not obligated, to make
          any inquiries, either directly or through third parties, that the
          Company deems necessary to validate your information and status as a
          parent or a legal guardian unless required under applicable laws.
        </p>
        <p>
          NOTWITHSTANDING THE FOREGOING, YOU ACKNOWLEDGE THAT THE COMPANY CAN
          NOT GUARANTEE THE ACCURACY OF ANY INFORMATION SUBMITTED BY ANY USER
          (INCLUDING A MINOR USER) AND THE COMPANY IS NOT OBLIGATED TO VERIFY
          THE IDENTITY OF ANY USER, INCLUDING ANY USER’S CLAIM TO BE A PARENT OR
          A LEGAL GUARDIAN, OR TO VERIFY THAT A PARENT OR THE LEGAL GUARDIAN
          IDENTIFIED BY A MINOR USER DURING REGISTRATION OR USAGE IS SUCH MINOR
          USER’S ACTUAL PARENT OR LEGAL GUARDIAN. A USER ACCOUNT, ALONG WITH ALL
          ASSOCIATED MINOR USER ACCOUNTS, IF ANY, MAY BE TERMINATED BY THE
          COMPANY AT ANY TIME AND WITHOUT WARNING FOR ANY FAILURE TO ABIDE BY
          ANY OF THESE TERMS.
        </p>
        <p>
          You and/or a Minor User can terminate your account at any time after
          due intimation to our support team and by following the procedures as
          indicated by our support team. Please check our Privacy Policy
          [https://juzzit.in/privacy] to see what happens when you terminate
          your and/or the Minor User’s account.
        </p>
        <h3>Payments</h3>
        <p>
          Course pricing and related information is listed on the Website and/or
          other third-party platforms for each Course and is subject to changes
          without notice. The prices of Courses on Juzz-IT are determined based
          on our Pricing and Promotional Policy. If you and/or a Minor User are
          logged into your and/or the Minor User’s account, the listed currency
          you and/or a Minor User see is based on your and/or a Minor User’s
          location when you (on behalf of yourself and/or the Minor User)
          created your and/or the Minor User’s account. If you and/or the Minor
          User are not logged into your and/or the Minor User’s account, the
          price currency is based on the country where you and/or a Minor User
          are located. We do not enable Users and/or Minor Users to see pricing
          in other currencies. If you are a User and/or Minor User located in a
          country where use and sales tax, goods and services tax, or
          value-added tax is applicable to consumer sales, we are responsible
          for collecting and remitting that tax to the proper tax authorities.
        </p>
        <p>
          When you (on behalf of yourself and/or a Minor User) make a purchase,
          you agree not to use an invalid or unauthorized payment method. If
          your payment method fails and you and/or the Minor User still get
          access to the Course you and/or the Minor User are enrolling in, you
          (on behalf of yourself and/or a Minor User) agree to pay us the
          corresponding fees within 30 (thirty) days of notification from us. We
          reserve the right to disable access to any of our Services including
          any Course for which we have not received adequate payments. We use
          third-party payment gateways and/or aggregators to process payments
          applicable to the Services including the Courses offered by us.
          Similarly, we have also enabled the integration of third-party payment
          providers including without limitation the third-party credit facility
          providers to facilitate better payment options to you (on behalf of
          yourself and/or a Minor User), which may vary depending on your and/or
          the Minor User’s location. Third-party payment gateways/aggregators,
          third-party credit facility providers, digital lending apps/platforms,
          lending service providers, financial services intermediaries, or any
          other third-party facilitating financing and/or payment-related
          services shall collectively be referred to as “Third-Party Service
          Providers”.
        </p>
        <p>
          Third-Party Service Providers may also charge you (on behalf of
          yourself and/or a Minor User) fees to use or access their services and
          may require your and/or a Minor User’s Personal Information (as
          defined in the Privacy Policy) to complete any transaction on the
          Platform and/or at our Offline Centres. Further, to facilitate
          completion of your (on behalf of yourself and/or a Minor User)
          payments to us through the Platform or avail the payment options
          provided to you, you may be redirected to an external website operated
          by the Third-Party Service Provider. We cannot and do not (i)
          guarantee the adequacy of the privacy and security practices employed
          by or the content and media provided by the Third-Party Service
          Provider or its respective websites;
        </p>
        <p>or</p>
        <p>
          (ii) control collection or use of your and/or a Minor User’s Personal
          Information (as defined in the Privacy Policy) by such Third-Party
          Service Provider.
          <br />
          Hence, prior to using any services offered by a Third-Party Service
          Provider, we suggest that you (on behalf of yourself and/or a Minor
          User) read their terms and conditions, privacy policy, and other
          policies, that may apply, to understand their terms of usage and to
          understand how your and/or a Minor User’s Personal Information (as
          defined in the Privacy Policy) is being processed. Juzz-IT is not
          affiliated to any Third-Party Service Provider and neither Juzz-IT nor
          any of the Third-Party Service Provider are agents or employees of the
          other.
        </p>
        <p>
          Further, pursuant to the payment option you (on behalf of yourself
          and/or a Minor User) may choose, you (on behalf of yourself and/or a
          Minor User) may be required to enter into a separate agreement with
          the relevant Third-Party Service Provider. The agreement with the
          Third-Party Service Provider is an independent contract or agreement
          between you (on behalf of yourself and/or a Minor User) and such
          Third-Party Service Provider and Juzz-IT is not privy to any agreement
          or arrangement you (on behalf of yourself and/or a Minor User) may
          enter into with them. You (on behalf of yourself and/or a Minor User)
          will be bound by the terms and conditions of your agreement or
          arrangement with such Third￾Party Service Provider and Juzz-IT will
          not in any way be liable or responsible for or in relation to any such
          arrangement. Juzz-IT is only facilitating various payment options to
          you (on behalf of yourself and/or a Minor User) and is not offering
          any payment or loan by itself in any manner.
        </p>
        <p>
          You (on behalf of yourself and/or a Minor User) agree that you are
          solely responsible for all charges that occur through such Third-Party
          Service Providers and acknowledge and agree to indemnify, defend, and
          hold harmless Juzz-IT, its affiliates, their respective officers,
          directors, employees, and agents from any loss arising out of or
          related to the use of the Platform or any purchases made through the
          Platform.
        </p>
        <p>
          This obligation will survive your and/or a Minor User’s use of our
          Services and termination of your Agreement with us. For purposes of
          these Terms, “Loss” means all losses, liabilities, damages, awards,
          settlements, claims, suits, proceedings, costs, and expenses
          (including reasonable legal fees and disbursements and costs of
          investigation, litigation, settlement, judgment, interest, and
          penalties). Juzz￾IT shall not be liable to you and/or a Minor User for
          any claims arising out of any act or omission on the part of the
          Third-Party Service Provider(s) including, but not limited to, any
          lost, stolen, or incorrectly processed payments. Juzz-IT expressly
          disclaims any responsibility and liability for all services provided
          by the Third-Party Service Provider(s).
        </p>
        <h3>Refund Policy</h3>
        <p>
          All Course purchases are NON-REFUNDABLE and Juzz-IT shall not be
          obligated to entertain or accept any refund requests for any kind of
          payments made (including, whether pre-booking, installments, or full
          payments).
          <br />
          You and/or the Minor User shall only use Juzz-IT for lawful purposes.
          We reserve the right to disable, deactivate, suspend, and/or ban your
          account and/or access to any Course, Platform, and/or the Services in
          the event you violate applicable local or national laws or regulations
          or are in repeated or serious violations or breach of these Terms,
          Privacy Policy and Placement Policy. As mandated by the Information
          Technology (Intermediary Guidelines and Digital Media Ethics Code)
          Rules, 2021 and to the extent it is applicable to these Terms and the
          Privacy Policy, Juzz-IT hereby informs you and/or the Minor User that,
          in the use of the Services, you and/or the Minor User are not
          permitted to host, display, upload, send, modify, publish, transmit,
          update or share any information, that:
        </p>
        <p>belongs to another person and to which you do not have any right;</p>
        <p>
          is obscene, pornographic, paedophilic, libellous, invasive of
          another’s privacy, including bodily privacy, hateful, harassing on the
          basis of gender, racial or ethnically objectionable, relating to or
          encouraging money laundering or gambling, or promoting enmity between
          different groups on the grounds of religion or caste with the intent
          to incite violence;
        </p>
        <p>is harmful to child</p>
        <p>
          infringes any patent, trademark, copyright or other proprietary
          rights;
        </p>
        <p>
          deceives or misleads the addressee about the origin of the message or
          knowingly and intentionally communicates any misinformation or
          information which is patently false and untrue or misleading in
          nature;
        </p>
        <p>impersonates another person;</p>
        <p>
          contains software viruses, or any other computer code, files or
          program designed to interrupt, destroy or limit the functionality of
          any computer resource;
        </p>
        <p>violates any law for the time being in force.</p>
        <p>
          Juzz-IT has discretion in enforcing these Terms. We may terminate or
          suspend your and/or the Minor User’s permission to use our Platform
          and Services or ban your account at any time, with or without notice,
          for any violation of these Terms, if you (for yourself and/or the
          Minor User) fail to pay any fees when due, for extended periods of
          inactivity, for unexpected technical issues or problems, or if we
          suspect that you and/or the Minor User engage in fraudulent or illegal
          activities. Upon any such termination, we may delete your and/or the
          Minor User’s account and content, and we may prevent you and/or the
          Minor User from further access to the Platforms and use of our
          Services. Your and/or the Minor User’s content may still be available
          on the Platforms even if your and/or the Minor User’s account is
          terminated or suspended. You (on behalf of yourself and/or the Minor
          User) agree that we will have no liability to you, to the Minor User
          or any third party for termination of your and/or the Minor User’s
          account, removal of your and/or the Minor User’s content, or blocking
          of your and/or the Minor User’s access to our Platforms and Services.
        </p>
        <h3>Support System</h3>
        <p>
          The Support System provided by Juzz-IT should be strictly used only
          for getting help with the materials discussed in the Course. The
          Support System shall not be provided for side projects or assignments
          that the User and/or the Minor User might work on in their individual
          capacity. The duration of the support will depend on the plan that you
          (on behalf of yourself and/or the Minor User) have purchased. If you
          (on behalf of yourself and/or the Minor User) miss to make payments
          then access to the Support System will be cut off. If the User and/or
          Minor User is caught sharing videos, notes, programs, and other
          digital content that is owned by Juzz-IT then appropriate legal
          actions would be taken and the User (on behalf of themselves and/or
          the Minor User) has to bear all the associated costs.
        </p>
        <h3>Third-Party Software Licenses</h3>
        <p>
          For certain Courses, Juzz-IT provides legal software licenses for the
          Users and/or Minor Users to practice. The license for the Course
          Material will be available only for the duration of the Course and
          must be used only for learning the Course for which the license was
          provided. The use of these licenses for commercial activities will
          result in an immediate loss of access to the Course, the Support
          System, and any certificates. Commercial use of the Courses by the
          User and/or the Minor User will also lead to legal prosecution.
        </p>
        <h3>Juzz-IT’s Rights</h3>
        <p>
          We own the Platform and Services, including the Website, present or
          future applications and Courses, Course Material, our logos, API,
          database, code, and content created and/or delivered by our employees,
          consultants. You and/or a Minor User cannot tamper with those or use
          them without authorization.
          <br />
          All right, title, and interest in and to the Platform and Services,
          including our Website, our existing or future applications, our logos,
          APIs, databases, code, and the content our employees or partners
          submit or provide through our Services are and will remain the
          exclusive property of Juzz-IT. Our Platforms and Services are
          protected by copyright, trademark, and other laws. Nothing gives you
          and/or a Minor User a right to use the Juzz-IT name or any of the
          Juzz-IT trademarks, logos, domain names, and any other Intellectual
          Property Rights.
        </p>
        <p>
          Security, Intrusion & Detection You and/or the Minor User may not do
          any of the following while accessing or using the Platform and
          Services: access, tamper with or use non-public areas of the Platform,
          Juzz-IT’s computer systems, or the technical delivery systems of
          Juzz-IT’s service providers. disable, interfere with, or try to
          circumvent any of the features of the Platform related to security or
          probe, scan, or test the vulnerability of any of our systems. copy,
          modify, create a derivative work of, reverse engineer, reverse
          assemble, or otherwise attempt to discover any source code of or
          content on the Juzz-IT Platform or Services.
        </p>
        <p>
          Access or search or attempt to access or search our Platform by any
          means (automated or otherwise) other than through our currently
          available search functionalities that are provided via our Website,
          mobile apps, or API (and only pursuant to those API terms and
          conditions). You may not scrape, spider, use a robot or use other
          automated means of any kind to access the Services in any way use the
          Services to send altered, deceptive, or false source-identifying
          information (such as sending email communications falsely appearing as
          Juzz-IT), or interfere with, or disrupt, (or attempt to do so), the
          access of any User and/or Minor User, host, or network, including,
          without limitation, sending a virus, overloading, flooding, spamming,
          or mail-bombing the Platforms or Services, or in any other manner
          interfering with or creating an undue burden on the Services.
        </p>
        <p>
          Unauthorized axttempts to hack, copy, upload or change information, or
          otherwise cause harm/damage to the Services and/or any User/Minor User
          of the Services, is strictly prohibited and may be punishable under
          applicable law. For Website security purposes, and to ensure that the
          Service remains available to all genuine human Users and/Minor Users
          and to genuine businesses and organizations that have the consent of
          Juzz-IT, all network traffic is monitored.
        </p>
        <p>
          If a User (on behalf of themselves and/or a Minor User) disagrees with
          these Terms, then that User and/or Minor User should not use this
          Website.
        </p>
        <h3>Restriction of Liability and Disclaimer</h3>
        <p>
          Juzz-IT reserves the right, at its sole discretion, to restrict or
          block the access of a User and/or a Minor User, and/or to suspend or
          terminate the account of a User and/or a Minor User, and/or to remove
          or delete any information/content posted, uploaded or displayed by a
          User and/or a Minor User, and/or to deny a User and/or a Minor User’s
          future access to the Platform and/or the Service, or any part thereof,
          with or without notice and for any reason whatsoever, and shall have
          no liability or responsibility to the User and/or a Minor User in any
          manner whatsoever if it chooses to do so. The Platform, Services,
          Course and Course Material are provided on an “as is” basis without
          warranties of any kind, either express or implied. For all Users
          and/or a Minor Users as well as any entities or third parties
          mentioned or displayed anywhere on this Platform or as part of the
          Services, Juzz-IT expressly disclaims all liability for errors and
          omissions in the contents of this Platform or as part of its Services,
          or any inaccuracies, falsehoods, defamation, slander, libel,
          discriminatory terminology, obscenity, pornography or profanity you
          and/or a Minor User may encounter or any interpretation of such that
          you and/or a Minor User may make. Juzz-IT disclaims all warranties,
          express or implied, including, without limitation, implied warranties
          of merchantability, fitness for a particular use or purpose, title and
          non￾infringement with respect to the Platform, Services, Course and
          Course Material. Juzz-IT further does not warrant that the Website,
          the Services, access to the Course or Course Material will be
          uninterrupted or error free; nor is there any warranty as to the
          results that may be obtained from the use of the Website, the
          Services, access to the Course or Course Material.
          <br /> As a provider of interactive online and offline Services such
          as, but not limited to, job boards, school ad listings, public forum
          boards, or banner advertisements, Juzz-IT is not liable for any words,
          terminology, statements, representations, images, visuals or content
          provided by or viewed by a User and/or a Minor User. The information
          and images displayed therein are provided in good faith, but we make
          no guarantees about the completeness, accuracy, timeliness,
          availability, accessibility, merchantability, appropriateness, or
          fitness for any particular purpose of any of the information, images
          or visuals. The User (on behalf of themselves and/or the Minor User)
          accepts that the use of this Platform and of our Services is at the
          User’s/Minor User’s sole risk. The User (on behalf of themselves
          and/or the Minor User) is aware and voluntarily and wholly accepts
          that your and/or the Minor User’s use of this Platform, of our
          Services or any other Juzz-IT website or application is always
          entirely and exclusively your own responsibility. Juzz-IT accepts no
          responsibility for your and/or the Minor User’s use of this Platform
          and of our Services, or for anyone else’s (including any advertiser’s)
          use of it. Juzz-IT accepts no responsibility for any injury, loss,
          penalty, claim, damage, or interpretation arising out of or in any way
          connected with this Platform and/or our Services which includes, but
          is not limited to, the content, information or entities/third
          parties/advertisers displayed here, the usage of cookies or collection
          of data/information by this Platform and/or our Services or by
          entities/third parties/advertisers displayed here.
          <br /> By accessing, browsing, or using this Platform and/or our
          Services, you (on behalf of yourself and/or the Minor User) agree with
          these conditions and agree to indemnify, defend and hold harmless the
          owners, operators, and maintainers of this Platform and of the
          Services and their agents and partners. To the extent permitted by
          applicable law, we may decide to cease making available certain
          features of the Services at any time and for any reason.
          <br />
          We are not responsible for delay or failure of our performance of any
          of the Services caused by events beyond our reasonable control, like
          an act of war, hostility, sabotage, natural disaster, electrical,
          internet services, or telecommunication outage, or government
          restrictions. Indemnity and Release.
        </p>
        <p>
          To the extent permitted by applicable law, you (on behalf of yourself
          and/or the Minor User) agree to defend, indemnify and hold harmless
          Juzz-IT, its affiliates, their respective officers, directors,
          employees and agents, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney’s fees) arising from:
        </p>
        <p>(i) your and/or a Minor User’s use of and access to the Platform;</p>
        <p>
          (ii) your and/or a Minor User’s violation of any clauses of these
          Terms;
        </p>
        <p>
          (iii) your and/or a Minor User’s violation of any third party right,
          including without limitation any copyright, property, or privacy
          right;{" "}
        </p>
        or
        <p>
          (iv) breach or violation of the terms and conditions of, and/or
          default in payment of, any moneys, loans and/or equated monthly
          instalments under any third party arrangement / agreement;
        </p>
        <p>
          (v) violation of any applicable laws. This defense and indemnification
          obligation will survive these Terms and your and/or a Minor User’s use
          of the Platform.
        </p>
        <p>
          You (on behalf of yourself and/or the Minor User) hereby expressly
          release Juzz-IT, its affiliates and any of their respective officers,
          directors, employees and agents from and any cost, damage, liability
          or other consequence of any of the actions/inactions of any
          third-party vendors or service providers and specifically waive any
          claims or demands that you and/or a Minor User may have in this behalf
          against any of Juzz-IT, its affiliates and any of their respective
          officers, directors, employees and agents under any statute, contract
          or otherwise.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          TO THE EXTENT PERMITTED BY APPLICABLE LAW, JUZZ-IT’S TOTAL LIABILITY
          TO THE USER AND/OR THE MINOR USER, OR ANYONE CLAIMING BY OR THROUGH
          THE USER AND/OR THE MINOR USER OR ON THE USER’S AND/OR MINOR USER’S
          BEHALF, FOR ANY CLAIMS, LOSSES, COSTS, OR DAMAGES ARISING OUT OF,
          RESULTING FROM, OR IN ANY WAY RELATED TO THE SERVICES, FROM ANY CAUSE,
          SHALL NOT EXCEED THE COURSE FEES THE USER (ON BEHALF OF THEMSELVES
          AND/OR THE MINOR USER) PAID TO JUZZ-IT (EXCLUDING TAXES). TO THE
          EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL JUZZ￾IT BE
          LIABLE, FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, SPECULATIVE,
          INCIDENTAL (INCLUDING ANY DAMAGES INCURRED DUE TO A LOSS OF
          OPPORTUNITY, INCOME, OR PROFITS, LOSS OF INFORMATION, BUSINESS
          INTERRUPTION), EXEMPLARY, OR PUNITIVE DAMAGES, OR FOR ATTORNEYS’ FEES,
          EXPENSES, EXPERT WITNESS FEES, OR COSTS, WHETHER ARISING OUT OF BREACH
          OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, PRODUCT
          LIABILITY, OR OTHERWISE AND REGARDLESS OF WHETHER SUCH LOSS OR DAMAGE
          WAS FORESEEABLE OR THE USER AND/OR THE MINOR USER AND/OR JUZZ-IT
          HAVING BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
        </p>
        <h3>Placement Policy</h3>
        <p>
          The Placement Policy governs your placement process with JUZZ-IT and
          we advise you to go through this Clause to understand what your
          experience is going to look like. Your consent and acceptance to this
          Clause is a prerequisite for initiating the placement process with
          JUZZ-IT and hence, your understanding of this Placement Policy is
          imperative. Career Services Support Program.
        </p>
        <p>
          Each User registered in a Career Services Support Program must
          complete his/her profile and adhere to all the following requirements
          to be eligible for Career Services Support Program. For the purpose of
          this Placement Policy, “Career Services Support Program” shall
          collectively mean the Job Assistance Program or any other similar
          program of JUZZ-IT.
        </p>
        <p>
          The User agrees to provide his/her correct updated profile information
          including but not limited to information related to Class X, XII,
          under-graduation, post-graduation, graduation year, marks. The User
          shall be responsible for the correctness of his/her profile
          information and JUZZ-IT shall not be held responsible in case such
          profile information of the User is found to be incorrect.
        </p>
        <p>
          The User should actively reach out to JUZZ-IT for getting his/her
          profile data updated.
        </p>
        <p>
          The User shall graduate in all mandatory courses applicable for the
          Career Services Support Program. For the purpose of this Placement
          Policy, “Graduate” or “Graduation” shall mean completion of all the
          challenges associated with a course with an average 80% (eighty
          percent) score. The User shall clear the
        </p>
        <p>
          (A) domain based test (hereinafter referred to as “Career Service
          Eligibility Test”) in accordance with the relevant Career Services
          Support Program; and / or
        </p>
        <p>(B) the general aptitude based mock MCQ test, if applicable.</p>
        <p>
          The User shall be eligible to appear for Career Service Eligibility
          Test post completion of all the mandatory courses applicable for the
          Career Services Support Program in the manner set out in sub-clause
          (ii) above.
        </p>
        <p>
          JUZZ-IT reserves the right to give placement assistance to the User
          without conducting
          <br />
          Career Service Eligibility Test as per its sole discretion.
        </p>
        <p>
          The User shall clear at least 1 (one) internal and 1 (one) external
          mock interview with a score of 70% (seventy percent) in each such
          interviews. User can retake the external mock interview up to 5 (five)
          times and avail internal mock interviews based on the applicable
          Career Services Support Program availed by the User. Throughout the
          placement journey of the User, JUZZ-IT may organize multiple sessions
          relevant to current market requirements viz. ‘refreshers’, ‘HR,
          aptitude and verbal training’ and ‘fastrack’ sessions etc. These
          sessions will help User achieve good conversions in interviews. Hence,
          the User shall maintain at least 80% (eighty percent) attendance in
          these sessions organized by JUZZ-IT.
        </p>
        <p>
          In the event a User opts out of the Career Services Support Program,
          JUZZ-IT shall not be providing any further assistance to such User. A
          User can opt out of Career Services Support Program by communicating
          the same to us at education@juzzit.in.
        </p>
        <p>
          In the event a User gets a job placement in a company by himself/
          herself after pursuing Career Services Support Program, Juzz-IT
          reserves the right not to further assist the User in getting job in
          any other company under the Career Services Support Program.
        </p>
        <p>
          The User shall be responsible to discuss and finalize the joining date
          with the hiring partner. User should honor the joining date (after the
          offer roll out by the hiring partner to the User) as per the offer
          letter.
        </p>
        <p>
          Juzz-IT shall not be held responsible for revocation of the job offer
          of the User in the event such User does not honor the joining date as
          per the offer letter. In such scenario, Juzz-IT reserves the right to
          not provide any further assistance to the User.
        </p>
        <p>
          If a User receives more than 1 (one) offer owing to delay in the
          announcements of results by the hiring partners, the User is bound to
          accept the job offer whose results are declared earlier.
        </p>
        <p>
          The User accepts that his/her information can be used by Juzz-IT for
          promotional purpose.
        </p>
        <p>
          The User shall mandatorily share his/her offer letter with Juzz-IT
          once the same is rolled out by the hiring partner.
        </p>
        <p>
          In the event the User does not continue working with the hiring
          partner he/she is placed with, Juzz-IT reserves the right not to
          further assist the User in getting job with any other hiring partner
          under the Career Services Support Program.
        </p>
        <p>
          Juzz-IT highly values relationships with both our Users and hiring
          partners. Once an interview process has begun, User should participate
          in all interview rounds till he/she is rejected/selected by the hiring
          partner. Juzz-IT may terminate Career Services Support Program for
          such User who does not appear in an interview or does not follow the
          interview process as communicated by relevant hiring partner and
          Juzz-IT.
        </p>
        <p>
          If a hiring partner revokes a job offer of the User, Juzz-IT may
          resume providing Career Services Support Program at its own discretion
          provided such revocation of the job offer is not attributable to the
          User. After getting placed, if a hiring partner decides to terminate a
          User as per the terms of its agreement with the User or because of
          commission or omission of an act by the User which constitutes fraud,
          financial misappropriation, gross negligence, reckless or wilful
          misconduct, including refusal to comply with the directions of the
          board of the hiring partner, poor performance or any other behavioral
          issues of the User, in such event Juzz-IT is not obliged to provide
          further assistance to the User under the Career Services Support
          Program. All Users shall regularly check their emails and messages for
          getting information on any placement-related activities.
        </p>
        <p>
          It is mandatory for User to inform his/her career success mentors of
          any job offer received during the Career Services Support Program.
          Juzz-IT has a zero-tolerance policy for cheating/plagiarism. If any
          User is found cheating or misbehaving during the course of an
          interview process, such User will be debarred from placement process
          Juzz-IT.
        </p>
        <p>
          For the Users who avail any Career Services Support Program, Juzz-IT
          reserves the right to publish placement updates on its website. This
          will include the User’s picture, the Course he/she is enrolled in, and
          the role and company he/she shall join during or after the Course
          completion. User shall follow the interview etiquette as communicated
          by Juzz-IT and relevant hiring partners from time to time. If any User
          is found not adhering to these interview etiquettes during the course
          of an interview, such User may be debarred from placement process of
          Juzz-IT.
        </p>
        <p>
          The hiring partner may change its interview process as per its
          discretion and Juzz-IT shall not be held responsible for any such
          change in the interview process. User shall be debarred from Career
          Services Support Program if he/she:
          <br />
          does not appear in any interview without prior information or a valid
          reason; <br />
          or
          <br />
          is not reachable for 4 (four) weeks or more. In case of personal
          emergencies, User is required to keep his/her respective career
          success mentors informed. This is to ensure that a User’s profile is
          not shared with hiring partners when he/she is not available for
          interviews;
          <br /> or
          <br />
          misses 2 (two) payments. Missing payments will lead only to a
          temporary debarment. User can continue availing Services post
          payments.
        </p>
        <h3>Job Assistance Program</h3>
        <h4>Terms and conditions of the Job Assistance Program</h4>
        <p>
          In addition to the terms of the Career Services Support Program set
          out in Clause 13(a) (Career Services Support Program) above, the User
          needs to fulfill all the following terms and conditions to be eligible
          for Job Assistance Program: Job Assistance is provided for Users who
          perform well in our program. The User should have completed all
          applicable mandatory courses with more than 80% (eighty percent) marks
          to qualify for Job Assistance Program.
        </p>
        <p>
          The User shall not score less than 80% (eighty percent) in the Career
          Services Eligibility Test.
          <br />
          Post completion of the requirements set out above, our student success
          team will work closely with the User. The student success team will
          advise you on resume preparation and prepare you for technical
          interviews. Our student success team works with several hiring
          partners to help you get a job. As part of the process, they will
          provide several tasks and assignments that you need to complete on
          time. Failure to complete or exhibiting poor compliance towards these
          tasks will result in revoking the Job Assistance Program.<br></br>
          Every eligible User will be given placement opportunities basis the
          relevant purchase plan availed by the User. In case the User gets
          selected by a hiring partner before exhausting all the placement
          opportunities as per his/her purchase plan, Juzz-IT reserves the right
          to provide further assistance to such User at its own discretion.
          <br></br>
        </p>
        <p>
          A User cannot reject a job offer procured through the Job Assistance
          Program on the pretext of
          <br />
          compensation, location, bond etc. as long as the annual compensation
          is in accordance with <br />
          purchase plan. Juzz-IT’s promise of Job Assistance will be considered
          fulfilled in case the annual compensation offered to any User is in
          accordance with the purchase plan and such User will no longer be
          eligible for further assistance under the Job Assistance Program.
          <br />
          In the case of internships, the User may resume Job Assistance Program
          after successfully completing the internship.
          <br />
        </p>
        <p>
          After the User has exhausted all the placement opportunities as per
          the Job Assistance Program, Juzz-IT may choose to further assist the
          User as per its own discretion. Juzz-IT in collaboration with its
          industry partners may organize the placement drives for providing
          placement opportunities to the User, User should attend these drives
          at his/her own monetary expense. The Job Assistance Program of the
          User shall expire after a period of 6 (six) months from the date of
          completion of the Job Assistance Program. Except as otherwise
          specified by Juzz-IT, in case of any inconsistency between the terms
          of the Career Services Support Program set out in Clause 13(a) (Career
          Services Support Program) above and the terms and conditions of the
          Job Assistance Program set out herein, the latter shall prevail.
        </p>
        <h3>Binding Agreement</h3>
        <p>
          You (on behalf of yourself and/or the Minor User) agree that by
          registering, accessing, or using our Services, you (on behalf of
          yourself and/or the Minor User) are agreeing to enter into a legally
          binding contract with Juzz-IT. If you and/or a Minor User do not agree
          to these Terms, do not register, access, or otherwise use any of our
          Services. Any notice or other communication to Juzz-IT shall be
          provided in writing at the address mentioned on the Platform. Any
          notice and communication to the User and/or the Minor User shall be
          made on the email address provided by the User and/or the Minor User
          at the time of registration on the Platform and/or at our Offline
          Centres. If any provision of these Terms is found to be invalid, void
          or unenforceable by applicable law, then that provision will be deemed
          superseded by a valid, enforceable provision that most closely matches
          the intent of the original provision and the remainder of these Terms
          will continue in effect.
          <br />
          Even if we are delayed in exercising our rights or fail to exercise a
          right in one case, it does not mean we waive our rights under these
          Terms, and we may decide to enforce them in the future. If we decide
          to waive any of our rights in a particular instance, it does not mean
          we waive our rights generally or in the future. The following clauses
          shall survive the expiration or termination of these Terms: Clause 1
          (Course Enrolment), Clause 5 (Juzz-IT at Your Own Risk), Clause 8
          (Juzz-IT’s Rights), Clause 11 (Indemnity and Release), Clause 12
          (Limitation of Liability), Clause 14 (Binding Agreement) and Clause 15
          (Governing Law, Jurisdiction and Dispute Resolution).
        </p>
        <h3>Governing Law, Jurisdiction and Dispute Resolution</h3>
        <h4>Governing Law and Jurisdiction</h4>
        <p>
          These Terms are governed and interpreted by and construed in
          accordance with the laws of the
          <br />
          Republic of India and subject to the dispute resolution clause below,
          the court at Banglore shall have sole and exclusive jurisdiction over
          all matters arising out of or relating to the Terms. You agree and
          undertake not to initiate or instigate any class actions.
        </p>
        <h3>Dispute Resolution</h3>
        <p>
          Any and all disputes arising out of or in connection with these Terms
          shall be exclusively submitted to arbitration under the Arbitration
          and Conciliation Act, 1996, and shall be adjudicated by an arbitral
          tribunal comprising a sole arbitrator to be appointed by mutual
          consent of the parties. All proceedings of the arbitration shall be
          conducted in English language. The venue and seat of arbitration shall
          be Banglore. The arbitration award rendered by the sole arbitrator
          shall be final and binding. The substantive and procedural laws
          applicable to these Terms shall be the laws in force in the Republic
          of India.
        </p>
        <h3>Policy Changes</h3>
        <p>
          From time to time, we may update these Terms to clarify our practices
          or to reflect new or different practices (such as when we add new
          features) without any notice, and Juzz-IT reserves the right in its
          sole discretion to modify and/or make changes to these Terms at any
          time. If we make any material change, we will notify you and/or the
          Minor User using prominent means such as by email notice sent to the
          email address specified in your and/or the Minor User’s account or by
          posting a notice through our Platforms and Services.<br></br>
          Modifications will become effective on the day they are posted unless
          stated otherwise. Your and/or the Minor User’s continued use of our
          Services after changes become effective shall mean that you (on behalf
          of yourself and/or the Minor User) accept those changes. Any revised
          Terms shall supersede all previous Terms. If a User (on behalf of
          themselves and/or the Minor User) disagrees with this policy or any
          part therein, then that User and/or the Minor User should not use this
          Website.
        </p>
        <h3>Grievance Officer and How to Contact Us</h3>
        <p>
          If you find any discrepancies or have any grievances in relation to
          the Services, offered by us under these Terms or any other applicable
          terms and conditions or policies of the Company, as may be applicable,
          please contact the following:
        </p>
        <h5>Name: Karthik BV</h5>
        <h5>E-mail: query@juzzit.in</h5>
        <h5>Working Days: Mon-Fri</h5>
        <h5>Working Hours: 10 AM – 6 PM</h5>
        <p>
          We will strive to address your questions, feedback and concerns in a
          timely and effective manner and within the time period prescribed
          under applicable law. The details of the grievance officer may be
          changed by us from time to time by updating these Terms. Juzz-IT’s
          Vouchers Offer Juzz-IT may run offers, and campaigns of issuing
          vouchers for the benefit of its Users and/or Minor Users from third
          party voucher platforms including without limitation vouchers. The
          said offer has a set of following terms and conditions:
        </p>
        <h3>Applicability and Redemption</h3>
        <p>
          This offer is applicable only on selected Courses for a particular
          period of time as announced by Juzz-IT from time to time on the
          Website and other promotions as per their discretion.
          <br />
          Nature of the offer, duration of the offer and the manner of
          implementing the same is subject to the sole discretion of Juzz-IT
          from time to time.
          <br />
          In order to avail the offer, Users (on behalf of themselves and/or
          Minor Users) should purchase the Course only from India.
          <br />
          Voucher shall be issued to the User and/or the Minor User on the
          registered email id after the payment of the entire Course fees.
          <br />
          The voucher can be redeemed only on the website as prescribed on the
          voucher.
        </p>
        <h3>Disclaimer</h3>
        <p>
          In the event of the Course being cancelled/stopped by Juzz-IT, a new
          voucher can be issued by Juzz-IT at its own discretion and in such an
          event, if the User and/or Minor User chooses to opt out of the Course,
          no refund against the voucher shall be given, subject to applicable
          law. Juzz-IT reserves the rights to process or reject any voucher
          exchange requests as per its discretion.<br></br>
          Learning Support to Users and/or Minor Users Support Timing
          <br />
          In our constant endeavor to provide Users and/or Minor Users with
          superior learning support at every step, Juzz-IT offers personal
          support to its Users and/or Minor Users. The support staff of Juzz-IT
          will be available to help and guide the Users and/or Minor Users to
          clarify their doubts on the Course Materials only during working days
          between 10.00 AM to 7.00 PM IST.
          <br />
          Our support staff shall not be reachable after the working hours and
          on public/local and national holidays.
        </p>
        <p>
          For all-out of office hours support requests please write to us at
          education@juzzit.in or raise a ticket from your dashboard.
        </p>
        <h3>Language</h3>
        <p>
          All our Courses are designed and shall be delivered only in English.
        </p>
        <p>
          However, in order to bridge the language gap and for the benefit of
          the Users and/or Minor Users, we are making constant efforts to
          provide ad hoc support in Indian regional languages which is largely
          dependent on the availability of the support person (tech support /
          technical support engineer and career support). However, Juzz-IT does
          not guarantee or promise to provide the Course Material in any of the
          Indian regional languages other than English. We wish you all the best
          and all of us here at Juzz-IT are there to help and guide you through
          your journey with us, happy learning!
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
