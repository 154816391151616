import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";

////

export const getEnrolledCourseCount = async (studentId) => {
  try {
    const responce = await myaxios.get(
      `/std/student/${studentId}/batchCountDetails`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

///

export const updateStudentData = async (studentId, imageData) => {
  try {
    const response = await myaxios.put(
      `/std/student/${studentId}/updateDetails`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getStudentProfile = async (studentId) => {
  try {
    const responce = await myaxios.get(`/std/student/${studentId}/profile`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};

////

export const addProfileImage = async (studentId, imageData) => {
  try {
    const response = await myaxios.post(
      `/std/student/${studentId}/profileImage`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const UpdateProfileImage = async (imageId, imageData) => {
  try {
    const response = await myaxios.put(
      `/std/student/profileImage/${imageId}`,
      imageData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteProfileImage = async (imageId) => {
  try {
    const response = await myaxios.delete(
      `/std/student/profileImage/${imageId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getProfileImage = async (studentId) => {
  try {
    const response = await myaxios.get(
      `/std/student/${studentId}/profileImage`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

//// profile  social linka

//

export const addProfileSolialLinks = async (studentId, profileData) => {
  try {
    const response = await myaxios.post(
      `/std/student/${studentId}/socialLinks`,
      profileData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const UpdateProfileSolialLinks = async (socialLinkId, linksData) => {
  try {
    const response = await myaxios.put(
      `/std/student/sociallinks/${socialLinkId}`,
      linksData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteProfileSolialLinks = async (socialLinkId) => {
  try {
    const response = await myaxios.delete(
      `/std/student/sociallinks/${socialLinkId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getProfileSolialLinks = async (studentId) => {
  try {
    const response = await myaxios.get(
      `/std/student/${studentId}/socialLinks`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const updatePassWordData = async (studentId, passWordData) => {
  try {
    const response = await myaxios.put(
      `/std/student/${studentId}/resetPassWprd`,
      passWordData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};
export const updatePassWordDataOTP = async (studentId, passWordData) => {
  console.log(authHeader());
  try {
    const response = await myaxios.put(
      `/std/student/${studentId}/otp/resetPassword`,
      passWordData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

///

export const getAllEnrolledCourseData = async (studentId) => {
  try {
    const responce = await myaxios.get(
      `/bch/student/${studentId}/batchCourse/Details`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getAllPlacement = async (batchCourseId) => {
  try {
    const responce = await myaxios.get(
      `/bch/batchCourses/${batchCourseId}/Placements`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getliveCoursePlacementImages = async (placementId) => {
  try {
    const responce = await myaxios.get(
      `/bch/placements/${placementId}/placementImages`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getAllClassLinks = async (batchCourseId) => {
  try {
    const responce = await myaxios.get(
      `/bch/batchCourses/${batchCourseId}/classLinks`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

//// get all enrolled workshops

export const getAllEnrolledWorkShops = async (studentId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/student/${studentId}/workShopBatchs`,
      { headers: authHeader() }
    );
    return responce.data;
  } catch (error) {}
};

export const getEnrolledWorkShopPlacement = async (workShopBathcId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/workShopBatchs/${workShopBathcId}/placements`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getEnrolledWorkShopPlacementImage = async (placementId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/workShopPlacements/${placementId}/images`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getEnrolledWorkShopClassLinks = async (batchId) => {
  try {
    const responce = await myaxios.get(
      `/wsbch/workShopBatchs/${batchId}/classLinks`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getAllLiveClassRecodedVideoSuject = async (batchCourseId) => {
  try {
    const responce = await myaxios.get(
      `/bch/student/batchCourses/${batchCourseId}/recordedVideoSubjects`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};
export const getLiveClassSubjectRecodedvIDEOS = async (
  recordedVideoSubjectsId
) => {
  try {
    const responce = await myaxios.get(
      `/bch/recordedVideoSubjects/${recordedVideoSubjectsId}/recordedVideos`,
      {
        headers: authHeader(),
      }
    );
    console.log(responce.data);
    return responce.data;
  } catch (error) {}
};

//// all Recoded course Batchs

export const getallMyRecordedBatchs = async (studentId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/student/${studentId}/batchs`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getRecodedStudentPlacement = async (recodedStudentBatchId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recodedStudent/${recodedStudentBatchId}/placements`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getenrolledRecodedStudentPlacementImage = async (placementId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recodedStudent/placements/${placementId}/images`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getRecodedStudentClassLinks = async (recodedStudentBatchId) => {
  try {
    const responce = await myaxios.get(
      `/recoded/bch/recodedStudent/${recodedStudentBatchId}/classLinks`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getRecodedStudentClassModules = async (
  studentBatchId,
  courseTypeId
) => {
  try {
    const responce = await myaxios.get(
      `/cur/student/${studentBatchId}/courseTypes/${courseTypeId}/models`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getLessonTopicVides = async (studentBatchId, moduleId) => {
  try {
    const responce = await myaxios.get(
      `/cur/student/${studentBatchId}/module/${moduleId}/lessons/topics`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

/////  single video class

export const getTopicVideos = async (topicId) => {
  try {
    const responce = await myaxios.get(`/cur/topics/${topicId}/topicVideos`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};
export const getTopicDails = async (topicId) => {
  try {
    const responce = await myaxios.get(`/cur/student/topics/${topicId}`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};

///

export const getRecodedVideoDetailsByVideoId = async (
  topicId,
  studentBatchId
) => {
  try {
    const responce = await myaxios.get(
      `/cur/student/topics/${topicId}/${studentBatchId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getTopicAssignments = async (topicId) => {
  try {
    const response = await myaxios.get(`/cur/topic/${topicId}/assignments`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {}
};

export const addStudentAssignment = async (
  studentBatchId,
  assignmentId,
  formData
) => {
  try {
    const response = await myaxios.post(
      `/bch/student/batch/${studentBatchId}/${assignmentId}`,
      formData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const deleteAssignmentSumition = async (assignmentSubmitionId) => {
  try {
    const responce = await myaxios.delete(
      `/bch/student/batch/assignment/${assignmentSubmitionId}`,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getAssignmentSubmition = async (assignmentId) => {
  try {
    const response = await myaxios.get(
      `/bch/student/assignment/${assignmentId}/submition`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getAssignmentDetailsById = async (assignmentId) => {
  try {
    const response = await myaxios.get(
      `/cur/topic/assignment/${assignmentId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const addNewStudentrefreal = async (studentBatchId, formData) => {
  try {
    const responce = await myaxios.post(
      `/bch/student/batchCourse/${studentBatchId}/addreferal`,
      formData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};
