import React from "react";
import "./logoScroll.scss";
const InfinityScrollingLogos = ({ logos }) => {
  return (
    <div class="logos">
      <div class="logos-slide">
        {logos &&
          logos?.map((val, key) => {
            return (
              <img
                src={val.url}
                onClick={() => {
                  window.open(val.link, "_blank");
                }}
                alt="ssss"
                key={key}
              />
            );
          })}
      </div>

      <div class="logos-slide">
        {logos &&
          logos?.map((val, key) => {
            return (
              <img
                src={val.url}
                onClick={() => {
                  window.open(val.link, "_blank");
                }}
                alt="ssss"
                key={key}
              />
            );
          })}
      </div>
    </div>
  );
};

export default InfinityScrollingLogos;
