import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";

export const getAllCourseAndCourseType = async () => {
  try {
    const responce = await myaxios.get(`/api/courses/courseTypes`);
    console.log(responce);
    console.log("end");
    return responce.data;
  } catch (error) {}
};

///topic video

export const getTopicVideoWatchedList = async (studentBatchId, topicId) => {
  try {
    const response = await myaxios.get(
      `/bch/student/${studentBatchId}/videoWatched/topic/${topicId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};
export const updateTopicVideoWatchedList = async (
  videoWathcedId,
  updateData
) => {
  try {
    const response = await myaxios.put(
      `/bch/student/videowathced/${videoWathcedId}`,
      updateData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const addTopicVideoWatchedData = async (
  studentBathcId,
  topicId,
  watchedData
) => {
  try {
    const response = await myaxios.post(
      `/bch/student/videowathced/${studentBathcId}/${topicId}`,
      watchedData,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCourseTypesByCourseId = async (courseId) => {
  try {
    console.log(authHeader());
    const responce = await myaxios.get(`/api/courses/${courseId}/courseTypes`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};

export const sandEnrollRequest = async (studentId, courseTypeId, payload) => {
  try {
    const responce = await myaxios.post(
      `/enroll/request/${studentId}/courses/${courseTypeId}`,
      payload,
      { headers: authHeader() }
    );
    return responce.data;
  } catch (error) {}
};

export const getCourseTypeDetails = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/api/courseType/${courseTypeId}/allDetails`
    );
    return responce.data;
  } catch (error) {
    return [];
  }
};

export const getCourseTypeModuleData = async (courseTypeId) => {
  try {
    const responce = await myaxios.get(
      `/cur/student/courseTypes/${courseTypeId}/modelsDetails`,
      { headers: authHeader() }
    );
    return responce.data;
  } catch (error) {}
};
export const getCourseTypeModuleAllDetails = async (batchId) => {
  try {
    const responce = await myaxios.get(
      `/bch/student/batchCourse/${batchId}/ALLDetails`,
      { headers: authHeader() }
    );
    return responce.data;
  } catch (error) {}
};

export const getAllEnrollTypes = async () => {
  try {
    const responce = await myaxios.get(`/api/enrollTypes`);
    return responce.data;
  } catch (error) {}
};
