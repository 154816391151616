import React, { useEffect, useState } from "react";
import "./EnrolledRecordedCourses.scss";
import SingleRecoredCourseCompo from "./SingleRecoredCourseCompo";
import { getallMyRecordedBatchs } from "../../../../../../Redux/Actions/MyCourseActions";
import { useDispatch, useSelector } from "react-redux";
import { setEnrolledRecodedCourses } from "../../../../../../Redux/RedusersCompo/MyCourseReducer";
import Loading from "../../../../../../LoadingComponent/Loading";

const EnrolledRecordedCourses = () => {
  const [isLoading, setIsLoading] = useState(false);
  const enrolledRecodedCourses = useSelector(
    (state) => state.mycourseData.enrolledRecodedCourses
  );

  const dispatch = useDispatch();

  const userData = localStorage.getItem("userData");
  let user = {};

  if (userData) {
    user = JSON.parse(userData);
  }

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getallMyRecordedBatchs(user?.userId);
      dispatch(setEnrolledRecodedCourses(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, user?.userId]);

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="EnrolledRecordedCourses-main-container">
      <h1>My Enrolled Recorded Courses</h1>
      <div className="EnrolledRecordedCourses-content">
        {enrolledRecodedCourses &&
          enrolledRecodedCourses?.map((val, key) => {
            const courseName =
              val.COURSE_TYPE + "Certification in" + val.COURSE_NAME;
            return (
              <SingleRecoredCourseCompo
                studentBatchId={val.RECORDED_STUDENT_BATCH_ID}
                course_Id={val.courseType_Data?.course_type_id}
                img={val.courseType_Data?.CourseType_Image}
                key={key}
                heading={courseName}
              />
            );
          })}
      </div>
    </div>
  );
};

export default EnrolledRecordedCourses;
