import axios from "axios";

// export const myaxios = axios.create({
//   baseURL: "http://localhost:8082/",
// });

export const myaxios = axios.create({
  baseURL: "https://juzzit.online/",
});

// export const myaxios1 = axios.create({
//   baseURL: "https://juzzitdev.onrender.com",
// });
