import React, { useEffect } from "react";
import "./DashBoardHome.scss";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { getEnrolledCourseCount } from "../../../../../../Redux/Actions/MyCourseActions";
import { setbatchCount } from "../../../../../../Redux/RedusersCompo/MyCourseReducer";
import { useDispatch, useSelector } from "react-redux";
import { getStudentDataByToken } from "../../../../../../Redux/Actions/AuthAction";
import { setStudentDataToStore } from "../../../../../../Redux/RedusersCompo/AuthReducer";
const DashBoardHome = () => {
  // const userData = localStorage.getItem("userData");
  // let user = {};
  // if (userData) {
  //   user = JSON.parse(userData);
  // }

  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  const batchCount = useSelector((state) => state.mycourseData.batchCount);
  useEffect(() => {
    const getData = async () => {
      const data = await getEnrolledCourseCount(userDetails?.id);
      console.log(data);
      dispatch(setbatchCount(data));
    };
    getData();
  }, [dispatch, userDetails]);

  return (
    <div className="DashBoardHome-main-conteiner">
      <div className="DashBoardHome-container">
        <div className="DashBoardHome-content">
          <MenuBookTwoToneIcon />

          <span>{batchCount && batchCount?.all_course}</span>
          <p>Enrolled Courses</p>
        </div>
        <div className="DashBoardHome-content">
          <i class="fa-solid fa-layer-group"></i>
          <span>{batchCount && batchCount?.Active_Count}</span>
          <p>Active Courses </p>
        </div>
        <div className="DashBoardHome-content">
          <MilitaryTechIcon />
          <span>{batchCount && batchCount?.completed_course}</span>
          <p>Completed Courses</p>
        </div>
      </div>
    </div>
  );
};

export default DashBoardHome;
