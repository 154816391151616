import React from "react";
import HeaderApplyForm from "../HeaderApplyForm";
import "./PopUpVideoForm.scss";
import ReactPlayer from "react-player";

const PopUpVideoForm = ({ videoURL, SETDisplay, desplayPop }) => {
  return (
    <div className="PopUpVideoForm-main-container">
      <div className="PopUpVideoForm-container">
        <div className="PopUpVideoForm-video">
          <ReactPlayer
            url={videoURL}
            width={"100%"}
            height={"100%"}
            controls={false}
            playing={desplayPop}
          ></ReactPlayer>
        </div>
        <div className="PopUpVideoForm-form-container">
          <i className="fa-solid fa-x" onClick={SETDisplay}></i>
          <HeaderApplyForm />
        </div>
      </div>
    </div>
  );
};

export default PopUpVideoForm;
