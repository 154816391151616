import React from "react";
import "./FreeCourseLinks.scss";
import SingleFreeCourse from "./singleFreeCourseCompo/SingleFreeCourse";
const FreeCourseLinks = () => {
  return (
    <div className="FreeCourseLinks-main-containers">
      <div className="FreeCourseLinks-container">
        <SingleFreeCourse />
        <SingleFreeCourse />
        <SingleFreeCourse />
        <SingleFreeCourse />
        <SingleFreeCourse />
        <SingleFreeCourse />
      </div>
    </div>
  );
};

export default FreeCourseLinks;
