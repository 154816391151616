import React, { useEffect } from "react";
import "./Aboutbody.scss";
import CallIcon from "@mui/icons-material/Call";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LaptopMacOutlinedIcon from "@mui/icons-material/LaptopMacOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import AOS from "aos";
import "aos/dist/aos.css";

import imgf from "../../../../../img/Formimage/abouImge.png";
const Aboutbody = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="Aboutbody-main-container">
      <div className="left" data-aos="fade-right">
        <img src={imgf} alt="jdsfjk"></img>
        <p>Why Learn With Us?</p>
        <span>
          <CallIcon />
          +91 9353822274
        </span>
      </div>

      <div className="rigth">
        <div className="content" data-aos="zoom-in">
          <MenuBookTwoToneIcon />
          <h1>World-Class Mentors</h1>
          <p>
            Get taught by industry experts having years of experience in their
            respective fields apart from having cracked various top tech
            companies worldwide.
          </p>
        </div>
        <div className="content" data-aos="zoom-in">
          <DiamondOutlinedIcon />
          <h1>One-on-One Mentorship</h1>
          <p>
            Students will have mentors who will help them with their doubts,
            provide guidance, and help them out 24×7.
          </p>
        </div>
        <div className="content" data-aos="zoom-in">
          <LaptopMacOutlinedIcon />
          <h1>Wherever You Go</h1>
          <p>
            Join a network of thousands of students from across the globe to
            support our motive of Learn, Connect, and Grow!
          </p>
        </div>
        <div className="content" data-aos="zoom-in">
          <GroupsOutlinedIcon />
          <h1>Top-Notch LIVE Lectures</h1>
          <p>
            Join our three-month-long series of LIVE lectures with top mentors,
            recorded sessions, and teaching assistants.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aboutbody;
