import React, { useEffect, useState } from "react";
import "./JuzzItJobs.scss";
// import JobFilter from "./Components/JobFilterComponents/JobFilter";
import JobList from "./Components/JobListComponents/JobList";
import { getAllEvent } from "../../../../../Redux/Actions/EventAction";
import { useDispatch, useSelector } from "react-redux";
import { setJuzzitJobs } from "../../../../../Redux/RedusersCompo/EventReducer";
import { MenuItem, Select } from "@mui/material";
// import LearningBetter from "./Components/LearningBetterComponent/LearningBetter";
const JuzzItJobs = () => {
  const dispatch = useDispatch();

  const juzzitJobs = useSelector((state) => state.EventData.juzzitJobs);
  const [jobDomain, setJobDomain] = useState("All");
  const [data, setData] = useState("");

  useEffect(() => {
    const getData = async () => {
      const data = await getAllEvent();

      if (data && data.length > 0) {
        dispatch(
          setJuzzitJobs(
            [...data].sort((a, b) => {
              return b.CREATED_DATE - a.CREATED_DATE;
            })
          )
        );
      } else {
        dispatch(setJuzzitJobs(data));
      }
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    // if (juzzitJobs && juzzitJobs.length > 0) {
    //   setJobDomain(juzzitJobs[0].DOMAIN_NAME);
    // }

    if (juzzitJobs && juzzitJobs.length > 0) {
      setData(juzzitJobs);
    }
  }, [juzzitJobs, setData, jobDomain]);

  useEffect(() => {
    if (juzzitJobs && juzzitJobs.length > 0) {
      if (jobDomain === "All") {
        setData(juzzitJobs);
      } else {
        setData(
          juzzitJobs.filter((a) => {
            return a.DOMAIN_NAME === jobDomain;
          })
        );
      }
    }
  }, [juzzitJobs, setData, jobDomain]);

  const handleJobFilterChanges = () => {
    if (juzzitJobs && juzzitJobs.length > 0) {
      if (jobDomain === "All") {
        setData(juzzitJobs);
      } else {
        setData(
          juzzitJobs.filter((a) => {
            return a.DOMAIN_NAME === jobDomain;
          })
        );
      }
    }
  };

  return (
    <div className="JuzzItJobs-main-container">
      <div className="JuzzItJobs-container">
        <div className="JuzzItJobs-main-heading-contant">
          <div className="JuzzItJobs-heading-contant">
            <div className="JuzzItJobs-heading">
              <h1>JUZZ-IT Jobs</h1>
              <h5>
                Search for and submit applications to job openings in the fields
                of Data Science, Full stack Web Development and Software
                Engineering.
              </h5>
            </div>
          </div>
        </div>
        {/* <JobList data={data} /> */}
        <div className="JuzzItJobs-main-content">
          <div className="JuzzItJobs-content">
            <JobList data={data} />
          </div>
          {/* <JobFilter /> */}
          <div className="JuzzItJobs-JobFilter">
            <div className="JuzzItJobs-JobFilter-content">
              <div className="JuzzItJobs-element">
                <h1>job domain</h1>
                <Select
                  value={jobDomain}
                  onChange={(e) => {
                    setJobDomain(e.target.value);
                    handleJobFilterChanges();
                  }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {juzzitJobs &&
                    juzzitJobs?.map((val, key) => {
                      return (
                        <MenuItem value={val.DOMAIN_NAME}>
                          {val.DOMAIN_NAME}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JuzzItJobs;
