import React, { useEffect, useState } from "react";
import "./HeaderContent.scss";
// import { Button } from "@mui/material";
// import DemoForm from "../../DemoFormCompo/DemoForm";
const HeaderContent = () => {
  // const [showApplyForm, setShowApplyForm] = useState(false);
  // const [successSubmition, setsuccessSubmition] = useState(false);
  const [givenDate, setGivenDate] = useState("");
  const [loadingTime, setLoadingTime] = useState(null);

  useEffect(() => {
    // Set default value for givenDate as current date plus one day
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Add one day
    setGivenDate(currentDate.toISOString().slice(0, 16));
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 2); // Add one day
    setGivenDate(currentDate.toISOString().slice(0, 16));
  }, []);

  useEffect(() => {
    const calculateLoadingTime = () => {
      const currentDate = new Date();
      const targetDate = new Date(givenDate);
      const timeDifference = targetDate.getTime() - currentDate.getTime();
      if (timeDifference < 0) {
        setLoadingTime("The given date is in the past.");
      } else {
        const days = Math.floor(timeDifference / (1000 * 3600 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 3600 * 24)) / (1000 * 3600)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 3600)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        const formattedTime = `0${days} D :  ${hours
          .toString()
          .padStart(2, "0")} H :    ${minutes
          .toString()
          .padStart(2, "0")} M : ${seconds.toString().padStart(2, "0")} S`;
        setLoadingTime(formattedTime);
      }
    };
    const intervalId = setInterval(() => {
      calculateLoadingTime();
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [givenDate]);

  return (
    <div className="HeaderContent-main-containeter">
      <div className="HeaderContent-container">
        <div className="HeaderContent-main-content">
          <div className="HeaderContent-content">
            Secure your future career today with our Pay-After-Placement program
            – invest in your success with the upfront costs!"
          </div>
          <div className="HeaderContent-content">
            Secure your future career today with our Pay-After-Placement program
            – invest in your success with the upfront costs!"
          </div>
        </div>
        {/* <div className="HeaderContent-content">
          Secure your future career today with our Pay-After-Placement program –
          invest in your success with the upfront costs!" Limited Seat (95/180)
        </div> */}
        <div className="HeaderContent-date-content">
          {loadingTime && <p>{loadingTime}</p>}
          <span> Limited Seat (95/180)</span>
        </div>
      </div>
    </div>

    // <div className="HeaderContent-main-containeter">
    //   <div
    //     className={`PayAfterPlacement-apply-form ${
    //       showApplyForm ? "showApplyForm" : ""
    //     }`}
    //   >
    //     <DemoForm
    //       SETDisplay={() => setShowApplyForm(!showApplyForm)}
    //       setsuccessSubmition={() => setsuccessSubmition(true)}
    //     />
    //   </div>
    //   <div
    //     className={`sucessfully-submited-Request-main ${
    //       successSubmition ? "successSubmition" : ""
    //     }`}
    //   >
    //     <div className="sucessfully-submited-Request">
    //       <h1>We received your request. We will reach you soon.</h1>
    //       <Button
    //         variant="contained"
    //         onClick={() => setsuccessSubmition(false)}
    //       >
    //         Ok
    //       </Button>
    //     </div>
    //   </div>
    //   <div className="HeaderContent-container">
    //     <div className="HeaderContent-left-content">
    //       <span>Demo Class</span>
    //       <h5>Live Demo Class</h5>
    //     </div>
    //     <div className="HeaderContent-center-content">
    //       <p>7:30 PM - 8:30 PM </p>
    //       <p>Today</p>
    //     </div>

    //     <div className="HeaderContent-right-content">
    //       <Button
    //         variant="contained"
    //         size="small"
    //         className="book-now-button"
    //         onClick={() => {
    //           setShowApplyForm(true);
    //         }}
    //       >
    //         Book now
    //       </Button>
    //     </div>
    //   </div>
    // </div>
    // <div className="HeaderContent-main-containeter">
    //   <div className="HeaderContent-container">
    //     {/* <p>Working Hours: 9:00 am to 8:00 pm</p> */}
    //     <p>Contact Us +91 9353822274</p>
    //     <div className="social-links">
    //       <ul>
    //         <li>
    //           <img
    //             src={
    //               "https://cdn.iconscout.com/icon/free/png-256/free-facebook-logo-2019-1597680-1350125.png?f=webp"
    //             }
    //             alt="social-icon1"
    //             onClick={() => {
    //               window.open(
    //                 "https://www.facebook.com/educatejuzzit?mibextid=ZbWKwL",
    //                 "_blank"
    //               );
    //             }}
    //           ></img>
    //         </li>
    //         <li>
    //           <img
    //             src={
    //               "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
    //             }
    //             alt="social-icon1"
    //             onClick={() => {
    //               window.open(
    //                 "https://www.instagram.com/juzz_it_education?igshid=OGQ5ZDc2ODk2ZA==",
    //                 "_blank"
    //               );
    //             }}
    //           ></img>
    //         </li>
    //         <li>
    //           <img
    //             src={
    //               "https://static-00.iconduck.com/assets.00/youtube-icon-512x511-qrlabbtf.png"
    //             }
    //             alt="social-icon1"
    //             onClick={() => {
    //               window.open(
    //                 "https://www.youtube.com/@Juzz-ITEducation",
    //                 "_blank"
    //               );
    //             }}
    //           ></img>
    //         </li>
    //         <li>
    //           <img
    //             src={
    //               "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/LinkedIn_icon_circle.svg/2048px-LinkedIn_icon_circle.svg.png"
    //             }
    //             alt="social-icon1"
    //             onClick={() => {
    //               window.open(
    //                 "https://www.linkedin.com/company/juzz-it/",
    //                 "_blank"
    //               );
    //             }}
    //           ></img>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </div>
  );
};
export default HeaderContent;
