import React from "react";
import "./RecomendeCourses.scss";
// import { RcomeneddCourseData } from "./CourseData";
import SingleRecomenedCourse from "./Component/SingleRecomenedCourse";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css";

import "swiper/css/navigation";

// Import Swiper modules
// import { Navigation } from "swiper/modules";
import { useState } from "react";
import { useEffect } from "react";
import { getBranchData } from "../../../../../Redux/Actions/HomePageActions";
import { useDispatch, useSelector } from "react-redux";
import { setrecomendedCourses } from "../../../../../Redux/RedusersCompo/HomePageReducer";

import comingSoonImage from "../../../../../img/Home/soon.png";

const RecomendeCourses = () => {
  const recomendedCourses = useSelector(
    (state) => state.HomePageData.recomendedCourses
  );
  const [branch, setBranch] = useState("");
  useEffect(() => {
    if (recomendedCourses) {
      setBranch(recomendedCourses[0]?.branch_name);
    }
  }, [recomendedCourses]);

  const selectedBranch = recomendedCourses?.filter((val) => {
    return val.branch_name === branch;
  });

  const [slidesPerView, setSlidesPerView] = useState(5);

  const dispach = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getBranchData();
      let sortedData = [];

      if (data && data.length > 0) {
        sortedData = [...data]?.sort((a, b) => {
          return a.order - b.order;
        });
      }

      dispach(setrecomendedCourses(sortedData));
    };
    getData();
  }, [dispach]);

  useEffect(() => {
    const updateSlidesPerView = () => {
      // Adjust the logic based on your design requirements
      const screenWidth = window.innerWidth;
      if (screenWidth >= 668) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(1);
      }
    };

    // Update slides per view on initial load and window resize
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  if (selectedBranch)
    return (
      <>
        <div className="RecomendeCourses-main-container">
          <h1 className="All-components-heading">recommended courses</h1>
          <div className="RecomendeCourses-container">
            <div className="RecomendeCourses-heading-container">
              <ul>
                {recomendedCourses &&
                  recomendedCourses?.map((val, key) => {
                    if (val.recomended_status === "D") {
                      return null;
                    }
                    return (
                      <li
                        className="componets-itam-heading"
                        onClick={() => setBranch(val.branch_name)}
                        key={key}
                      >
                        {val.branch_name}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="RecomendeCourses-course-container">
              <h1 className="All-components-heading">{branch}</h1>
              <div className="RecomendeCourses-course-slide-content">
                {selectedBranch &&
                  selectedBranch?.map((val, key) => {
                    if (!val.coueses.length > 0) {
                      return (
                        <div className="recent-placement-comeingsoon">
                          <img src={comingSoonImage} alt="coming soon"></img>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                {selectedBranch && selectedBranch[0]?.coueses?.length > 0 ? (
                  <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={10}
                    // navigation={true}
                    // modules={[Navigation]}
                    className="mySwiper"
                  >
                    {selectedBranch &&
                      selectedBranch?.map((val, key) => {
                        if (val.recomended_status === "D") {
                          return null;
                        }
                        return (
                          <>
                            {val.coueses?.map((val1, key1) => {
                              if (val1.recoemnded_Status === "D") {
                                return null;
                              }
                              return (
                                <>
                                  {val1.courseType?.map((val2, key2) => {
                                    if (
                                      val2.Recomende_Status === "D" ||
                                      val2.course_typeName === "Workshop" ||
                                      val2.course_typeName === "Internship"
                                    ) {
                                      return null;
                                    }
                                    return (
                                      <SwiperSlide
                                        className="SwiperSlide"
                                        key={key2}
                                      >
                                        <SingleRecomenedCourse
                                          cousesName={val1.course_name}
                                          courseData={val2}
                                        />
                                      </SwiperSlide>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                  </Swiper>
                ) : (
                  ""
                )}
              </div>

              <div className="RecomendeCourses-course-content">
                {selectedBranch &&
                  selectedBranch?.map((val, key) => {
                    if (!val.coueses.length > 0) {
                      return (
                        <div className="recent-placement-comeingsoon">
                          <img src={comingSoonImage} alt="coming soon"></img>
                        </div>
                      );
                    }

                    if (val.recomended_status === "D") {
                      return null;
                    }
                    return (
                      <>
                        {val.coueses?.map((val1, key1) => {
                          if (val1.recoemnded_Status === "D") {
                            return null;
                          }
                          return (
                            <>
                              {val1.courseType?.map((val2, key2) => {
                                if (
                                  val2.Recomende_Status === "D" ||
                                  val2.course_typeName === "Workshop" ||
                                  val2.course_typeName === "Internship"
                                ) {
                                  return null;
                                }
                                return (
                                  <SingleRecomenedCourse
                                    cousesName={val1.course_name}
                                    key={key2}
                                    courseData={val2}
                                  />
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default RecomendeCourses;
