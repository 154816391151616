import React, { useEffect, useState } from "react";
import "./RecodedClasses.scss";
import { NavLink, useParams } from "react-router-dom";
import SingleRecodedClassVideoModule from "./SingleRecodedClassVideoModule";
import { getRecodedStudentClassModules } from "../../../../../../Redux/Actions/MyCourseActions";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../../../../../LoadingComponent/Loading";
import { setenrolledRecodedCourseModules } from "../../../../../../Redux/RedusersCompo/MyCourseReducer";
const RecodedClasses = () => {
  const { courseId, studentBatchId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const enrolledRecodedCourseModules = useSelector(
    (state) => state.mycourseData.enrolledRecodedCourseModules
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getRecodedStudentClassModules(courseId);
      dispatch(setenrolledRecodedCourseModules(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, courseId]);

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="EnrolledRecordedCoursesRecodedClasses-main-container">
      <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/sessionLinks`}
              className={"link"}
            >
              {" "}
              session links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="EnrolledRecordedCoursesRecodedClasses-module-Container">
        {enrolledRecodedCourseModules &&
          enrolledRecodedCourseModules?.map((val, key) => {
            return (
              <SingleRecodedClassVideoModule key={key} modeuleData={val} />
            );
          })}
      </div>
    </div>
  );
};

export default RecodedClasses;
