import React, { useEffect } from "react";
import "./CourseStructure.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "aos";
import "aos/dist/aos.css";
const CourseStructure = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  let sotedModuleData = [];
  if (props.moduls && props.moduls.length > 0) {
    sotedModuleData = [...props.moduls].sort((a, b) => a.order - b.order);
  }

  return (
    <div className="CourseStructure-main-container">
      <div className="CourseStructure-container">
        {props.moduleHeading === "NO" ? (
          <h4 data-aos="fade-up-right" data-aos-duration="1000">
            Program Structure
          </h4>
        ) : (
          <h4>{props.moduleHeading}</h4>
        )}

        <span data-aos="zoom-in" data-aos-duration="900">
          {props.duriationDesc}
        </span>
        <div className="CourseStructure-content">
          {sotedModuleData &&
            sotedModuleData?.map((val, key) => {
              const val1 = [...val?.lessons].sort((a, b) => a.order - b.order);
              return (
                <Accordion key={key} className="Accordion">
                  <AccordionSummary
                    id={`panel${key}-header`}
                    aria-controls={`panel${key}-content`}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="h6" className="Typography-head">
                      {key + 1}
                      {".   "}
                      {val.module_Title}
                    </Typography>
                  </AccordionSummary>

                  {val1 &&
                    val1?.map((val1, key1) => {
                      return (
                        <AccordionDetails key={key1}>
                          <Typography variant="h6" className="Typography-desc">
                            {"    "}
                            <i class="fa-solid fa-angles-right"></i>
                            {"  "}
                            {val1.lesson_Tites}
                          </Typography>
                        </AccordionDetails>
                      );
                    })}
                </Accordion>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CourseStructure;
