import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
  eventVideo: [],
  juzzitJobs: [],
  jobDetails: [],
  landingPageData: [],
};

const EventReducer = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEvents(state, action) {
      state.events = action.payload;
    },
    setEventVideo(state, action) {
      state.eventVideo = action.payload;
    },
    setJuzzitJobs(state, action) {
      state.juzzitJobs = action.payload;
    },

    setJobDetails(state, action) {
      state.jobDetails = action.payload;
    },

    setlandingPageData(state, action) {
      state.landingPageData = action.payload;
    },
  },
});

export const {
  setEvents,
  setEventVideo,
  setJuzzitJobs,
  setJobDetails,
  setlandingPageData,
} = EventReducer.actions;

export default EventReducer.reducer;
