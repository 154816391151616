import React from "react";
import "./SingleFreeCourse.scss";
import { Button } from "@mui/material";
const SingleFreeCourse = () => {
  return (
    <div className="SingleFreeCourse-main-container">
      <div className="SingleFreeCourse-top-container"></div>
      <div className="SingleFreeCourse-bottom-container"></div>{" "}
      <div className="SingleEvent-loading-conatent">
        <div className="loading-animation"></div>
        <h2>Coming soon</h2>
      </div>
      {/* <div className="SingleEvent-img-content">
        <img
          src="https://d2beiqkhq929f0.cloudfront.net/public_assets/assets/000/064/543/original/LP_-_2024-02-09T151827.195.webp?1707472484"
          alt="sss"
        />
      </div>
      <div className="SingleEvent-main-contant">
        <h2>What does it take to become a Microsoft SDE?</h2>
        <span>STARTS ON: February 21, 2024 7:00 PM (IST)</span>
        <span>ENDS ON: February 21, 2024 8:00 PM (IST)</span>
      </div>*/}
      <div className="buttom-contant">
        <Button variant="contained">Enroll Now</Button>
      </div>
    </div>
  );
};

export default SingleFreeCourse;
