import React, { useEffect, useState } from "react";
import "./SocialLinks.scss";
// import { useNavigate } from "react-router-dom";
import { setprofileSocialLinks } from "../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateProfileSolialLinks,
  addProfileSolialLinks,
  getProfileSolialLinks,
} from "../../../../../../../Redux/Actions/MyCourseActions";
import { Button } from "@mui/material";
import Loading from "../../../../../../../LoadingComponent/Loading";
import { getStudentDataByToken } from "../../../../../../../Redux/Actions/AuthAction";
import { setStudentDataToStore } from "../../../../../../../Redux/RedusersCompo/AuthReducer";
import SettingSideBar from "../SettingSideBar";
const SocialLinks = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  // const userData = localStorage.getItem("userData");
  // let user = {};
  // if (userData) {
  //   user = JSON.parse(userData);
  // }

  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  const profileSocialLinks = useSelector(
    (state) => state.mycourseData.profileSocialLinks
  );

  const [FacebookLink, setFacebookLink] = useState("");

  const [TwitterLink, setTwitterLink] = useState("");
  const [LinkedinLink, setLinkedinLink] = useState("");
  const [WebsiteLink, setWebsiteLink] = useState("");
  const [GithubLink, setGithubLink] = useState("");
  const [showUpdateStatus, setshowUpdateStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (profileSocialLinks && profileSocialLinks.length > 0) {
      setFacebookLink(profileSocialLinks[0]?.facebook_link);
      setTwitterLink(profileSocialLinks[0]?.twitter_link);
      setLinkedinLink(profileSocialLinks[0]?.linkedin_link);
      setWebsiteLink(profileSocialLinks[0]?.website_link);
      setGithubLink(profileSocialLinks[0]?.github_link);
    }
  }, [profileSocialLinks]);
  const addProfileLinks = async () => {
    const socialLinks = {
      Facebook_link: FacebookLink,
      Linkedin_links: LinkedinLink,
      Github_link: GithubLink,
      Website_link: WebsiteLink,
      Twitter_link: TwitterLink,
    };

    if (profileSocialLinks && profileSocialLinks.length > 0) {
      setIsLoading(true);
      const response = await UpdateProfileSolialLinks(
        profileSocialLinks[0]?.SOCIAL_LINKS_ID,
        socialLinks
      );
      setIsLoading(false);

      if (response?.status === true) {
        setshowUpdateStatus(true);
      }
    } else {
      setIsLoading(true);
      const response = await addProfileSolialLinks(
        userDetails && userDetails?.id,
        socialLinks
      );
      setIsLoading(false);
      if (response?.status === true) {
        setshowUpdateStatus(true);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getProfileSolialLinks(userDetails && userDetails?.id);
      dispatch(setprofileSocialLinks(data));
    };
    getData();
  }, [dispatch, userDetails]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="SocialLinks-main-container">
      <div
        className={`MyProfileSetting-successfull-main-container ${
          showUpdateStatus ? "showUpdatedStatus" : ""
        }`}
      >
        <div className="MyProfileSetting-successfull-container">
          <h1>Detaild updated successfully</h1>
          <Button
            onClick={() => setshowUpdateStatus(false)}
            variant="contained"
          >
            ok
          </Button>
        </div>
      </div>

      <SettingSideBar />
      {/* <div className="SocialLinks-links-contant">
        <ul>
          <li onClick={() => navigate("/DashBoard/Myprofile/setting")}>
            Profile
          </li>
          <li onClick={() => navigate("/DashBoard/Myprofile/resetPassWord")}>
            PassWord
          </li>
          <li
            className="active"
            onClick={() => navigate("/DashBoard/Myprofile/socialLinks")}
          >
            social links
          </li>
        </ul>
      </div> */}
      <div className="SocialLinks-main-content-container">
        <div className="SocialLinks-main-content">
          <ul>
            <li>
              <h1>
                <i class="fa-brands fa-facebook-f"></i>Facebook
              </h1>
              <input
                value={FacebookLink}
                onChange={(e) => setFacebookLink(e.target.value)}
                type="text"
              ></input>
            </li>
            <li>
              <h1>
                <i class="fa-brands fa-twitter"></i>Twitter
              </h1>
              <input
                type="text"
                value={TwitterLink}
                onChange={(e) => setTwitterLink(e.target.value)}
              ></input>
            </li>
            <li>
              <h1>
                <i class="fa-brands fa-linkedin-in"></i>Linkedin
              </h1>
              <input
                value={LinkedinLink}
                onChange={(e) => setLinkedinLink(e.target.value)}
                type="text"
              ></input>
            </li>
            <li>
              <h1>
                <i class="fa-solid fa-globe"></i>Website
              </h1>
              <input
                onChange={(e) => setWebsiteLink(e.target.value)}
                value={WebsiteLink}
                type="text"
              ></input>
            </li>
            <li>
              <h1>
                {" "}
                <i class="fa-brands fa-github"></i>Github
              </h1>
              <input
                onChange={(e) => setGithubLink(e.target.value)}
                value={GithubLink}
                type="text"
              ></input>
            </li>
          </ul>
          <Button className="button" onClick={addProfileLinks}>
            update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
