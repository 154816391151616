import React, { useState, useEffect } from "react";
import HeaderContent from "./HeaderContentComponents/HeaderContent";
import "./DemoHeader.scss";
import logo from "../../../img/logo/juzz-it_logo.jpg";
import { Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { getAllCourseAndCourseType } from "../../../Redux/Actions/CourcesAction";
import { addHeaderAllCourse } from "../../../Redux/RedusersCompo/CourcesReducer";
import { getStudentDataByToken } from "../../../Redux/Actions/AuthAction";
import { setStudentDataToStore } from "../../../Redux/RedusersCompo/AuthReducer";
import { getBranchData } from "../../../Redux/Actions/HomePageActions";
import courseLogo from "../../../img/Home/course_icon.svg";
const DemoHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const [homeSideBarContent, setHomeSideBarContent] = useState(false);
  const [branch, setBranch] = useState("");

  const [courseName, setCourseName] = useState("");

  const [showSideBar, setShowSideBar] = useState(false);
  const [sideBarBranch, setsideBarBranch] = useState("");
  const [sideBarCourse, setsideBarCourse] = useState("");

  const [showSideBranch, setShowSideBranch] = useState(false);
  const [showSideCourses, setShowSideCourses] = useState(false);
  const [showSideCoursesType, setshowSideCoursesType] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseCourseTypes = useSelector(
    (state) => state.courseData.headerAllCourse
  );

  const hadleNavigation = (url) => {
    setShowSideBar(false);
    navigate(url);
    setShowSideBranch(false);
    setShowSideCourses(false);
    setshowSideCoursesType(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  let selectedBranch = [];

  useEffect(() => {
    if (courseCourseTypes && courseCourseTypes.length > 0) {
      const selectedBranchData = courseCourseTypes?.filter((a) => {
        return a.branch_name === branch;
      });

      if (
        selectedBranchData[0]?.coueses &&
        selectedBranchData[0]?.coueses.length > 0
      ) {
        const sortedCourse = [...selectedBranchData[0]?.coueses]?.sort(
          (a, b) => {
            return a.order - b.order;
          }
        );

        if (sortedCourse && sortedCourse.length > 0) {
          const sortedActiveData = sortedCourse?.filter((a) => {
            return a.course_active === "A";
          });

          if (sortedActiveData && sortedActiveData.length > 0) {
            setCourseName(sortedActiveData[0]?.course_name);
          }
        }
      }
    }
  }, [courseCourseTypes, branch]);

  useEffect(() => {
    const getData = async () => {
      const data = await getBranchData();

      let sortedDataActive = [];

      if (data && data.length > 0) {
        const sortedData = [...data]?.sort((a, b) => {
          return a.order - b.order;
        });

        if (sortedData && sortedData.length > 0) {
          sortedDataActive = sortedData?.filter((a) => {
            return a.active_status === "A";
          });
          setBranch(sortedDataActive[0]?.branch_name);
        }
      }
      dispatch(addHeaderAllCourse(sortedDataActive));
    };
    getData();
  }, [dispatch]);

  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  if (courseCourseTypes && courseCourseTypes?.length > 0) {
    const selected = courseCourseTypes?.filter((a) => {
      return a.branch_name === branch;
    });

    if (selected && selected.length > 0) {
      selectedBranch = selected;
    }
  }

  let selectedActiveCourses = [];

  if (selectedBranch && selectedBranch.length > 0) {
    if (selectedBranch[0]?.coueses && selectedBranch[0]?.coueses.length > 0) {
      const sotrtedData = [...selectedBranch[0]?.coueses]?.sort((a, b) => {
        return a.order - b.order;
      });

      if (sotrtedData && sotrtedData.length > 0) {
        const selectedCourses = sotrtedData?.filter((a) => {
          return a.course_name === courseName;
        });

        if (selectedCourses && selectedCourses.length > 0) {
          selectedActiveCourses = selectedCourses?.filter((a) => {
            return a.course_active === "A";
          });

          if (selectedActiveCourses && selectedActiveCourses.length > 0) {
          }
        }
      }
    }
  }

  console.log(sideBarCourse);

  return (
    <div className="Header-all-main-container">
      <HeaderContent />
      <div className={`Header-main-container ${isScrolled ? "scrolled" : ""}`}>
        <div className="Header-main-content">
          <div onClick={() => hadleNavigation("/")} className="logo-container">
            <img src={logo} alt="juzz-it-logo"></img>
          </div>
          <div className={`nav-bar-main-container`}>
            <ul>
              <li className="course-content">
                Courses<i class="fa-solid fa-angle-down"></i>
                <div className="drop-down">
                  <div className="drop-down-main-content">
                    <div className="drop-branch-content">
                      {courseCourseTypes && courseCourseTypes.length > 0 ? (
                        <ul>
                          {courseCourseTypes &&
                            courseCourseTypes?.map((val, key) => {
                              if (val.active_status === "D") {
                                return null;
                              }
                              return (
                                <li
                                  onClick={() => setBranch(val.branch_name)}
                                  key={key}
                                  className={`${
                                    val.branch_name === branch
                                      ? "selectedBranch"
                                      : ""
                                  }`}
                                >
                                  <span>{val.branch_name}</span>
                                  <i class="fa-solid fa-caret-right"></i>
                                </li>
                              );
                            })}
                        </ul>
                      ) : (
                        <div className="comming-soon-data">
                          <span>coming soon</span>
                        </div>
                      )}
                    </div>
                    <div className="drop-course-content">
                      {selectedBranch &&
                      selectedBranch.length > 0 &&
                      selectedBranch[0]?.coueses &&
                      selectedBranch[0]?.coueses.length ? (
                        <ul>
                          {selectedBranch &&
                            selectedBranch.length > 0 &&
                            selectedBranch?.map((val, key) => {
                              if (val.course_active === "D") {
                                return null;
                              }

                              return (
                                <>
                                  {val.coueses?.map((val1, key1) => {
                                    if (val1.course_active === "D") {
                                      return null;
                                    }
                                    return (
                                      <li
                                        onClick={() =>
                                          setCourseName(val1.course_name)
                                        }
                                        className={`${
                                          val1.course_name === courseName
                                            ? "selectedCourse"
                                            : ""
                                        }`}
                                      >
                                        <span>{val1.course_name}</span>{" "}
                                        <i class="fa-solid fa-caret-right"></i>
                                      </li>
                                    );
                                  })}
                                </>
                              );
                            })}
                        </ul>
                      ) : (
                        <div className="comming-soon-data">
                          <span>coming soon</span>
                        </div>
                      )}
                    </div>
                    <div className="drop-courseType-content">
                      {selectedActiveCourses &&
                      selectedActiveCourses.length > 0 &&
                      selectedActiveCourses[0]?.courseType &&
                      selectedActiveCourses[0]?.courseType.length > 0 ? (
                        <ul>
                          {selectedActiveCourses &&
                            selectedActiveCourses?.map((val, key) => {
                              return (
                                <>
                                  {val.courseType?.map((val1, key2) => {
                                    if (
                                      val1.active_courseType === "A"
                                      // && val1.course_typeName !== "Workshop" &&
                                      // val1.course_typeName !== "Internship"
                                    ) {
                                      return (
                                        <li
                                          onClick={() => {
                                            hadleNavigation(
                                              `/courseType/${val1.course_typeId}`
                                            );
                                          }}
                                        >
                                          <img src={courseLogo} alt="cs"></img>
                                          <span>{val1.course_Heading}</span>
                                        </li>
                                      );
                                    }
                                    return null;
                                  })}
                                </>
                              );
                            })}
                        </ul>
                      ) : (
                        <div className="comming-soon-data">
                          <span>coming soon</span>
                        </div>
                      )}
                      <h2
                        onClick={() => {
                          hadleNavigation("/Cources");
                        }}
                      >
                        All Courses
                      </h2>
                    </div>
                  </div>
                </div>
              </li>
              <li
                onClick={() => {
                  hadleNavigation("/AcademicTraining");
                }}
              >
                Academic Training & Internship
              </li>
              {/* <li
                onClick={() => {
                  hadleNavigation("/Workshops");
                }}
              >
                Workshops
              </li> */}
              {/* <li
                onClick={() => {
                  hadleNavigation("/Internships");
                }}
              >
                Internships
              </li> */}
              {/* <li
                onClick={() => {
                  hadleNavigation("/About");
                }}
              >
                About
              </li> */}
              <li
                onClick={() => {
                  hadleNavigation("/OurAlumini");
                }}
              >
                Our Alumni
              </li>
              <li
                onClick={() => {
                  hadleNavigation("/FreeCourses");
                }}
              >
                Free Courses
              </li>
              <li
                onClick={() => {
                  hadleNavigation("/Events");
                }}
              >
                Events
              </li>
              <li
                onClick={() => {
                  hadleNavigation("/JuzzItJobs");
                }}
              >
                Jobs
              </li>
            </ul>
          </div>

          <div className="sidebar-juzz-it-contant">
            <span>
              {tokenData ? (
                <span
                  to={"/DashBoard"}
                  onClick={() => hadleNavigation(`/DashBoard`)}
                  className="link"
                >
                  Dashboard
                </span>
              ) : (
                <span to={"/LogIn"} onClick={() => hadleNavigation(`/LogIn`)}>
                  <Button
                    variant="outlined"
                    className="loginButton"
                    activeclassName="active"
                    size="large"
                  >
                    login
                  </Button>
                </span>
              )}
            </span>
            <div className="sidebar-icon">
              {" "}
              <i
                onClick={() => {
                  setShowSideBar(true);
                }}
                class="fa-solid fa-bars"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`about-us-main-home-sidebar-container ${
          homeSideBarContent ? "homeSideBarContent" : ""
        }`}
      >
        <div className="about-us-main-home-sidebar-divider"></div>
        <div className="about-us-sidebar-dcjas-content">
          <div className="about-us-main-home-sidebar-content">
            <i
              onClick={() => {
                setHomeSideBarContent(false);
              }}
              class="fa-solid fa-x"
            ></i>
            <div className="about-us-main-home-logo">
              <img src={logo} alt="dcs"></img>
              <h1>Juzz IT Education</h1>
            </div>
            <div className="about-us-main-home-intruduction">
              <p>
                Juzz IT believes in the transformative power of education and
                the boundless opportunities it offers. As an innovative online
                learning platform, we are dedicated to providing accessible and
                engaging educational experiences to learners around the globe.
              </p>
              <span>Become An Instructor</span>
            </div>

            <div className="about-us-main-home-address">
              <p>
                2nd Floor, #827/6, D.V.R ARCADE, Ramamurthi Nagar Main Rd,
                Dayananda Layout, Mukkutam Nagar, Ramamurthy Nagar, Bengaluru,
                <br></br> Karnataka 560030<br></br>
                +91 9353822274
              </p>
              <span>
                <i class="fa-reg  ular fa-envelope"></i>education@juzzit.in
              </span>

              <ul>
                <li>
                  <i class="fa-brands fa-facebook-f"></i>
                </li>
                <li>
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </li>
                <li>
                  <i class="fa-brands fa-twitter"></i>
                </li>
                <li>
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`DemoHeader-main-sideBar-containainer ${
          showSideBar ? "showSideBar" : ""
        }`}
      >
        <div className="DemoHeader-sideBar-container">
          <div className="DemoHeader-sideBar-container-scross">
            <div
              onClick={() => hadleNavigation("/")}
              className="logo-container"
            >
              <img src={logo} alt="juzz-it-logo"></img>
            </div>
            <i class="fa-solid fa-x" onClick={() => setShowSideBar(false)}></i>
          </div>

          <div className="sideBare_nav-container">
            <ul>
              <li>
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> Courses{" "}
                </span>
                <i
                  class="fa-solid fa-right-long"
                  onClick={() => {
                    setShowSideBranch(true);
                  }}
                ></i>
              </li>
              <li
                onClick={() => {
                  hadleNavigation("/Cources");
                }}
              >
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> All Courses{" "}
                </span>
              </li>
              {/* <li
                onClick={() => {
                  hadleNavigation("/Workshops");
                }}
              >
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> Workshops{" "}
                </span>
              </li> */}
              <li
                onClick={() => {
                  hadleNavigation("/AcademicTraining");
                }}
              >
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> Academic Training{" "}
                </span>
              </li>
              {/* <li
                onClick={() => {
                  hadleNavigation("/Internships");
                }}
              >
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> Internships{" "}
                </span>
              </li> */}

              <li
                onClick={() => {
                  hadleNavigation("/OurAlumini");
                }}
              >
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> Our Alumni{" "}
                </span>
              </li>

              <li
                onClick={() => {
                  hadleNavigation("/FreeCourses");
                }}
              >
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> Free Courses
                </span>
              </li>

              <li
                onClick={() => {
                  hadleNavigation("/Events");
                }}
              >
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> Events{" "}
                </span>
              </li>
              <li
                onClick={() => {
                  hadleNavigation("/JuzzItJobs");
                }}
              >
                <span>
                  {" "}
                  <i class="fa-solid fa-angles-right"></i> Jobs{" "}
                </span>
              </li>
            </ul>
            <div
              className={`sideBar-branchs-container ${
                showSideBranch ? "showSideBranch" : ""
              }`}
            >
              <ul>
                {courseCourseTypes &&
                  courseCourseTypes?.map((val, key) => {
                    if (val.active_status === "D") {
                      return null;
                    }
                    return (
                      <li>
                        <span>
                          {" "}
                          <i class="fa-solid fa-angles-right"></i>{" "}
                          {val.branch_name}{" "}
                        </span>{" "}
                        <i
                          class="fa-solid fa-right-long"
                          onClick={() => {
                            setShowSideCourses(true);
                            setShowSideBranch(false);
                            setsideBarBranch(val.branch_name);
                          }}
                        ></i>
                      </li>
                    );
                  })}
              </ul>
              <span className="side-bar-back">
                <i
                  class="fa-solid fa-left-long"
                  onClick={() => {
                    setShowSideBranch(false);
                  }}
                ></i>
              </span>
            </div>
            <div
              className={`sideBar-course-container ${
                showSideCourses ? "showSideCourses" : ""
              }`}
            >
              <ul>
                {courseCourseTypes &&
                  courseCourseTypes.length > 0 &&
                  courseCourseTypes?.map((val, key) => {
                    if (val.active_status === "D") {
                      return null;
                    }

                    if (val.branch_name === sideBarBranch) {
                      return (
                        <>
                          {val.coueses?.map((val1, key1) => {
                            if (val1.course_active === "D") {
                              return null;
                            }
                            return (
                              <li
                                onClick={() => setCourseName(val1.course_name)}
                              >
                                <span>
                                  <i class="fa-solid fa-angles-right"></i>{" "}
                                  {val1.course_name}
                                </span>
                                <i
                                  class="fa-solid fa-right-long"
                                  onClick={() => {
                                    setshowSideCoursesType(true);
                                    setShowSideCourses(false);
                                    setShowSideBranch(false);
                                    setsideBarCourse(val1.course_name);
                                  }}
                                ></i>
                              </li>
                            );
                          })}
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
              </ul>
              <span className="side-bar-back">
                <i
                  class="fa-solid fa-left-long"
                  onClick={() => {
                    setShowSideCourses(false);
                    setShowSideBranch(true);
                  }}
                ></i>
              </span>
            </div>
            <div
              className={`sideBar-courseType-container ${
                showSideCoursesType ? "showSideCoursesType" : ""
              }`}
            >
              <ul>
                {courseCourseTypes &&
                  courseCourseTypes.length > 0 &&
                  courseCourseTypes.map((val, key) => {
                    if (val.active_status === "D") {
                      return null;
                    }

                    if (val.branch_name === sideBarBranch) {
                      return (
                        <>
                          {val.coueses?.map((val1, key1) => {
                            if (val1.course_active === "D") {
                              return null;
                            }

                            if (val1.course_name === sideBarCourse) {
                              return (
                                <>
                                  {val1.courseType?.map((val2, key2) => {
                                    if (
                                      val2.active_courseType === "A"
                                      //&& val2.course_typeName !== "Workshop" &&
                                      // val2.course_typeName !== "Internship"
                                    ) {
                                      return (
                                        <li
                                          onClick={() => {
                                            hadleNavigation(
                                              `/courseType/${val2.course_typeId}`
                                            );
                                          }}
                                        >
                                          <span>
                                            <i class="fa-solid fa-angles-right"></i>{" "}
                                            {val2.course_Heading}
                                          </span>
                                        </li>
                                      );
                                    }
                                    return null;
                                  })}
                                </>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
              </ul>
              <span className="side-bar-back">
                <i
                  class="fa-solid fa-left-long"
                  onClick={() => {
                    setshowSideCoursesType(false);
                    setShowSideCourses(true);
                    setShowSideBranch(false);
                  }}
                ></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoHeader;
