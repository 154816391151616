import React from "react";
import "./WhatRolesCanPlay.scss";
const WhatRolesCanPlay = (props) => {
  const {
    tools,
    toolsTomaster,
    employeeRoles,
    skillsCondition,
    skillsHeading,
    toolsCondition,
    toolsHeading,
    employeeRolesCondition,
    employeeRolesHeading,
  } = props;

  console.log(toolsTomaster);

  return (
    <div className="WhatRolesCanPlay-main-container">
      <div className="WhatRolesCanPlay-container">
        <div className="WhatRolesCanPlay-contnet-container">
          {employeeRolesCondition === "A" ? (
            <div className="WhatRolesCanPlay-top-contant">
              {employeeRolesHeading === "NO" ? (
                <h1>
                  What roles can a candidate apply for after completing this
                  program{" "}
                </h1>
              ) : (
                <h1>{employeeRolesHeading}</h1>
              )}

              <div className="WhatRolesCanPlay-main-contant">
                {employeeRoles &&
                  employeeRoles?.map((val, key) => {
                    return (
                      <div className="WhatRolesCanPlay-content">
                        <h3>{val.ROLE_HEADING}</h3>
                        <p>{val.ROLE_DESC}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {skillsCondition === "A" ? (
            <div className="WhatRolesCanPlay-center-contant">
              {skillsHeading === "NO" ? (
                <h1>Skills to Master</h1>
              ) : (
                <h1>{skillsHeading}</h1>
              )}

              <div className="WhatRolesCanPlay-center-main-contant">
                {tools &&
                  tools?.map((val, key) => {
                    return (
                      <div key={key} className="ToolAndTechnology-content">
                        <span>{val.tool_name}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {toolsCondition === "A" ? (
            <div className="WhatRolesCanPlay-bottom-contant">
              {toolsHeading === "NO" ? (
                <h1>Tools And Technology to Master</h1>
              ) : (
                <h1>{toolsHeading}</h1>
              )}

              <div className="WhatRolesCanPlay-toll-content">
                {tools &&
                  tools?.map((val, key) => {
                    return (
                      <div key={key} className="ToolAndTechnology-content">
                        {val.tool_images?.map((val1, key1) => {
                          if (val1.active_Status === "D") {
                            return null;
                          }
                          return <img src={val1.image_URL} alt="qwhsiyu"></img>;
                        })}
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* {toolsCondition === "A" ? (
            <div className="WhatRolesCanPlay-bottom-contant">
              {toolsHeading === "NO" ? (
                <h1>Tools And Technology to Master</h1>
              ) : (
                <h1>{toolsHeading}</h1>
              )}

              <div className="WhatRolesCanPlay-toll-content">
                {toolsTomaster &&
                  toolsTomaster?.map((val, key) => {
                    if (val.Active_Status === "D") {
                      return null;
                    }

                    return (
                      <div key={key} className="ToolAndTechnology-content">
                        <img src={val.image_URL} alt="qwhsiyu"></img>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};

export default WhatRolesCanPlay;
