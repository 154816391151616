import React from "react";
import SignInWithEmail from "./SignInWithEmailComponet/SignInWithEmail";
import logo from "../../img/logo/juzz-it_logo.jpg";
import "./SignIn.scss";
import { Button } from "@mui/material";
const SignIn = (props) => {
  return (
    <div className="SignIn-container">
      <div className="signin-content">
        <img src={logo} alt="logo"></img>
        <h2>Wellcome To JUZZ-IT</h2>
        <h5>Good To See You Again...!</h5>
        <h4>Don't have an account?</h4>
        <Button
          variant="contained"
          className="button"
          onClick={props.setIsLogn}
        >
          SignUp
        </Button>
      </div>

      <div className="signin-type-container">
        <SignInWithEmail />
      </div>
    </div>
  );
};

export default SignIn;
