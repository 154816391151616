import { configureStore } from "@reduxjs/toolkit";
import CoursesReducer from "./RedusersCompo/CourcesReducer";
import AuthReducer from "./RedusersCompo/AuthReducer";
import MyCourseReducer from "./RedusersCompo/MyCourseReducer";

import BlogReducer from "./RedusersCompo/BlogReducer";
import TeamMemberReducer from "./RedusersCompo/TeamMemberReducer";
import HomePageReducer from "./RedusersCompo/HomePageReducer";
import EventReducer from "./RedusersCompo/EventReducer";

const store = configureStore({
  reducer: {
    courseData: CoursesReducer,
    authdata: AuthReducer,
    mycourseData: MyCourseReducer,

    blogData: BlogReducer,
    teamMemberData: TeamMemberReducer,
    HomePageData: HomePageReducer,
    EventData: EventReducer,
  },
});

export default store;
