import React from "react";
//import CourseStructure from "../../Components/CourseStructureComponent/CourseStructure";
import "./Curriculum.scss";
import CourseStructure from "../CourseStructureComponent/CourseStructure";
const Curriculum = ({
  moduls,
  duriationDesc,
  prigramHighlite,
  programhighliteCondition,
  programhighliteHeading,
  moduleHeading,
}) => {
  return (
    <div className="Curriculum-main-container">
      <div className="Curriculum-container">
        <div className="Curriculum-left-container">
          <CourseStructure
            moduleHeading={moduleHeading}
            duriationDesc={duriationDesc}
            moduls={moduls}
          />
        </div>
        {programhighliteCondition === "A" ? (
          <div className="Curriculum-right-container">
            <div className="Curriculum-right-content">
              {programhighliteHeading === "NO" ? (
                <h1>Program Highlights</h1>
              ) : (
                <h1>{programhighliteHeading}</h1>
              )}

              <ul>
                {prigramHighlite &&
                  prigramHighlite?.map((val, key) => {
                    return (
                      <li>
                        <i class="fa-solid fa-arrow-right"></i>
                        {val.Program_Highlite}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Curriculum;
