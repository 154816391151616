import React, { useEffect, useState } from "react";
import "./MyProfile.scss";
import {
  UpdateProfileImage,
  addProfileImage,
  getProfileImage,
  getStudentProfile,
} from "../../../../../../Redux/Actions/MyCourseActions";
import { useDispatch, useSelector } from "react-redux";
import {
  setStudentProfile,
  setprofileImage,
} from "../../../../../../Redux/RedusersCompo/MyCourseReducer";
import Loading from "../../../../../../LoadingComponent/Loading";
import { setStudentDataToStore } from "../../../../../../Redux/RedusersCompo/AuthReducer";
import { getStudentDataByToken } from "../../../../../../Redux/Actions/AuthAction";
import imageplaceHolderImage from "../../../../../../img/Profile image/photo.png";
import { uploadImage } from "../../../../../../Redux/Actions/CloudenarowImageUplode";
const MyProfile = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [showImageForm, setshowImageForm] = useState(false);
  const StudentProfile = useSelector(
    (state) => state.mycourseData.StudentProfile
  );

  const profileImageData = useSelector(
    (state) => state.mycourseData.profileImage
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  useEffect(() => {
    const getData = async () => {
      const data = await getProfileImage(userDetails && userDetails?.id);
      dispatch(setprofileImage(data));
    };
    getData();
  }, [dispatch, userDetails]);

  // const userData = localStorage.getItem("userData");
  // let user = {};
  // if (userData) {
  //   user = JSON.parse(userData);
  // }
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getStudentProfile(userDetails && userDetails.id);
      dispatch(setStudentProfile(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, userDetails]);

  const handleAddImage = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = await uploadImage(profileImage);
    setIsLoading(false);
    if (data) {
      const newImageData = {
        image_URL: data?.data?.secure_url,
      };
      if (
        profileImageData &&
        profileImageData[0]?.imageData &&
        profileImageData[0]?.imageData.length > 0
      ) {
        const responce = await UpdateProfileImage(
          profileImageData &&
            profileImageData[0]?.imageData &&
            profileImageData[0]?.imageData[0]?.IMAGE_ID,
          newImageData
        );
        if (responce?.status === true) {
          const data = await getProfileImage(userDetails && userDetails?.id);
          dispatch(setprofileImage(data));
          setshowImageForm(false);
          setProfileImage(null);
        }
      } else {
        const responce = await addProfileImage(
          userDetails && userDetails?.id,
          newImageData
        );
        if (responce?.status === true) {
          const data = await getProfileImage(userDetails && userDetails?.id);
          dispatch(setprofileImage(data));
          setshowImageForm(false);
          setProfileImage(null);
        }
      }
    }
  };
  if (isLoading) {
    <Loading />;
  }

  let formattedDate = "";
  if (StudentProfile && StudentProfile.length > 0) {
    const timestamp = StudentProfile[0]?.created_Data;
    const date = new Date(timestamp);

    const options = { year: "numeric", month: "long", day: "numeric" };
    formattedDate = date.toLocaleDateString("en-US", options);
  }

  return (
    <div className="MyProfile-main-container">
      <div
        className={`profileimage-upload-container ${
          showImageForm ? "showImageForm" : ""
        }`}
      >
        <div className="profileimage-upload-form">
          <i class="fa-solid fa-x" onClick={() => setshowImageForm(false)}></i>

          <div className="profileimage-uploaded-img">
            {profileImageData &&
              profileImageData[0]?.imageData &&
              profileImageData[0]?.imageData.length > 0 && (
                <img
                  src={
                    profileImageData &&
                    profileImageData[0]?.imageData &&
                    profileImageData[0]?.imageData[0]?.IMAGE_URL
                  }
                  alt="profileImage"
                ></img>
              )}

            {profileImage && (
              <img src={URL.createObjectURL(profileImage)} alt="User Profile" />
            )}
          </div>
          <form onSubmit={handleAddImage}>
            <input
              type="file"
              onChange={(e) => setProfileImage(e.target.files[0])}
            ></input>
            <button type="submit">upload</button>
          </form>
        </div>
      </div>
      <div className="myprofile-image-content">
        <div className="myprofile-image">
          {profileImageData &&
          profileImageData.length > 0 &&
          profileImageData[0]?.imageData &&
          profileImageData[0]?.imageData.length > 0 &&
          !profileImage ? (
            <img
              src={
                profileImageData &&
                profileImageData[0]?.imageData &&
                profileImageData[0]?.imageData[0]?.IMAGE_URL
              }
              alt="imajjge"
            ></img>
          ) : (
            <img src={imageplaceHolderImage} alt="imajjge"></img>
          )}

          <div className="edite-image-data">
            {/* <img
                  onClick={() => setshowImageForm(true)}
                  src={editeImage}
                  alt="sdfcs"
                ></img> */}
            <i onClick={() => setshowImageForm(true)} class="fas fa-edit"></i>
          </div>
        </div>
        <h1>
          Name :{" "}
          <span>{profileImageData && profileImageData[0]?.studentName}</span>
        </h1>
        <h1>
          last updated: <span>{formattedDate}</span>
        </h1>
      </div>
      <div className="MyProfile-container">
        <h1>MyProfile</h1>
        <div className="MyProfile-main-content">
          <div className="MyProfile-content">
            <h2>Registration Date</h2>
            <p>{formattedDate}</p>
          </div>
          <div className="MyProfile-content">
            <h2>Usernames</h2>
            <p>{StudentProfile && StudentProfile[0]?.student_name}</p>
          </div>
          <div className="MyProfile-content">
            <h2> Email</h2>
            <p>{StudentProfile && StudentProfile[0]?.student_email}</p>
          </div>
          <div className="MyProfile-content">
            <h2> Phone Number</h2>
            <p>{StudentProfile && StudentProfile[0]?.mobile_Number}</p>
          </div>
          <div className="MyProfile-content">
            <h2> Address</h2>
            <p>{StudentProfile && StudentProfile[0]?.student_address}</p>
          </div>
          <div className="MyProfile-content">
            <h2> website link</h2>
            <p
              onClick={() => {
                window.open(
                  StudentProfile[0]?.social_Links[0]?.website_link,
                  "_blank"
                );
              }}
            >
              {StudentProfile &&
                StudentProfile[0]?.social_Links[0]?.website_link}
            </p>
          </div>
          <div className="MyProfile-content">
            <h2> twitter link</h2>
            <p
              onClick={() => {
                window.open(
                  StudentProfile[0]?.social_Links[0]?.twitter_link,
                  "_blank"
                );
              }}
            >
              {StudentProfile &&
                StudentProfile[0]?.social_Links[0]?.twitter_link}
            </p>
          </div>
          <div className="MyProfile-content">
            <h2> github link</h2>
            <p
              onClick={() => {
                window.open(
                  StudentProfile[0]?.social_Links[0]?.github_link,
                  "_blank"
                );
              }}
            >
              {StudentProfile &&
                StudentProfile[0]?.social_Links[0]?.github_link}
            </p>
          </div>
          <div className="MyProfile-content">
            <h2> facebook link</h2>
            <p
              onClick={() => {
                window.open(
                  StudentProfile[0]?.social_Links[0]?.facebook_link,
                  "_blank"
                );
              }}
            >
              {StudentProfile &&
                StudentProfile[0]?.social_Links[0]?.facebook_link}
            </p>
          </div>
          <div className="MyProfile-content">
            <h2>linkedin link</h2>
            <p
              onClick={() => {
                window.open(
                  StudentProfile[0]?.social_Links[0]?.linkedin_link,
                  "_blank"
                );
              }}
            >
              {StudentProfile &&
                StudentProfile[0]?.social_Links[0]?.linkedin_link}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
