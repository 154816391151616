import React, { useEffect, useState } from "react";
import "./AssignmentDetails.scss";
import {
  addStudentAssignment,
  getAssignmentDetailsById,
  getAssignmentSubmition,
} from "../../../../../../Redux/Actions/MyCourseActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setassignmentSubmitedData,
  setassignmentData,
} from "../../../../../../Redux/RedusersCompo/MyCourseReducer";
import { Button } from "@mui/material";
import { myaxios } from "../../../../../../Redux/ApiBase";

const AssignmentDetails = () => {
  const { studentBatchId, assignmentId } = useParams();

  const [file, setFile] = useState(null);

  //   const [key1, setKey1] = useState("");

  const dispatch = useDispatch();

  const assignmentData = useSelector(
    (state) => state.mycourseData.assignmentData
  );

  const assignmentSubmitedData = useSelector(
    (state) => state.mycourseData.assignmentSubmitedData
  );

  useEffect(() => {
    const getData = async () => {
      const data = await getAssignmentSubmition(assignmentId);
      dispatch(setassignmentSubmitedData(data));
    };
    getData();
  }, [dispatch, assignmentId]);
  useEffect(() => {
    const getData = async () => {
      const data = await getAssignmentDetailsById(assignmentId);
      dispatch(setassignmentData(data));
    };
    getData();
  }, [dispatch, assignmentId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    const response = await addStudentAssignment(
      studentBatchId,
      assignmentId,
      formData
    );
    console.log(response);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const downloadForm = async (filename) => {
    const data = {
      path: filename,
    };

    try {
      const res = await myaxios.post("/api/data/download", data, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const options = { year: "numeric", month: "long", day: "numeric" };

  return (
    <div className="AssignmentDetails-main-container">
      <div className="AssignmentDetails-container">
        <h4>
          Assignment :{" "}
          <span>{assignmentData && assignmentData?.assignment_topic}</span>
        </h4>
        <p>
          <i class="fa-solid fa-arrow-right"></i>{" "}
          {assignmentData && assignmentData?.assignment_desc}
        </p>
        <Button
          variant="contained"
          onClick={() =>
            downloadForm(assignmentData && assignmentData?.file_location)
          }
          className="button"
        >
          download
        </Button>
      </div>
      {assignmentSubmitedData && assignmentSubmitedData?.length > 0 ? (
        <div className="AssignmentDetails-submition">
          <h4>
            submited Date :{" "}
            <span>
              {new Date(
                assignmentSubmitedData &&
                  assignmentSubmitedData[0]?.CREATED_DATE
              ).toLocaleDateString("en-US", options)}
            </span>
          </h4>
          <Button
            variant="contained"
            onClick={() =>
              downloadForm(
                assignmentSubmitedData &&
                  assignmentSubmitedData[0]?.FILE_LOCATION
              )
            }
            className="button"
          >
            download
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <input type="file" id="file" onChange={handleFileChange} />
          </div>

          <div>
            <Button
              className="submite_button"
              variant="contained"
              type="submit"
              color="success"
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AssignmentDetails;
