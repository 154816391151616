import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "./CourseDetailsBar.scss";
import AppsIcon from "@mui/icons-material/Apps";
import { Button } from "@mui/material";
const CourseDetailsBar = () => {
  const { courseId, batchId, studentBatchId } = useParams();

  const [showContentBar, setShowContynetBar] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="CourseDetailsBar-dotbar">
      <AppsIcon
        onClick={() => {
          setShowContynetBar(true);
        }}
      />

      <div
        className={`CourseDetailsBar-main-container ${
          showContentBar ? "showContentBar" : ""
        }`}
      >
        <div className="cross-icon">
          <i
            onClick={() => {
              setShowContynetBar(false);
            }}
            class="fa-solid fa-x"
          ></i>
        </div>

        <ul>
          {" "}
          <NavLink
            to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/details`}
            className={"link"}
          >
            {" "}
            <li>course details </li>
          </NavLink>
          <NavLink
            to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classLinks`}
            className={"link"}
          >
            <li>class links </li>
          </NavLink>
          <NavLink
            to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/placements`}
            className={"link"}
          >
            {" "}
            <li>placements </li>
          </NavLink>
          <NavLink
            to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classes`}
            className={"link"}
          >
            {" "}
            <li>classes </li>
          </NavLink>
        </ul>
        <Button
          onClick={() => {
            navigate(`/DashBoard/MyliveCourses/${studentBatchId}/Refere`);
          }}
          variant="contained"
          className="refer-button"
        >
          refer and earn
        </Button>
      </div>
    </div>
  );
};

export default CourseDetailsBar;
