import React, { useState } from "react";
import "./PrepareToBreakThrough.scss";

import icon1 from "../../../../../img/Home/Prepare To Break/icom1.2.png";
import icon2 from "../../../../../img/Home/Prepare To Break/icon2.1.jpg";
import icon3 from "../../../../../img/Home/Prepare To Break/icon3.1.png";
import icon4 from "../../../../../img/Home/Prepare To Break/icon4.1.png";
import icon5 from "../../../../../img/Home/Prepare To Break/icon5.1.png";
import DemoForm from "../../../DemoFormCompo/DemoForm";
import { Button } from "@mui/material";

const PrepareToBreakThrough = () => {
  const [showForm, setshowForm] = useState(false);
  const [successSubmition, setsuccessSubmition] = useState(false);
  return (
    <div className="PrepareToBreakThrough-main-container">
      <div className={`demo-form ${showForm ? "desplayDemoform" : ""}`}>
        <DemoForm
          SETDisplay={() => setshowForm(!showForm)}
          setsuccessSubmition={() => setsuccessSubmition(true)}
        />
      </div>

      <div
        className={`sucessfully-submited-Request-main ${
          successSubmition ? "successSubmition" : ""
        }`}
      >
        <div className="sucessfully-submited-Request">
          <h1>We received your request. We will reach you soon.</h1>
          <Button
            variant="contained"
            onClick={() => setsuccessSubmition(false)}
          >
            Ok
          </Button>
        </div>
      </div>
      <div className="PrepareToBreakThrough-container">
        <div className="All-components-heading">
          Get ready to make a significant leap
          <br /> into the leading positions
          <br /> within the highly competitive <br /> tech job market.
        </div>
        <div className="all-component-heading-discription">
          Each of our programs includes <span>exclusive Career Services</span>{" "}
          designed to empower you to leverage your knowledge and skills for
          success in your upcoming interviews or to thrive in your present role.
        </div>
        <ul>
          <li>
            <img src={icon1} alt="cas"></img>
            <h5 className="componets-itam-heading">
              Personalized career coaching sessions with leading industry
              experts.
            </h5>
          </li>
          <li>
            <img src={icon2} alt="cas"></img>
            <h5 className="componets-itam-heading">
              Real-time practice interviews with industry professionals.
            </h5>
          </li>
          <li>
            <img src={icon3} alt="cas"></img>
            <h5 className="componets-itam-heading">
              A sequence of simulated assessments and comprehensive interview
              preparation sessions to excel in premier tech job opportunities.
            </h5>
          </li>
          <li>
            <img src={icon4} alt="cas"></img>
            <h5 className="componets-itam-heading">
              Specialized assistance to optimize your profile on platforms such
              as GitHub, LinkedIn, and your resume.
            </h5>
          </li>
          <li>
            <img src={icon5} alt="cas"></img>
            <h5 className="componets-itam-heading">
              Direction on initiating applications for a diverse range of job
              opportunities aligned with your career aspirations.
            </h5>
          </li>
        </ul>
        <div
          className="book-form-button-home"
          onClick={() => {
            setshowForm(true);
          }}
        >
          <span>
            {" "}
            Book Your Free Trial, Now<i class="fa-solid fa-right-long"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PrepareToBreakThrough;
