import React, { useEffect, useState } from "react";
import "./SingleCourseTypeData.scss";
import { getCourseTypeDetails } from "../../../../Redux/Actions/CourcesAction";
import { addcourseTypeAllDetailes } from "../../../../Redux/RedusersCompo/CourcesReducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../LoadingComponent/Loading";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AboutProgram from "./CourseTypeCompo/AboutProgramComponent/AboutProgram";
import WhoConJoinThisProgram from "./CourseTypeCompo/WhoConJoinThisProgramCompo/WhoConJoinThisProgram";
import WhatRolesCanPlay from "./CourseTypeCompo/WhatRolesCanPlayCompo/WhatRolesCanPlay";
import Curriculum from "./CourseTypeCompo/CurriculumComponent/Curriculum";
import InterestedStudentformCompo from "./CourseTypeCompo/InterestedStudentForm/InterestedStudentformCompo";
import { Button } from "@mui/material";
import NewCourseTypeProject from "./CourseTypeCompo/NewCourseTypeProjectsCompomnent/NewCourseTypeProject";
import PopUpVideoForm from "./CourseTypeCompo/PopUpVideoFormCompo/PopUpVideoForm";
import tsn_Logo from "../../../../img/logo/TSN_logo.jpg";
import logo from "../../../../img/logo/EUAS.jpg";

import iso_logo from "../../../../img/logo/what-is-iso.webp";
import CareerServices from "./CourseTypeCompo/CareerServicesComponent/CareerServices";
import OurAlumniWorksAtCompo from "./CourseTypeCompo/OurAlumniWorksAtComponent/OurAlumniWorksAtCompo";
import FrequentlyAskedQuestions from "./CourseTypeCompo/FrequentlyAskedQuestionsCompo/FrequentlyAskedQuestions";
import ProgramFeeStucture from "./CourseTypeCompo/ProgramFeeStuctureComponent/ProgramFeeStucture";
import CertificateCompo from "./CourseTypeCompo/CertificateComponents/CertificateCompo";
import { Helmet } from "react-helmet";

// import logo from "../../../../img/logo/juzz-it_logo.jpg";

const SingleCourseTypeData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { courseTypeId } = useParams();

  const [desplayPop, setdesplayPop] = useState(false);
  const courseTypeAllDetails = useSelector(
    (state) => state.courseData.courseTypeAllDetailes
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getCourseTypeDetails(courseTypeId);
      dispatch(addcourseTypeAllDetailes(data));
      setIsLoading(false);
    };

    getData();
  }, [dispatch, courseTypeId]);

  let courseTypeDetails = [];
  let courseDetails = [];
  let essentials = [];
  let KeyHilight = [];
  let Objective = [];
  let Projectslist = [];
  let tools = [];
  // let moduls = [];
  let videos = [];
  let bagroundImages = [];
  let programhighlite = [];
  let faqs = [];
  let careerService = [];
  let courseDetailsCondition = [];
  let toolsTomaster = [];
  let employeeRoles = [];
  // let programStructure1 = [];
  let programStructure2 = [];
  // let certificate = [];
  let alumini_Logo_image = [];

  if (courseTypeAllDetails != null) {
    courseTypeDetails = courseTypeAllDetails[0]?.course_Type_Detailes;
    essentials = courseTypeAllDetails[0]?.course_Type_Essentials;
    programhighlite = courseTypeAllDetails[0]?.couseType_Program_Highlights;
    careerService = courseTypeAllDetails[0]?.career_services;
    faqs = courseTypeAllDetails[0]?.courseType_FAQ;
    courseDetails = courseTypeAllDetails[0]?.course;
    KeyHilight = courseTypeAllDetails[0]?.course_Type_KeyHighLight;
    Objective = courseTypeAllDetails[0]?.course_Type_Objective;
    Projectslist = courseTypeAllDetails[0]?.course_Type_Projects;
    tools = courseTypeAllDetails[0]?.course_Type_Tools;
    // moduls = courseTypeAllDetails[0]?.course_Type_modules;
    videos = courseTypeAllDetails[0]?.courseType_video;
    bagroundImages = courseTypeAllDetails[0]?.courseType_bagroundImage;
    if (
      courseTypeAllDetails[0]?.Course_Type_Details_Conditions &&
      courseTypeAllDetails[0]?.Course_Type_Details_Conditions.length > 0
    ) {
      courseDetailsCondition =
        courseTypeAllDetails[0]?.Course_Type_Details_Conditions[0];
    }

    toolsTomaster = courseTypeAllDetails[0]?.tools_to_mastar;
    employeeRoles = courseTypeAllDetails[0]?.course_employee_role;
    // programStructure1 = courseTypeAllDetails[0]?.program_structure_1;
    programStructure2 = courseTypeAllDetails[0]?.program_structure_2;
    // certificate = courseTypeAllDetails[0]?.course_Type_Certificate;
    alumini_Logo_image =
      courseTypeAllDetails[0]?.course_Type_alumini_logo_Image;
  }

  // const navigate = useNavigate();

  let bagroundImageURL = "";

  if (bagroundImages) {
    const bagroundImageData = bagroundImages.filter((image) => {
      return image.active_Status === "A";
    });

    if (bagroundImageData) {
      bagroundImageURL = bagroundImageData[0]?.image_URL;
    }
  }

  let videoURL = "";

  if (videos) {
    const videodata = videos.filter((video) => {
      return video.active_Status === "A";
    });

    if (videodata) {
      videoURL = videodata[0]?.video_URL;
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  courseTypeDetails &&
    courseTypeDetails[0]?.course_Overview.split("\n").map((line, index) => {
      console.log(line);
      return <h3 key={index}>{line}</h3>;
    });

  const lines = String(
    courseTypeDetails && courseTypeDetails[0]?.SUB_HEADING
  ).split(".");

  // const courseTypeName = courseDetails && courseDetails[0]?.course_name;

  return (
    <div className="SingleCourseTypeData-main-container">
      <Helmet>
        <meta property="og:title" content="Your Course Title" />
        <meta
          property="og:description"
          content={
            courseTypeDetails && courseTypeDetails[0]?.CourseType_Heading
          }
        />
        <meta property="og:image" content={bagroundImageURL} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className={`videoforrmPopup ${desplayPop ? "showpop" : ""}`}>
        <PopUpVideoForm
          className
          videoURL={videoURL}
          SETDisplay={() => setdesplayPop(false)}
          desplayPop={desplayPop}
        />
      </div>
      <div className="SingleCourseTypeData-header-main-container">
        <div className="SingleCourseTypeData-header-container">
          <div className="SingleCourseTypeData-top-header-contents">
            <div className="SingleCourseTypeData-top-left-header-contents">
              <span>
                home<i class="fa-solid fa-chevron-right"></i>
                {courseDetails && courseDetails[0]?.course_name}
                <i class="fa-solid fa-chevron-right"></i>
                {courseTypeAllDetails &&
                  courseTypeAllDetails[0]?.course_typeName}
                <i class="fa-solid fa-chevron-right"></i>
                {courseTypeDetails && courseTypeDetails[0]?.CourseType_Heading}
                {/* {`${
                    courseTypeAllDetails &&
                    courseTypeAllDetails[0]?.course_typeName
                  } certification in ${
                    courseDetails && courseDetails[0]?.course_name
                  }`} */}
              </span>
              <div className="SingleCourseTypeData-top-left-main-contant">
                {/* <h1>{`${
                    courseTypeAllDetails &&
                    courseTypeAllDetails[0]?.course_typeName
                  } certification in ${
                    courseDetails && courseDetails[0]?.course_name
                  }`}</h1> */}
                <h1>
                  {courseTypeDetails &&
                    courseTypeDetails[0]?.CourseType_Heading}
                </h1>

                <h4>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i> 500+ Ratings
                </h4>
                <div className="subheading" style={{ whiteSpace: "pre-line" }}>
                  {lines.map((line, index) => (
                    <h3 key={index}>{line}</h3>
                  ))}
                </div>

                <p> {courseTypeDetails && courseTypeDetails[0]?.course_Desc}</p>
                <div className="microsoft-colaburation">
                  <h6>In collaboration with</h6>
                  <img src={tsn_Logo} alt="microsoft"></img>
                </div>
                <div className="apply-button">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setdesplayPop(true);
                      // navigate(`/courseType/${courseTypeId}/sendEnrollRequest`);
                    }}
                    className="link"
                  >
                    Apply Now <i class="fa-regular fa-square-check"></i>
                  </Button>
                  <Button
                    onClick={() => {
                      window.open(
                        // "https://pay.jodo.in/pages/XGLDaL7NfXRz2Fdn",
                        "https://razorpay.me/@juzz-it",
                        "_blank"
                      );
                    }}
                    variant="outlined"
                    className="link"
                  >
                    Enroll Now <i class="fa-regular fa-square-check"></i>
                  </Button>
                </div>
                <div className="RANKED-programs">
                  <h5>
                    <i class="fa-solid fa-medal"></i>{" "}
                    {courseTypeDetails && courseTypeDetails[0]?.BOTTOM_HEADING}
                    {/* RANKED #1{" "}
                      {courseDetails && courseDetails[0]?.course_name} BY JUZZ-IT */}
                  </h5>
                </div>
              </div>
            </div>
            <div className="SingleCourseTypeData-top-right-header-contents">
              <div className="right-badeoung">
                <div
                  className="popApp-img-container"
                  onClick={() => setdesplayPop(true)}
                >
                  <img src={bagroundImageURL} alt="course_type_img"></img>
                  <div class="video-buttom-animation">
                    <span style={{ "--i": 0 }}></span>
                    <span style={{ "--i": 1 }}></span>
                    <span style={{ "--i": 2 }}></span>
                    <span style={{ "--i": 3 }}></span>
                    <h1>
                      <i class="fa-solid fa-play"></i>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="SingleCourseTypeData-bottom-header-content">
        {essentials &&
          essentials?.map((val, key) => {
            return (
              <div className="SingleCourseTypeData-bottom-content">
                <p>{val.essential_Type}</p>
                <h6> {val.essentil_Desc} </h6>
              </div>
            );
          })}
      </div>
      <div className="Process-Advisors-container">
        <div className="Process-Advisors-left-container">
          <img src={logo} alt="sss"></img>
          <img src={iso_logo} alt="sss"></img>
        </div>
        <div className="Process-Advisors-right-container">
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            autoplay={{ delay: 2000 }}
            // pagination={{ clickable: true }}
            className="mySwiper"
          >
            <SwiperSlide className="SwiperSlide">
              <h3>
                EUAS is independent, impartial and paradigm poiner in the field
                of accreditation.
              </h3>
            </SwiperSlide>
            <SwiperSlide className="SwiperSlide">
              <h3>
                EUAS is throught to be the largest independent accreditation
                body in the world.{" "}
              </h3>
            </SwiperSlide>
            <SwiperSlide className="SwiperSlide">
              <h3>Established in 2012. </h3>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <AboutProgram KeyHilight={KeyHilight} />

      {courseDetailsCondition?.show_objective === "A" ? (
        <WhoConJoinThisProgram
          Objective={Objective}
          heading={courseDetailsCondition?.objective_heading}
        />
      ) : (
        ""
      )}

      {courseDetailsCondition?.show_tools_to_master === "A" ||
      courseDetailsCondition?.show_skills_To_Master === "A" ||
      courseDetailsCondition?.show_Employee_Role === "A" ? (
        <WhatRolesCanPlay
          tools={tools}
          employeeRoles={employeeRoles}
          toolsTomaster={toolsTomaster}
          skillsCondition={courseDetailsCondition?.show_skills_To_Master}
          skillsHeading={courseDetailsCondition?.skills_To_Master_Heading}
          toolsCondition={courseDetailsCondition?.show_tools_to_master}
          toolsHeading={courseDetailsCondition?.tools_to_master_heading}
          employeeRolesCondition={courseDetailsCondition?.show_Employee_Role}
          employeeRolesHeading={courseDetailsCondition?.Emploee_heading}
        />
      ) : (
        ""
      )}

      {courseDetailsCondition?.show_program_structure_2 === "A" ? (
        <Curriculum
          moduls={programStructure2}
          // moduls={moduls}
          duriationDesc={
            courseTypeDetails && courseTypeDetails[0]?.course_DuriationDesc
          }
          moduleHeading={courseDetailsCondition?.program_structure_2_heading}
          prigramHighlite={programhighlite}
          programhighliteCondition={
            courseDetailsCondition?.show_program_highlight
          }
          programhighliteHeading={
            courseDetailsCondition?.program_highlight_heading
          }
        />
      ) : (
        ""
      )}

      <InterestedStudentformCompo />

      {courseDetailsCondition?.show_project === "A" ? (
        <NewCourseTypeProject
          Projectslist={Projectslist}
          heading={courseDetailsCondition?.project_heading}
        />
      ) : (
        ""
      )}

      {courseDetailsCondition?.show_career_service === "A" ? (
        <CareerServices
          careerService={careerService}
          heading={courseDetailsCondition?.career_service_heading}
        />
      ) : (
        ""
      )}

      {courseDetailsCondition?.show_alumini_works_at === "A" ? (
        <OurAlumniWorksAtCompo
          heading={courseDetailsCondition?.alumini_works_at_heading}
          alumini_Logo_image={alumini_Logo_image}
        />
      ) : (
        ""
      )}

      <ProgramFeeStucture
        courseTypeName={
          courseTypeAllDetails && courseTypeAllDetails[0]?.course_typeName
        }
        payment={courseTypeDetails && courseTypeDetails[0]?.discount_Price}
        noconstEmi={courseTypeDetails && courseTypeDetails[0]?.actual_Price}
        condition={courseDetailsCondition?.show_no_cost_emi}
      />

      {courseDetailsCondition?.show_certificate === "A" ? (
        <CertificateCompo />
      ) : (
        ""
      )}

      {courseDetailsCondition?.show_faq === "A" ? (
        <FrequentlyAskedQuestions
          faqs={faqs}
          heading={courseDetailsCondition?.faq_heading}
        />
      ) : (
        ""
      )}
    </div>
  );
};

//  Commerce

export default SingleCourseTypeData;
