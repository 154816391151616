import React, { useState } from "react";
import "./SingleEvent.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EventForm from "./EventForm";
const SingleEvent = ({ EventType }) => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  let imageData = {};

  if (EventType?.EventTypeImage && EventType?.EventTypeImage.length > 0) {
    imageData = EventType.EventTypeImage.filter((image) => {
      return image.ACTIVE_STATUS === "A";
    })[0];
  }

  return (
    <div className="SingleEvent-main-container">
      {EventType ? (
        <>
          <div className={`eventForm-contant ${showForm ? "showForm" : ""}`}>
            <EventForm
              setShowForm={() => {
                setShowForm(false);
              }}
              EventType={EventType}
              imgData={imageData?.IMAGE_URL}
            />
          </div>
          <div className="SingleEvent-img-content">
            <img src={imageData?.IMAGE_URL} alt="sss" />
          </div>
          <div className="SingleEvent-main-contant">
            <h2>{EventType?.EVENT_TYPE_NAME}</h2>
          </div>
        </>
      ) : (
        <div className="SingleEvent-loading-conatent">
          <div className="loading-animation"></div>
          <h2>Coming soon</h2>
        </div>
      )}
      <div className="buttom-contant">
        <Button
          className="Button"
          variant="contained"
          onClick={() => {
            navigate(setShowForm(true));
          }}
        >
          Register Now
        </Button>
      </div>
    </div>
  );
};

export default SingleEvent;
