import React from "react";
import Cource from "./Cource";
import "./ListCources.scss";
// import AOS from "aos";
import "aos/dist/aos.css";

// import { NavLink } from "react-router-dom";
const ListCources = (props) => {
  const coursesTypes = props.coursesTypes;
  const courseName = props.courseName;
  console.log(coursesTypes);

  return (
    <>
      {coursesTypes &&
        coursesTypes?.map((courseType, key) => {
          const heading = `${courseType.course_typeName} Certification in  ${courseName}`;
          if (
            courseType.active_courseType === "A" &&
            courseType.course_typeName !== "Workshop" &&
            courseType.course_typeName !== "Internship"
          ) {
            return (
              <Cource
                heading={heading}
                courseTypeLevel={courseType.course_level}
                courseTypeId={courseType.course_typeId}
                courseTypeImage={courseType.courseType_bagroundImage}
                imageURL="https://juzzit.in/wp-content/uploads/2022/01/Html-system-for-website-concept.png"
                key={key}
              />
            );
          } else {
            return null;
          }
        })}
    </>
  );
};

export default ListCources;
