import React, { useEffect, useState } from "react";
import "./EnrolledLiveCoursePlacements.scss";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAllPlacements } from "../../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getAllPlacement } from "../../../../../../../../Redux/Actions/MyCourseActions";
import Loading from "../../../../../../../../LoadingComponent/Loading";
import CourseDetailsBar from "../CourseDetailsBar";

const EnrolledLiveCoursePlacements = () => {
  const { batchId } = useParams();
  const allPlacement = useSelector((state) => state.mycourseData.allPlacements);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getAllPlacement(batchId);
      dispatch(setAllPlacements(data));
      setIsLoading(false);
    };
    getData();
  }, [batchId, dispatch]);

  if (isLoading) {
    <Loading />;
  }
  return (
    <div className="EnrolledLiveCoursePlacements-main-container">
      <CourseDetailsBar />
      {/* <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classLinks`}
              className={"link"}
            >
              {" "}
              class links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div> */}

      <TableContainer component={Paper} className="Placements-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>PLACEMENT ID</TableCell>
              <TableCell>COMPANY NAME</TableCell>
              <TableCell>COMPANY INTRODUCTION</TableCell>

              <TableCell>APPLY LINK</TableCell>
              <TableCell>view details </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allPlacement &&
              allPlacement?.map((val, key) => {
                if (val.active_placement === "A") {
                  return (
                    <TableRow key={key}>
                      <TableCell>{val.placement_Id}</TableCell>
                      <TableCell>{val.company_Name}</TableCell>
                      <TableCell>{val.company_Intruduction}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            window.open(val.apply_link, "_blank");
                          }}
                          variant="contained"
                          color="secondary"
                        >
                          link
                        </Button>
                      </TableCell>
                      <TableCell>
                        <NavLink to={`${val.placement_Id}/details`}>
                          <Button>view details</Button>
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default EnrolledLiveCoursePlacements;
