import React, { useEffect } from "react";
import "./CertificateCompo.scss";
import certificat from "../../../../../../img/Course/Rubel Internship Certificate.png";
import AOS from "aos";
import "aos/dist/aos.css";
const CertificateCompo = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="CertificateCompo-main-container">
      <div className="CertificateCompo-container">
        <div className="CertificateCompo-left" data-aos="fade-down">
          <h1>
            Earned with Excellence:
            <br />
            Your Certificate
            <br />
            of Achievement
          </h1>
        </div>
        <div className="CertificateCompo-right">
          <img src={certificat} alt="dsf"></img>
        </div>
      </div>
    </div>
  );
};

export default CertificateCompo;
