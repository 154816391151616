import React from 'react'
import './PrivacyPolicy.scss'
const PrivacyPolicy = () => {
  return (
    <div className='PrivacyPolicy-main-conteiner'>
        <div className='PrivacyPolicy-container'>
            <h1>INTRODUCTION</h1>
            <p> Juzz-IT (along with its subsidiaries, the “Company”, “us”, “we”, or “our”)understands and recognizes your right and/or that of<br></br> the Minor User (as defined in the Terms) to confidentiality and is committed to protecting and preserving your privacy <br></br>and/or that of the Minor User. Pursuant to such commitment, the Company has developed this Privacy Policy for the <br></br>handling of your Information and/or that of a Minor User, as defined and <br></br>provided hereunder.</p>
            <p>
                   This Privacy Policy applies to our Users and/or Minor Users, employees and<br/>
                   consultants and governs the use of the Company’s Services on its Website and<br/>
                   Platform and at its Offline Centres as defined in the Juzz-IT terms and conditions<br/>
                   available a ( https://juzzit.in/ )(the “Terms”)and any new feature or tool which may<br/>
                   be added to the current Website and Platform. <br/>
            </p>

            <div className='PrivacyPolicy-content'>
                   <p>
                         Subject to the provisions of this Privacy Policy, the applicable Terms and all<br/>
                         applicable laws, the Company may collect, receive, store, deal in, handle and/or<br/>
                         share Personal Information (as defined hereinafter) including Sensitive Personal<br/>
                         Data (as defined hereinafter) and Non-Personal Information (as defined hereinafter)<br/>
                         pertaining to the Users and/or Minor Users online and/or offline use of the Services.<br/>
                         This Privacy Policy outlines the Information the Company may process and/or use<br/>
                         to improve the User and/or Minor User experience while using the Services.<br/>
                         You (on behalf of yourself and/or a Minor User) must be in a position to provide<br/>
                         informed consent prior to providing any Information required for the use of the<br/>
                         Services. You agree that by purchasing our Services, accessing our Website and<br/>
                         Platform or attending Courses at our Offline Centres, you (on behalf of yourself<br/>
                         and/or a Minor User) consent to us collecting, receiving, storing, possessing,<br/>
                         dealing with, sharing and/or handling your Information in accordance with the<br/>
                         terms of this Privacy Policy. Accordingly, you (on behalf of yourself and/or a Minor<br/>
                         User) are required to read and understand this Privacy Policy, along with such other<br/>
                         Terms, as may be applicable to your use or that of the Minor User’s use of the<br/>
                         Services, before submitting any Information. Please note that your and/or a Minor<br/>
                         User’s access to the Services is conditional upon your consenting to this Privacy<br/>
                         Policy. Please further note that if you are a Minor User, please immediately stop<br/>
                         using the Services until your parent or legal guardian approves and consents to the<br/>
                         usage of the Services in accordance with the Privacy Policy and the applicable<br/>
                         Terms relating to use of the Services.<br/>
                   </p>

                   <h5>DEFINITIONS</h5>
                   <p>
                         For the purposes of this Privacy Policy, unless the context otherwise requires:<br/>
                         “Account” shall mean the online account created on the Platform by the User for<br/>
                         use of the Services (as defined below).<br/>
                   </p>
                   <p>
                         “Course” shall mean our programs offered remotely through either our Platform,<br/>
                         pre-recorded multimedia and audio-visual content, live streamed classes on third<br/>
                         party applications and/or our programs offered physically at our Offline Centres.<br/>
                         “Course Material” shall mean the information provided by Juzz-IT to accompany a<br/>
                         Course as part of the Services which is available on our Platform and includes<br/>
                         assessment tests and examinations.<br/>

                   </p>
                   <p>

                         “Force Majeure Event” shall mean any event that is beyond the reasonable control<br/>
                         of the Company and shall include, without limitation, sabotage, fire, flood,<br/>
                         explosion, act of God, civil commotion, pandemics, strikes or industrial action of<br/>
                         any kind, riots, insurrection, war, acts of government, computer hacking,<br/>
                         unauthorized access to your mobile or storage device, crashes, breach of security<br/>
                         and encryption, power or electricity failure or unavailability of adequate power or<br/>
                         electricity, or any unauthorized act of third party service provider engaged by the<br/>
                         Company in accordance with this Privacy Policy.<br/>
                   </p>
                   <p>
                         “Information” shall mean collectively Non-Personal Information (as defined below)<br/>
                         and Personal Information (as defined below).<br/>
                         “Non-Personal Information” shall mean and include:<br/>
                         the type of device used by the User and/or the Minor User;<br/>
                         the website that referred you and/or a Minor User to the Platform and the exit<br/>
                         page; and<br/>
                         unique identifiers, which are used to collect and store information about an app or<br/>
                         device, such as preferred language, time zone, screen size, browser and other<br/>
                         settings.<br/>
                         “Offline Centre” means a physical premises operated by the Company to deliver<br/>
                         offline classes.<br/>
                   </p>
                   <p>
                         “Personal Information” means any information relating to the Users and/or Minor<br/>
                         Users which, either directly or indirectly, in combination with other information<br/>
                         available or likely to be available with the Company, is capable of identifying such<br/>
                         Users and/or Minor Users, and includes Sensitive Personal Data (as defined below)<br/>
                         of the User and/or Minor User. Personal Information shall include, for example,<br/>
                         name, mobile number, the internet protocol address and/or geographic location of<br/>
                         the User and/or Minor Users, any files consisting of audio, visual, and/or audio￾visual information provided by the User and/or Minor Users to the Company, or<br/>
                         which the Company gains access to pursuant to or in connection with the User<br/>
                         and/or the Minor User using the Services. <br/>
                   </p>

                   <p>
                         “Platform” means the Website and any other feature or tool provided and operated<br/>
                         by Juzz-IT, including any application that may be launched by Juzz-IT.<br/>
                         “Services” means the provision of the Course and/or the Course Material together<br/>
                         with such other services as agreed from time to time and purchased by you (on<br/>
                         behalf of yourself and/or the Minor User) and availed remotely through either our<br/>
                         Platform, pre-recorded multimedia and audio-visual content, live streamed classes<br/>
                         on third party application and/or physically at our Offline Centres.<br/>
                         “Sensitive Personal Data” means such personal information relating to passwords,<br/>
                         financial information such as bank account or credit card or other payment<br/>
                         instrument details, physical, mental or physiological health condition, sexual<br/>
                         orientation, medical records and history, biometric information, etc.<br/>
                         “Third Party” means any person or entity other than you and/or the Minor User, or<br/>
                         the Company.<br/>
                   </p>
                   <p>
                         “User” (or, “you”, “your”, and “yourself”) means any individual who is a natural<br/>
                         person aged 18 (eighteen) years or above, and who is competent and capable of<br/>
                         contracting within the meaning of the Indian Contract Act, 1872 and accesses our<br/>
                         Website and/or purchases the Services. Such an individual, where applicable,<br/>
                         includes the parent/legal guardian or any other authorized individual who facilitates<br/>
                         the use of the Services for a Minor User.<br/>
                   </p>

                   <h5>INFORMATION COLLECTION</h5>
                   <p>
                         When Do We Collect Information and What Types of Information do we Collect.<br/>
                         The Company obtains the Information (including Personal Information) when you or<br/>
                         a Minor User purchases, downloads, registers, accesses or uses any of the Services<br/>
                         provided by the Company. When you or a Minor User visits our Website or Offline<br/>
                         Centres or accesses our Platform and you (on behalf of yourself or a Minor User)<br/>
                         voluntarily fill one of our contact forms, via an in-person, chat or phone session, or<br/>
                         as part of a purchase of one of our Courses or register on our Website, you (on<br/>
                         behalf of yourself and/or a Minor User) generally provide:<br/>
                         your and/or a Minor User’s name, gender, date of birth, guardian’s name (if<br/>
                         applicable), email address, phone number and mailing address;<br/>
                         information about your business, such as company name, company size, business<br/>
                         type; <br/>
                  </p>

                 <p>
                         your Juzz-IT account details which may include billing information, and account<br/>
                         password; and<br/>
                         other information you (on behalf of yourself and/or a Minor User) may provide at<br/>
                         our Offline Centres, or enter or upload into our system when using the Platform.<br/>
                         The above-mentioned information is necessary to allow the Company to operate the<br/>
                         Platform, deliver Courses at our Offline Centres and provide the Services to Users<br/>
                         and/or Minor Users. Without it, we may not be able to provide you and/or a Minor<br/>
                         User with all the requested Services, which could result in you or a Minor User not<br/>
                         being able to use the Platform or avail our Services (or any part thereof).<br/>
                         In addition, the Platform may collect certain Information automatically, including,<br/>
                         but not limited to:<br/>
                 </p>
                 <p>
                         the type of handheld device used;<br/>
                         the handheld device’s unique device ID;<br/>
                         the IP address of such handheld device;<br/>
                         such handheld device’s operating system; and<br/>
                         the type of internet browsers you and/or a Minor User uses.<br/>
                         We may collect usage information about your and/or a Minor User’s use of our<br/>
                         Services, including but not limited to:<br/>
                         the number of tests, Courses you and/or a Minor User has attempted on the<br/>
                         Platform and/or at our Offline Centres;<br/>
                         the number of videos you and/or a Minor User has viewed;<br/>
                         the Courses you and/or a Minor User participates in; and<br/>
                         the amount of time spent to complete a Course on the Platform and/or at our<br/>
                         Offline Centres.<br/>
                </p>
                <p>
                         This enables us to better tailor educational experiences that are most appropriate<br/>
                         for you and/or a Minor User.<br/>
                         What Information is Collected through Third Parties?<br/>
                         We also obtain data from third parties. We protect data obtained from third parties<br/>
                         according to the practices described in this Privacy Policy, plus any additional<br/>
                         restrictions imposed by the source of the data. These third-party sources vary over<br/>
                         time, but have included:<br/>
                         data brokers from which we purchase demographic data to supplement the data we<br/>
                         collect;<br/>
               </p>

               <p>
                         social networks when you (on behalf of yourself and/or a Minor User) grant<br/>
                         permission to make your data public on these networks including permission to<br/>
                         access your and/or a Minor User’s data on one or more of these networks;<br/>
                         enterprise customers where you are an employee availing our Services;<br/>
                         existing or past Users and/or Minor Users;<br/>
                         service providers that help us determine a location based on your and/or a Minor<br/>
                         User’s IP address in order to customize certain products to your and/or a Minor<br/>
                         User’s location;<br/>
                         partners with which we may offer co-branded services or engage in joint marketing<br/>
                         activities; and<br/>
                         publicly available sources such as open government databases or other data in the<br/>
                         public domain.<br/>
                         We may also receive Personal Information about you and/or Minor Users whose<br/>
                         access to our Services is being facilitated by you from other sources like postal<br/>
                         mail, telephone or fax. We may add this information to the information we have<br/>
                         already collected from you and/or a Minor User via the Platform or at our Offline<br/>
                         Centres in order to improve the products and Services we provide.<br/>
                         Technologies we may use.<br/>
                         In order to provide a personalized learning and high-quality experience for our<br/>
                         Users and/or Minor Users, we may use various technologies that automatically<br/>
                         record certain technical information from the browser or device that you or a Minor<br/>
                         User uses when you and/or a Minor User visits or accesses the handheld device,<br/>
                         reads our emails, uses our Services or otherwise engages with us. This information<br/>
                         is typically collected through a variety of tracking technologies, including cookies,<br/>
                         web beacons, flash objects, log files, and similar technology (collectively, “Tracking<br/>
                         Technologies”). These Tracking Technologies collect information about how you<br/>
                         and/or a Minor User use the Services (such as the pages viewed, the links clicked,<br/>
                         and other actions taken on the Services). We may collect analytics data, or use<br/>
                         third party analytics tools, to help us measure traffic and usage trends for the<br/>
                         Platform and to understand more about the demographics of our Users and/or<br/>
                         Minor Users. We may also work with third party partners to employ technologies<br/>
                         that assist us in providing the Services and enhance the User and/or Minor User<br/>
                         experience. Any third-party services used by the Company shall be governed by<br/>
                         their respective terms of use and privacy policy, as well as any applicable laws.<br/>
                         When you and/or a Minor User uses the Services, any Information you (on behalf of<br/>
                         yourself and/or a Minor User) provide may be sent to the Company, and such<br/>
                         Information collected by the Company may be stored by the Company on its<br/>
                         servers or any cloud-based platform operated by any third party, in accordance<br/>
                         with applicable laws and their privacy policy.<br/>
                         COOKIE POLICY<br/>
                         What are Cookies?<br/>
                         A cookie is a small file which asks permission to be placed on a computer’s or<br/>
                         device’s hard drive. Once you (on behalf of yourself and/or a Minor User) agree, the<br/>
                         file is added and the cookie helps analyze web traffic or lets you and/or a Minor<br/>
                         User know when you and/or a Minor User visit a particular site. Cookies allow web<br/>
                         applications to respond to you and/or a Minor User as an individual. The web<br/>
                         application can tailor its operations to your and/or a Minor User’s needs, likes and<br/>
                         dislikes by gathering and remembering information about your and/or a Minor<br/>
                         User’s preferences.<br/>
                         How do we Use Cookies?<br/>
                         We use traffic log cookies to identify which pages are being used. This helps us<br/>
                         analyze data about webpage traffic and improve the Platform in order to tailor it to<br/>
                         customer needs. We only use this information for statistical analysis purposes and<br/>
                         then the data is removed from the system.<br/>
                         Overall, cookies help us provide you and/or a Minor User with a better Platform, by<br/>
                         enabling us to monitor which webpages you find useful and which you do not. A<br/>
                         cookie in no way gives us access to your computer or any device or any Information<br/>
                         about you or a Minor User, other than the data shared with us. You (on behalf of<br/>
                         yourself and/or a Minor User) can choose to accept or decline cookies.<br/>
                         How to Manage Cookies?<br/>
                         Most web browsers automatically accept cookies, but you (on behalf of yourself<br/>
                         and/or a Minor User) can usually modify your browser setting to decline cookies, if<br/>
                         you prefer. Please refer to your web-browser provider or system guide for detailed<br/>
                         information on disabling cookies. However, in case you choose to disable the<br/>
                         cookies, you and/or a Minor User may be prevented from taking full advantage of<br/>
                         the Platform.<br/>
                         PURPOSES OF COLLECTING, STORING AND USING YOUR INFORMATION AND THE<br/>
                         MANNER OF SUCH USAGE<br/>
                         The Company uses, stores, and processes Information, including Personal<br/>
                         Information, about you and/or a Minor User for the following purposes:<br/>
                         to use the Information to provide, develop, enhance and improve the Services,<br/>
                         conduct relevant research, create internal guidelines for content creation, and<br/>
                         record and register geographic influences of the Services and to offer targeted<br/>
                         advertisements to you and/or a Minor User and to create publicity material to be<br/>
                         shared with third parties.<br/>
                  </p>
                  <p>
                          the Information collected is used for internal operational purposes such as<br/>
                          responding to customer service requests, administering your account, enforcing<br/>
                          content license restrictions, determining preferred language and providing and<br/>
                          improving the Services.<br/>
                          to offer Users and/or Minor Users personalized content based on the search history,<br/>
                          and key words used in navigating the Platform, as well as usage history of the<br/>
                          Platform.<br/>
                  </p>
                  <p>
                        to facilitate your transactions with other Users and/or Minor Users when you and/or<br/>
                        a Minor User use our Service(s) and perform statistical analyses of User and/or<br/>
                        Minor User behavior and characteristics at an aggregate level in order to measure<br/>
                        interest in and use of the various areas of the Platform.<br/>
                        to provide Information collected on the Platform and/or at our Offline Centres, to<br/>
                        third parties, including those who are working with the Company (i) to operate<br/>
                        (including analyzing data), improve and enhance the features and experiences on<br/>
                        the Services, including providing relevant government-approved certificates, and<br/>
                        financial assistance to Users, and (ii) for relevant research and analytics on the<br/>
                        Services or in connection with the services provided by such third parties, which<br/>
                        may be by way of providing access to the Information on the Company’s server<br/>
                        located in India and/or transferring the Information to the servers of such third<br/>
                        parties located in India.<br/>
                        Further, notwithstanding anything to the contrary contained herein, you may (on<br/>
                        behalf of yourself and/or a Minor User) at any time choose to provide a written<br/>
                        notice to the Company to revoke the consent given by you (on behalf of yourself<br/>
                        and/or a Minor User) to share information with third parties who are working with<br/>
                        the Company. Upon receipt of such notice, we may not be able to continue to<br/>
                        provide you and/or a Minor User with the Services (or any part thereof). Please<br/>
                        note that the Company shall not be responsible or liable in any manner whatsoever<br/>
                        for any actions of any third parties that receive the Information.<br/>
                        we may contact you and/or a Minor User via tele-call, email, SMS, or other forms of<br/>
                        communication for, inter alia, informing you of new service/ product offerings,<br/>
                        offering you targeted advertisements of our Services, and collecting feedback in<br/>
                        relation to the Platform and the use of the Services, or sharing information with you<br/>
                        and/or a Minor User about certain promotions or features in relation to the Platform<br/>
                        and our Services. You and/or a Minor User may receive such communication when<br/>
                        you (on behalf of yourself and/or a Minor User) have registered as a User and/or a<br/>
                        Minor User on the Platform or at our Offline Centres. By agreeing to this Privacy<br/>
                        Policy, you (on behalf of yourself and/or a Minor User) give us explicit permission to<br/>
                        do so. Irrespective of the fact if you (on behalf of yourself and/or a Minor User)<br/>
                        have also registered yourself under DND or DNC or NCPR service, you (on behalf of<br/>
                        yourself and/or a Minor User) still authorize us to give you a call for the above￾mentioned purposes.<br/>
                        if we are involved in a merger, acquisition, due diligence, reorganization,<br/>
                        bankruptcy or liquidation where the Company’s business will not continue as a<br/>
                        going concern, receivership, sale of the Company’s assets, or transition of the<br/>
                        Services to another provider, then your Information and of a Minor User may be<br/>
                        transferred as part of such a transaction as permitted by applicable laws and/or<br/>
                        contract. The use of your Information and that of a Minor User following any of<br/>
                        these events will be governed by the policies in effect at the time the applicable<br/>
                        Information was collected.<br/>
                        HOW WE STORE AND PROCESS YOUR INFORMATION<br/>
                        Your and/or a Minor User’s Information will primarily be stored in electronic form.<br/>
                        However, certain data may also be stored in physical form.<br/>
                        We may enter into agreements with third parties (within or outside of India) to<br/>
                        store or process your and/or a Minor User’s Information, subject to applicable laws.<br/>
                        These third parties may have their own security standards to safeguard this<br/>
                        Information and we will, on a commercially reasonable basis, require such third<br/>
                        parties to adopt reasonable security standards to safeguard this Information, as per<br/>
                        the requirements of applicable laws. Please note that by agreeing to this Privacy<br/>
                        Policy, you (on behalf of yourself and/or a Minor User) hereby agree to and permit<br/>
                        the sharing your Information with such third parties for storage and processing<br/>
                        purposes.<br/>
                        Currently, we plan to retain User and/or Minor User Information while an Account is<br/>
                        active and for such time period thereafter as is reasonably required for the<br/>
                        purposes for which the Information may lawfully be used or is otherwise required<br/>
                        under applicable laws for the time being in force. We may alter this practice<br/>
                        according to legal and business requirements. For example: we retain User and/or<br/>
                        Minor User information for a period of 180 (One Hundred and Eighty) days after<br/>
                        cancellation or termination of such User and/or Minor User accounts to comply with<br/>
                        applicable laws.<br/>
                        WHO WE SHARE AND DISCLOSE YOUR INFORMATION TO<br/>
                        You (on behalf of yourself and/or a Minor User) hereby unconditionally agree and<br/>
                        permit that the Company may transfer, share, disclose or part with all or any of<br/>
                        your and/or a Minor User’s Information, within and outside of India to (i) various<br/>
                        group entities, third party service providers (without any reference to persona or<br/>
                        identity), general public and the National Skills Development Corporation, for one<br/>
                        or more of the purposes set forth in Clause 5, (ii) government agencies upon<br/>
                        directions of such agency, or (iii) to any other third party as may be required under<br/>
                        applicable laws. In case of any Personal Information so transferred, shared,<br/>
                        disclosed or parted with, we will seek to, as may be required under applicable laws,<br/>
                        contractually require the receiving parties of the Information to ensure the same<br/>
                        level of data protection adopted by the Company is adhered to by the receiving<br/>
                        party, subject to applicable laws.<br/>
                        You (on behalf of yourself and/or a Minor User) acknowledge and agree that, to the<br/>
                        extent permissible under applicable laws, it is adequate that when the Company<br/>
                        transfers your and/or a Minor User’s Information, in accordance with the provisions<br/>
                        of this Privacy Policy, to any other entity within or outside your country of<br/>
                        residence, the Company will seek to place contractual requirements on the<br/>
                        transferee, which will oblige the transferee to adhere to the provisions of this<br/>
                        Privacy Policy.<br/>
                        You (on behalf of yourself and/or a Minor User) acknowledge and agree that the<br/>
                        Company may transfer/disclose/share Information (other than Sensitive Personal<br/>
                        Data) to third parties who support our business, including such as providing<br/>
                        technical infrastructure services, analyzing how our Services are used, measuring<br/>
                        the effectiveness of advertisements, providing customer /buyer services, facilitating<br/>
                        payments, conducting academic research and surveys, or your employer/Skill-Lync<br/>
                        authorized training partner who pays for your subscription. The Company will seek<br/>
                        to place contractual requirements on such transferees to adhere to confidentiality<br/>
                        obligations consistent with this Privacy Policy.<br/>
                        You (on behalf of yourself and/or a Minor User) acknowledge and agree that the<br/>
                        Company may also transfer/disclose/share information with other third parties such<br/>
                        as non-banking financial institutions, banks, credit facility providers, payment<br/>
                        gateways and/or aggregators, digital lending apps/platforms, lending service<br/>
                        providers, financial services intermediaries and / or any other entity facilitating<br/>
                        financing and/or payment related services, in order to facilitate the provision of<br/>
                        monetary assistance to Users. The Company will seek to place contractual<br/>
                        requirements on such transferees to adhere to confidentiality obligations and<br/>
                        security measures consistent with this Privacy Policy.<br/>
                        The Company may also disclose Personal Information with such third parties, or<br/>
                        otherwise to enforce this Privacy Policy, or when the Company believes it is<br/>
                        necessary to do so in order to investigate, prevent, or take action regarding illegal<br/>
                        activities, suspected fraud, situations involving potential threats to the physical<br/>
                        safety of any person, including any User and/or a Minor User’s, violations of various<br/>
                        terms and conditions or our policies, including this Privacy Policy.<br/>
                        We reserve the right to disclose your and/or a Minor User’s Information when<br/>
                        required to do so by applicable laws or regulations, or under any legal obligation or<br/>
                        order under applicable laws, or in response to a request from a law enforcement or<br/>
                        governmental agency or judicial, quasi-judicial or any other statutory or<br/>
                        constitutional authority, or to protect our rights, safety and property, or establish or<br/>
                        exercise our legal rights or defend against legal claims.<br/>
                        HOW WE SECURE YOUR INFORMATION<br/>
                        Security Practices in place.<br/>
                        The security of your and/or a Minor User’s Personal Information is important to us.<br/>
                        The Company strives to ensure the security of this Personal Information and to<br/>
                        protect it against unauthorized access or unauthorized alteration, disclosure, loss,<br/>
                        misuse or destruction. The Company adopts reasonable security practices and<br/>
                        procedures, including physical, managerial, operational and technical security<br/>
                        measures, as mandated under applicable laws for the protection of your and/or a<br/>
                        Minor User’s Personal Information. Provided that your (on behalf of yourself and/or<br/>
                        a Minor User) right to claim damages shall be limited to the right to claim only<br/>
                        statutory damages under the Information Technology Act, 2000 and you (on behalf<br/>
                        of yourself and/or a Minor User) hereby waive and release the Company and all the<br/>
                        Company’s entities from any claim of damages under contract or under tort, to the<br/>
                        extent permissible under applicable laws. If we become aware that your and/or a<br/>
                        Minor User’s Personal Information has been accessed or disclosed in a manner not<br/>
                        in accordance with this Privacy Policy, we will use reasonable efforts to notify you<br/>
                        (on behalf of yourself and/or a Minor User) of the nature and extent of such access<br/>
                        or disclosure (to the extent we know of such access or disclosure) as soon as<br/>
                        reasonably possible and as permitted by, and in accordance with, applicable laws.<br/>
                        Limitation of Access to Information.<br/>
                        The Company limits access to Information to those employees, agents, contractors<br/>
                        and other third parties on a need-to-know basis, i.e., those who need the access in<br/>
                        order to fulfill the tasks and duties relating to Service provision. All service<br/>
                        providers are permitted to process the Information based on the Company’s<br/>
                        instructions, and they are subject to a duty of confidentiality and are required to be<br/>
                        compliant and demonstrate compliance with applicable data protection laws.<br/>
                        Confidentiality Obligations.<br/>
                        Information shared by the Company with third parties are subject to confidentiality<br/>
                        agreement which, inter alia, provides that such third parties shall not disclose the<br/>
                        Information further unless such disclosure is for the purposes set forth in Clause 5.<br/>
                        However, the Company is not responsible for any breach of security or for any<br/>
                        actions of any third parties that receive your and/or a Minor User’s Personal<br/>
                        Information.<br/>
                        Limitation of our Liability.<br/>
                        Notwithstanding anything contained in this Privacy Policy or elsewhere, to the<br/>
                        extent permissible under applicable laws, the Company shall not be held<br/>
                        responsible for any loss, damage or misuse of your and/or a Minor User’s Personal<br/>
                        Information, if such loss, damage or misuse is attributable to a force majeure<br/>
                        event.<br/>
                        While we will endeavor to take all reasonable and appropriate steps to keep secure<br/>
                        any Personal Information which we hold and prevent unauthorized access or<br/>
                        disclosures, you (on behalf of yourself and/or a Minor User) acknowledge that the<br/>
                        internet or computer networks are not fully secure and that we cannot provide any<br/>
                        absolute assurance regarding the security of your and/or a Minor User’s Personal<br/>
                        Information.<br/>
                        YOUR RIGHTS<br/>
                        You (on behalf of yourself and/or a Minor User) may exercise any of the rights<br/>
                        described in this section by sending an email to education@juzzit.in. Please note<br/>
                        that we may ask you (on behalf of yourself and/or a Minor User) to verify your<br/>
                        and/or a Minor User’s identity before taking further action on your request.<br/>
                        Rectification of Inaccurate or Incomplete Information.<br/>
                        You (on behalf of yourself and/or a Minor User) may contact us at the email<br/>
                        address provided above to review your Personal Information and/or update, correct<br/>
                        or amend inaccurate, deficient or incomplete Personal Information, and we will<br/>
                        make reasonable efforts to update the same (to the extent feasible for the<br/>
                        Company).<br/>
                        Please note that while you (on behalf of yourself and/or a Minor User) have the<br/>
                        right to ask us to correct inaccurate, deficient or incomplete Personal Information<br/>
                        concerning you and/or a Minor User (and which you cannot update yourself within<br/>
                        your and/or a Minor User’s Account), you are responsible for keeping your Personal<br/>
                        Information up-to-date, to the extent you (on behalf of yourself and/or a Minor<br/>
                        User) can update the same within your and/or a Minor User’s Account.<br/>
                        Data Retention and Erasure.<br/>
                        If you no longer want the Company to use your and/or the Minor User’s Personal<br/>
                        Information to provide the Services (or any part thereof) to you and/or a Minor<br/>
                        User, or you wish to close your and/or a Minor User’s Account, you (on behalf of<br/>
                        yourself and/or a Minor User) can request that we erase this Personal Information<br/>
                        and close your and/or a Minor User’s Account. Provided, however, any requests for<br/>
                        erasure, or closure of Account, will be acted upon in accordance with applicable<br/>
                        laws and may adversely impact the Company’s ability to provide the Services to<br/>
                        you and/or a Minor User. You (on behalf of yourself and/or a Minor User) hereby<br/>
                        agree and acknowledge that a mere request for erasure does not guarantee an<br/>
                        immediate erasure of Personal Information, and all such requests will be acted upon<br/>
                        in accordance with applicable laws.<br/>
                        Please note that if you (on behalf of yourself and/or a Minor User) request the<br/>
                        erasure of your Personal Information:<br/>
                        we may retain some of your and/or a Minor User’s Personal Information as<br/>
                        necessary for protecting our legitimate business interests, such as fraud detection<br/>
                        and prevention and enhancing safety. For example, if we suspend an Account for<br/>
                        fraud or safety reasons, we may retain certain information from that Account <br/>
                        prevent that User from opening a new Account in the future.<br/>
                        we retain User and/or Minor User information for a period of 180 (One Hundred and<br/>
                        Eighty) days after cancellation or termination of such User accounts to comply with<br/>
                        applicable laws.<br/>
                        we will not be required to delete any information which has been de-identified or<br/>
                        disassociated with personal identifiers such that it can no longer be used to<br/>
                        reasonably identify a particular individual.<br/>
                        the Company will not be able to provide you and/or the Minor User with the<br/>
                        Services (or any part thereof) and you and/or a Minor User should cease and desist<br/>
                        from using the Services.<br/>
                        Consent; Withdrawing Consent and Restriction of Processing.<br/>
                        In the event you do not consent to sharing your and/or a Minor User’s Personal<br/>
                        Information with us for provision of the Services (or any part thereof), we reserve<br/>
                        the right to restrict or deny the provision of such Services (or any part thereof).<br/>
                        Where you have provided your consent to the processing of your and/or a Minor<br/>
                        User’s Personal Information by the Company you may withdraw your consent by<br/>
                        sending a communication to our email address at education@juzzit.in specifying in<br/>
                        reasonable detail the consent you are withdrawing (on behalf of yourself and/or a<br/>
                        Minor User). Please note that the withdrawal of your consent (on behalf of yourself<br/>
                        and/or a Minor User) does not affect the lawfulness of any processing activities<br/>
                        based on such consent before its withdrawal. If you withdraw your consent (on<br/>
                        behalf of yourself and/or a Minor User) and restrict processing of any Personal<br/>
                        Information, the Company may not be able to provide you and/or the Minor User<br/>
                        with the Services (or any part thereof) and you and/or the Minor User may be<br/>
                        required to cease to use the Services (or any part thereof).<br/>
                        Please note that if you withdraw your consent to process your and/or a Minor User’s<br/>
                        Personal Information in accordance with the Privacy Policy, we will not be required<br/>
                        to delete any such information which has been anonymized, de-identified or<br/>
                        disassociated with personal identifiers such that it can no longer be used to<br/>
                        reasonably identify a particular individual.<br/>
                        LINKS TO THIRD PARTY APPS AND WEBSITES<br/>
                        The Platform and any communication sent to you and/or a Minor User may<br/>
                        contain/display third party advertisements, contents, applications, and links to<br/>
                        other websites/mobile applications of interest. However, once you and/or a Minor<br/>
                        User has used any of these links to, or through any other means leave the Platform,<br/>
                        you and/or a Minor User should note that we do not have any control over that<br/>
                        other website(s)/mobile application(s). You (on behalf of yourself and/or a Minor<br/>
                        User) agree and acknowledge that these websites/mobile applications are operated<br/>
                        by third parties and are not controlled by, or affiliated to, or associated with us<br/>
                        unless expressly specified. We are not responsible for any form of transmission,<br/>
                        whatsoever, received by you and/or the Minor User from any such third party.<br/>
                        Accordingly, we do not make any representations concerning the privacy practices<br/>
                        or policies of such third parties or terms of use of such websites or mobile<br/>
                        applications, nor do we control or guarantee the accuracy, integrity, or quality of<br/>
                        the information, data, text, software, music, sound, photographs, graphics, videos,<br/>
                        messages or other materials available on such websites or mobile applications.<br/>
                        Therefore, we cannot be responsible for the protection and privacy of any<br/>
                        information, which you and/or the Minor User provide whilst visiting such<br/>
                        websites/mobile applications and this Privacy Policy does not govern such<br/>
                        websites/mobile applications. You (on behalf of yourself and/or a Minor User)<br/>
                        should exercise caution and look at the privacy statement applicable to the<br/>
                        website(s)/mobile application(s) in question. The information provided by you<br/>
                        and/or a Minor User to such third-party websites/mobile applications shall be<br/>
                        governed in accordance with the privacy policies of such websites/mobile<br/>
                        applications and it is recommended that you (on behalf of yourself and/or a Minor<br/>
                        User) review the privacy policy on any such websites and/or mobile applications<br/>
                        prior to using such websites/mobile applications.<br/>
                        GRIEVANCE OFFICER<br/>
                        If you find any discrepancies or have any grievances in relation to the Services, or<br/>
                        the collection, processing, storage, use, disclosure and transfer of your and/or a<br/>
                        Minor User’s Personal Information under this Privacy Policy or any other applicable<br/>
                        terms and conditions or policies of the Company, as may be applicable, please<br/>
                        contact the following:<br/>
                        Name: Karthik B V<br/>
                        E-mail: query@juzzit.in .<br/>
                        Working Days: Mon – Fri<br/>
                        Working Hours: 10 am – 6 pm<br/>
                        We will strive to address your feedback and concerns in a timely and effective<br/>
                        manner and within the time period prescribed under applicable law. The details of<br/>
                        the grievance officer may be changed by us from time to time by updating this<br/>
                        Privacy Policy.<br/>
                  </p>
              </div>
          </div>
      </div>
        )
    }
export default PrivacyPolicy