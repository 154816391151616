import React from "react";
import "./RightToLeftScroll.scss";

const RightToLeftScroll = () => {
  const fintechPartner = [
    {
      url: "https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31",
    },
    {
      url: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Meta-Logo.png",
    },
    {
      url: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Airbnb_Logo_B%C3%A9lo.svg/2560px-Airbnb_Logo_B%C3%A9lo.svg.png",
    },
    {
      url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2560px-Amazon_logo.svg.png",
    },
  ];

  return (
    <>
      <div class="logosData">
        <div class="logosData-slide">
          {fintechPartner &&
            fintechPartner?.map((val, key) => {
              return (
                <img
                  src={val.url}
                  onClick={() => {
                    window.open(val.link, "_blank");
                  }}
                  alt="ssss"
                  key={key}
                />
              );
            })}
          {fintechPartner &&
            fintechPartner?.map((val, key) => {
              return (
                <img
                  src={val.url}
                  onClick={() => {
                    window.open(val.link, "_blank");
                  }}
                  alt="ssss"
                  key={key}
                />
              );
            })}
        </div>

        <div class="logosData-slide">
          {fintechPartner &&
            fintechPartner?.map((val, key) => {
              return (
                <img
                  src={val.url}
                  onClick={() => {
                    window.open(val.link, "_blank");
                  }}
                  alt="ssss"
                  key={key}
                />
              );
            })}
          {fintechPartner &&
            fintechPartner?.map((val, key) => {
              return (
                <img
                  src={val.url}
                  onClick={() => {
                    window.open(val.link, "_blank");
                  }}
                  alt="ssss"
                  key={key}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default RightToLeftScroll;
