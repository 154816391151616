import React, { useEffect } from "react";
// import PageHeader from "../../../pages/PageHeader";
import ListCources from "./Components/ListCources";
import { useSelector, useDispatch } from "react-redux";
import { addAllCourses } from "../../../../Redux/RedusersCompo/CourcesReducer";
import { getAllCourseAndCourseType } from "../../../../Redux/Actions/CourcesAction";
import "./AllCources.scss";

// import headerImg from "../../../../img/AllCourse.png";
// import CourseHeaderBanner from "../CourseHeaderBanner";
import { useState } from "react";
import Loading from "../../../../LoadingComponent/Loading";
const AllCources = () => {
  const courses = useSelector((store) => store.courseData.allCourse);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  console.log(courses);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getAllCourseAndCourseType();
      dispatch(addAllCourses(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch]);
  // let image = {};

  // if (courses) {
  //   const imagesData = courses[0]?.course_BagtoundImage;

  //   if (imagesData) {
  //     const imagedata = imagesData.filter((img) => {
  //       return img.active_Status === "A";
  //     });

  //     if (imagedata) {
  //       image = imagedata[0];
  //     }
  //   }
  // }

  // const contants = () => {
  //   return (
  //     <>
  //       <h1>all our courses</h1>
  //     </>
  //   );
  // };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="AllCources-main-container">
      <div className="AllCources-container">
        <div className="AllCources-contents">
          {courses &&
            courses?.map((course, key) => {
              const courseName = course.course_name;
              if (course.course_active === "A") {
                return (
                  <ListCources
                    key={key}
                    coursesTypes={course.courseType}
                    courseName={courseName}
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default AllCources;
