import React, { useRef, useEffect, useState, useCallback } from "react";
import Plyr from "plyr";
import Hls from "hls.js";
import "plyr/dist/plyr.css";
import "./SingleLiveClassVideoPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStudentDataByToken } from "../../../../../Redux/Actions/AuthAction";
import { setStudentDataToStore } from "../../../../../Redux/RedusersCompo/AuthReducer";
import {
  addTopicVideoWatchedData,
  // addTopicVideoWatchedData,
  getTopicVideoWatchedList,
  updateTopicVideoWatchedList,
  // updateTopicVideoWatchedList,
} from "../../../../../Redux/Actions/CourcesAction";
import {
  getLessonTopicVides,
  getLiveClassSubjectRecodedvIDEOS,
  getRecodedVideoDetailsByVideoId,
} from "../../../../../Redux/Actions/MyCourseActions";
import {
  setemrolledRecodedCourseModuleVideos,
  setenrolledLiveClassRcordedSingleVodeo,
  setenrolledLiveClassRcordedVideoSubjectVodeo,
  setvideoWatchedData,
} from "../../../../../Redux/RedusersCompo/MyCourseReducer";
// import { Button } from "@mui/material";
import ListOfAllClassVideos from "../RecodedGeneral/RecodedClassesComponets/ClassVideosComponents/components/ListOfAllClassVideos";
import AssignMent from "./SinglsPageComponent/AssignMent";
//import Loading from "../../../../../LoadingComponent/Loading";
const SingleLiveClassVideoPage = () => {
  const videoRef = useRef(null);
  // const [isLoading, setIsLoading] = useState(false);

  const [showCourseContent, setShowCourseContent] = useState(false);
  const { studentBatchId, topicId, moduleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.authdata.StudentData);

  const tokenData = localStorage.getItem("tokenData");
  const enrolledLiveClassRcordedSingleVodeo = useSelector(
    (state) => state.mycourseData.enrolledLiveClassRcordedSingleVodeo
  );
  if (!tokenData) {
    navigate("/");
  }
  const emrolledRecodedCourseModuleVideos = useSelector(
    (state) => state.mycourseData.emrolledRecodedCourseModuleVideos
  );

  const hlsUrl = `https://d1fay353eajt77.cloudfront.net/${
    enrolledLiveClassRcordedSingleVodeo &&
    enrolledLiveClassRcordedSingleVodeo[0]?.topic_vodeo &&
    enrolledLiveClassRcordedSingleVodeo[0]?.topic_vodeo[0]?.file_location
  }`;
  // const hlsUrl = `/videos/coumputerScience/basic python/Deep dive of numpy functions/master.m3u8`;
  const updateWatchedPercentage = useCallback(
    async (percentage) => {
      const data = {
        percentage_Data: Number(percentage),
      };

      const response = await getTopicVideoWatchedList(studentBatchId, topicId);

      if (response && response.length > 0) {
        const videoWatchedId = response[0]?.videoWatchedId;
        const videoWatchedPercentage = response[0]?.percentage;

        console.log("if 1");

        if (response[0]?.complition_status === "D") {
          console.log("if 2");
          console.log(videoWatchedPercentage);
          if (
            Number(videoWatchedPercentage) < Number(percentage) &&
            percentage !== 0
          ) {
            console.log("if 3");
            if (percentage >= 90) {
              console.log("if 4");
              const updateData = {
                completed_Status: "change",
                PERCENTAGE: Number(percentage),
              };
              console.log("After updateTopicVideoWatchedList");
              const responseD = await updateTopicVideoWatchedList(
                videoWatchedId,
                updateData
              );
              console.log("After updateTopicVideoWatchedList", responseD);
            } else {
              const updateData2 = {
                PERCENTAGE: Number(percentage),
              };
              console.log("Before updateTopicVideoWatchedList");
              const responseD2 = await updateTopicVideoWatchedList(
                videoWatchedId,
                updateData2
              );
              console.log("After updateTopicVideoWatchedList", responseD2);
            }
          }
        } else {
          if (response[0]?.percentage < percentage) {
            const updateData3 = {
              PERCENTAGE: Number(percentage),
            };
            const responseD3 = await updateTopicVideoWatchedList(
              videoWatchedId,
              updateData3
            );
            console.log("After updateTopicVideoWatchedList", responseD3);
          }
        }
      } else {
        console.log("else 1");
        try {
          const addResponse = await addTopicVideoWatchedData(
            studentBatchId,
            topicId,
            data
          );
          console.log(addResponse);
        } catch (error) {
          console.error("Error updating watched percentage:", error);
        }
      }

      dispatch(setvideoWatchedData(response));
    },
    [dispatch, studentBatchId, topicId]
  );

  // const hlsUrl =
  //   "https://d7p5gncpfqoge.cloudfront.net/assets/videos/jsj/JUZZ IT20minutes.mp4.m3u8";
  useEffect(() => {
    const video = videoRef.current;
    const defaultOptions = {
      quality: {
        default: 720,
        options: [720, 480, 360],
        forced: true,
        onChange: (e) => updateQuality(e),
      },
    };

    const updateQuality = (e) => {
      window.hls.levels.forEach((l, i) => {
        if (l.height === e) {
          window.hls.currentLevel = i;
        }
      });
    };

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(hlsUrl);
      hls.attachMedia(video);

      hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
        const availableQualities = hls.levels.map((l) => l.height);

        defaultOptions.quality = {
          default: availableQualities[0],
          options: availableQualities,
          forced: true,
          onChange: (e) => updateQuality(e),
        };

        defaultOptions.controls = [
          "play-large",
          "restart",
          "rewind",
          "play",
          "fast-forward",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "captions",
          "settings",
          "pip",
          "airplay",
          "fullscreen",
        ];

        const player = new Plyr(video, defaultOptions);
        player.on("play", () => {
          const interval = setInterval(() => {
            const currentTime = player.currentTime;
            const videoDuration = video.duration;
            const watchedPercentage = (currentTime / videoDuration) * 100;
            updateWatchedPercentage(watchedPercentage);
          }, 30000);

          player.on("pause", () => clearInterval(interval));
          player.on("stop", () => clearInterval(interval));
        });
      });
      window.hls = hls;
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = `https://d20bxbroqsxp7u.cloudfront.net/${
        enrolledLiveClassRcordedSingleVodeo &&
        enrolledLiveClassRcordedSingleVodeo[0]?.topic_vodeo &&
        enrolledLiveClassRcordedSingleVodeo[0]?.topic_vodeo[0]?.file_location
      }`;
    }
  }, [hlsUrl, enrolledLiveClassRcordedSingleVodeo, updateWatchedPercentage]);

  useEffect(() => {
    const getData = async () => {
      // setIsLoading(true);
      const data = await getLessonTopicVides(studentBatchId, moduleId);
      dispatch(setemrolledRecodedCourseModuleVideos(data));
      // setIsLoading(false);
    };
    getData();
  }, [dispatch, studentBatchId, moduleId]);

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  useEffect(() => {
    const getData = async () => {
      // setIsLoading(true);
      const data = await getRecodedVideoDetailsByVideoId(
        topicId,
        studentBatchId
      );
      dispatch(setenrolledLiveClassRcordedSingleVodeo(data));
      // setIsLoading(false);
    };
    getData();
  }, [dispatch, topicId, studentBatchId]);

  useEffect(() => {
    const getData = async () => {
      const data = await getTopicVideoWatchedList(studentBatchId, topicId);
      dispatch(setvideoWatchedData(data));
    };
    getData();
  }, [dispatch, studentBatchId, topicId]);

  useEffect(() => {
    const getData = async () => {
      // setIsLoading(true);
      const data = await getLiveClassSubjectRecodedvIDEOS(topicId);
      dispatch(setenrolledLiveClassRcordedVideoSubjectVodeo(data));
      // setIsLoading(false);
    };
    getData();
  }, [dispatch, topicId]);
  // if (isLoading) {
  //   return <Loading />;
  // }

  const reloadData = async () => {
    const data = await getRecodedVideoDetailsByVideoId(topicId, studentBatchId);
    dispatch(setenrolledLiveClassRcordedSingleVodeo(data));
  };

  return (
    <div className="SingleLiveCourseVideoPage-main-container">
      <div className="SingleLiveCourseVideoPage-container">
        <h1>Program videos</h1>
        <div className="SingleLiveCourseVideoPage-video-main-content">
          <div
            className={`courseContent-viewer ${
              showCourseContent ? "showIsTrue" : ""
            }`}
          >
            <p>
              {<i class="fa-regular fa-hand-point-right"></i>}
              {enrolledLiveClassRcordedSingleVodeo &&
                enrolledLiveClassRcordedSingleVodeo[0]?.topic_title}
            </p>
            <span onClick={() => setShowCourseContent(!showCourseContent)}>
              Course Content <i class="fa-solid fa-caret-right"></i>
            </span>
          </div>
          <div className="SingleLiveCourseVideoPage-video-lesson-content">
            <div className="SingleLiveCourseVideoPage-video-content">
              <div className="video-contant">
                <video ref={videoRef} controls width="100%" height="100%" />
              </div>
            </div>
            <div
              className={`SingleLiveCourseVideoPage-lesson-container ${
                showCourseContent ? "showCourseContent" : ""
              }`}
            >
              <ListOfAllClassVideos
                videoData={emrolledRecodedCourseModuleVideos}
              />
            </div>
          </div>
        </div>

        <div className="SingleLiveCourseVideoPage-class-note-contant">
          <div className="SingleLiveCourseVideoPage-note-headings">
            <ul>
              {/* <li>About</li> */}
              <li>Assignment</li>
              {/* <li>Ask Quation</li> */}
            </ul>
          </div>
          <div className="SingleLiveCourseVideoPage-note-contant">
            <AssignMent
              reloadData={reloadData}
              AssignMentData={
                enrolledLiveClassRcordedSingleVodeo &&
                enrolledLiveClassRcordedSingleVodeo[0]?.topic_assignments
              }
            />
          </div>
          {/* <AskAQuation /> */}
        </div>
      </div>
    </div>
  );
};

export default SingleLiveClassVideoPage;
