import React, { useEffect, useState } from "react";
import "./OurPlacements.scss";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPlacementData } from "../../../Redux/Actions/HomePageActions";
import { setrecentPlacements } from "../../../Redux/RedusersCompo/HomePageReducer";

// import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SingleStudentPlacement1 from "./Components/SingleStudentPlacement";

const OurPlacements = () => {
  const [visiblePlacements, setVisiblePlacements] = useState(4);
  const dispatchRecentPlacements = useDispatch();
  const recentPlacements = useSelector(
    (state) => state.HomePageData.recentPlacements
  );
  useEffect(() => {
    const getData = async () => {
      const data = await getRecentPlacementData();
      dispatchRecentPlacements(setrecentPlacements(data));
    };
    getData();
  }, [dispatchRecentPlacements]);

  const loadMorePlacements = () => {
    setVisiblePlacements((prevVisiblePlacements) => prevVisiblePlacements + 4);
  };
  const loadLessPlacements = () => {
    setVisiblePlacements((prevVisiblePlacements) => prevVisiblePlacements - 4);
  };

  let sortedRecentPlacements;

  if (recentPlacements && recentPlacements.length > 0) {
    sortedRecentPlacements = [...recentPlacements].sort((a, b) => {
      return a.ORDER - b.ORDER;
    });
  }

  return (
    <div className="OurPlacements-main-top-container">
      <div className="OurPlacements-headercoomponent">
        <div className="OurPlacements-header-left">
          <span className="span">Recent Placements</span>
          <h4>We help our students get placed in Top Companies</h4>
          <p>
            At Juzz-It Institute, we specialize in guiding students to secure
            placements in top-tier companies. Elevate your career prospects with
            our expert assistance and unlock opportunities at the forefront of
            your field.
          </p>
          <div
            className="book-form-button-home"
            style={{ justifyContent: "flex-start", width: "250px" }}
          >
            {" "}
            <span style={{ width: "100%" }}>Hear from our students</span>
          </div>
        </div>
        <div className="OurPlacements-header-right">
          <img
            src="https://assets-global.website-files.com/5f8e106d7c87153937611668/62bd4317ae138647c9937767_Ad%20Placement.jpg"
            alt="dj"
          ></img>
        </div>
      </div>
      <div className="OurPlacements-main-container">
        <h1 className="All-components-heading">Recent Placements</h1>
        <div className="OurPlacements-container">
          {sortedRecentPlacements &&
            sortedRecentPlacements
              .slice(0, visiblePlacements)
              .map((val, key) => (
                <SingleStudentPlacement1 key={key} PLACEMENTdATA={val} />
              ))}
        </div>
        <div className="load-more-buttom">
          {visiblePlacements < recentPlacements.length && (
            <Button
              variant="contained"
              onClick={loadMorePlacements}
              className="button"
            >
              Load More
            </Button>
          )}
          {visiblePlacements > 3 && (
            <Button
              variant="contained"
              onClick={loadLessPlacements}
              className="button"
            >
              Load Less
            </Button>
          )}
        </div>
      </div>
      {/* <div className="ourRecentBlog-main-container">
        <div className="ourRecentBlog-container">
          <h5>Our Blogs</h5>
          <p>
            {allBlogs &&  
              allBlogs.map((val) => (
                <span
                  key={val.BLOG_ID}
                  onClick={() => {
                    navigate(`/Blogs/${val.BLOG_ID}/details`);
                  }}
                >
                  <u>{val.BLOG_HEADING}</u>
                  <i className="fa-solid fa-grip-lines-vertical"></i>
                </span>
              ))}
          </p>
        </div>
      </div> */}
      {/* <div className="OurPlacements-logo-main-container">
        <div className="OurPlacements-logo-container">
          <h1>Our Student Works At</h1>
          <div className="OurPlacements-logo-contant">
            <img
              src="https://intellipaat.com/wp-content/themes/intellipaat/images/Hiring-Partners.png"
              alt="dxadx"
            ></img>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default OurPlacements;
