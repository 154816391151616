import React from "react";
import "./SingleRecomenedCourse.scss";
import { useNavigate } from "react-router-dom";
const SingleRecomenedCourse = ({ courseData }) => {
  // const courseTypeImage = courseData.courseTypeImage;
  // let bagroundImageURL = "";

  // if (courseTypeImage) {
  //   const bagroundImageData = courseTypeImage.filter((image) => {
  //     return image.active_Status === "A";
  //   });

  //   if (bagroundImageData) {
  //     bagroundImageURL = bagroundImageData[0]?.image_URL;
  //   }
  // }

  const navigate = useNavigate();

  return (
    <div className="SingleRecomenedCourse-main-container">
      <div className="SingleRecomenedCourse-content">
        <h2 className="componets-itam-heading">{courseData?.course_Heading}</h2>
        <div className="SingleRecomenedCourse-main-content">
          <div className="SingleCourse-Content">
            <h5 className="componets-itam-heading">
              {" "}
              <i class="fa-solid fa-angles-right"></i>Career Outcome
            </h5>
            <p className="all-component-description">
              {courseData?.course_Career_Outcome}{" "}
            </p>
          </div>
          <div className="SingleCourse-Content">
            <h5 className="componets-itam-heading">
              {" "}
              <i class="fa-solid fa-angles-right"></i>What You Will Learn
            </h5>
            <p className="all-component-description">
              {courseData?.course_OverView}
            </p>
          </div>
        </div>
      </div>
      <div
        className="learn_more"
        onClick={() => {
          navigate(`/courseType/${courseData?.course_typeId}`);
        }}
      >
        <span>Learn more</span>
        <i class="fa-solid fa-right-long"></i>
      </div>
    </div>
  );
};

export default SingleRecomenedCourse;
