import React, { useState } from "react";
import "./HaveAnyFurther.scss";
import img from "../../../../../img/Home/stick_figure_holding_up_question_mark_300_nwm-removebg-preview.png";
import { Button } from "@mui/material";
import DemoForm from "../../../DemoFormCompo/DemoForm";
const HaveAnyFurther = () => {
  const [showForm, setshowForm] = useState(false);
  const [successSubmition, setsuccessSubmition] = useState(false);
  return (
    <div className="HaveAnyFurther-main-container">
      <div className={`demo-form ${showForm ? "desplayDemoform" : ""}`}>
        <DemoForm
          SETDisplay={() => setshowForm(!showForm)}
          setsuccessSubmition={() => setsuccessSubmition(true)}
        />
      </div>

      <div
        className={`sucessfully-submited-Request-main ${
          successSubmition ? "successSubmition" : ""
        }`}
      >
        <div className="sucessfully-submited-Request">
          <h1 className="All-components-heading">
            We received your request. We will reach you soon.
          </h1>
          <Button
            variant="contained"
            onClick={() => setsuccessSubmition(false)}
          >
            Ok
          </Button>
        </div>
      </div>
      <div className="HaveAnyFurther-container">
        <div className="HaveAnyFurther-left-content">
          <h1>Have any further questions?</h1>
          <span
            onClick={() => {
              setshowForm(true);
            }}
          >
            contact with us<i class="fa-solid fa-right-long"></i>
          </span>
        </div>
        <div className="HaveAnyFurther-right-conatent">
          <img src={img} alt="vvfd"></img>
        </div>
      </div>
    </div>
  );
};

export default HaveAnyFurther;
