import React, { useEffect, useState } from "react";
import "./EnrolledLiveCourseDetails.scss";
import { useParams } from "react-router-dom";
import { getCourseTypeModuleData } from "../../../../../../../Redux/Actions/CourcesAction";
import { useDispatch, useSelector } from "react-redux";
import { setmyCourseTypeDetailS } from "../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import EnrolledCourseStructure from "./EnrolledCourseStructureComponent/EnrolledCourseStructure";
import Loading from "../../../../../../../LoadingComponent/Loading";
import CourseDetailsBar from "./CourseDetailsBar";
const EnrolledLiveCourseDetails = () => {
  const { courseId, batchId, studentBatchId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const courseTypeDetails = useSelector(
    (state) => state.mycourseData.myCourseTypeDetailS
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getCourseTypeModuleData(courseId);
      dispatch(setmyCourseTypeDetailS(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, courseId]);

  let moduls = [];

  if (courseTypeDetails && courseTypeDetails.length > 0) {
    moduls = courseTypeDetails[0]?.course_Type_modules;
  }

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="EnrolledLiveCourseDetails-main-container">
      <CourseDetailsBar />
      {/* <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classLinks`}
              className={"link"}
            >
              {" "}
              class links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div> */}
      <div className="student-batch-content">
        <div className="studemnt-profile-container">
          <div className="studemnt-profile-content">
            <h2> Batch Id</h2>
            <span>{batchId}</span>
          </div>
          <div className="studemnt-profile-content">
            <h2>your Batch Id</h2>
            <span>{studentBatchId}</span>
          </div>
          {/* <div className="studemnt-profile-content">
            <h2> Batch Name</h2>
            <span>{"dscnasdoi"}</span>
          </div> */}
        </div>
        <EnrolledCourseStructure moduls={moduls} />
      </div>
    </div>
  );
};
export default EnrolledLiveCourseDetails;
