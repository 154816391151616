import "./App.css";
import LogIn from "./AthenticationComponent/LogIn";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./StudentComponenets/Components/HomeComponent/Home";
import About from "./StudentComponenets/pages/AboutPages/AboutUsPages/About";
//import Faqs from "./StudentComponenets/pages/AboutPages/FaqsPages/Faqs";
import OurTeam from "./StudentComponenets/pages/AboutPages/OurTeamPhage/OurTeam";
import Contact from "./StudentComponenets/pages/ContactPages/Contact";
//import Placements from "./StudentComponenets/pages/SuccessStoriesPage/PlacementPages/Placements";
//import StudentReview from "./StudentComponenets/pages/SuccessStoriesPage/StudentReviewsPages/StudentReview";
import Footer from "./StudentComponenets/Components/FooterComponent/Footer";
import AllCources from "./StudentComponenets/Components/CourcesComponents/AllCourcesComponent/AllCources";
import SingleCourcePage from "./StudentComponenets/Components/CourcesComponents/SingleCourceComponent/SingleCourcePage";
import Error from "./ErrorComponenet/Error";
import Blogs from "./StudentComponenets/pages/BlogesPages/Bloges";
import PrivacyPolicy from "./StudentComponenets/pages/Privacy Policy/PrivacyPolicy";
import TermsConditions from "./StudentComponenets/pages/Terms & Conditions/TermsConditions";
import EnrollRequest from "./StudentComponenets/Components/CourcesComponents/SingleCourceComponent/EnrollRequest";
import ScrollToTopButton from "./StudentComponenets/ScrollToTopButton";
import ScrollToTop from "./ScrolltoTopComponent/ScrollToTop";
import DashBoard from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/DashBoard";
import DashBoardHome from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/DashboardHomeComponents/DashBoardHome";
import EnrolledLiveCourses from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledLiveCoursesComponents/EnrolledLiveCourses";
import EnrolledRecordedCourses from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledRecodedCoursesComponents/EnrolledRecordedCourses";
import EnrolledLiveCourseDetails from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledLiveCoursesComponents/Components/EnrolledLiveCourseDetails";
import EnrolledLiveCourseClassLinks from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledLiveCoursesComponents/Components/EnrolledLiveCourseClassLinksComponents/EnrolledLiveCourseClassLinks";
import EnrolledLiveCoursePlacements from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledLiveCoursesComponents/Components/EnrolledLiveCoursePlacementsComponents/EnrolledLiveCoursePlacements";
import EnrolledRecordedCoursesDetails from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledRecodedCoursesComponents/Components/EnrolledRecordedCoursesDetails";
import EnrolledLiveCoursePlacementsDetails from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledLiveCoursesComponents/Components/EnrolledLiveCoursePlacementsComponents/EnrolledLiveCoursePlacementsDetails";
import BlogDetails from "./StudentComponenets/pages/BlogesPages/Components/BlogDetails";
import Video from "./StudentComponenets/pages/Video";
import AllLiveClassRecodedVideo from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/AdisionalCOmpo/AllLiveClassVideos";
import SingleLiveCourseVideoPage from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/AdisionalCOmpo/SingleLiveClassVideoPage";
import EnrolledRecordedCoursesPlacement from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/AdisionalCOmpo/EnrolledRecodedStdPlacement";
import EnrolledRecordedCoursesPlacementDetails from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/AdisionalCOmpo/EnrolledRecodedStdPlacementDetail";
import RecodedClasses from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/RecodedGeneral/RecodedClassesComponets/RecodedClasses";
import SingeRecordedClassVideoPage from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/RecodedGeneral/RecodedClassesComponets/Components/SingeRecordedClassVideoPage";
import AllClassVideos from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/RecodedGeneral/RecodedClassesComponets/ClassVideosComponents/AllClassVideos";
import EnrolledRecordedCoursesSessionLinks from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/RecodedGeneral/SessionLinksComponents/SessionLinks";
import LoginWithOTp from "./AthenticationComponent/LoginWithOTp";
import HandleForgotPassWord from "./AthenticationComponent/HandleForgotPassWord";
//import Header from "./StudentComponenets/Components/HeaderComponents/Header";
import SingleCourseTypeData from "./StudentComponenets/Components/CourcesComponents/SingleCourceComponent/SingleCourseTypeData";
import LandIngPage from "./StudentComponenets/Components/LandingPageCompoment/LandIngPage";
import RecentPlacementDetail from "./StudentComponenets/Components/HomeComponent/Components/RecentPlacementsCompone/RecentPlacementDetailCompo/RecentPlacementDetail";
import RPprojectDetails from "./StudentComponenets/Components/HomeComponent/Components/RecentPlacementsCompone/RecentPlacementDetailCompo/Componet/RPprojectDetails";
import VideoModules from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledLiveCoursesComponents/Components/EnrolledLiveCourseRecordedVideoComponents/VideoModules";
import ModulesLesson from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/EnrolledLiveCoursesComponents/Components/EnrolledLiveCourseRecordedVideoComponents/ModulesLesson";
import AllAssignments from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/AdisionalCOmpo/components/AllAssignments";
import AssignmentDetails from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/AdisionalCOmpo/components/AssignmentDetails";
import MyProfile from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/MyProfileComponents/MyProfile";
import MyProfileSetting from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/MyProfileSettingComponents/MyProfileSetting";
import ResetPassWord from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/MyProfileSettingComponents/Components/ResetPassWord";
import SocialLinks from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/Components/MyProfileSettingComponents/Components/SocialLinks";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setStudentDataToStore } from "./Redux/RedusersCompo/AuthReducer";
import {
  getStudentDataByToken,
  validateToken,
} from "./Redux/Actions/AuthAction";
import OurPlacements from "./StudentComponenets/Components/PlacementsComponent/OurPlacements";
// import DemoHeader from "./StudentComponenets/Components/HeaderComponents/DemoHeader";
import Workshops from "./StudentComponenets/Components/WorkshopsComponents/Workshops";
import Internships from "./StudentComponenets/Components/InternshipsComponents/Internships";
import DemoHeader from "./StudentComponenets/Components/HeaderComponents/DemoHeader";
import AcademicTraining from "./StudentComponenets/Components/AcademicTrainingComp/AcademicTraining";
import Events from "./StudentComponenets/Components/EventsComponents/Events";
import Refere from "./StudentComponenets/Components/HeaderComponents/DashBoardComponents/RefereCompo/Refere";
import FreeCourses from "./StudentComponenets/Components/FreeCoursesCompoments/FreeCourses";
import JuzzItJobs from "./StudentComponenets/Components/HomeComponent/Components/JuzzItJobsComponents/JuzzItJobs";
import EventVideo from "./StudentComponenets/Components/EventsComponents/EventsHeaderCompo/components/SingleEventCompo/EventVideoCompo/EventVideo";
import JobDetails from "./StudentComponenets/Components/HomeComponent/Components/JuzzItJobsComponents/Components/JobDetails";
import ApplicationSubmited from "./StudentComponenets/Components/LandingPageCompoment/ApplicationSubmited";

function App() {
  const userData = localStorage.getItem("userData");

  if (userData) {
    localStorage.removeItem("userData");
  }

  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      let headerContent = "";
      const user = localStorage.getItem("tokenData");
      if (user) {
        headerContent = JSON.parse(user).jwt_token;
      }
      if (user) {
        const tokenData = {
          token: headerContent,
        };
        const responce = await validateToken(tokenData);

        if (responce?.status === false) {
          localStorage.removeItem("tokenData");
        }
      } else {
        return {};
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      let headerContent = "";
      const user = localStorage.getItem("tokenData");
      if (user) {
        headerContent = JSON.parse(user).jwt_token;
      }
      if (user) {
        const tokenData = {
          token: headerContent,
        };
        const data = await getStudentDataByToken(tokenData);
        dispatch(setStudentDataToStore(data));
      } else {
        return {};
      }
    };
    getData();
  }, [dispatch]);

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <ScrollToTop />
        {/* <Header /> */}
        <DemoHeader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/OurAlumini" element={<OurPlacements />} />
          <Route path="/Internships" element={<Internships />} />
          <Route path="/Workshops" element={<Workshops />} />
          <Route path="/AcademicTraining" element={<AcademicTraining />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Events/:eventId/:eventname" element={<EventVideo />} />
          <Route path="/JuzzItJobs" element={<JuzzItJobs />} />
          <Route path="/JuzzItJobs/:jobId" element={<JobDetails />} />
          <Route path="/FreeCourses" element={<FreeCourses />} />
          <Route
            path="/application/:subjectName/:pageId"
            element={<LandIngPage />}
          />
          <Route path="/OurTeam" element={<OurTeam />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Blogs/:blogId/details" element={<BlogDetails />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route path="/Cources" element={<AllCources />} />
          <Route path="/LoginWithOTp" element={<LoginWithOTp />} />
          <Route path="/ForGotPassWord" element={<HandleForgotPassWord />} />

          <Route path="/page/:pageId/:pageName" element={<LandIngPage />} />
          <Route path="/page/success" element={<ApplicationSubmited />} />
          <Route
            path="/OurAlumini/:placementId"
            element={<RecentPlacementDetail />}
          />
          <Route
            path="/OurAlumini/:placementId/projects/:projectId"
            element={<RPprojectDetails />}
          />
          <Route
            path="/MyliveCourses/recodedVideo/subject/:subjectId/videos/:videoId"
            element={<Video />}
          ></Route>

          <Route
            path="/MyliveCourses/recodedVideo/subject/:subjectId/videos"
            element={<AllLiveClassRecodedVideo />}
          ></Route>

          <Route
            path="/DashBoard/MyliveCourses/:courseId/:batchId/:studentBatchId/classes/module/:moduleId/topic/:topicId"
            element={<SingleLiveCourseVideoPage />}
          >
            <Route path="" element={<AllAssignments />}></Route>
            <Route
              path="assignment/:assignmentId"
              element={<AssignmentDetails />}
            ></Route>
          </Route>
          <Route
            path="/DashBoard/MyRecodedCourse/:courseId/:studentBatchId/classes/module/:moduleId/videos/:topic_id"
            element={<SingeRecordedClassVideoPage />}
          />
          <Route path="/DashBoard" element={<DashBoard />}>
            <Route index element={<DashBoardHome />} />
            <Route path="MyProFile" element={<MyProfile />} />

            <Route path="MyProFile/setting" element={<MyProfileSetting />} />
            <Route path="MyProFile/resetPassWord" element={<ResetPassWord />} />
            <Route path="MyProFile/socialLinks" element={<SocialLinks />} />

            <Route path="MyliveCourses" element={<EnrolledLiveCourses />} />

            <Route
              path="MyliveCourses/:courseId/:batchId/:studentBatchId/details"
              element={<EnrolledLiveCourseDetails />}
            />
            <Route
              path="MyliveCourses/:courseId/:batchId/:studentBatchId/placements"
              element={<EnrolledLiveCoursePlacements />}
            >
              <Route
                path=":placementId/details"
                element={<EnrolledLiveCoursePlacementsDetails />}
              />
            </Route>
            <Route
              path="MyliveCourses/:courseId/:batchId/:studentBatchId/classLinks"
              element={<EnrolledLiveCourseClassLinks />}
            />
            <Route
              path="MyliveCourses/:courseId/:batchId/:studentBatchId/recodedVideos"
              element={<RecodedClasses />}
            />
            <Route
              path="MyliveCourses/:courseId/:batchId/:studentBatchId/classes"
              element={<VideoModules />}
            />
            <Route
              path="MyliveCourses/:courseId/:batchId/:studentBatchId/classes/module/:moduleId/lessons"
              element={<ModulesLesson />}
            />

            <Route
              path="MyliveCourses/:studentBatchId/Refere"
              element={<Refere />}
            />
            <Route
              path="MyliveCourses/:courseId/:batchId/:studentBatchId/recodedVideos/subject/:subjectId/videos"
              element={<AllLiveClassRecodedVideo />}
            />

            <Route
              path="MyRecodedCourse"
              element={<EnrolledRecordedCourses />}
            />

            <Route
              path="MyRecodedCourse/:courseId/:studentBatchId/details"
              element={<EnrolledRecordedCoursesDetails />}
            />
            <Route
              path="MyRecodedCourse/:courseId/:studentBatchId/placements"
              element={<EnrolledRecordedCoursesPlacement />}
            >
              <Route
                path=":placementId/details"
                element={<EnrolledRecordedCoursesPlacementDetails />}
              />
            </Route>
            <Route
              path="MyRecodedCourse/:courseId/:studentBatchId/sessionLinks"
              element={<EnrolledRecordedCoursesSessionLinks />}
            />
            <Route
              path="MyRecodedCourse/:courseId/:studentBatchId/classes"
              element={<RecodedClasses />}
            />
            <Route
              path="MyRecodedCourse/:courseId/:studentBatchId/classes/module/:moduleId/videos"
              element={<AllClassVideos />}
            />
          </Route>
          <Route
            path="/cources/:courseId/courseTypes"
            element={<SingleCourcePage />}
          />
          <Route path="/LogIn" element={<LogIn />} />
          <Route
            path="/courseType/:courseTypeId"
            element={<SingleCourseTypeData />}
          />
          <Route
            path="/courseType/:courseTypeId/sendEnrollRequest"
            element={<EnrollRequest />}
          />
          <Route path="LogIn" element={<LogIn />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
        <ScrollToTopButton />
      </BrowserRouter>
    </div>
  );
}

export default App;
