import React, { useState } from "react";
import "./AssignMent.scss";
import { Button, TextField } from "@mui/material";
import {
  addStudentAssignment,
  deleteAssignmentSumition,
} from "../../../../../../Redux/Actions/MyCourseActions";
import { useParams } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";

const AssignMent = ({ reloadData, AssignMentData }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [massageError, setMassageError] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [massage, setMassage] = useState("");
  // const [uploadId, setUploadId] = useState("");

  const [downloadpath, setdownloadPath] = useState("");
  const { studentBatchId } = useParams();

  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [updateAlertMessage, setUpdateAlertMessage] = useState("");
  const [UpdateId, setUpdateId] = useState(null);
  const [updateType, setUpdateType] = useState("");

  const handleUpdateRequest = async (type, PATH, id) => {
    if (type === "SUBITE_ASSIGNMENT") {
      setUpdateType("SUBITE_ASSIGNMENT");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to  SUBMIT it  ?");
    }
    if (type === "DELETE_ASSIGNMENT") {
      setUpdateType("SUBITE_ASSIGNMENT");
      setOpenDialog(true);
      setUpdateAlertMessage("Are you sure you want to  delete it  ?");
    }
    if (type === "DOUMLOAD_ASSIGNMENT") {
      setUpdateType("DOUMLOAD_ASSIGNMENT");
      setOpenDialog(true);
      setUpdateAlertMessage("do you want to  download it  ?");
      setdownloadPath(PATH);
    }
    if (type === "DOWNLOAD_SUMITIOM_DATA") {
      setUpdateType("DOWNLOAD_SUMITIOM_DATA");
      setOpenDialog(true);
      setUpdateAlertMessage("do you want to  download it  ?");
      setdownloadPath(PATH);
    }

    if (type === "DELETE_ASSIGNMENT") {
      setUpdateType("DELETE_ASSIGNMENT");
      setOpenDialog(true);
      setUpdateAlertMessage("do you want to  delete it  ?");
    }
    setUpdateId(id);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateStatus = async () => {
    setOpenDialog(false);

    if (updateType === "DELETE_ASSIGNMENT") {
      const responce = await deleteAssignmentSumition(UpdateId);
      if (responce?.status === true) {
        reloadData();
        setSuccessMessage("Assignment delete successfully");
        setOpenSnackbar(true);
      }
    }

    if (updateType === "DOUMLOAD_ASSIGNMENT") {
      downloadForm(downloadpath);
    }
    if (updateType === "DOWNLOAD_SUMITIOM_DATA") {
      downloadForm(downloadpath);
    }
    if (updateType === "SUBITE_ASSIGNMENT") {
      handleSubmit();
    }
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const downloadForm = async (filename) => {
    try {
      const cdnUrl = `https://d7p5gncpfqoge.cloudfront.net/${filename}`;
      const response = await fetch(cdnUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file", error);
    }
  };

  const handleSubmit = async () => {
    if (!massage.trim()) {
      setMassageError(true);
      return;
    } else {
      setMassageError(false);
    }

    if (!fileData) {
      // File not selected
      return;
    }

    // Add your file upload logic here, including file format validation
    const allowedFormats = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "text/plain",
    ];
    if (!allowedFormats.includes(fileData.type)) {
      alert(
        "Invalid file format. Please select an image (jpg, png), a PDF file, or a text file."
      );
      return;
    }
    const formData = new FormData();
    formData.append("file", fileData);
    formData.append("submition_desc", massage);

    const responce = await addStudentAssignment(
      studentBatchId,
      UpdateId,
      formData
    );
    if (responce?.status === true) {
      reloadData();
      setSuccessMessage("Assignment sumited successfully");
      setOpenSnackbar(true);
    }

    // Clear the form after submission
    setMassage("");
    setFileData(null);
  };

  const handleFileUploadClick = () => {
    // Trigger the hidden file input
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileData(file);
  };

  return (
    <div className="AssignMent-main-container">
      {AssignMentData && AssignMentData.length > 0 ? (
        <div className="AssignMent-accordion">
          {AssignMentData &&
            AssignMentData.map((val, index) => {
              if (val.active_Status === "D") {
                return null;
              }
              return (
                <div
                  key={index}
                  className={`AssignMent-accordion-item ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <div
                    className="accordion-header"
                    onClick={() => toggleAccordion(index)}
                  >
                    <div className="accordion-header-contant">
                      <h4>
                        <span>{index + 1}.</span>
                        {val.assignment_topic}
                      </h4>
                      <p>
                        {" "}
                        {<i class="fa-regular fa-hand-point-right"></i>}{" "}
                        {val.assignmant_desc}
                      </p>
                    </div>

                    <Button
                      variant="contained"
                      className="button"
                      onClick={() =>
                        handleUpdateRequest(
                          "DOUMLOAD_ASSIGNMENT",
                          val.file_location
                        )
                      }
                    >
                      Download
                    </Button>
                  </div>
                  {activeIndex === index && (
                    <div className="AssignMent-accordion-content">
                      {val.assignmentSubmitedDate &&
                      val.assignmentSubmitedDate.length > 0 ? (
                        <div className="assignment-submitionData">
                          <p>
                            {" "}
                            {
                              <i class="fa-regular fa-hand-point-right"></i>
                            }{" "}
                            {val.assignmentSubmitedDate[0]?.SUBMITION_DEAS}
                          </p>
                          <div className="successfully-sumited-button">
                            <Button
                              className="button"
                              onClick={() =>
                                handleUpdateRequest(
                                  "DOWNLOAD_SUMITIOM_DATA",
                                  val.assignmentSubmitedDate[0]?.FILE_LOCATION
                                )
                              }
                              variant="contained"
                            >
                              download
                            </Button>
                            <Button
                              className="button"
                              onClick={() =>
                                handleUpdateRequest(
                                  "DELETE_ASSIGNMENT",
                                  " ",
                                  val.assignmentSubmitedDate[0]
                                    ?.ASSIGNMENT_SUBMITION_ID
                                )
                              }
                              variant="contained"
                              color="error"
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="assignment-submitionData-form">
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/jpeg, image/png, application/pdf, text/plain"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />

                          <TextField
                            type="text"
                            className="message"
                            label="Message"
                            value={massage}
                            onChange={(e) => {
                              setMassage(e.target.value);
                              setMassageError(false); // Reset error when user types
                            }}
                            rows={4}
                            fullWidth
                            multiline
                            error={massageError}
                            helperText={
                              massageError ? "Message cannot be empty" : ""
                            }
                          />
                          <div className="sumit-assignment-button">
                            <Button
                              className="button"
                              variant="outlined"
                              onClick={handleFileUploadClick}
                            >
                              Upload File
                            </Button>
                            {fileData && <span>{fileData.name}</span>}
                          </div>
                          <Button
                            className="button"
                            onClick={() => {
                              handleUpdateRequest(
                                "SUBITE_ASSIGNMENT",
                                " ",
                                val.assignment_id
                              );
                            }}
                            variant="contained"
                          >
                            Submit
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}

          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle className="dialog-title">
              Confirm Status Update
            </DialogTitle>
            <DialogContent className="dialog-content">
              {updateAlertMessage}
            </DialogContent>
            <DialogActions className="dialog-actions">
              <Button
                variant="contained"
                onClick={handleDialogClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleUpdateStatus}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openSnackbar}
            autoHideDuration={5000} // Auto-hide after 5 seconds
            onClose={handleCloseSnackbar}
            message={successMessage}
          />
        </div>
      ) : (
        <div className="AssignMent-no-data-container">
          <p>appearing soon</p>
        </div>
      )}
    </div>
  );
};

export default AssignMent;
