import React from "react";
import "./FressCourseHeader.scss";
//import FintechParterLogogoScroller from "../../../HomeComponent/Components/PlacementOpportunitiesCompo/FintechParterLogogoScroller";
import RightToLeftScroll from "../scrollcomponent/RightToLeftScroll";
import LeftTorightScroll from "../scrollcomponent/LeftTorightScroll";
const FressCourseHeader = () => {
  return (
    <div className="FressCourseHeader-main-container">
      <div className="FressCourseHeader-container">
        <h1 className="All-components-heading">
          Enhance your learning daily with JUZZ-IT.
        </h1>

        <div className="FressCourseHeader-main-contant">
          <div className="FressCourseHeader-contant">
            <div className="book-form-button-home" style={{ margin: 0 }}>
              <span>GET JOB READY!!</span>
            </div>
            <h2 className="All-components-heading">
              Learn through free classes and become eligible to receive our
              scholarship opportunity
            </h2>
            <p className="all-component-heading-discription">
              Master coding like the elite 1% by tackling hundreds of coding
              challenges and assessments commonly encountered in leading tech
              firms such as Meta, Amazon, and Google.
            </p>
            {/* <FintechParterLogogoScroller /> */}
            <RightToLeftScroll />
            <LeftTorightScroll />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FressCourseHeader;
