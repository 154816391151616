import React from "react";
import "./SingleBlog.scss";
import { NavLink, useNavigate } from "react-router-dom";
const SingleBlog = (props) => {
  const blogData = props.blogData;
  const navigate = useNavigate();
  return (
    <div className="SingleBlog-main-container">
      <NavLink to={`/Blogs/${blogData.BLOG_ID}/details`} className="link">
        <div className="SingleBlog-img-content">
          {blogData.blog_Image &&
            blogData.blog_Image?.map((val1, key1) => {
              if (val1.active_Status === "A") {
                return <img src={val1.image_URL} alt="qwhsiyu"></img>;
              }
              return null;
            })[0]}
        </div>
        <div className="SingleBlog-content">
          <h1>{blogData.BLOG_HEADING}</h1>
          <p>{blogData.BLOG_INTRUDUCTION}</p>

          <span
            onChange={() => {
              navigate();
            }}
            className="span"
          >
            <span>Read More</span> <i class="fa-solid fa-arrow-right"></i>
          </span>
        </div>
      </NavLink>
    </div>
  );
};

export default SingleBlog;
