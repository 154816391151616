import React, { useEffect, useState } from "react";
import "./EnrolledRecodedStdPlacement.scss";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllPlacement,
//   getRecodedStudentPlacement,
// } from "../../../../../../../../Redux/Actions/MyCourseActions";
import { setenrolledRecodedCoursesPlacements } from "../../../../../Redux/RedusersCompo/MyCourseReducer";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import Loading from "../../../../../LoadingComponent/Loading";
import { getRecodedStudentPlacement } from "../../../../../Redux/Actions/MyCourseActions";
const EnrolledRecordedCoursesPlacement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { courseId, studentBatchId } = useParams();
  const allPlacement = useSelector(
    (state) => state.mycourseData.enrolledRecodedCoursesPlacements
  );
  const dispatch = useDispatch();

  console.log(allPlacement);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getRecodedStudentPlacement(studentBatchId);
      dispatch(setenrolledRecodedCoursesPlacements(data));
      setIsLoading(false);
    };
    getData();
  }, [studentBatchId, dispatch]);
  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="EnrolledRecordedCoursesPlacement-mainContailner">
      <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/sessionLinks`}
              className={"link"}
            >
              {" "}
              session links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div>

      <TableContainer component={Paper} className="Placements-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>PLACEMENT ID</TableCell>
              <TableCell>COMPANY NAME</TableCell>
              <TableCell>COMPANY INTRODUCTION</TableCell>

              <TableCell>APPLY LINK</TableCell>
              <TableCell>view details </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allPlacement &&
              allPlacement?.map((val, key) => {
                if (val.active_status === "A") {
                  return (
                    <TableRow key={key}>
                      <TableCell>{val.placement_Id}</TableCell>
                      <TableCell>{val.placement_CompanyName}</TableCell>
                      <TableCell>
                        {val.placement_ConmpanyIntruduction}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            window.open(val.apply_Link, "_blank");
                          }}
                          variant="contained"
                          color="secondary"
                        >
                          link
                        </Button>
                      </TableCell>
                      <TableCell>
                        <NavLink to={`${val.placement_Id}/details`}>
                          <Button>view details</Button>
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default EnrolledRecordedCoursesPlacement;
