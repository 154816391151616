import React from "react";
import "./AcademicTraining.scss";
import AcademicTrainingHeader from "./Componets/AcademicTrainingHeaderComp/AcademicTrainingHeader";
import TailormadeSolutions from "./Componets/TailormadeSolutionsCompo/TailormadeSolutions";
import InfinityScrollingLogos from "../HomeComponent/logoScrollComponent/logoScroll";
import InfinityScrollingLogo from "../../../img/Home/AwardsIcon.jpeg";
import { logos } from "../HomeComponent/logoScrollComponent/logoData";
import OfflineWorkShop from "./OfflineWorkShopComponent/OfflineWorkShop";
// import OwrColaburetion from "./Componets/OwrColaburetionCompo/OwrColaburetion";
// import ApplicationForm from "./Componets/ApplicationFormCompo/ApplicationForm";
const AcademicTraining = () => {
  return (
    <div className="AcademicTraining-main-comtainer">
      <AcademicTrainingHeader />
      {/* <TopIndustryPlayers /> */}  
      <OfflineWorkShop />
      <TailormadeSolutions />

      {/* <OwrColaburetion /> */}
      <div className="AcademicTraining-news-main-container">
        <div className="AcademicTraining-news-content">
          {/* <h1>JUZZ-IT In News</h1> */}
          <div className="AcademicTraining-news-container-img">
            <img src={InfinityScrollingLogo} alt="jdjdj"></img>
          </div>
          <div className="HomeHeader-news-container-logos">
            <InfinityScrollingLogos logos={logos} />
          </div>
        </div>
      </div>
      {/* <WhatOurStudentsSay />
        <OurFullTimeFaculty />
        <LeadingIndustryBodies />
        <EmployabilityOffering /> */}
      {/* 
      <RecentPlacements /> */}

      {/* <ApplicationForm /> */}
    </div>
  );
};

export default AcademicTraining;
