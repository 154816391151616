import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authStatus: false,
  newuserData: {},
  signUpOtpResponce: {},
  StudentData: {},
};

const AuthReducer = createSlice({
  name: "Auth",
  initialState: initialState,
  reducers: {
    setAuthStatus(state, action) {
      state.authStatus = action.payload;
    },
    setNewuserData(state, action) {
      state.newuserData = action.payload;
    },
    setsignUpOtpResponce(state, action) {
      state.signUpOtpResponce = action.payload;
    },
    setStudentDataToStore(state, action) {
      state.StudentData = action.payload;
    },
  },
});

export const { setAuthStatus, setsignUpOtpResponce, setStudentDataToStore } =
  AuthReducer.actions;

export default AuthReducer.reducer;
