export default function authHeader() {
  let headerContent = "";
  const user = localStorage.getItem("tokenData");
  if (user) {
    headerContent = JSON.parse(user).jwt_token;
  }
  if (user) {
    
    return { Authorization: "Bearer " + headerContent };
  } else {
    return {};
  }
}
