import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRecodedStudentClassModules } from "../../../../../../../../Redux/Actions/MyCourseActions";
import { setenrolledRecodedCourseModules } from "../../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import Loading from "../../../../../../../../LoadingComponent/Loading";
import "./VideoModules.scss";
import SingleRecodedClassVideoModule from "../../../../RecodedGeneral/RecodedClassesComponets/SingleRecodedClassVideoModule";
import CourseDetailsBar from "../CourseDetailsBar";
const VideoModules = () => {
  const { courseId, studentBatchId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const enrolledRecodedCourseModules = useSelector(
    (state) => state.mycourseData.enrolledRecodedCourseModules
  );

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getRecodedStudentClassModules(
        studentBatchId,
        courseId
      );
      dispatch(setenrolledRecodedCourseModules(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, studentBatchId, courseId]);

  // const enrolledLiveClassRcordedVideoSubject = useSelector(
  //   (state) => state.mycourseData.enrolledLiveClassRcordedVideoSubject
  // );

  // useEffect(() => {
  //   const getData = async () => {
  //     setIsLoading(true);
  //     const data = await getAllLiveClassRecodedVideoSuject(batchId);
  //     dispatch(setenrolledLiveClassRcordedVideoSubject(data));
  //     setIsLoading(false);
  //   };
  //   getData();
  // }, [dispatch, batchId]);

  if (isLoading) {
    <Loading />;
  }
  return (
    <div className="EnrolledLiveCourseRecordedVideo-main-container">
      <CourseDetailsBar />
      {/* <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classLinks`}
              className={"link"}
            >
              {" "}
              class links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div> */}
      <div className="EnrolledLiveCourseRecordedVideo-main-content">
        {/* {enrolledLiveClassRcordedVideoSubject &&
      enrolledLiveClassRcordedVideoSubject?.map((val, key) => {
        return (
          <SingleLiveClassRecodeVideoSubject subjectData={val} key={key} />
        );
      })} */}

        {enrolledRecodedCourseModules &&
          enrolledRecodedCourseModules?.map((val, key) => {
            if (val.active_Module === "D") {
              return null;
            }
            return (
              <SingleRecodedClassVideoModule key={key} modeuleData={val} />
            );
          })}
      </div>
    </div>
  );
};

export default VideoModules;
