import React, { useEffect, useRef, useState } from "react";
import "./LandIngPage.scss";
// import { Button, TextField } from "@mui/material";
import {
  // addLandingPAGEdETAILSDetails,
  getPageData,
} from "../../../Redux/Actions/EventAction";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setlandingPageData } from "../../../Redux/RedusersCompo/EventReducer";
// import Loading from "../../../LoadingComponent/Loading";

import { getRecentPlacementData } from "../../../Redux/Actions/HomePageActions";
import { setrecentPlacements } from "../../../Redux/RedusersCompo/HomePageReducer";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import SingleStudentPlacement from "../HomeComponent/Components/RecentPlacementsCompone/Components/SingleStudentPlacement";
import BikingForm from "../HomeComponent/Components/HomeHeaderComponent/BikingFormCompo/BikingForm";

const LandIngPage = () => {
  const { pageId } = useParams();

  // const [loading, setLoading] = useState(false);
  const landingPageData = useSelector(
    (state) => state.EventData.landingPageData
  );

  // const navigate = useNavigate();
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   mobileNumber: "",
  // });

  // const [formError, setFormError] = useState({
  //   name: "",
  //   email: "",
  //   mobileNumber: "",
  // });

  // const handleOnchange = (event) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  //   // Clear errors when user starts typing
  //   setFormError({ ...formError, [event.target.name]: "" });
  // };
  const [slidesPerView, setSlidesPerView] = useState(5);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getPageData(pageId);
      dispatch(setlandingPageData(data));
    };
    getData();
  }, [dispatch, pageId]);

  // const validateForm = () => {
  //   let newErrors = {};

  //   if (!formData.name) {
  //     newErrors.name = "Name is required";
  //   }

  //   if (!formData.email) {
  //     newErrors.email = "Email is required";
  //   } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
  //     newErrors.email = "Invalid email address";
  //   }

  //   if (!formData.mobileNumber) {
  //     newErrors.mobileNumber = "Mobile number is required";
  //   } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
  //     newErrors.mobileNumber = "Invalid mobile number (10 digits)";
  //   }

  //   setFormError(newErrors);
  //   return Object.keys(newErrors).length === 0;
  //};

  // const handleFormSubmition = async (event) => {
  //   event.preventDefault(); // Prevent default form submission
  //   const isValid = validateForm();
  //   if (isValid) {
  //     const data = {
  //       name: formData.name,
  //       email: formData.email,
  //       mobile_Number: Number(formData.mobileNumber),
  //     };

  //     setLoading(true);
  //     const responce = await addLandingPAGEdETAILSDetails(pageId, data);

  //     setTimeout(() => {
  //       setLoading(false);
  //       if (responce?.status === true) {
  //         navigate("/page/success");
  //       }
  //     }, 4000);
  //   }
  // };

  let imageData = {};

  if (
    landingPageData &&
    landingPageData.length > 0 &&
    landingPageData[0].page_banner &&
    landingPageData[0].page_banner.length > 0
  ) {
    console.log(landingPageData[0].page_banner);
    const imageDatas = landingPageData[0].page_banner.filter((a) => {
      return a.active_status === "A";
    });

    if (imageDatas && imageDatas.length > 0) {
      imageData = imageDatas[0];
    }
  }

  const [showForm, setshowForm] = useState(false);

  const recentPlacements = useSelector(
    (state) => state.HomePageData.recentPlacements
  );

  const dispach = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getRecentPlacementData();
      dispach(setrecentPlacements(data));
    };

    getData();
  }, [dispach]);

  useEffect(() => {
    const updateSlidesPerView = () => {
      // Adjust the logic based on your design requirements
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1200) {
        setSlidesPerView(4);
      } else if (screenWidth >= 992) {
        setSlidesPerView(3);
      } else if (screenWidth >= 768) {
        setSlidesPerView(3);
      } else if (screenWidth >= 600) {
        setSlidesPerView(2);
      } else if (screenWidth >= 500) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(1);
      }
    };

    // Update slides per view on initial load and window resize
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  let sortedRecentPlacements;

  if (recentPlacements && recentPlacements.length > 0) {
    sortedRecentPlacements = [...recentPlacements].sort((a, b) => {
      return a.ORDER - b.ORDER;
    });
  }
  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <div className="LandIngPage-main-container">
      <div className="LandIngPage-container">
        <div className="landing-page-top-main-contnet">
          {/* <h1 className="All-components-heading" style={{ padding: 0 }}>
            pay after placement
          </h1> */}
          <div className="landing-page-top-contnet">
            <div className="landind-page-left-contant">
              <h1>
                {landingPageData &&
                  landingPageData.length > 0 &&
                  landingPageData[0].page_topic}
              </h1>
              <p>
                {landingPageData &&
                  landingPageData.length > 0 &&
                  landingPageData[0].page_desc}
              </p>
              <ul>
                {landingPageData &&
                  landingPageData.length > 0 &&
                  landingPageData[0].page_keyHighlite.map((val, key) => {
                    return (
                      <li key={key}>
                        <>{key + 1}.</> {val.keyhighLite}
                      </li>
                    );
                  })}
                {/* <li>24*7 Support</li>
                <li>1:1 Mock Interview</li>
                <li>Learn From Industry Expert</li>
                <li>Learn From Industry Expert</li> */}
              </ul>
            </div>
            {/* <div className="landind-page-right-contant">
              <form onSubmit={handleFormSubmition}>
                
                <h3>Application Form </h3>
                <div className="FORM-INPUT-ELEMENT">
                  <lebel className="lebel">
                    Full Name <span>*</span>
                  </lebel>
                  <TextField
                    value={formData.name}
                    name="name"
                    label="Enter Name"
                    type="text"
                    variant="outlined"
                    onChange={handleOnchange}
                    error={!!formError.name}
                    helperText={formError.name}
                    className="TextField"
                    size="small"
                  />
                </div>
               
                <TextField
                  value={formData.name}
                  name="name"
                  label="Enter Name"
                  type="text"
                  variant="outlined"
                  onChange={handleOnchange}
                  error={!!formError.name}
                  helperText={formError.name}
                /> 
                <div className="FORM-INPUT-ELEMENT">
                  <lebel className="lebel">
                    Email ID <span>*</span>
                  </lebel>

                  <TextField
                    value={formData.email}
                    name="email"
                    label="Enter Email"
                    type="text"
                    variant="outlined"
                    onChange={handleOnchange}
                    error={!!formError.email}
                    helperText={formError.email}
                    className="TextField"
                    size="small"
                  />
                </div>
                <div className="FORM-INPUT-ELEMENT">
                  <lebel className="lebel">
                    Phone Number <span>*</span>
                  </lebel>
                  <TextField
                    value={formData.mobileNumber}
                    name="mobileNumber"
                    label="Enter Mobile Number"
                    type="number"
                    variant="outlined"
                    onChange={handleOnchange}
                    error={!!formError.mobileNumber}
                    helperText={formError.mobileNumber}
                    className="TextField"
                    size="small"
                  />
                </div>
                <Button variant="contained" type="submit" className="button">
                  Submit
                </Button>
              </form>
            </div> */}
            <div className="BikingForm-form">
              <BikingForm />
            </div>
          </div>
        </div>
      </div>

      {landingPageData &&
        landingPageData.length > 0 &&
        landingPageData[0].page_objective &&
        landingPageData[0].page_objective.length > 0 && (
          <div className="LandIngPage-main-objectiv-container">
            <h1>Certificates</h1>
            <div className="LandIngPage-objective-content">
              {landingPageData[0].page_objective.map((val, key) => {
                return (
                  <div className="LandIngPage-objective" key={key}>
                    <h2>{val.objective_heading}</h2>
                    <p>{val.objective_desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}

      <div className="RecentPlacements-main-container">
        <div className={`demo-form ${showForm ? "desplayDemoform" : ""}`}>
          {/* <DemoForm
            SETDisplay={() => setshowForm(!showForm)}
            setNavigate={() => {
              navigate("/OurAlumini");
            }}
          /> */}
        </div>
        <div className="RecentPlacements-container">
          <h1 className="All-components-heading">Our Alumni</h1>
          <div className="RecentPlacements-content">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-navigation-size": "15px",

                "--swiper-navigation-padding": "15px",
                "--swiper-navigation-right": "15px",
              }}
              slidesPerView={slidesPerView}
              spaceBetween={20}
              modules={[Navigation]}
              className="mySwiper"
              navigation={{
                nextEl: navigationNextRef.current,
                prevEl: navigationPrevRef.current,
              }}
            >
              {sortedRecentPlacements &&
                sortedRecentPlacements?.map((val, key) => {
                  return (
                    <SwiperSlide>
                      <SingleStudentPlacement PLACEMENTdATA={val} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
          <div className="navigation-sliders">
            <div ref={navigationPrevRef} className="left-slider">
              {/* Replace with your SVG ICON for Previous Slide */}
              <i class="fa-solid fa-left-long"></i>
            </div>
            <div ref={navigationNextRef} className="right-slider">
              {/* Replace with your SVG ICON for Next Slide */}
              <i class="fa-solid fa-right-long"></i>
            </div>
          </div>
          <div
            className="book-form-button-home"
            onClick={() => setshowForm(true)}
            style={{ width: "150px" }}
          >
            <span style={{ width: "100%", textAlign: "center" }}>
              {" "}
              All Students
            </span>
          </div>
        </div>
      </div>

      <div className="OurAlumniWorksAtCompo-container">
        <div className="OurAlumniWorksAtCompo-logo-contant">
          <img
            src={
              imageData && imageData.banner_URL
                ? imageData.banner_URL
                : "https://intellipaat.com/wp-content/themes/intellipaat/images/Hiring-Partners.png"
            }
            alt="dxadx"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default LandIngPage;
