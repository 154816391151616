import React, { useState } from "react";
import "./NewContactUs.css";
import { serntSignUpContactRequest } from "../../../Redux/Actions/AuthAction";
const NewContactUs = () => {
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleFormSubmition = async (event) => {
    event.preventDefault();
    const data = {
      EMAIL: email,
      MOBILE_NUMBER: mobileNumber,
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      MASSAGE: message,
    };
    console.log(data);
    const responce = await serntSignUpContactRequest(data);
    console.log(responce);
  };

  return (
    <section id="section-wrapper">
      <div className="box-wrapper">
        <div className="info-wrap">
          <h2 className="info-title">Contact Information</h2>
          <h3 className="info-sub-title">
            Fill up the form and our Team will get back to you within 24 hours
          </h3>
          <ul className="info-details">
            <li>
              <i className="fas fa-phone-alt"></i>
              <span>Phone:</span>{" "}
              <a href="https://juzzit.in/">+ 919353822274</a>
            </li>
            <li>
              <i className="fas fa-paper-plane"></i>
              <span>Email:</span>{" "}
              <a href="https://juzzit.in/">education@juzzit.in</a>
            </li>
            <li>
              <i className="fas fa-globe"></i>
              <span>Website:</span> <a href="https://juzzit.in/">juzzit.in</a>
            </li>
          </ul>
          <ul className="social-icons">
            <li>
              <a href="https://www.facebook.com/educatejuzzit?mibextid=ZbWKwL">
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/juzz_it_education?igshid=OGQ5ZDc2ODk2ZA==">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/juzz-it/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="form-wrap">
          <form onSubmit={handleFormSubmition}>
            <h2 className="form-title">Send us a message</h2>
            <div className="form-fields">
              <div className="form-divider">
                <div className="form-group">
                  <input
                    type="text"
                    className="fname"
                    value={firstName}
                    onChange={(e) => setfirstName(e.target.value)}
                    placeholder="First Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="lname"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="form-divider">
                <div className="form-group">
                  <input
                    type="email"
                    className="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Mail"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    value={mobileNumber}
                    onChange={(e) => setmobileNumber(e.target.value)}
                    className="phone"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id=""
                  placeholder="Write your message"
                ></textarea>
              </div>
            </div>
            <input
              type="submit"
              value="Send Message"
              className="submit-button"
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewContactUs;
