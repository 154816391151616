import React, { useEffect, useState } from "react";
import "./AllLiveClassVideos.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLiveClassSubjectRecodedvIDEOS } from "../../../../../Redux/Actions/MyCourseActions";
import { setenrolledLiveClassRcordedVideoSubjectVodeo } from "../../../../../Redux/RedusersCompo/MyCourseReducer";
import { NavLink, useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
import ListOfLiveClassRecodedVideo from "./ListOfLiclassVodeos";
import Loading from "../../../../../LoadingComponent/Loading";
const AllLiveClassRecodedVideo = () => {
  const { courseId, batchId, studentBatchId, subjectId } = useParams();
  const enrolledLiveClassRcordedVideoSubjectVodeo = useSelector(
    (state) => state.mycourseData.enrolledLiveClassRcordedVideoSubjectVodeo
  );
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getLiveClassSubjectRecodedvIDEOS(subjectId);
      dispatch(setenrolledLiveClassRcordedVideoSubjectVodeo(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, subjectId]);

  if (enrolledLiveClassRcordedVideoSubjectVodeo) {
    console.log(enrolledLiveClassRcordedVideoSubjectVodeo[0]?.videoURL);
  }

  if (isLoading) {
    <Loading />;
  }
  return (
    <div className="AllLiveClassRecodedVideo-main-container">
      <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classLinks`}
              className={"link"}
            >
              {" "}
              class links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/recodedVideos`}
              className={"link"}
            >
              recoded videos
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="AllLiveClassRecodedVideo-video-container">
        <ListOfLiveClassRecodedVideo
          videoContent={enrolledLiveClassRcordedVideoSubjectVodeo}
        />
      </div>
    </div>
  );
};

export default AllLiveClassRecodedVideo;
