import React, { useEffect } from "react";
//import PageHeader from "../PageHeader";
//import MapComponents from "./Components/MapComponentsComponents/MapComponents";
//import FormCompo from "./Components/FormComponentsComponents/FormCompo";
import "./Contact.scss";
//import ContactImage from "./Components/ContactImageCompo/ContactImage";
import AOS from "aos";
import "aos/dist/aos.css";
import NewContactUs from "./NewContactUs";
// import MapApi from "./Components/MapComponents/Map";

//import contactUsImage from "../../../img/contactUsImage.png";

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  // const contants = () => {
  //   return (
  //     <>
  //       <h1></h1>
  //     </>
  //   );
  // };
  return (
    <div className="Contact-main-container">
      {/* <PageHeader heading={"Contact"} url={contactUsImage} /> */}
      <div className="Contact-main-content">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.3563241933766!2d77.6638937!3d13.012966299999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae154b340127e9%3A0xb7d556926ff691bd!2sJUZZ-IT%20Education!5e0!3m2!1sen!2sin!4v1702391006609!5m2!1sen!2sin"
          width="98%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="googlemap"
        ></iframe>
        <div className="Contact-main">
          <div className="Contact-contact-block">
            <NewContactUs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
