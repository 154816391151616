import React from "react";
import "./AboutProgram.scss";
import HeaderApplyForm from "../HeaderApplyForm";
const AboutProgram = ({ KeyHilight }) => {
  let sortedKeyHilight = [];

  console.log(KeyHilight);
  if (KeyHilight && KeyHilight.length > 0) {
    sortedKeyHilight = [...KeyHilight].sort((a, b) => a.order - b.order);
  }
  return (
    <div className="AboutProgram-main-container">
      <div className="AboutProgram-container">
        <div className="AboutProgram-left-container">
          <h1>About Program</h1>
          {/* <p>
            Crafted by industry experts, this data science and machine learning
            program aims to maximize your opportunities for securing your ideal
            job. Engage in live classes and e-learning videos to master the
            intricacies of data science.
          </p> */}
          <div className="keyhilight-main-container">
            <h2>Key Highlights</h2>
            <div className="keyhilight-container">
              {sortedKeyHilight &&
                sortedKeyHilight?.map((val, key) => {
                  return (
                    <h5 key={key}>
                      <i class="fa-regular fa-circle-check"></i>
                      {" " + val.highlight}
                    </h5>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="AboutProgram-right-container">
          <HeaderApplyForm />
        </div>
      </div>
    </div>
  );
};

export default AboutProgram;
