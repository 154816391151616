import React, { useEffect, useState } from "react";
import "./EnrolledLiveCourses.scss";
import SingleCourseCompo from "./SingleCourseCompo";
import { getAllEnrolledCourseData } from "../../../../../../Redux/Actions/MyCourseActions";
import { useDispatch, useSelector } from "react-redux";
import { setEnrolledCourses } from "../../../../../../Redux/RedusersCompo/MyCourseReducer";
import Loading from "../../../../../../LoadingComponent/Loading";
import { setStudentDataToStore } from "../../../../../../Redux/RedusersCompo/AuthReducer";
import { getStudentDataByToken } from "../../../../../../Redux/Actions/AuthAction";
const EnrolledLiveCourses = () => {
  const enrolledCourses = useSelector(
    (state) => state.mycourseData.enrolledCourses
  );
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // const userData = localStorage.getItem("userData");
  // let user = {};
  // if (userData) {
  //   user = JSON.parse(userData);
  // }

  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getAllEnrolledCourseData(
        userDetails &&  userDetails?.id
      );
      dispatch(setEnrolledCourses(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, userDetails]);

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="EnrolledLiveCourses-main-container">
      <h1>My Enrolled Courses</h1>
      <div className="EnrolledLiveCourses-content">
        {enrolledCourses &&
          enrolledCourses?.map((val, key) => {
            const courseName =
              val.course_type_name +
              " " +
              "Certification in" +
              " " +
              val.course_name;

            return (
              <SingleCourseCompo
                courseId={val.course_type_id}
                img={val.courseType_Images}
                batchId={val.batch_course_id}
                studentBatchId={val.batch_course_student_id}
                key={key}
                heading={courseName}
                percentage={val.completed_percentage}
              />
            );
          })}
      </div>
    </div>
  );
};

export default EnrolledLiveCourses;
