import React, { useEffect, useState } from "react";
import "./EnrolledRecordedCoursesDetails.scss";
import { NavLink, useParams } from "react-router-dom";
import { getCourseTypeModuleData } from "../../../../../../../Redux/Actions/CourcesAction";
import { useDispatch, useSelector } from "react-redux";
import { setenrolledRecodedCoursesCourseTypaDetails } from "../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import EnrolledCourseStructure from "../../EnrolledLiveCoursesComponents/Components/EnrolledCourseStructureComponent/EnrolledCourseStructure";
import Loading from "../../../../../../../LoadingComponent/Loading";
const EnrolledRecordedCoursesDetails = () => {
  const { courseId, studentBatchId } = useParams();

  const courseTypeDetails = useSelector(
    (state) => state.mycourseData.enrolledRecodedCoursesCourseTypaDetails
  );

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getCourseTypeModuleData(courseId);
      dispatch(setenrolledRecodedCoursesCourseTypaDetails(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, courseId]);

  let moduls = [];

  if (courseTypeDetails !== null) {
    moduls = courseTypeDetails[0]?.course_Type_modules;
  }

  if (isLoading) {
    <Loading />;
  }
  return (
    <div className="EnrolledRecordedCoursesDetails-main-container">
      <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/sessionLinks`}
              className={"link"}
            >
              {" "}
              session links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="student-batch-content">
        <div className="studemnt-profile-container">
          <div className="studemnt-profile-content">
            <h2>Student Batch Id</h2>
            <span>{studentBatchId}</span>
          </div>
          {/* <div className="studemnt-profile-content">
            <h2> Batch Id</h2>
            <span>{batchId}</span>
          </div>
          <div className="studemnt-profile-content">
            <h2> Batch Name</h2>
            <span>{"dscnasdoi"}</span>
          </div> */}
        </div>
        <EnrolledCourseStructure moduls={moduls} />
      </div>
    </div>
  );
};

export default EnrolledRecordedCoursesDetails;
