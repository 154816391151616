import React from "react";
//import img from "../../../../../img/Home/Purple and White Minimalist Comparison Chart Graph (1).png";
import "./RealWorkExperience.scss";

const RealWorkExperience = () => {
  return (
    <div className="RealWorkExperience-main-container">
      <div className="RealWorkExperience-container">
        <div className="All-components-heading">
          Experience Genuine Work, Immerse in Project-Based Learning with
          JUZZ-IT{" "}
        </div>
        <div className="all-component-heading-discription">
          Experience the most effective skill acquisition through hands-on
          application in real-world scenarios. At JUZZ-IT, we elevate the
          learning process by providing genuine work experience-based learning.
          Rather than merely watching tutorials, you actively engage in building
          real products, mirroring the experiences of professional engineers.
        </div>
        <div className="all-component-heading-discription">
          Essentially, we guide you on a path mirroring the career growth
          trajectory of a professional in a leading product company.
        </div>

        {/* <img src={img} alt="dcjks" /> */}
      </div>
    </div>
  );
};

export default RealWorkExperience;
