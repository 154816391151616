import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import "./SignUp.scss";
import { useNavigate } from "react-router-dom";
import {
  AccountExistByEmail,
  addNewStudent,
  serntSignUpOtpRequest,
} from "../../Redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthStatus,
  setsignUpOtpResponce,
} from "../../Redux/RedusersCompo/AuthReducer";
import Loading from "../../LoadingComponent/Loading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../../img/logo/juzz-it_logo.jpg";
const SignUp = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const [signUpNotification, setsignUpNotification] = useState(false);
  const [otpVarification, setotpVarification] = useState(false);
  const [isLoading, setIsLoadding] = useState(false);
  const [otpError, setOtpError] = useState("");

  const navigate = useNavigate();

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const isMobileNumberValid = (mobileNumber) => {
    const mobileNumberPattern = /^[0-9]{10}$/;
    return mobileNumberPattern.test(mobileNumber);
  };

  const isPasswordValid = (password) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordPattern.test(password);
  };

  const signUpOtpResponce = useSelector(
    (state) => state.authdata.signUpOtpResponce
  );

  console.log(signUpOtpResponce.requestId);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    // Basic form validation
    if (
      name === "" ||
      email === "" ||
      mobileNumber === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      setError("All fields are required.");
      return;
    }

    if (!isEmailValid(email)) {
      setError("Invalid email address.");
      return;
    }

    if (email.includes("*")) {
      setError("Email must not include '*'.");
      return;
    }
    if (email.includes("/")) {
      setError("Email must not include '/'.");
      return;
    }
    if (!isMobileNumberValid(mobileNumber)) {
      setError("Invalid mobile number. It should be a 10-digit number.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Password and Confirm Password do not match.");
      return;
    }
    if (!isPasswordValid(password)) {
      setError(
        "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one digit"
      );
      return;
    }
    setsignUpNotification(true);
  };
  const HandleSendOTPrEQUEST = async () => {
    setIsLoadding(true);
    setsignUpNotification(false);
    setOtpError("");
    const responce1 = await AccountExistByEmail(email);
    if (responce1?.status === false) {
      const requestData = {
        EMAIL: email,
      };
      const responce = await serntSignUpOtpRequest(requestData);
      if (responce?.status === true) {
        setIsLoadding(false);
        dispatch(setsignUpOtpResponce({}));
        dispatch(setsignUpOtpResponce(responce));
        setotpVarification(true);
      } else {
        setIsLoadding(false);
      }
    } else {
      setIsLoadding(false);
      setError(responce1?.massege);
    }
  };

  const handlleOTPvarification = async () => {
    setIsLoadding(true);
    setOtpError("");
    if (!otp) {
      setOtpError("OTP is required for verification.");
      return;
    }

    const userData = {
      student_Name: name,
      student_Email: email,
      student_MobileNum: Number(mobileNumber),
      student_Password: password,
      otp: otp,
    };
    const response = await addNewStudent(
      signUpOtpResponce?.requestId,
      userData
    );
    if (response?.successStatus === true) {
      setError("Signup successful");
      localStorage.setItem("userData", JSON.stringify(response));
      dispatch(setAuthStatus(true));
      navigate("/");
      window.location.reload();
    } else {
      setIsLoadding(false);
      setOtpError("Enter correct OTP");
    }

    setPassword("");
    setConfirmPassword("");
    setError(null); // Clear any previous error
  };

  if (isLoading) {
    return <Loading />;
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleConformePasswordVisibility = () => {
    setShowConformPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={`signUp-container ${props.home ? "homeIsmainTrue" : ""}`}>
      <div
        className={`SignUp-conformation-pop-up-container  ${
          signUpNotification ? "showNotification" : ""
        }`}
      >
        <div className="SignUp-conformation-pop-up">
          <h1>
            For the varification we send the mail to email <span>{email}</span>{" "}
          </h1>
          <div className="SignUp-conformation-pop-up-button">
            <Button
              variant="outlined"
              onClick={() => setsignUpNotification(false)}
            >
              cancel
            </Button>
            <Button variant="outlined" onClick={HandleSendOTPrEQUEST}>
              send
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`signUp-otp-main-container ${
          otpVarification ? "showOtpForm" : ""
        }`}
      >
        <div className="signUp-otp-container">
          <h1>Enter OTP</h1>

          {otpError && (
            <Typography className="error-message" color={"red"}>
              {otpError}
            </Typography>
          )}
          <input
            type="number"
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
          ></input>
          <Button onClick={handlleOTPvarification} variant="outlined">
            varify
          </Button>
          <Button onClick={HandleSendOTPrEQUEST} variant="outlined">
            Resend OTP
          </Button>
        </div>
      </div>

      <div className={`signUp-content ${props.home ? "homeIsTrue" : ""}`}>
        <img src={logo} alt="logo"></img>
        <h2 variant="h2">Welcome To Juzz - IT</h2>
        <h5 variant="h5">Good to see you again . . . !</h5>
        <h4 variant="h4">Already have an account?</h4>
        <Button
          className="button"
          onClick={props.setIsLogn}
          variant="contained"
        >
          Sign In
        </Button>
      </div>
      <section className={`signUp-form-container`}>
        <div className="signup-Form">
          <h1 variant="h1">Sign Up</h1>
          <div className="form">
            {error && (
              <Typography className="error-message" color={"red"}>
                {error}
              </Typography>
            )}

            <TextField
              type="text"
              label="User Name"
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input"
            />

            <TextField
              type="text"
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input"
            />

            <TextField
              type="number"
              label="Mobile Number"
              variant="standard"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              className="input"
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              type={showConformPassword ? "text" : "password"}
              variant="standard"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConformePasswordVisibility}
                      edge="end"
                    >
                      {showConformPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              className="signupbutton"
              onClick={handleSubmit}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
