// src/components/BikingForm.js
import React, { useState } from "react";
import "./BikingForm.scss";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import "./BikingForm.scss";
import { serntSignUpDemoRequest } from "../../../../../../Redux/Actions/AuthAction";
import Loading from "../../../../../../LoadingComponent/Loading";

const BikingForm = ({ corse_request, setDisplayForm }) => {
  const [demoSubmitionStatus, setDemoSubmitionStatus] = useState(false);

  const [isloading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    topic: "", // Modified to hold the selected topic
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    topic: "", // Modified to hold the checkbox validation error
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (topic) => {
    setFormData({
      ...formData,
      topic: topic,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        EMAIL: formData.email,
        MOBILE_NUMBER: formData.mobile,
        NAME: formData.name,
        REQUEST_COURSE: corse_request ? corse_request : formData.topic,
      };
      setLoading(true);
      const response = await serntSignUpDemoRequest(data);
      setLoading(false);
      if (response?.status === true) {
        setDemoSubmitionStatus(true);
        setFormData({
          name: "",
          email: "",
          mobile: "",
          topic: "", // Modified to reset the selected topic
        });
      }
    } else {
      console.log("Form validation failed");
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: formData.name ? "" : "Name is required",
      email: /\S+@\S+\.\S+/.test(formData.email) ? "" : "Invalid email address",
      mobile: /^\d{10}$/.test(formData.mobile)
        ? ""
        : "Mobile must be 10 digits",
      topic: formData.topic ? "" : "Select one topic", // Modified to validate the topic
    };

    setErrors(newErrors);

    // Check if there are no errors
    return Object.values(newErrors).every((error) => error === "");
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="biking-form">
      <div
        className={`domo-submition-main-successfully ${
          demoSubmitionStatus ? "demosubmitionSuccessfully" : ""
        }`}
      >
        <div className="domo-submition-successfully">
          <h5>We Received Your Request. We Will Be Reaching You Soon</h5>
          <Button
            variant="contained"
            onClick={() => {
              setDemoSubmitionStatus(false);

              if (setDisplayForm && corse_request) {
                setDisplayForm();
              }
            }}
          >
            OK
          </Button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <h2>
          Book a <span>Free</span> Demo, now!
        </h2>
        <div className="ckeck-box-main-contant">
          <p>Your Topic of Interest *</p>
          <div className="check-box-content">
            <div className="Checkbox-content">
              <label>Software Development</label>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.topic === "Software Development"}
                    onChange={() =>
                      handleCheckboxChange("Software Development")
                    }
                    color="primary"
                    size="small"
                  />
                }
              />
            </div>

            <div className="Checkbox-content">
              <label>Data Science</label>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.topic === "Data Science"}
                    onChange={() => handleCheckboxChange("Data Science")}
                    color="primary"
                    size="small"
                  />
                }
              />
            </div>
            <div className="Checkbox-content">
              <label>Others</label>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.topic === "Others"}
                    onChange={() => handleCheckboxChange("Others")}
                    color="primary"
                    size="small"
                  />
                }
              />
            </div>
          </div>
          {errors.topic && (
            <p
              style={{ color: "red", fontSize: "13px" }}
              className="error-message"
            >
              {errors.topic}
            </p>
          )}
        </div>

        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
          variant="standard"
        />

        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          variant="standard"
        />

        <TextField
          label="Mobile"
          name="mobile"
          type="number"
          value={formData.mobile}
          onChange={handleChange}
          error={!!errors.mobile}
          helperText={errors.mobile}
          variant="standard"
        />

        <div className="button-main-contant">
          <Button type="submit" variant="contained" className="button">
            Submit
          </Button>
          <span>
            <i className="fa-solid fa-people-group"></i>Limited seats left
          </span>
        </div>
      </form>
    </div>
  );
};

export default BikingForm;
