import React, { useState } from "react";
import "./HeaderApplyForm.scss";
import img from "../../../../../img/Formimage/Screenshot 2023-12-17 190309.png";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { serntSignUpDemoRequest } from "../../../../../Redux/Actions/AuthAction";
import Loading from "../../../../../LoadingComponent/Loading";

const HeaderApplyForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successSubmition, setsuccessSubmition] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(mobileNumber)) {
      newErrors.mobileNumber = "Invalid mobile number (10 digits)";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      EMAIL: email,
      MOBILE_NUMBER: mobileNumber,
    };

    if (validateForm()) {
      try {
        setLoading(true);
        // Add your submission logic here

        const response = await serntSignUpDemoRequest(data);
        if (response?.status === true) {
          setMobileNumber("");
          setEmail("");
          setsuccessSubmition(true);
        }

        setEmail("");
        setMobileNumber("");
      } catch (error) {
        console.error("Error submitting the form:", error);
        // Handle error appropriately (e.g., display a message to the user)
      } finally {
        setLoading(false);
      }
    } else {
      // Handle form validation errors appropriately (e.g., display a message to the user)
    }
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <div className="HeaderApplyForm-main-container">
      <div className="HeaderApplyForm-img">
        <img src={img} alt="dcsd" className="request-form"></img>
      </div>

      <div
        className={`sucessfully-submited-Request-main ${
          successSubmition ? "successSubmition" : ""
        }`}
      >
        <div className="sucessfully-submited-Request">
          <h1>We received your request. We will reach you soon.</h1>
          <Button
            variant="contained"
            onClick={() => setsuccessSubmition(false)}
          >
            Ok
          </Button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <TextField
          variant="filled"
          className="TextField"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          variant="filled"
          className="TextField"
          label="Mobile Number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          error={!!errors.mobileNumber}
          helperText={errors.mobileNumber}
        />
        <Button type="submit" className="button" variant="contained">
          Start Application
        </Button>
        <p>
          <i className="fa-solid fa-square-check"></i> By providing your contact
          details, you agree to our
          <span onClick={() => navigate("/TermsConditions")}>
            Terms of Use
          </span>{" "}
          &nbsp;&
          <span onClick={() => navigate("/PrivacyPolicy")}>
            {" "}
            Privacy Policy
          </span>
        </p>
      </form>
    </div>
  );
};
export default HeaderApplyForm;
