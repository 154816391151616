import React, { useState } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import { useNavigate } from "react-router-dom";
import "./SettingSideBar.scss";
const SettingSideBar = () => {
  const [showSettingSideBar, setshowSettingSideBar] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="SettingSideBar-main-container">
      <AppsIcon
        onClick={() => {
          setshowSettingSideBar(true);
        }}
      />
      <div
        className={`SettingSideBar-links-contant ${
          showSettingSideBar ? "showSettingSideBar" : ""
        }`}
      >
        <div className="cross-icon">
          <i
            onClick={() => {
              setshowSettingSideBar(false);
            }}
            class="fa-solid fa-x"
          ></i>
        </div>
        <ul>
          <li
            className="active"
            onClick={() => navigate("/DashBoard/Myprofile/setting")}
          >
            Profile
          </li>
          <li onClick={() => navigate("/DashBoard/Myprofile/resetPassWord")}>
            PassWord
          </li>
          <li onClick={() => navigate("/DashBoard/Myprofile/socialLinks")}>
            social links
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettingSideBar;
