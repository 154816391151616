import React from "react";
import "./AcademicTrainingHeader.scss";

//import img from "../../../../../img/acadamy/WhatsApp Image 2024-02-13 at 3.59.33 PM.jpeg";
const AcademicTrainingHeader = () => {
  return (
    <div className="AcademicTrainingHeader-main-container">
      <div className="AcademicTrainingHeader-top-main-container">
        <div className="AcademicTrainingHeader-top-container">
          <div className="top-left-contnet">
            <span className="span">JUZZ-IT Academic Training</span>
            <h1>
              Prepare your students
              <br />
              with skills that
              <br /> are relevant to the industry.
            </h1>
            <h5>
              JUZZ-IT, the pioneering e-learning platform, is at the forefront
              of revolutionizing engineering education.
            </h5>
            {/* <p>In adherence to the 2024 UGC Guidelines.</p> */}
            <div
              className="book-form-button-home"
              style={{ justifyContent: "flex-start", width: "200px" }}
            >
              <span variant="contained" style={{ width: "100%" }}>
                Join Now
              </span>
            </div>
          </div>
          <div className="top-right-contant">
            <img
              src={
                "https://www.asme.org/getmedia/9441ade8-2f08-4890-80a5-0f511e7e09c1/a-better-approach-to-teaching-stem_hero.jpg.aspx?width=460&height=360&ext=.jpg"
              }
              alt="sca"
            ></img>
          </div>
        </div>
      </div>
      <div className="AcademicTrainingHeader-bottom-main-container">
        <div className="AcademicTrainingHeader-bottom-container">
          <ul>
            <li>
              <h4>50+</h4>
              <p>
                {" "}
                <span>courses</span>
              </p>
            </li>
            <li>
              <h4>250+</h4>
              <p>
                {" "}
                <span>industry experts</span>
              </p>
            </li>
            <li>
              <h4>1000+</h4>
              <p>
                {" "}
                <span>core engineering placements </span>
                <br /> in last one year
              </p>
            </li>
            <li>
              <h4>6000+</h4>
              <p>
                {" "}
                <span>hours of live class </span>
                <br /> content
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AcademicTrainingHeader;
