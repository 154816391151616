import React, { useState } from "react";
import "./ProgramFeeStucture.scss";
import { Button } from "@mui/material";
import img from "../../../../../../img/logo/finzy-logo-1x1_dyqago.png";
import img1 from "../../../../../../img/logo/images.jpeg";

const ProgramFeeStucture = ({
  payment,
  noconstEmi,
  courseTypeName,
  condition,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="ProgramFeeStucture-main-container">
      <div
        className={`ProgramFeeStucture-more-details ${
          showDetails ? "showDetails" : ""
        }`}
      >
        <div className="ProgramFeeStucture-more-details-content">
          <i
            class="fa-solid fa-xmark"
            onClick={() => {
              setShowDetails(false);
            }}
          ></i>

          <h2>Pay After Placement</h2>

          <ul>
            <li>
              <h4>Paid at the time of enrollment</h4>
              <p>₹ 35,000</p>
            </li>
            <li>
              <h4>Monthly payments during course</h4>
              <p>₹ 0</p>
            </li>
            <li>
              <h4>Monthly payments after starting new job</h4>
              <p>
                8 % of monthly payout until the remaining{" "}
                {payment && Number(payment) - 35000} is paid out
              </p>
            </li>
          </ul>
          <div className="ProgramFeeStucture-total">
            <h4>Total cost</h4>
            <p>₹{payment}</p>
          </div>
        </div>
      </div>

      <div className="ProgramFeeStucture-container">
        <h1>Program Fee</h1>
        <div className="ProgramFeeStucture-contant">
          {courseTypeName === "Professional" ? (
            <>
              <div className="ProgramFeeStucture-top-contant">
                <div className="ProgramFeeStucture-top-left-contant">
                  <div>
                    <p>₹ 35,000</p>
                    <h4>Registration Fee </h4>
                  </div>
                  +
                  <div>
                    <p>₹ {payment && Number(payment) - 35000}</p>
                    <h4>Pay After Landing a Tech Job </h4>
                  </div>
                </div>
                <div className="Button-content">
                  <Button
                    onClick={() => {
                      window.open(
                        // "https://pay.jodo.in/pages/XGLDaL7NfXRz2Fdn",
                        "https://razorpay.me/@juzz-it",
                        "_blank"
                      );
                    }}
                    variant="contained"
                    className="Button"
                  >
                    Apply Now
                  </Button>
                  <p
                    onClick={() => {
                      setShowDetails(true);
                    }}
                  >
                    more details{" "}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="ProgramFeeStucture-top-contant">
                <div className="ProgramFeeStucture-top-left-contant">
                  {/* <div>
                    <p>₹ 5,000</p>
                    <h4>Registration Fee </h4>
                  </div> */}

                  <div>
                    <p>₹ {payment}</p>
                    {/* <h4>Pay in EMI </h4> */}
                  </div>
                </div>
                <div className="Button-content">
                  <Button
                    onClick={() => {
                      window.open(
                        "https://pay.jodo.in/pages/XGLDaL7NfXRz2Fdn",
                        "_blank"
                      );
                    }}
                    variant="contained"
                    className="Button"
                  >
                    Apply Now
                  </Button>
                  {/* <p
                onClick={() => {
                  setShowDetails(true);
                }}
              >
                more details{" "}
              </p> */}
                </div>
              </div>
            </>
          )}

          {condition === "A" ? (
            <div className="ProgramFeeStucture-bottom-contant">
              <div className="ProgramFeeStucture-top-left-contant">
                <h3>No Cost EMI Starts at</h3>
                <p>₹ {noconstEmi} </p>
              </div>
              <div className="ProgramFeeStucture-top-right-contant">
                <p>
                  We partnered with financing companies to provide competitive
                  finance option at 0% interest rate with no hidden costs
                </p>
                <h5>Financing Partners</h5>
                <ul>
                  {/* List of financing partner logos */}
                  <li>
                    <img src={img} alt="sa" />
                  </li>
                  <li>
                    <img src={img1} alt="sa" />
                  </li>
                  {/* <li>
                    <img src={img1} alt="sa" />
                  </li> */}
                  <li>
                    <img
                      src={"https://www.jodo.in/assets/images/jodo-logo.svg"}
                      alt="sa"
                    />
                  </li>
                  <li>
                    <img
                      src={
                        "https://images.yourstory.com/cs/images/companies/873626781307775384724487681215762383503360n-1641176361560.jpg?fm=auto&ar=1:1&mode=fill&fill=solid&fill-color=fff"
                      }
                      alt="sa"
                    />
                  </li>
                </ul>
                <h6>
                  The credit facility is provided by a third party credit
                  facility provider and any arrangement with such third party is
                  outside JUZZ-IT purview.
                </h6>
              </div>
            </div>
          ) : (
            // Render the following JSX block if condition is not "D"
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramFeeStucture;
