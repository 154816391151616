import React, { useState } from "react";
import "./HandleForgotPassWord.scss";
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import Loading from "../LoadingComponent/Loading";
import {
  setAuthStatus,
  setsignUpOtpResponce,
} from "../Redux/RedusersCompo/AuthReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountExistByEmail,
  serntSignUpOtpRequest,
  validateUserDataWithOtp,
} from "../Redux/Actions/AuthAction";
import { updatePassWordDataOTP } from "../Redux/Actions/MyCourseActions";
import { useNavigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const HandleForgotPassWord = () => {
  const [email, setEmail] = useState("");
  const [signUpNotification, setsignUpNotification] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoadding] = useState(false);
  const [otpVarification, setotpVarification] = useState(false);
  const [otp, setOtp] = useState("");
  const [OTPerror, setOtpError] = useState(null);
  const [requestSubmition, setrequestSubmition] = useState(false);
  const [password, setpassword] = useState("");
  const [conformPassWod, setconformPassWod] = useState("");
  const [passwordUpdateStatus, setpasswordUpdateStatus] = useState(false);

  const [conformpassWordMatchError, setconformpassWordMatchError] =
    useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const userData = localStorage.getItem("userData");
  let user = {};
  if (userData) {
    user = JSON.parse(userData);
  }

  // com

  //   const navigate = useNavigate();
  const signUpOtpResponce = useSelector(
    (state) => state.authdata.signUpOtpResponce
  );

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  console.log(signUpOtpResponce.requestId);
  const dispatch = useDispatch();

  const onSubmitHandler = async () => {
    if (!isEmailValid(email)) {
      setError("Invalid email address.");
      return;
    }

    const responce1 = await AccountExistByEmail(email);

    if (responce1?.status === true) {
      setIsLoadding(false);
      setsignUpNotification(true);
    }

    setIsLoadding(false);
    setError("Account with the email not found");
  };

  const HandleSendOTPrEQUEST = async () => {
    setIsLoadding(true);
    setsignUpNotification(false);

    const requestData = {
      EMAIL: email,
    };

    const responce = await serntSignUpOtpRequest(requestData);

    if (responce?.status === true) {
      setIsLoadding(false);
      dispatch(setsignUpOtpResponce({}));
      dispatch(setsignUpOtpResponce(responce));
      setotpVarification(true);
    } else {
      setIsLoadding(false);
    }
  };

  const handlleOTPvarification = async () => {
    setIsLoadding(true);

    const logInData = {
      email: signUpOtpResponce?.requestEmail,
      otp: otp,
    };

    const response = await validateUserDataWithOtp(
      signUpOtpResponce?.requestId,
      logInData
    );
    console.log(response);

    if (response?.successStatus === true) {
      const tokenData = {
        jwt_token: response.jwtTokan,
      };
      localStorage.setItem("tokenData", JSON.stringify(tokenData));

      setIsLoadding(false);
      dispatch(setAuthStatus(true));
      setrequestSubmition(true);
      setotpVarification(false);
    } else {
      setOtpError(response?.massage);
    }
    setIsLoadding(false);
    setEmail("");
    setOtp("");
  };

  const isPasswordValid = (password) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordPattern.test(password);
  };

  const handleUpdatePassWord = async () => {
    if (password === "") {
      setconformpassWordMatchError("Password must required");
      return;
    }

    if (!isPasswordValid(password)) {
      setconformpassWordMatchError(
        "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one digit"
      );
      return;
    }

    if (password !== conformPassWod) {
      setconformpassWordMatchError(
        "Password and Confirm Password do not match."
      );
      return;
    }

    const socialLinks = {
      password: password,
    };

    setIsLoadding(true);
    const response = await updatePassWordDataOTP(user?.userId, socialLinks);
    setIsLoadding(false);

    if (response?.successStatus === true) {
      // localStorage.setItem("userData", JSON.stringify(response));
      const tokenData = {
        jwt_token: response.jwtTokan,
      };
      localStorage.setItem("tokenData", JSON.stringify(tokenData));
      setrequestSubmition(false);
      setpasswordUpdateStatus(true);
    }
  };
  const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleConformePasswordVisibility = () => {
    setShowConformPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="HandleForgotPassWord-main-container">
      <div
        className={`HandleForgotPassWord-login-successfull ${
          passwordUpdateStatus ? "login-succssful" : ""
        }`}
      >
        <div className="HandleForgotPassWord-login-successfull-container">
          <div className="HandleForgotPassWord-login-successfull-contant">
            <h1>password updated successfully</h1>
            <Button
              variant="contained"
              onClick={() => {
                setpasswordUpdateStatus(false);
                navigate("/");
              }}
              className="generalButtom"
            >
              Ok
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`HandleForgotPassWord-login-successfull  ${
          requestSubmition ? "update" : ""
        } `}
      >
        <div className="HandleForgotPassWord-login-successfull-container">
          <div className="HandleForgotPassWord-login-successfull-contant">
            {conformpassWordMatchError ? (
              <Typography className="Typography" color={"red"}>
                {conformpassWordMatchError}
              </Typography>
            ) : (
              ""
            )}

            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="standard"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              className="input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              type={showConformPassword ? "text" : "password"}
              variant="standard"
              value={conformPassWod}
              onChange={(e) => setconformPassWod(e.target.value)}
              className="input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConformePasswordVisibility}
                      edge="end"
                    >
                      {showConformPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              onClick={handleUpdatePassWord}
              variant="contained"
              className="generalButtom"
            >
              update
            </Button>

            {/* <h1>login Successfully</h1>
          <Button
            variant="contained"
            onClick={() => {
              setrequestSubmition(false);
              navigate(`/`);
              window.location.reload();
            }}
          >
            continue
          </Button> */}
          </div>
        </div>
      </div>

      <div
        className={`HandleForgotPassWord-conformation-pop-up-container  ${
          signUpNotification ? "showNotification" : ""
        }`}
      >
        <div className="HandleForgotPassWord-conformation-pop-up">
          <h1>
            For the varification we send the mail to email <span>{email}</span>{" "}
          </h1>
          <div className="HandleForgotPassWord-conformation-pop-up-button">
            <Button
              variant="contained"
              onClick={() => setsignUpNotification(false)}
              className="generalButtom"
            >
              cancel
            </Button>
            <Button
              variant="contained"
              onClick={HandleSendOTPrEQUEST}
              className="generalButtom"
            >
              send
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`HandleForgotPassWord-otp-main-container ${
          otpVarification ? "showOtpForm" : ""
        }`}
      >
        <div className="HandleForgotPassWord-otp-c-container">
          <div className="HandleForgotPassWord-otp-container">
            <span>{OTPerror}</span>
            <h1>Enter OTP</h1>
            <TextField
              type="number"
              variant="standard"
              label="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
            ></TextField>
            <Button
              onClick={handlleOTPvarification}
              variant="contained"
              className="generalButtom"
            >
              varify
            </Button>
            <div className="button-contant-divider">
              <Button
                onClick={HandleSendOTPrEQUEST}
                variant="contained"
                className="generalButtom"
              >
                Resend OTP
              </Button>
              <Button
                onClick={() => setotpVarification(false)}
                variant="contained"
                className="generalButtom"
              >
                cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="HandleForgotPassWord-container">
        <div className="HandleForgotPassWord-content">
          <span>{error}</span>
          <h1>Enter Email Address </h1>
          <TextField
            label="Email"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
          <Button
            variant="contained"
            onClick={onSubmitHandler}
            className="generalButtom"
          >
            next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HandleForgotPassWord;
