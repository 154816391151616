import React, { useState } from "react";
import SignIn from "./SignInCompone/SignIn";
import SignUp from "./SignUpComponent/SignUp";
import "./LogIn.scss";

const LogIn = () => {
  const [isLogIn, setIsLogn] = useState(true);

  return (
    <div className="login-main-container">
      {isLogIn ? (
        <SignIn
          setIsLogn={() => {
            setIsLogn(!isLogIn);
          }}
        />
      ) : (
        <SignUp
          setIsLogn={() => {
            setIsLogn(!isLogIn);
          }}
        />
      )}
    </div>
  );
};

export default LogIn;
