import React, { useState } from "react";
import "./RecentPlacementProjectList.scss";
import RPSinglePlacement from "./RPSinglePlacement";
import { Button } from "@mui/material";
const RecentPlacementProjectList = ({ projects }) => {
  const [countValue, setCountValue] = useState(3);

  let sortedProject = [];

  if (projects && projects?.length > 0) {
    sortedProject = [...projects].sort((a, b) => a.order - b.order);
  }

  return (
    <div className="RecentPlacementProjectList-main-Container">
      <h1>{projects && projects?.length} projects</h1>
      <div className={`RecentPlacementProjectList-container`}>
        {sortedProject &&
          sortedProject?.slice(0, countValue).map((val, key) => {
            return <RPSinglePlacement project={val} />;
          })}
      </div>
      <div className="button-contant">
        {countValue > 3 && (
          <Button
            onClick={() => setCountValue((p) => p - 3)}
            variant="contained"
            className="button"
          >
            Read Less
          </Button>
        )}
        {countValue < sortedProject.length && (
          <Button
            onClick={() => setCountValue((p) => p + 3)}
            variant="contained"
            className="button"
          >
            Read More
          </Button>
        )}
      </div>
    </div>
  );
};

export default RecentPlacementProjectList;
