import React, { useEffect } from "react";
import "./EnrolledCourseStructure.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "aos";
import "aos/dist/aos.css";
// import { useParams } from "react-router-dom";
// import Loading from "../../../../../../../../LoadingComponent/Loading";
const EnrolledCourseStructure = (props) => {
  // const { courseId, batchId, studentBatchId } = useParams();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="CourseStructure-main-container">
      <div className="CourseStructure-container">
        <div
          className="CourseStructure-content"
          // data-aos="fade-up"
          // data-aos-anchor-placement="center-center"
          // data-aos-duration="800"
        >
          {props.moduls &&
            props.moduls?.map((val, key) => {
              return (
                <Accordion key={key} className="Accordion">
                  <AccordionSummary
                    id={`panel${key}-header`}
                    aria-controls={`panel${key}-content`}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography
                      variant="h6"
                      className="Typography-head"
                      style={{ fontFamily: "AR One Sans" }}
                    >
                      {key + 1}
                      {".   "}
                      {val.module_Title}
                    </Typography>
                  </AccordionSummary>

                  {val.lessons &&
                    val.lessons?.map((val1, key1) => {
                      return (
                        <AccordionDetails key={key1}>
                          <Typography variant="h6" className="Typography-desc">
                            {"    "}
                            --{"  "}
                            {val1.lesson_Tites}
                          </Typography>
                        </AccordionDetails>
                      );
                    })}
                </Accordion>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default EnrolledCourseStructure;
