import React, { useEffect, useState } from "react";
import "./JuzzItInNews.scss";
import { logos } from "../../logoScrollComponent/logoData";
import { Button } from "@mui/material";
const JuzzItInNews = () => {
  const [visibleImage, setVisibleImage] = useState(8);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setVisibleImage(4);
    }
  }, []);

  const loadMorePlacements = () => {
    setVisibleImage((previous) => previous + 4);
  };

  const loadLessPlacements = () => {
    setVisibleImage((previous) => previous - 4);
  };

  return (
    <>
      <h1 className="All-components-heading">JUZZ-IT AT NEWS</h1>
      <div className="JuzzItInNews-main-container">
        <div className="JuzzItInNews-container">
          {logos &&
            logos?.slice(0, visibleImage).map((val, key) => {
              return (
                <div
                  className="JuzzItInNews-contents"
                  key={key}
                  onClick={() => {
                    window.open(val.link, "_blanck");
                  }}
                >
                  <img src={val.url} alt={`logos ${key + 1}`} />
                  {/* <div>
                  <p>
                    weedf ii iu f f in ieruefh er iuewhfi f jewewfh uwhfi iwefew
                    ufhw iwui
                  </p>
                </div> */}
                </div>
              );
            })}
        </div>
      </div>
      <div className="load-more-buttom">
        {visibleImage < logos.length && (
          <Button
            variant="contained"
            color="inherit"
            size="large"
            onClick={loadMorePlacements}
            className="button"
          >
            Load More
          </Button>
        )}
        {visibleImage > 6 && (
          <Button
            variant="contained"
            color="inherit"
            size="large"
            onClick={loadLessPlacements}
            className="button"
          >
            Load Less
          </Button>
        )}
      </div>
    </>
  );
};

export default JuzzItInNews;
