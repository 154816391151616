import React from "react";
import "./SingleRecodedClassVideoModule.scss";
import { NavLink } from "react-router-dom";
const SingleRecodedClassVideoModule = (props) => {
  const modeuleData = props.modeuleData;

  return (
    <div className="SingleRecodedClassVideoModule-main-container">
      <NavLink
        className="nav-link"
        to={`module/${modeuleData?.module_Id}/lessons`}
      >
        {modeuleData.module_image &&
          modeuleData.module_image?.map((val1, key1) => {
            if (val1.active_status === "A") {
              return <img src={val1.IMAGE_URL} alt="qwhsiyu"></img>;
            }
            return null;
          })[0]}

        <h3>{modeuleData?.module_Title}</h3>
        <div className="percentage-of-completion">
          <p>
            {modeuleData.module_completion_percentage?.completed_percentage}%
          </p>
          <div className="percentage-main-strip">
            <div
              style={{
                width: `${modeuleData.module_completion_percentage?.completed_percentage.toFixed(
                  2
                )}`,
              }}
              className="percentage-strip"
            ></div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};
export default SingleRecodedClassVideoModule;
