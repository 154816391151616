import React from "react";
import "./Home.scss";
// import SolutionProsses from "./Components/SolutionProssesComponent/SolutionProsses";

//import FeaturedCources from "./Components/FeaturedCourcesComponent/FeaturedCources";
import HomeHeader from "./Components/HomeHeaderComponent/HomeHeader";
import PlacementOpportunities from "./Components/PlacementOpportunitiesCompo/PlacementOpportunities";
import RecentPlacements from "./Components/RecentPlacementsCompone/RecentPlacements";
import RecomendeCourses from "./Components/RecomendeCoursesComponent/RecomendeCourses";
// import RecentBlogs from "./Components/RecentBlogsComponent/RecentBlogs";
import PrepareToBreakThrough from "./Components/PrepareToBreakThroughCompo/PrepareToBreakThrough";
import FintechParter from "./Components/FintechParterComponent/FintechParter";
import AnAllRoundEffective from "./Components/AnAllRoundEffectiveCompo/AnAllRoundEffective";
import RealWorkExperience from "./Components/RealWorkExperienceComponent/RealWorkExperience";
import HaveAnyFurther from "./Components/HaveAnyFurtherCompo/HaveAnyFurther";
import BeacomeTrainer from "./Components/BeacomeTrainerCompo/BeacomeTrainer";
import JuzzItInNews from "./Components/JuzzItInNewsComponents/JuzzItInNews";
//s import PayAfterPlacement from "./Components/PayAfterPlacementCompo/PayAfterPlacement";

// import VideoPlayer from "./Videoplay";
const Home = () => {
  return (
    <div className="home-container">
      <HomeHeader />
      <RecentPlacements />
      {/* <PayAfterPlacement /> */}
      {/* <HomeWorkShops /> */}
      <RecomendeCourses />
      <PrepareToBreakThrough />
      <PlacementOpportunities />
      <AnAllRoundEffective />

      <JuzzItInNews />
      <FintechParter />
      <HaveAnyFurther />
      <RealWorkExperience />

      {/* <FeaturedCources /> */}
      {/* <RecentBlogs /> */}

      <BeacomeTrainer />
    </div>
  );
};

export default Home;
