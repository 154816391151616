import React, { useEffect } from "react";
import "./RPprojectDetails.scss";
import HeaderApplyForm from "../../../../../CourcesComponents/SingleCourceComponent/CourseTypeCompo/HeaderApplyForm";
import RecentPlacementProjectList from "./RecentPlacementProjectList";
import { getRecentPlacementProjectDetailsData } from "../../../../../../../Redux/Actions/HomePageActions";
import { useParams } from "react-router-dom";

import logo from "../../../../../../../img/logo/juzz-it_logo.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setrecentPlacementProjectDetails } from "../../../../../../../Redux/RedusersCompo/HomePageReducer";
const RPprojectDetails = () => {
  const { placementId, projectId } = useParams();

  const dispach = useDispatch();

  const recentPlacementProjectDetails = useSelector(
    (state) => state.HomePageData.recentPlacementProjectDetails
  );

  useEffect(() => {
    const getData = async () => {
      const data = await getRecentPlacementProjectDetailsData(
        placementId,
        projectId
      );
      dispach(setrecentPlacementProjectDetails(data));
    };
    getData();
  }, [dispach, placementId, projectId]);

  let image = {};

  if (
    recentPlacementProjectDetails &&
    recentPlacementProjectDetails?.length > 0
  ) {
    const imageData = recentPlacementProjectDetails[0]?.images.filter((val) => {
      return val.active_status === "A";
    });
    if (imageData && imageData.length > 0) {
      image = imageData[0];
    }
  }

  let certificate = {};

  if (
    recentPlacementProjectDetails &&
    recentPlacementProjectDetails?.length > 0
  ) {
    const certificateData =
      recentPlacementProjectDetails[0]?.certificate.filter((val) => {
        return val.active_Status === "A";
      });
    if (certificateData && certificateData.length > 0) {
      certificate = certificateData[0];
    }
  }

  return (
    <div className="RPprojectDetails-main-container">
      <div className="RecentPlacementDetail-container">
        <div className="RecentPlacementDetail-left-content">
          <HeaderApplyForm />
          <div className="RecentPlacementDetail-certificate">
            <p>Certificate recived </p>
            <img
              src={certificate && certificate?.certificate_URL}
              alt="sx"
            ></img>
          </div>
        </div>
        <div className="RecentPlacementDetail-right-content">
          <div className="RecentPlacementDetail-main-profileData">
            <div className="RecentPlacementDetail-profileData">
              <div className="RecentPlacementDetail-profileImage">
                <img
                  src={image && image.image_URL ? image.image_URL : logo}
                  alt="xcdas"
                ></img>
              </div>
              <div className="RecentPlacementDetail-data">
                <h1>
                  {recentPlacementProjectDetails &&
                    recentPlacementProjectDetails[0]?.name}
                </h1>
                <p>
                  {" "}
                  <i class="fa-solid fa-user-tie"></i>{" "}
                  {recentPlacementProjectDetails &&
                    recentPlacementProjectDetails[0]?.position}
                </p>
              </div>
              <span
                className=" span"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    recentPlacementProjectDetails &&
                      recentPlacementProjectDetails[0]?.linkedin_Link,
                    "_blank"
                  );
                }}
              >
                connect at
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2Wb7G67EcR44qT3KQLlLzI1Fna_L2lPXfTI1sx8_z2w&s"
                  alt="linked-in-logo"
                />
              </span>
            </div>
            <div className="RPprojectDetails-projectDetails">
              <div className="RPprojectDetails-project-contnet">
                <h1 className="All-components-heading">
                  Project{" "}
                  {recentPlacementProjectDetails &&
                    recentPlacementProjectDetails[0]?.projectData &&
                    recentPlacementProjectDetails[0]?.projectData[0]
                      ?.order}{" "}
                  :
                  {recentPlacementProjectDetails &&
                    recentPlacementProjectDetails[0]?.projectData &&
                    recentPlacementProjectDetails[0]?.projectData[0]
                      ?.project_name}
                </h1>
                <p>
                  <i class="fa-solid fa-angles-right"></i>{" "}
                  {recentPlacementProjectDetails &&
                    recentPlacementProjectDetails[0]?.projectData &&
                    recentPlacementProjectDetails[0]?.projectData[0]
                      ?.project_desc1}
                </p>
              </div>
              <ul>
                {recentPlacementProjectDetails &&
                  recentPlacementProjectDetails[0]?.projectData &&
                  recentPlacementProjectDetails[0]?.projectData[0]?.project_Image.map(
                    (val, key) => {
                      if (val.active_status === "D") {
                        return null;
                      }
                      return (
                        <li key={key}>
                          <img src={val.image_URL} alt="cx"></img>
                        </li>
                      );
                    }
                  )}
              </ul>
              <span
                onClick={() => {
                  window.open(
                    recentPlacementProjectDetails &&
                      recentPlacementProjectDetails[0]?.projectData &&
                      recentPlacementProjectDetails[0]?.projectData[0]
                        ?.project_desc2,
                    "_blank"
                  );
                }}
              >
                {" "}
                {recentPlacementProjectDetails &&
                  recentPlacementProjectDetails[0]?.projectData &&
                  recentPlacementProjectDetails[0]?.projectData[0]
                    ?.project_desc2}
              </span>
            </div>
          </div>
          <RecentPlacementProjectList
            projects={
              recentPlacementProjectDetails &&
              recentPlacementProjectDetails[0]?.projects
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RPprojectDetails;
