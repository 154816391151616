import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBlogs: [],
  blogDetails: [],
  blogComments: [],
};

const BlogReducer = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setallBlogs: (state, action) => {
      state.allBlogs = action.payload;
    },
    setblogDetails: (state, action) => {
      state.blogDetails = action.payload;
    },
    setblogComments: (state, action) => {
      state.blogComments = action.payload;
    },
  },
});

export const { setallBlogs, setblogDetails, setblogComments } =
  BlogReducer.actions;

export default BlogReducer.reducer;
