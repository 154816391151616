import React from "react";
import "./WhoConJoinThisProgram.scss";
const WhoConJoinThisProgram = ({ Objective, heading }) => {
  let sortedData = [];

  if (Objective && Objective.length > 0) {
    sortedData = [...Objective].sort((a, b) => a.ORDER - b.ORDER);
  }

  return (
    <div className="WhoConJoinThisProgram-main-container">
      <div className="WhoConJoinThisProgram-container">
        {heading === "NO" ? <h1>Objectives </h1> : <h1>{heading}</h1>}

        <div className="WhoConJoinThisProgram-main-contant">
          <div className="WhoConJoinThisProgram-left-container">
            <div className="WhoConJoinThisProgram-contant">
              {sortedData &&
                sortedData?.map((val, key) => {
                  return (
                    <p>
                      <i class="fa-solid fa-check"></i> {val.objective}
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="WhoConJoinThisProgram-right-container">
            {/* <img
              src="https://intellipaat.com/course-image/2020/08/who-can-apply.png"
              alt="ala"
            ></img> */}
            <img
              src="https://images.meesho.com/images/products/71008540/9zd22_512.webp"
              alt="ala"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoConJoinThisProgram;
