import React from "react";
import "./Events.scss";
import EventsHeader from "./EventsHeaderCompo/EventsHeader";
const Events = () => {
  return (
    <div className="Events-main-contaniner">
      <EventsHeader />
    </div>
  );
};

export default Events;
