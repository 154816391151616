import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./EnrolledLiveCourseClassLinks.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllClassLinks } from "../../../../../../../../Redux/Actions/MyCourseActions";
import { setClassLinks } from "../../../../../../../../Redux/RedusersCompo/MyCourseReducer";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Loading from "../../../../../../../../LoadingComponent/Loading";
import CourseDetailsBar from "../CourseDetailsBar";
const EnrolledLiveCourseClassLinks = () => {
  const { batchId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const classLinkDetails = useSelector(
    (state) => state.mycourseData.classLinks
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getAllClassLinks(batchId);
      dispatch(setClassLinks(data));
      setIsLoading(false);
    };
    getData();
  }, [batchId, dispatch]);

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="EnrolledLiveCourseClassLinks-main-container">
      <CourseDetailsBar />

      <TableContainer
        component={Paper}
        className="BatchClassLink-table-container"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI NO</TableCell>
              <TableCell>CLASS TITLE</TableCell>
              <TableCell>SUBJECT NAME</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>CLASS TIME</TableCell>
              <TableCell>CLASS LINK</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classLinkDetails &&
              classLinkDetails?.map((val, key) => {
                const date = new Date(val.class_Date);
                const options = {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };
                const formattedDate = date.toLocaleDateString("en-US", options);

                return (
                  <TableRow key={key}>
                    <TableCell>{key + 1}</TableCell>
                    <TableCell>{val.class_Title}</TableCell>
                    <TableCell>{val.subject_Name}</TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>{val.class_Time}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          window.open(val.class_Link, "_blank");
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        link
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EnrolledLiveCourseClassLinks;
