import React, { useEffect, useState } from "react";
import "./EnrollRequest.scss";
import { NavLink, useNavigate, useParams } from "react-router-dom";
//import PageHeader from "../../../pages/PageHeader";
import {
  getAllEnrollTypes,
  sandEnrollRequest,
} from "../../../../Redux/Actions/CourcesAction";
import { useDispatch, useSelector } from "react-redux";
import { addEnrollTypes } from "../../../../Redux/RedusersCompo/CourcesReducer";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Loading from "../../../../LoadingComponent/Loading";
import AOS from "aos";
import "aos/dist/aos.css";
import { getStudentDataByToken } from "../../../../Redux/Actions/AuthAction";
import { setStudentDataToStore } from "../../../../Redux/RedusersCompo/AuthReducer";

//import img from "../../../../img/studentReview.png";
const EnrollRequest = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  const { courseTypeId } = useParams();
  const [student_name, setStudent_name] = useState("");
  const [student_email, setStudent_email] = useState("");
  const [student_mobileNumber, setStudent_mobileNumber] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [enrollType, setEnrollTypes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requestSubmition, setrequestSubmition] = useState(false);

  const [notLogedIn, setNotLoggedIn] = useState(false);

  const [sumitionMassage, submitionMessage] = useState(
    "Your request submited Successfully"
  );
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    student_name: false,
    student_email: false,
    student_mobileNumber: false,
    country: false,
    state: false,
    district: false,
    pinCode: false,
    enrollType: false,
  });

  const enrollTypes = useSelector((state) => state.courseData.enrollTypes);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllEnrollTypes();
      dispatch(addEnrollTypes(data));
    };
    getData();
  }, [dispatch]);

  const validateForm = () => {
    const newErrors = {
      student_name: !student_name,
      student_email: !student_email,
      student_mobileNumber: !student_mobileNumber,
      country: !country,
      state: !state,
      district: !district,
      pinCode: !pinCode,
      enrollType: !enrollType,
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  const onsubmiteHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validateForm()) {
      // Form is not valid, do not proceed
      return;
    }

    // Your existing logic for sending enrollment request
    // const userData = localStorage.getItem("userData");
    // let user = {};

    // if (userData) {
    //   user = JSON.parse(userData);
    // }

    const newStudent = {
      student_name: student_name,
      enroll_Type: enrollType,
      student_email: student_email,
      student_mobileNumber: Number(student_mobileNumber),
      country: country,
      state: state,
      district: district,
      pinCode: Number(pinCode),
    };

    if (userDetails && userDetails?.id) {
      const responce = await sandEnrollRequest(
        userDetails?.id,
        courseTypeId,
        newStudent
      );
      if (responce.status === true) {
        setIsLoading(false);
        setrequestSubmition(true);
        setCountry("");
        setDistrict("");  
        setPinCode("");
        setState("");
        setStudent_email("");
        setStudent_mobileNumber("");
        setStudent_name("");
      } else {
        setIsLoading(false);
        setrequestSubmition(true);
        submitionMessage("failed to seand the request");
      }
    } else {
      setIsLoading(false);
      setNotLoggedIn(true);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="EnrollRequest-main-Container">
      <div
        className={`successfully-main-request-Submited ${
          requestSubmition ? "successfullySubmited" : ""
        }`}
      >
        <div className="successfully-request-Submited" data-aos="zoom-in">
          <h1>{sumitionMassage}</h1>
          <Button
            variant="contained"
            onClick={() => {
              setrequestSubmition(false);
              navigate(`/courseType/${courseTypeId}`);
            }}
          >
            continue
          </Button>
        </div>
      </div>
      <div
        className={`notLogged-in-main-request-Submited ${
          notLogedIn ? "logInerrorSubmited" : ""
        }`}
      >
        <div className="notLogged-in-request-Submited" data-aos="zoom-in">
          <h1>please login to continue</h1>
          <Button
            variant="contained"
            onClick={() => {
              setNotLoggedIn(false);
            }}
          >
            ok
          </Button>
        </div>
      </div>
      {/* 
      <PageHeader url={img} /> */}

      <div className="EnrollRequest-form-container">
        <NavLink to={`/courseType/${courseTypeId}`}>
          {" "}
          <i className="fa-solid fa-backward course-i"></i>
        </NavLink>
        <div className="EnrollRequest-form-contant">
          <form onSubmit={onsubmiteHandler}>
            <div className="form-element">
              <TextField
                label="Enter student Name"
                variant="standard"
                value={student_name}
                onChange={(e) => setStudent_name(e.target.value)}
                error={errors.student_name}
                helperText={errors.student_name && "Name is required"}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter student email"
                variant="standard"
                value={student_email}
                onChange={(e) => setStudent_email(e.target.value)}
                error={errors.student_email}
                helperText={errors.student_email && "Email is required"}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Mobile Number"
                variant="standard"
                type="number"
                value={student_mobileNumber}
                onChange={(e) => setStudent_mobileNumber(e.target.value)}
                error={errors.student_mobileNumber}
                helperText={
                  errors.student_mobileNumber && "Mobile Number is required"
                }
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter country"
                variant="standard"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                error={errors.country}
                helperText={errors.country && "Country is required"}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter state"
                variant="standard"
                value={state}
                onChange={(e) => setState(e.target.value)}
                error={errors.state}
                helperText={errors.state && "State is required"}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter district"
                variant="standard"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                error={errors.district}
                helperText={errors.district && "District is required"}
              />
            </div>
            <div className="form-element">
              <TextField
                label="Enter pinCode"
                variant="standard"
                type="number"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
                error={errors.pinCode}
                helperText={errors.pinCode && "Pin Code is required"}
              />
            </div>
            <div className="form-element">
              <FormControl>
                <InputLabel>Enter Enroll Type</InputLabel>
                <Select
                  value={enrollType}
                  onChange={(e) => setEnrollTypes(e.target.value)}
                  error={errors.enrollType}
                >
                  <MenuItem value="">Select</MenuItem>
                  {enrollTypes &&
                    enrollTypes?.map((val, key) => (
                      <MenuItem key={key} value={val?.enrollType}>
                        {val?.enrollType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <Button variant="contained" color="primary" type="submit">
              Add
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnrollRequest;
