import React, { useEffect } from "react";
import "./Cource.scss";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Cource = (props) => {
  const courseTypeImage = props.courseTypeImage;

  let imageURL = "";
  if (courseTypeImage) {
    const imageData = courseTypeImage?.filter((image) => {
      return image.active_Status === "A";
    });
    
    if (imageData) {
      imageURL = imageData[0]?.image_URL;
    }
  }

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="cource-main">
      <NavLink to={`/courseType/${props.courseTypeId}`} className="link">
        <div className="Cource-img">
          <img src={imageURL} alt="sab"></img>
        </div>
        <p className="heading-link">{props.heading}</p>
        <span>
          <i class="fa-solid fa-paperclip"></i>
          {props.courseTypeLevel}
        </span>
      </NavLink>
    </div>
  );
};

export default Cource;
