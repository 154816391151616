import React, { useEffect, useState } from "react";
import "./EventsHeader.scss";
// import logo from "../../../../img/logo/juzz-it_logo.jpg";
import EventList from "./components/EventList";
import { getALLEvents } from "../../../../Redux/Actions/EventAction";
import { useDispatch, useSelector } from "react-redux";
import { setEvents } from "../../../../Redux/RedusersCompo/EventReducer";
const EventsHeader = () => {
  const [typeSelected, setTypeSelected] = useState("ALL");
  const dispatch = useDispatch();

  const events = useSelector((state) => state.EventData.events);

  useEffect(() => {
    const getData = async () => {
      const data = await getALLEvents();
      dispatch(setEvents(data));
    };
    getData();
  }, [dispatch]);

  let filteredData = [];

  if (events && events.length > 0) {
    filteredData = [...events].sort((a, b) => {
      return a.ORDER - b.ORDER;
    });
  }

  let eventTypeData = [];

  if (events && events.length > 0) {
    if (typeSelected === "ALL") {
      eventTypeData = events;
    } else {
      eventTypeData = events.filter((event) => {
        return event.EVANT_NAME === typeSelected;
      });
    }
  }

  return (
    <div className="EventsHeader-main-contaniner">
      <div className="EventsHeader-container">
        <div className="EventsHeader-contant">
          <div className="EventsHeader-top-contant">
            <div className="EventsHeader-top-left-contant">
              {/* <img src={logo} alt="logo" /> */}
              <h4 className="All-components-heading">
                Acquire tech insights from seasoned industry leaders with
                hands-on experience!
              </h4>
            </div>
            <div className="EventsHeader-top-right-contant"></div>
          </div>
          <div className="EventsHeader-nav-contant">
            <ul>
              <li
                className={`${typeSelected === "ALL" ? "active" : ""}`}
                onClick={() => {
                  setTypeSelected("ALL");
                }}
              >
                All
              </li>
              {filteredData &&
                filteredData?.map((val, key) => {
                  return (
                    <li
                      className={`${
                        typeSelected === val.EVANT_NAME ? "active" : ""
                      }`}
                      onClick={() => {
                        setTypeSelected(val.EVANT_NAME);
                      }}
                    >
                      {val.EVANT_NAME}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>

      <div className="event-main-contnat">
        <div className="event-list-contnat">
          <EventList eventTypeData={eventTypeData} />
        </div>
        <div className="event-list-keyhighlight">
          <h1>Why Join</h1>
          <p>JUZZ-IT Masterclasses</p>
          <ul>
            <li>
              <img
                src="https://assets-v2.scaler.com/assets/events/certificate-01-62466f3f71bfde641037ecfcdcbb3ddc800f4c7347c595bf01eeec457a698132.svg.gz"
                alt="ss"
              />
              <div className="left-contnat">
                <p>JUZZ-IT</p>
                <span>Certificate</span>
              </div>
            </li>
            <li>
              <img
                src="https://assets-v2.scaler.com/assets/events/video-recorder-760c5cec32a76c5fb002cd88e513f7312730f1a01bd7b094f24135aa74e6c42b.svg.gz"
                alt="ss"
              />
              <div className="left-contnat">
                <p>Live & Recorded</p>
                <span>Learning</span>
              </div>
            </li>

            <li>
              <img
                src="https://assets-v2.scaler.com/assets/events/stars-02-958cc085a8ea7b5cb68f28c182183d5ecb9daf0d3f8a71a0189f82da8baddecb.svg.gz"
                alt="ss"
              />
              <div className="left-contnat">
                <p>Top</p>
                <span>Instructors</span>
              </div>
            </li>
            {/* <li>
              <img
                src="https://assets-v2.scaler.com/assets/events/certificate-01-62466f3f71bfde641037ecfcdcbb3ddc800f4c7347c595bf01eeec457a698132.svg.gz"
                alt="ss"
              />
              <div className="left-contnat">
                <p>JUZZ-IT</p>
                <span>Certificate</span>
              </div>
            </li> */}
            <li>
              <img
                src="https://assets-v2.scaler.com/assets/events/certificate-01-62466f3f71bfde641037ecfcdcbb3ddc800f4c7347c595bf01eeec457a698132.svg.gz"
                alt="ss"
              />
              <div className="left-contnat">
                <p>Bonus</p>
                <span>Resources</span>
              </div>
            </li>
            <li>
              <img
                src="https://assets-v2.scaler.com/assets/events/quiz-5797725b3d0b2f3ec4571df37a810ef5f28656647e549938f33d6b0ac05a6221.svg.gz"
                alt="ss"
              />
              <div className="left-contnat">
                <p>Live</p>
                <span>Quizzes</span>
              </div>
            </li>
            <li>
              <img
                src="https://assets-v2.scaler.com/assets/events/earth-22dc28e0b1c07d45b94784ca6d4063bc1d717bb792987ba92643fed439c267ac.svg.gz"
                alt="ss"
              />
              <div className="left-contnat">
                <p>Real</p>
                <span>World Topics</span>
              </div>
            </li>
          </ul>
          <div className="User-rating">
            <h5>Average User Rating</h5>
            <div className="divider"></div>
            <p>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <span>4.8/5</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsHeader;
