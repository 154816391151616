import React from "react";
import "./EventList.scss";
import SingleEvent from "./SingleEventCompo/SingleEvent";
const EventList = ({ eventTypeData }) => {
  return (
    <div className="EventList-main-container">
      {eventTypeData &&
        eventTypeData?.map((val, key) => {
          return val.EventTyps?.map((val, key) => {
            return <SingleEvent EventType={val} />;
          });
        })}
      <SingleEvent />
      <SingleEvent />
    </div>
  );
};

export default EventList;
