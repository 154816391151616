import React from "react";
import "./FreeCourses.scss";
import FressCourseHeader from "./components/FressCourseHeaderComponent/FressCourseHeader";
import FreeCourseLinks from "./components/FreeCourseLinksCompo/FreeCourseLinks";
import FastForworYourCareerWithUs from "./components/FastForworYourCareerWithUsCompo/FastForworYourCareerWithUs";
const FreeCourses = () => {
  return (
    <div className="FreeCourses-main-contrainer">
      <div className="FreeCourses-container">
        <FressCourseHeader />
        <FreeCourseLinks />
        <FastForworYourCareerWithUs />
      </div>
    </div>
  );
};

export default FreeCourses;
