import React from 'react'
import './PageHeader.scss'
import { Link } from 'react-router-dom'
const PageHeader = (props) => {

  return (
    <div className='PageHeader-containet' style={{backgroundImage: `url("${props.url}")`}}>

        <h1>{props.content}</h1>
        <div className='pageHeder-button'>
            <p><Link to={'/'} className='link'>Home</Link></p>
            <span>|</span>
            <p>{props.heading}</p>
        </div>
    </div>
  )
}
export default PageHeader