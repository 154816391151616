import React, { useEffect, useState } from "react";
import { TextField, Button, IconButton, InputAdornment } from "@mui/material";
import {
  AccountExistByEmail,
  validateUserData,
} from "../../../Redux/Actions/AuthAction";
import { useNavigate } from "react-router-dom";
import "./SignInWithEmail.scss";
import Loading from "../../../LoadingComponent/Loading";
import { useDispatch } from "react-redux";
import { setAuthStatus } from "../../../Redux/RedusersCompo/AuthReducer";
import AOS from "aos";
import "aos/dist/aos.css";

import { Visibility, VisibilityOff } from "@mui/icons-material";

const SignInWithEmail = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    allError: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [requestSubmition, setrequestSubmition] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginFailure, setLoginFailure] = useState("");
  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const isPasswordValid = (password) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordPattern.test(password);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({ email: "", password: "" });

    if (!isEmailValid(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      return;
    }

    if (!isPasswordValid(password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one digit",
      }));
      return;
    }

    const responce1 = await AccountExistByEmail(email);

    if (responce1?.status === false) {
      setLoginFailure("Account with the email not found");
      return;
    }

    const logInData = {
      email: email,
      password: password,
    };

    setIsLoading(true);
    const response = await validateUserData(logInData);

    if (response?.successStatus === true) {
      const tokenData = {
        jwt_token: response.jwtTokan,
      };
      localStorage.setItem("tokenData", JSON.stringify(tokenData));
      localStorage.setItem("userData", JSON.stringify(response));
      setIsLoading(false);
      dispatch(setAuthStatus(true));
      setrequestSubmition(true);
      if (props?.home) {
        window.location.reload();
      }
    } else {
      setIsLoading(false);
      setLoginFailure(response.massage);
    }
    setEmail("");
    setPassword("");
  };

  if (isLoading) {
    return <Loading />;
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <section className="SignInWithEmail-container">
      <div
        className={`successfully-main-request-Submited ${
          requestSubmition ? "successfullySubmited" : ""
        }  ${props.home ? "homePageSusscssTrue" : ""} `}
      >
        <div className="successfully-request-Submited" data-aos="zoom-in">
          <h1>login Successfull</h1>
          <div
            onClick={() => {
              setrequestSubmition(false);
              navigate(`/`);
              window.location.reload();
            }}
            className="button-home"
          >
            continue
          </div>
        </div>
      </div>
      <h1 variant="h1">Sign In</h1>
      {loginFailure && (
        <span className="span" color="error">
          {loginFailure}
        </span>
      )}
      <div className="form-container">
        <div className="form-contant">
          <form onSubmit={handleSubmit}>
            {/* ... (unchanged code) ... */}

            <TextField
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={Boolean(errors.email)}
              helperText={errors.email}
              className="input"
            />

            <TextField
              label="Password"
              variant="standard"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={Boolean(errors.password)}
              helperText={errors.password}
              className="input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="sighin-button"
            >
              Sign In
            </Button>
            <div
              className={`logWitheOtp    ${props.home ? "homePageTrue" : ""}`}
            >
              <p variant="h3">OR</p>
              <Button
                onClick={() => navigate("/LoginWithOTp")}
                variant="outlined"
              >
                Log In with OTP
              </Button>
            </div>
            {/* <span onClick={() => navigate("/ForGotPassWord")}>
              forgot passWord
            </span> */}
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignInWithEmail;
