import React, { useEffect, useState } from "react";
import Loading from "../../../../../../../../LoadingComponent/Loading";
import { useParams } from "react-router-dom";
import ListOfAllClassVideos from "../../../../RecodedGeneral/RecodedClassesComponets/ClassVideosComponents/components/ListOfAllClassVideos";
import { getLessonTopicVides } from "../../../../../../../../Redux/Actions/MyCourseActions";
import { setemrolledRecodedCourseModuleVideos } from "../../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import { useDispatch, useSelector } from "react-redux";
import "./ModulesLesson.scss";
import CourseDetailsBar from "../CourseDetailsBar";
const ModulesLesson = () => {
  const { studentBatchId, moduleId } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const emrolledRecodedCourseModuleVideos = useSelector(
    (state) => state.mycourseData.emrolledRecodedCourseModuleVideos
  );

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getLessonTopicVides(studentBatchId, moduleId);
      dispatch(setemrolledRecodedCourseModuleVideos(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, studentBatchId, moduleId]);

  if (isLoading) {
    <Loading />;
  }
  return (
    <div className="ModulesLesson-main-container">
      <CourseDetailsBar />
      {/* <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classLinks`}
              className={"link"}
            >
              {" "}
              class links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyliveCourses/${courseId}/${batchId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div> */}

      <div className="AllClassVideos-container">
        <ListOfAllClassVideos videoData={emrolledRecodedCourseModuleVideos} />
      </div>
    </div>
  );
};

export default ModulesLesson;
