import { myaxios } from "../ApiBase";

export const getBranchData = async () => {
  try {
    const responce = await myaxios.get(`/api/branchData`);
    return responce.data;
  } catch (error) {}
};

export const getRecentPlacementData = async () => {
  try {
    const responce = await myaxios.get(`/api/recentPlacements`);
    return responce.data;
  } catch (error) {}
};

export const getRecentPlacementDetailsData = async (placementId) => {
  try {
    const responce = await myaxios.get(`/api/recentPlacements/${placementId}`);
    return responce.data;
  } catch (error) {}
};
export const getRecentPlacementProjectDetailsData = async (
  placementId,
  projectId
) => {
  try {
    const responce = await myaxios.get(
      `/api/recentPlacements/${placementId}/projects/${projectId}`
    );
    return responce.data;
  } catch (error) {}
};
