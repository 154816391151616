import React, { useState } from "react";
import "./LoginWithOTp.scss";
import { Button, TextField } from "@mui/material";
import Loading from "../LoadingComponent/Loading";
import {
  setAuthStatus,
  setsignUpOtpResponce,
} from "../Redux/RedusersCompo/AuthReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountExistByEmail,
  serntSignUpOtpRequest,
  validateUserDataWithOtp,
} from "../Redux/Actions/AuthAction";
import { useNavigate } from "react-router-dom";
const LoginWithOTp = () => {
  const [email, setEmail] = useState("");

  const [signUpNotification, setsignUpNotification] = useState(false);
  const [error, setError] = useState(null);
  const [OTPerror, setOtpError] = useState(null);
  const [isLoading, setIsLoadding] = useState(false);
  const [otpVarification, setotpVarification] = useState(false);
  const [otp, setOtp] = useState("");
  // const [loginSuccess, setLoginSuccess] = useState(false);
  // const [loginFailure, setLoginFailure] = useState("");
  const [requestSubmition, setrequestSubmition] = useState(false);

  const navigate = useNavigate();
  const signUpOtpResponce = useSelector(
    (state) => state.authdata.signUpOtpResponce
  );

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  console.log(signUpOtpResponce.requestId);
  const dispatch = useDispatch();

  const onSubmitHandler = async () => {
    if (!isEmailValid(email)) {
      setError("Invalid email address.");
      return;
    }

    const responce1 = await AccountExistByEmail(email);

    if (responce1?.status === true) {
      setIsLoadding(false);
      setsignUpNotification(true);
    }
    setIsLoadding(false);
    setError("Account with the email not found");
  };

  const HandleSendOTPrEQUEST = async () => {
    setIsLoadding(true);
    setsignUpNotification(false);

    const requestData = {
      EMAIL: email,
    };

    const responce = await serntSignUpOtpRequest(requestData);

    if (responce?.status === true) {
      setIsLoadding(false);
      dispatch(setsignUpOtpResponce({}));
      dispatch(setsignUpOtpResponce(responce));
      setotpVarification(true);
    } else {
      setIsLoadding(false);
    }
  };

  const handlleOTPvarification = async () => {
    setIsLoadding(true);

    const logInData = {
      email: signUpOtpResponce?.requestEmail,
      otp: otp,
    };

    const response = await validateUserDataWithOtp(
      signUpOtpResponce?.requestId,
      logInData
    );
    console.log(response);

    if (response?.successStatus === true) {
      // localStorage.setItem("userData", JSON.stringify(response));
      // setLoginSuccess(true);
      // setLoginFailure("");

      const tokenData = {
        jwt_token: response.jwtTokan,
      };
      localStorage.setItem("tokenData", JSON.stringify(tokenData));

      setIsLoadding(false);
      dispatch(setAuthStatus(true));
      setrequestSubmition(true);
    } else {
      setOtpError("Enter correct OTP");
    }
    setIsLoadding(false);
    setEmail("");
    setOtp("");
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="LoginWithOTp-main-container">
      <div
        className={`LoginWithOTp-login-successfull ${
          requestSubmition ? "requestSubmition" : ""
        }`}
      >
        <div className="LoginWithOTp-login-successfull-container">
          <div className="LoginWithOTp-login-successfull-contant">
            <h1>login Successfull</h1>
            <Button
              variant="contained"
              onClick={() => {
                setrequestSubmition(false);
                navigate(`/`);
                window.location.reload();
              }}
              className="generalButtom"
            >
              continue
            </Button>
          </div>
        </div>
      </div>
      {/* <div
        className={`successfully-main-request-Submited ${
          requestSubmition ? "successfullySubmited" : ""
        } `}
      >
        <div className="successfully-request-Submited" data-aos="zoom-in">
          <div
            className="successfully-request-sa-Submited"
            data-aos="zoom-in"
          ></div>
        </div>
      </div> */}

      <div
        className={`LoginWithOTp-conformation-pop-up-container  ${
          signUpNotification ? "showNotification" : ""
        }`}
      >
        <div className="LoginWithOTp-conformation-pop-up">
          <h1>
            For the varification we send the mail to email <span>{email}</span>{" "}
          </h1>
          <div className="LoginWithOTp-conformation-pop-up-button">
            <Button
              variant="contained"
              onClick={() => setsignUpNotification(false)}
              className="generalButtom"
            >
              cancel
            </Button>
            <Button
              variant="contained"
              onClick={HandleSendOTPrEQUEST}
              className="generalButtom"
            >
              send
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`LoginWithOTp-otp-main-container ${
          otpVarification ? "showOtpForm" : ""
        }`}
      >
        <div className="LoginWithOTp-otp-c-container">
          <div className="LoginWithOTp-otp-container">
            <span>{OTPerror}</span>
            <h1>Enter OTP</h1>
            <TextField
              type="number"
              variant="standard"
              label="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
            ></TextField>
            <Button
              onClick={handlleOTPvarification}
              variant="contained"
              className="generalButtom"
            >
              varify
            </Button>
            <div className="button-contant-divider">
              <Button
                onClick={HandleSendOTPrEQUEST}
                variant="contained"
                className="generalButtom"
              >
                Resend OTP
              </Button>
              <Button
                onClick={() => setotpVarification(false)}
                variant="contained"
                className="generalButtom"
              >
                cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="LoginWithOTp-container">
        <div className="LoginWithOTp-content">
          <span>{error}</span>
          <h1>Enter Email Address </h1>
          <TextField
            label="Email"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
          <Button
            variant="contained"
            onClick={onSubmitHandler}
            className="generalButtom"
          >
            next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginWithOTp;
