import React from "react";
import "./Member.scss";
const Member = (props) => {
  const memberData = props.memberData;

  console.log(memberData);
  return (
    <div class="Member-main-container">
      <div class="Member-main-img-container">
        {memberData?.MEMBER_IMAGES &&
          memberData?.MEMBER_IMAGES?.map((val1, key1) => {
            if (val1.active_Status === "A") {
              return <img src={val1.image_URL} alt="qwhsiyu"></img>;
            }
            return null;
          })[0]}
      </div>

      <div class="card__content">
        <span class="card__name">{memberData?.MEMBER_NAME}</span>
        <span class="card__title">{memberData?.MEMBER_POSITION}</span>

        {/* <p class="card__text">{memberData?.MEMBER_INTRUDUCTION}</p> */}
      </div>
    </div>
  );
};

export default Member;
