import React from "react";
import "./LeftTorightScroll.scss";
const LeftTorightScroll = () => {
  const fintechPartner = [
    {
      url: "https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31",
    },
    {
      url: "https://logos-world.net/wp-content/uploads/2020/04/Linkedin-Logo.png",
    },
    {
      url: "https://helios-i.mashable.com/imagery/articles/03y6VwlrZqnsuvnwR8CtGAL/hero-image.fill.size_1200x675.v1623372852.jpg",
    },
    {
      url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/2560px-Netflix_2015_logo.svg.png",
    },
  ];
  return (
    <>
      <div class="LeftTorightScroll">
        <div class="LeftTorightScroll-slide">
          {fintechPartner &&
            fintechPartner?.map((val, key) => {
              return (
                <img
                  src={val.url}
                  onClick={() => {
                    window.open(val.link, "_blank");
                  }}
                  alt="ssss"
                  key={key}
                />
              );
            })}
          {fintechPartner &&
            fintechPartner?.map((val, key) => {
              return (
                <img
                  src={val.url}
                  onClick={() => {
                    window.open(val.link, "_blank");
                  }}
                  alt="ssss"
                  key={key}
                />
              );
            })}
        </div>

        <div class="LeftTorightScroll-slide">
          {fintechPartner &&
            fintechPartner?.map((val, key) => {
              return (
                <img
                  src={val.url}
                  onClick={() => {
                    window.open(val.link, "_blank");
                  }}
                  alt="ssss"
                  key={key}
                />
              );
            })}
          {fintechPartner &&
            fintechPartner?.map((val, key) => {
              return (
                <img
                  src={val.url}
                  onClick={() => {
                    window.open(val.link, "_blank");
                  }}
                  alt="ssss"
                  key={key}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default LeftTorightScroll;
