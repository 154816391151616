import React from "react";
//import FintechParterLogogoScroller from "../PlacementOpportunitiesCompo/FintechParterLogogoScroller";
import "./FintechParter.scss";
const FintechParter = () => {
  const fintechPartner = [
    {
      url: "https://www.getshopse.com/assets/images/ShopSe-logo-website.svg",
      link: "https://www.getshopse.com/",
    },
    {
      url: "https://razorpay.com/newsroom-content/uploads/2020/12/output-onlinepngtools-1-1.png",
      link: "https://razorpay.com/",
    },
    {
      url: "https://www.jodo.in/assets/images/jodo-logo.svg",
      link: "https://www.jodo.in/",
    },
    {
      url: "https://varthana.com/wp-content/uploads/2022/10/h_logo.png",
      link: "https://varthana.com/",
    },
    {
      url: "https://res.cloudinary.com/finzy/image/upload/v1702367764/finzy_logo_full_ca8aib.svg",
      link: "https://finzy.com/",
    },
    {
      url: "https://abroadcube.com/assets/images/services/credenc-202211151157.png",
      link: "https://www.credenc.com/",
    },
    // {
    //   url: "https://credencemf.com/Content/credencemf.com/Logo/RealImage/825logo-final.png",
    //   link: "https://credencemf.com/",
    // },
  ];

  return (
    <div className="FintechParter-main-container">
      <div className="FintechParter-container">
        <h1 className="All-components-heading ">Our Fintech Partners</h1>

        {/* <FintechParterLogogoScroller /> */}

        <div className="FintechParter-logos">
          <ul>
            {fintechPartner?.map((val, key) => {
              return (
                <li key={key}>
                  <img
                    src={val.url}
                    alt={`logos${key + 1}`}
                    onClick={() => {
                      window.open(val.link, "_blank");
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FintechParter;
