import React from "react";
import "./SingleStudentPlacement1.scss";
// import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import { useNavigate } from "react-router-dom";
import logo from "../../../../img/logo/juzz-it_logo.jpg";
const SingleStudentPlacement1 = ({ PLACEMENTdATA }) => {
  const navigate = useNavigate();
  let image = {};

  if (PLACEMENTdATA) {
    const imageData = PLACEMENTdATA.images.filter((val) => {
      return val.active_status === "A";
    });

    if (imageData && imageData.length > 0) {
      image = imageData[0];
    }
  }

  return (
    <div className="SingleStudentPlacement1-main-container">
      <div
        className="SingleStudentPlacement1-container"
        onClick={() =>
          navigate(`/OurAlumini/${PLACEMENTdATA?.recentPlacement_ID}`)
        }
      >
        <div className="SingleStudentPlacement1-top-img">
          <img
            className="img"
            src={image && image.image_URL ? image.image_URL : logo}
            alt="asa"
          ></img>
          <h1>
            {" "}
            <h2>{PLACEMENTdATA?.StudentName}</h2>
          </h1>
        </div>
        <div className="SingleStudentPlacement1-course-taken">
          <h4>course undertaken </h4>
          <i class="fa-solid fa-arrow-down"></i>
          <p> {PLACEMENTdATA?.CourseUnderTaken}</p>
        </div>
        <div className="SingleStudentPlacement1-placed-data">
          <h3>{PLACEMENTdATA?.PlacedPosition}</h3>

          <i class="fa-solid fa-angles-down"></i>
          <p> {PLACEMENTdATA?.PlacedCompany}</p>
        </div>
      </div>

      <div
        className="SingleStudentPlacement1-linkedin"
        onClick={() => {
          window.open(PLACEMENTdATA?.linkedin_Link, "_blank");
        }}
      >
        <h3>
          connect at linkedin <i class="fa-brands fa-linkedin"></i>
        </h3>
        <i class="fa-solid fa-right-long"></i>
      </div>

      {/* <div className="SingleStudentPlacement-top-main-container">
        <div className="SingleStudentPlacement-top--top-content">
         
          <div className="SingleStudentPlacement-top-heading-content">
            <h2>{PLACEMENTdATA?.StudentName}</h2>
            <p>
              <i class="fa-solid fa-user-tie"></i>
              {PLACEMENTdATA?.PlacedPosition}
            </p>
            <p>
              {" "}
              <i class="fa-regular fa-building"></i>
              {PLACEMENTdATA?.PlacedCompany}
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(PLACEMENTdATA?.linkedin_Link, "_blank");
              }}
            >
              <i class="fa-brands fa-linkedin"></i>
            </p>
          </div>
        </div>
      </div>
      <div className="SingleStudentPlacement-bottom-content">
        <h1>course undertaken - </h1>
        <p> {PLACEMENTdATA?.CourseUnderTaken}</p>
      </div>
      <div
        onClick={() =>
          navigate(`/OurAlumini/${PLACEMENTdATA?.recentPlacement_ID}`)
        }
        className="SingleStudentPlacement-bottom-line"
      >
        read more <i class="fa-solid fa-right-long"></i>
      </div> */}
    </div>
  );
};

export default SingleStudentPlacement1;
