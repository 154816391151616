import React, { useState } from "react";
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import "./CareerServices.scss";
// import img from "../../../../../../img/Course/juzz-it_careere/bank.png";
// import img1 from "../../../../../../img/Course/juzz-it_careere/clipboard.png";
// import img2 from "../../../../../../img/Course/juzz-it_careere/social-care.png";

const CareerServices = ({ careerService, heading }) => {
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, index } = props;

    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  return (
    <div className="CareerServices-main-container">
      <div className="CareerServices-container">
        {heading === "NO" ? (
          <h1>Career Services By JUZZ-IT</h1>
        ) : (
          <h1>{heading}</h1>
        )}

        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          centered
          className={isMobile ? "vertical-tabs" : "horizontal-tabs"}
          orientation={isMobile ? "vertical" : "horizontal"}
          sx={
            isMobile
              ? { borderRight: 1, borderColor: "divider" }
              : { borderBottom: 1, borderColor: "divider" }
          }
        >
          {careerService &&
            careerService?.map((val, key) => {
              return <Tab label={val.heading} className="Tab" />;
            })}
        </Tabs>
        {careerService &&
          careerService.map((heading, headingIndex) => (
            <TabPanel
              key={`${headingIndex}-`}
              className="TabPanel-main"
              value={value}
              index={headingIndex}
            >
              {heading.career_service_details.map((details) => (
                <div className="TabPanel">
                  <div className="TabPanel-main-contant">
                    {details.images && details.images.length > 0 && (
                      <img
                        src={details.images[0].image_URL}
                        alt="ImageAlt Text"
                      />
                    )}
                    <div className="TabPanel-contant">
                      <h4>
                        {details.details_heading}{" "}
                        {/* <i className="fa-regular fa-calendar-days"></i> */}
                      </h4>{" "}
                      <p>{details.details_desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </TabPanel>
          ))}

        {/* 
        <TabPanel className="TabPanel-main" value={value} index={0}>
          <div className="TabPanel">
            <div className="TabPanel-main-contant">
              <img src={img2} alt="sasa"></img>
              <div className="TabPanel-contant">
                <h4>
                  <span> Job Opportunities Guaranteed*</span>{" "}
                  <i class="fa-regular fa-calendar-days"></i>Upon movement to
                  the Placement Pool{" "}
                </h4>{" "}
                <p>
                  {" "}
                  Get a 100% job Opportunities guarantee upon movement to the
                  placement pool after clearing the Placement Readiness Test
                  (PRT).
                </p>
              </div>
            </div>
            <div className="TabPanel-main-contant">
              <img src={img2} alt="sasa"></img>
              <div className="TabPanel-contant">
                <h4>
                  <span> Access to JUZZ-IT Job Portal</span>{" "}
                  <i class="fa-regular fa-calendar-days"></i>Within 6 Months of
                  Course Completion
                </h4>{" "}
                <p>
                  Exclusive access to our dedicated job portal and apply for
                  jobs. More than 500 hiring partners’ including top start-ups
                  and product companies hiring our learners. Mentored support on
                  job search and relevant jobs for your career growth.
                </p>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel className="TabPanel-main" value={value} index={1}>
          <div className="TabPanel">
            <div className="TabPanel-main-contant">
              <img src={img1} alt="sasa"></img>
              <div className="TabPanel-contant">
                <h4>
                  <span> Mock Interview Preparation After </span>{" "}
                  <i class="fa-regular fa-calendar-days"></i>80% of the course
                  completion{" "}
                </h4>
                <p>
                  {" "}
                  Students will go through a number of mock interviews conducted
                  by technical experts who will then offer tips and constructive
                  feedback for reference and improvement.
                </p>
              </div>
            </div>
            <div className="TabPanel-main-contant">
              <img src={img1} alt="sasa"></img>
              <div className="TabPanel-contant">
                <h4>
                  <span>1 on 1 Career Mentoring Sessions</span>{" "}
                  <i class="fa-regular fa-calendar-days"></i>After 90% of the
                  course completion{" "}
                </h4>{" "}
                <p>
                  Attend one-on-one sessions with career mentors on how to
                  develop the required skills and attitude to secure a dream job
                  based on a learners’ educational background, past experience,
                  and future career aspirations.
                </p>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel className="TabPanel-main" value={value} index={2}>
          <div className="TabPanel">
            <div className="TabPanel-main-contant">
              <img src={img} alt="sasa"></img>
              <div className="TabPanel-contant">
                <h4>
                  <span> Career Oriented Sessions </span>{" "}
                  <i class="fa-regular fa-calendar-days"></i>Throughout the
                  course{" "}
                </h4>{" "}
                <p>
                  {" "}
                  Over 20+ live interactive sessions with an industry expert to
                  gain knowledge and experience on how to build skills that are
                  expected by hiring managers. These will be guided sessions and
                  that will help you stay on track with your up skilling
                  objective.
                </p>
              </div>
            </div>
            <div className="TabPanel-main-contant">
              <img src={img} alt="sasa"></img>
              <div className="TabPanel-contant">
                <h4>
                  <span>Resume & LinkedIn Profile Building</span>{" "}
                  <i class="fa-regular fa-calendar-days"></i>After 70% of course
                  completion{" "}
                </h4>{" "}
                <p>
                  {" "}
                  Get assistance in creating a world-class resume & Linkedin
                  Profile from our career services team and learn how to grab
                  the attention of the hiring manager at profile shortlisting
                  stage.
                </p>
              </div>
            </div>
          </div>
        </TabPanel> */}
      </div>
    </div>
  );
};

export default CareerServices;
