import React from "react";
import "./RPSinglePlacement.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
const RPSinglePlacement = ({ project }) => {
  const { placementId } = useParams();
  const navigate = useNavigate();
  return (
    <div className="RPSinglePlacement-main-container">
      <div className="project_Content">
        <h4>
          Project {project?.order} : <span>{project?.project_Name}</span>
        </h4>
        <p
          onClick={() => {
            window.open(project?.project_desc2, "_blank");
          }}
        >
          {project?.project_desc2}
        </p>
      </div>

      <Button
        onClick={() =>
          navigate(`/OurAlumini/${placementId}/projects/${project?.project_id}`)
        }
        className="button"
        variant="contained"
      >
        Read More
      </Button>
    </div>
  );
};

export default RPSinglePlacement;
