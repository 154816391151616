import React, { useEffect } from "react";
import "./EventVideo.scss";
import { useDispatch, useSelector } from "react-redux";
import { setEventVideo } from "../../../../../../../Redux/RedusersCompo/EventReducer";
import { getEeventVideo } from "../../../../../../../Redux/Actions/EventAction";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
const EventVideo = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const eventVideo = useSelector((state) => state.EventData.eventVideo);

  useEffect(() => {
    const getData = async () => {
      const data = await getEeventVideo(eventId);
      dispatch(setEventVideo(data));
    };
    getData();
  }, [dispatch, eventId]);

  return (
    <div className="EventVideo-main-container">
      <div className="EventVideo-container">
        <div className="video-contant">
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={
              eventVideo &&
              eventVideo.length > 0 &&
              eventVideo[0]?.VIDEO_DATA &&
              eventVideo[0]?.VIDEO_DATA.length > 0 &&
              eventVideo[0]?.VIDEO_DATA[0]?.VIDEO_URL
            }
            className="ReactPlayer"
            controls={true} // Add controls
            playing={true} // Add autoplay
          />
        </div>
      </div>
    </div>
  );
};

export default EventVideo;
