import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recomendedCourses: [],
  recentPlacements: [],
  recentPlacementDetails: [],
  recentPlacementProjectDetails: [],
};

const HomePageReducer = createSlice({
  name: "home",
  initialState,
  reducers: {
    setrecomendedCourses(state, Action) {
      state.recomendedCourses = Action.payload;
    },
    setrecentPlacements(state, action) {
      state.recentPlacements = action.payload;
    },
    setrecentPlacementDetails(state, action) {
      state.recentPlacementDetails = action.payload;
    },

    setrecentPlacementProjectDetails(state, action) {
      state.recentPlacementProjectDetails = action.payload;
    },
  },
});

export const {
  setrecomendedCourses,
  setrecentPlacements,
  setrecentPlacementDetails,
  setrecentPlacementProjectDetails,
} = HomePageReducer.actions;

export default HomePageReducer.reducer;
