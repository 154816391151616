import React from "react";
import "./SingleJobList.scss";
import { Button } from "@mui/material";
import logo from "../../../../../../../../img/logo/juzz-it_logo.jpg";
import { useNavigate } from "react-router-dom";

const SingleJobList = ({ jobData }) => {
  const navigate = useNavigate();
  console.log(jobData);
  let image;
  if(jobData &&
    
    jobData.imageData){

      image= jobData?.imageData.filter((a)=>{
return a.active_status === "A";
      })[0]

    }
      

  return (
    <div className="SingleJobList-main-container">
      <div className="SingleJobList-container">
        {jobData ? (
          <>
            <div className="SingleJobList-main-contnat">
              <div className="company-logo">
              {
                image?<img src={image.image_URL} alt="sdcd"/>:
              <img src={logo} alt="sdcd" />
              }
              </div>
              <div className="job-locations">
                <h1>{jobData.COMPANY_NAME}</h1>
                <ul>
                  {jobData?.JobLocationData?.map((val) => {
                    return <li key={val.LOCATION_ID}>{val.LOCATION}</li>;
                  })}
                </ul>
                <p>{jobData.JOB_INTRUDUCTION}</p>
              </div>
            </div>

            <div className="SingleJobList-apply-details">
              <Button
                onClick={() => {
                  navigate(`/JuzzItJobs/${jobData.JOB_ID}`);
                }}
                variant="outlined"
                color="success"
              >
                View Details
              </Button>
              {/* <Button variant="contained" className="Apply-BUTTON">
                Apply
              </Button> */}
            </div>
          </>
        ) : (
          <>
            <div className="SingleJobList-contant">
              <div className="loading-animation"></div>
              <h2>Coming soon</h2>
            </div>
            <div className="SingleJobList-apply-details">
              <Button variant="outlined" color="success">
                View Details
              </Button>
              <Button variant="contained" className="Apply-BUTTON">
                Apply
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SingleJobList;
