import React from "react";
import "./SingleRecoredCourseCompo.scss";
import { NavLink } from "react-router-dom";
const SingleRecoredCourseCompo = (props) => {
  const imageData = props.img;

  const image = imageData?.filter((image) => {
    return image.active_Status === "A";
  })[0];

  return (
    <div className="SingleRecoredCourseCompo-main-container">
      <NavLink
        to={`${props.course_Id}/${props.studentBatchId}/details`}
        className={"nav-link"}
      >
        <img src={image.image_URL} alt="course"></img>
        <h2>{props.heading}</h2>
      </NavLink>
    </div>
  );
};

export default SingleRecoredCourseCompo;
