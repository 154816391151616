import React from "react";
import "./TailormadeSolutions.scss";

import img from "../../../../../img/acadamy/C1.jpeg";
import img1 from "../../../../../img/acadamy/c2.jpeg";
import img2 from "../../../../../img/acadamy/WhatsApp Image 2024-02-13 at 4.19.04 PM.jpeg";
const TailormadeSolutions = () => {
  return (
    <div className="TailormadeSolutions-main-container">
      <div className="TailormadeSolutions-container">
        <h1 className="componets-itam-heading">
          Tailormade solutions
          <br /> for academic partners
        </h1>
        <div className="TailormadeSolutions-main-contant">
          <div className="TailormadeSolutions-contant">
            <div className="left-content">
              <img src={img} alt="dsa"></img>
            </div>
            <div className="right-contant">
              <h2 className="componets-itam-heading">
                1. Integrated Curriculum Programs
              </h2>
              <p
                className="all-component-description"
                style={{ fontSize: "16px", lineHeight: "22px" }}
              >
                Collaborating with your institution, JUZZ-IT introduces a
                diverse array of integrated programs spanning various domains.
                These programs seamlessly incorporate into the credit system as
                electives and projects, providing your students with a
                distinctive and personalized training experience. Additionally,
                they involve industry-relevant projects, expediting the
                engineering admissions process.
              </p>
            </div>
          </div>
          <div className="TailormadeSolutions-contant">
            <div className="left-content">
              <img
                src={
                  "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTXTERNNfBdtXs9C7iG5bRfFQL5esBsxJF-AW7SPKAswSi2QhHY"
                }
                alt="dsa"
              ></img>
            </div>
            <div className="right-contant">
              <h2 className="componets-itam-heading">
                2.Placement-Centric Booster Programs
              </h2>
              <p
                className="all-component-description"
                style={{ fontSize: "16px", lineHeight: "22px" }}
              >
                Tailored for your final-year students and graduates, our
                specialized booster programs focus on imparting cutting-edge
                skills, giving them a competitive edge for a successful
                jumpstart in their careers.
              </p>
            </div>
          </div>
          <div className="TailormadeSolutions-contant">
            <div className="left-content">
              <img src={img2} alt="dsa"></img>
            </div>
            <div className="right-contant">
              <h2 className="componets-itam-heading">
                3. Comprehensive Selection of Specialized Courses
              </h2>
              <p
                className="all-component-description"
                style={{ fontSize: "16px", lineHeight: "22px" }}
              >
                Explore JUZZ-IT's vast digital library, featuring over 250
                specialized courses and postgraduate programs, encompassing more
                than 6000 hours of recorded content. Dive into our extensive
                collection spanning core and IT domains.
              </p>
            </div>
          </div>
          <div className="TailormadeSolutions-contant">
            <div className="left-content">
              <img
                src="https://lh3.googleusercontent.com/p/AF1QipMXYYfNyuT9hXfUhYjtj9QcT0MRQ5Gu_Eg74aqW=s680-w680-h510"
                alt="dsa"
              ></img>
            </div>
            <div className="right-contant">
              <h2 className="componets-itam-heading">
                4. JUZZ-IT's Center of Excellence
              </h2>
              <p
                className="all-component-description"
                style={{ fontSize: "16px", lineHeight: "22px" }}
              >
                Embark on an innovative journey with JUZZ-IT's "EV Labs" – a
                specialized facility designed for virtual simulations, providing
                hands-on training in electric vehicle design. Tailor your
                experience by choosing between a virtual EV Lab or establishing
                a physical lab equipped with the latest hardware and
                infrastructure, according to your preferences.
              </p>
            </div>
          </div>
          <div className="TailormadeSolutions-contant">
            <div className="left-content">
              <img src={img1} alt="dsa"></img>
            </div>
            <div className="right-contant">
              <h2 className="componets-itam-heading">
                5. Tailored Placement Assistance
                <br />
                Benefit from personalized support with:
              </h2>
              <ul style={{ fontSize: "16px", lineHeight: "22px" }}>
                <li className="all-component-description">
                  Assured participation in five interviews
                </li>
                <li className="all-component-description">
                  Crafting of a unique and compelling resume and LinkedIn
                  profile
                </li>
                <li className="all-component-description">
                  Practice through mock interviews
                </li>
                <li className="all-component-description">
                  pecialized training in communication and aptitude
                </li>
                <li className="all-component-description">
                  Rigorous preparation for technical assessments
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailormadeSolutions;
