import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCourse: [],
  courseData: {},
  course_courseTypes: [],
  futuredCourses: [],
  courseTypeAllDetailes: [],
  headerAllCourse: [],
  enrollTypes: [],
};

const CoursesReducer = createSlice({
  name: "courses",
  initialState,
  reducers: {
    addAllCourses: (state, action) => {
      state.allCourse = action.payload;
    },
    addCourseData: (state, action) => {
      state.courseData = action.payload;
    },
    addfuturedCourses: (state, action) => {
      state.futuredCourses = action.payload;
    },
    addcourseTypeAllDetailes: (state, action) => {
      state.courseTypeAllDetailes = action.payload;
    },
    addCourse_courseTypes: (state, action) => {
      state.course_courseTypes = action.payload;
    },
    addHeaderAllCourse: (state, action) => {
      state.headerAllCourse = action.payload;
    },
    addEnrollTypes: (state, action) => {
      state.enrollTypes = action.payload;
    },
  },
});

export const {
  addAllCourses,
  addCourseData,
  addfuturedCourses,
  addcourseTypeAllDetailes,
  addCourse_courseTypes,
  addHeaderAllCourse,
  addEnrollTypes,
} = CoursesReducer.actions;

export default CoursesReducer.reducer;
