import { myaxios } from "../ApiBase";

export const validateUserData = async (logInData) => {
  try {
    const responce = await myaxios.post("/Auth/student/login", logInData);
    return responce.data;
  } catch (error) {
    return {
      successStatus: false,
      massage: "Server error. Please try again later.",
    };
  }
};
export const validateUserDataWithOtp = async (requestId, logInData) => {
  try {
    const responce = await myaxios.post(
      `/Auth/student/otp/${requestId}/login`,
      logInData
    );
    return responce.data;
  } catch (error) {
    return {
      successStatus: false,
      massage: "pleace enter the currect otp",
    };
  }
};

export const addNewStudent = async (requestId, studentData) => {
  try {
    console.log(requestId);
    const responce = await myaxios.post(
      `/std/signUp/${requestId}`,
      studentData
    );
    return responce.data;
  } catch (error) {}
};

export const AccountExistByEmail = async (email) => {
  try {
    const responce = await myaxios.get(`/api/student/${email}/exists`);
    return responce.data;
  } catch (error) {}
};

export const serntSignUpOtpRequest = async (requestData) => {
  try {
    const responce = await myaxios.post(`/api/otp/sendRequest`, requestData);
    return responce.data;
  } catch (error) {}
};

export const serntSignUpDemoRequest = async (requestData) => {
  try {
    const responce = await myaxios.post(`/api/otp/addForDemo`, requestData);
    return responce.data;
  } catch (error) {}
};

export const serntSignUpEventRequest = async (requestData) => {
  try {
    const responce = await myaxios.post(
      `/api/otp/events/requests`,
      requestData
    );
    return responce.data;
  } catch (error) {}
};

export const serntSignUpContactRequest = async (requestData) => {
  try {
    const responce = await myaxios.post(
      `/api/otp/addContactRequest`,
      requestData
    );
    return responce.data;
  } catch (error) {}
};

export const getStudentDataByToken = async (tokenData) => {
  try {
    const responce = await myaxios.put(
      `/api/student/jwt/studentData`,
      tokenData
    );
    return responce.data;
  } catch (error) {}
};

export const validateToken = async (tokenData) => {
  try {
    const responce = await myaxios.put(
      `/api/student/jwt/validateToken`,
      tokenData
    );
    return responce.data;
  } catch (error) {}
};

export const sendTrainerRequest = async (trailnerData) => {
  try {
    const responce = await myaxios.post(`/api/otp/trainer/apply`, trailnerData);
    return responce.data;
  } catch (error) {}
};
