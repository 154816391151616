import React from "react";
import "./OurAlumniWorksAtCompo.scss";
const OurAlumniWorksAtCompo = ({ heading, alumini_Logo_image }) => {
  let imageData = {};

  if (alumini_Logo_image && alumini_Logo_image.length > 0) {
    const data = alumini_Logo_image.filter((element) => {
      return element.ACTIVE_STATUS === "A";
    });
    console.log(data);
    if (data) {
      imageData = data[0];
    } else {
      imageData = {
        IMAGE_URL:
          "https://intellipaat.com/wp-content/themes/intellipaat/images/Hiring-Partners.png",
      };
    }
  } else {
    imageData = {
      IMAGE_URL:
        "https://intellipaat.com/wp-content/themes/intellipaat/images/Hiring-Partners.png",
    };
  }

  console.log(alumini_Logo_image);

  return (
    <div className="OurAlumniWorksAtCompo-main-container">
      <div className="OurAlumniWorksAtCompo-container">
        {heading === "NO" ? <h1>Our Alumni Works At</h1> : <h1>{heading}</h1>}

        <div className="OurAlumniWorksAtCompo-logo-contant">
          <img src={imageData.IMAGE_URL} alt="dxadx"></img>
        </div>
      </div>
    </div>
  );
};

export default OurAlumniWorksAtCompo;
