import React, { useEffect, useRef, useState } from "react";
import "./PlacementOpportunities.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper/modules";
import { Button } from "@mui/material";
import DemoForm from "../../../DemoFormCompo/DemoForm";

const UnicornsImg = [
  {
    imgurl:
      "https://w7.pngwing.com/pngs/305/719/png-transparent-paytm-ecommerce-shopping-social-icons-circular-color-icon-thumbnail.png",
  },
  {
    imgurl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpxvmNqbQ1Y4dyI_jSCvfKkxvRYXr0Qa5zErN_nmAMa4XHzLyZdeWE709_0xN6CfI0dE8&usqp=CAU",
  },
  {
    imgurl:
      "https://mir-s3-cdn-cf.behance.net/projects/max_808/b9336c93700473.Y3JvcCwxNzM2LDEzNTcsMCwxNzE.png",
  },
  {
    imgurl: "https://cdn.worldvectorlogo.com/logos/postman.svg",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/7/70/Grofers-Logo-orange.png",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9d/CRED-LOGO2.png",
  },
  {
    imgurl: "https://entrackr.com/storage/2022/02/CARS24.jpg",
  },
  {
    imgurl:
      "https://i.pinimg.com/736x/6b/ec/c0/6becc01a1b399a4ee8fc6916a9de0083.jpg",
  },
  {
    imgurl:
      "https://images.moneycontrol.com/static-mcnews/2023/06/Meesho-682x435.jpg?impolicy=website&width=770&height=431",
  },
  // {
  //   imgurl:
  //     "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.theweek.in%2Fnews%2Fbiz-tech%2F2021%2F11%2F23%2Fnobroker-becomes-indias-first-real-estate-unicorn.html&psig=AOvVaw2NqO5eBY_is6p3poiLPpmF&ust=1707671024134000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCJirlM2goYQDFQAAAAAdAAAAABAD",
  // },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/3/3a/PharmEasy_logo.png",
  },
  {
    imgurl: "https://asset.brandfetch.io/idvsI-ggxm/idE37EUsSR.png",
  },
  {
    imgurl:
      "https://1000logos.net/wp-content/uploads/2022/08/Ola-Cabs-Logo-500x281.png",
  },
  {
    imgurl:
      "https://1000logos.net/wp-content/uploads/2021/02/Flipkart-logo.png",
  },
  // {
  //   imgurl: "",
  // },
];

const UnicornsImg12 = [
  {
    imgurl:
      "https://m.economictimes.com/thumb/msid-93620572,width-1600,height-900,resizemode-4,imgsize-8428/shiprocket.jpg ",
  },
  {
    imgurl: "https://somatix.com/wp-content/uploads/2022/07/tracxn_logonew.png",
  },
  {
    imgurl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf4A6cW8ajueA3k1B800TgJvgNp4GPDfHc1eEoKzZOQWAtrR32mxRK8dKrTAzy3_LMhg&usqp=CAU",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/1/14/FamPay_Logo.png",
  },
  {
    imgurl:
      "https://images.livemint.com/img/2022/02/22/original/1_1645511965584.jpg",
  },
  {
    imgurl:
      "https://images.livemint.com/img/2023/02/08/600x338/Lendingkart-logo_1200X630_1675871999078_1675872005438_1675872005438.png",
  },
  {
    imgurl: "https://miro.medium.com/v2/resize:fit:1200/0*UiDRbGh8K4M5Rs7R.png",
  },
  {
    imgurl: "https://etimg.etb2bimg.com/photo/95275293.cms",
  },
  {
    imgurl:
      "https://partner.visa.com/content/dam/gpp/partner-directory/Juspay%20Logo.png",
  },
  {
    imgurl:
      "https://www.gartner.com/pi/vendorimages/capillary-technologies_1639723040758.png",
  },
  {
    imgurl:
      "https://static.xtrawine.com/images/producers/vicara_1141_logo_2.jpg",
  },
  {
    imgurl:
      "https://assets-global.website-files.com/627a69c86fa82f4a4297a263/627a7cafee874d3e47ba07a2_Logo_Black.svg",
  },
  // {
  //   imgurl: "",
  // },
  // {
  //   imgurl: "",
  // },
  // {
  //   imgurl: "",
  // },
  // {
  //   imgurl: "",
  // },
  // {
  //   imgurl: "",
  // },
  // {
  //   imgurl: "",
  // },
];

const UnicornsImg1234 = [
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2560px-Amazon_logo.svg.png",
  },
  {
    imgurl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTxwmA__C8JATYXlUDb56zD1qjtOyY8MJTwXO-WSXFFg&s",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/2560px-Salesforce.com_logo.svg.png",
  },
  {
    imgurl:
      "https://lh3.googleusercontent.com/S11qAubY_QJNGANGzDRsfu8moZZAaZ19CAn5X1KETjWk24MG6XgjtC7iNJTFcIP1784tF3p-k9IH11-Lfr9ofsBJUOfkAtCkgE812nlJgrHnK4LKoGKEYLzhl077LHMS6IJQ84YmCkRg7UuCZ_vn8p132X3UKuinlSiisNuWhNEEzS_4DQx2kMEaJbX4t6z6gGHIY9Sk8w",
  },
  {
    imgurl:
      "https://media.licdn.com/dms/image/D5612AQH79G_HXqZOXQ/article-cover_image-shrink_600_2000/0/1697900397961?e=2147483647&v=beta&t=YcAOjcumNL5EX0w0DH8eNlmDAkThhkcgg4uj-nI6dtU",
  },
  {
    imgurl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcMUkhH99CgqWCsT-Q6ok1f_9x9yc_QpbkJ3PsAlUuGQ&s",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fc/IBM_logo_in.jpg",
  },
  {
    imgurl:
      "https://www.arcesium.com/wp-content/uploads/2022/03/Arcesium-OG.jpg",
  },
  {
    imgurl: "https://walmarkgroup.stada/Content/img/WalmarkStadaGroup2.svg",
  },
  {
    imgurl: "https://mma.prnewswire.com/media/1583755/Tekion_logo.jpg",
  },
  {
    imgurl: "https://1000logos.net/wp-content/uploads/2021/05/VMware-logo.png",
  },
  {
    imgurl:
      "https://static.javatpoint.com/top10-technologies/images/top-10-electrical-companies-in-india4.png",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/BHEL_logo.svg/1200px-BHEL_logo.svg.png",
  },
  {
    imgurl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFBbOXqqobWirZdB8vGXjQRiwk4-YbVD5kL6utGdln9A&s",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Deloitte_Logo.png",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/PricewaterhouseCoopers_Logo.svg/2560px-PricewaterhouseCoopers_Logo.svg.png",
  },
  {
    imgurl:
      "https://1000logos.net/wp-content/uploads/2021/04/Accenture-logo.png",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/1280px-Microsoft_logo_%282012%29.svg.png",
  },
];

const UnicornsImg123 = [
  {
    imgurl:
      "https://m.economictimes.com/thumb/msid-70565593,width-1200,height-900,resizemode-4,imgsize-150919/1.jpg",
  },
  {
    imgurl:
      "https://www.financialexpress.com/wp-content/uploads/2022/03/ola-electric-new-plant.jpg",
  },
  {
    imgurl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfdRq0sK40FYf1AN72MRCK8dfQdg7KU3CCHVHHHFpldA&s",
  },
  {
    imgurl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Tech_Mahindra_New_Logo.svg/2560px-Tech_Mahindra_New_Logo.svg.png",
  },
  {
    imgurl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOtAm5J3AXUtmbNtPIL2E9C2x4zKDWorxFuR9ycZgEjw&s",
  },
  {
    imgurl: "https://imperialsociety.in/wp-content/uploads/2018/10/TOYOTA.jpg",
  },
  {
    imgurl:
      "https://imperialsociety.in/wp-content/uploads/2018/10/Ashok-Leyland.jpg",
  },
  {
    imgurl: "https://imperialsociety.in/wp-content/uploads/2018/10/Maruti.jpg",
  },
  {
    imgurl: "https://imperialsociety.in/wp-content/uploads/2018/10/Trinity.jpg",
  },
  {
    imgurl:
      "https://imperialsociety.in/wp-content/uploads/2018/10/Ashok-Leyland.jpg",
  },
  {
    imgurl: "https://imperialsociety.in/wp-content/uploads/2018/10/ampere.jpg",
  },
  {
    imgurl: "https://imperialsociety.in/wp-content/uploads/2018/10/Trinity.jpg",
  },

  // {
  //   imgurl:
  //     "https://static.javatpoint.com/top10-technologies/images/top-10-electrical-companies-in-india4.png",
  // },
  // {
  //   imgurl:
  //     "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/BHEL_logo.svg/1200px-BHEL_logo.svg.png",
  // },
  // {
  //   imgurl:
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFBbOXqqobWirZdB8vGXjQRiwk4-YbVD5kL6utGdln9A&s",
  // },
  // {
  //   imgurl:
  //     "https://upload.wikimedia.org/wikipedia/commons/1/15/Deloitte_Logo.png",
  // },
  // {
  //   imgurl:
  //     "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/PricewaterhouseCoopers_Logo.svg/2560px-PricewaterhouseCoopers_Logo.svg.png",
  // },
  // {
  //   imgurl:
  //     "https://1000logos.net/wp-content/uploads/2021/04/Accenture-logo.png",
  // },
  // {
  //   imgurl:
  //     "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/1280px-Microsoft_logo_%282012%29.svg.png",
  // },
];

const PlacementOpportunities = () => {
  const [slidesPerView, setSlidesPerView] = useState(5);
  useEffect(() => {
    const updateSlidesPerView = () => {
      // Adjust the logic based on your design requirements
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1200) {
        setSlidesPerView(6);
      } else if (screenWidth >= 992) {
        setSlidesPerView(5);
      } else if (screenWidth >= 768) {
        setSlidesPerView(4);
      } else if (screenWidth >= 600) {
        setSlidesPerView(3);
      } else if (screenWidth >= 500) {
        setSlidesPerView(2);
      } else if (screenWidth >= 450) {
        setSlidesPerView(2);
      }
    };
    // Update slides per view on initial load and window resize
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const navigationPrevRef1 = useRef(null);
  const navigationNextRef1 = useRef(null);
  const navigationPrevRef2 = useRef(null);
  const navigationNextRef2 = useRef(null);
  const navigationPrevRef3 = useRef(null);
  const navigationNextRef3 = useRef(null);
  const [showForm, setshowForm] = useState(false);
  const [successSubmition, setsuccessSubmition] = useState(false);

  return (
    <>
      <div className="All-components-heading">
        work with Tech Unicorns, Soonicorns, <br /> or multinational
        corporations (MNCs).
      </div>{" "}
      <div className="PlacementOpportunities-main-container">
        <div className={`demo-form ${showForm ? "desplayDemoform" : ""}`}>
          <DemoForm
            SETDisplay={() => setshowForm(!showForm)}
            setsuccessSubmition={() => setsuccessSubmition(true)}
          />
        </div>

        <div
          className={`sucessfully-submited-Request-main ${
            successSubmition ? "successSubmition" : ""
          }`}
        >
          <div className="sucessfully-submited-Request">
            <h1>We received your request. We will reach you soon.</h1>
            <Button
              variant="contained"
              onClick={() => setsuccessSubmition(false)}
            >
              Ok
            </Button>
          </div>
        </div>

        <div className="PlacementOpportunities-container">
          <div
            style={{ maxWidth: 900 }}
            className="all-component-heading-discription"
          >
            <span>
              Craft a compelling GitHub portfolio with diverse projects
            </span>{" "}
            to captivate recruiters at unicorns, multinational corporations
            (MNCs), and rapidly expanding startups.
          </div>
          <div className="PlacementOpportunities-logo-slide">
            <div className="PlacementOpportunities-slide">
              {/* <p>
              <span>Hyper growth startups & Soonicorns</span>
            </p> */}
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-navigation-size": "15px",

                  "--swiper-navigation-padding": "15px",
                  "--swiper-navigation-right": "15px",
                }}
                slidesPerView={slidesPerView}
                spaceBetween={30}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
                autoplay={{ delay: 1500 }}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
              >
                {UnicornsImg &&
                  UnicornsImg?.map((val, key) => {
                    return (
                      <SwiperSlide>
                        <img src={val.imgurl} alt="sSSQW" />
                      </SwiperSlide>
                    );
                  })}

                <div className="navigation-sssliders">
                  <div ref={navigationPrevRef} className="left-slider">
                    {/* Replace with your SVG ICON for Previous Slide */}
                    <i class="fa-solid fa-left-long"></i>
                  </div>
                  <div ref={navigationNextRef} className="right-slider">
                    {/* Replace with your SVG ICON for Next Slide */}
                    <i class="fa-solid fa-right-long"></i>
                  </div>
                </div>
              </Swiper>
            </div>
            <div className="PlacementOpportunities-slide">
              {/* <p>
              <span>Global tech MNCs Learning </span>
            </p> */}
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-navigation-size": "15px",

                  "--swiper-navigation-padding": "15px",
                  "--swiper-navigation-right": "15px",
                }}
                slidesPerView={slidesPerView}
                spaceBetween={20}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
                autoplay={{ delay: 2000 }}
                navigation={{
                  prevEl: navigationPrevRef1.current,
                  nextEl: navigationNextRef1.current,
                }}
              >
                {UnicornsImg12 &&
                  UnicornsImg12?.map((val, key) => {
                    return (
                      <SwiperSlide>
                        <img src={val.imgurl} alt="sSSQW" />
                      </SwiperSlide>
                    );
                  })}
                <div className="navigation-sssliders">
                  <div ref={navigationPrevRef1} className="left-slider">
                    {/* Replace with your SVG ICON for Previous Slide */}
                    <i class="fa-solid fa-left-long"></i>
                  </div>
                  <div ref={navigationNextRef1} className="right-slider">
                    {/* Replace with your SVG ICON for Next Slide */}
                    <i class="fa-solid fa-right-long"></i>
                  </div>
                </div>
              </Swiper>
            </div>
            <div className="PlacementOpportunities-slide">
              {/* <p>
              {" "}
              <span>Unicorns</span>
            </p> */}
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-navigation-size": "15px",

                  "--swiper-navigation-padding": "15px",
                  "--swiper-navigation-right": "15px",
                }}
                slidesPerView={slidesPerView}
                spaceBetween={20}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
                autoplay={{ delay: 2500 }}
                navigation={{
                  prevEl: navigationPrevRef2.current,
                  nextEl: navigationNextRef2.current,
                }}
              >
                {UnicornsImg1234 &&
                  UnicornsImg1234?.map((val, key) => {
                    return (
                      <SwiperSlide>
                        <img src={val.imgurl} alt="sSSQW" />
                      </SwiperSlide>
                    );
                  })}
                <div className="navigation-sssliders">
                  <div ref={navigationPrevRef2} className="left-slider">
                    {/* Replace with your SVG ICON for Previous Slide */}
                    <i class="fa-solid fa-left-long"></i>
                  </div>
                  <div ref={navigationNextRef2} className="right-slider">
                    {/* Replace with your SVG ICON for Next Slide */}
                    <i class="fa-solid fa-right-long"></i>
                  </div>
                </div>
              </Swiper>
            </div>
            <div className="PlacementOpportunities-slide">
              {/* <p>
              {" "}
              <span>Unicorns</span>
            </p> */}
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-navigation-size": "15px",

                  "--swiper-navigation-padding": "15px",
                  "--swiper-navigation-right": "15px",
                }}
                slidesPerView={slidesPerView}
                spaceBetween={20}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
                autoplay={{ delay: 2500 }}
                navigation={{
                  prevEl: navigationPrevRef2.current,
                  nextEl: navigationNextRef2.current,
                }}
              >
                {UnicornsImg123 &&
                  UnicornsImg123?.map((val, key) => {
                    return (
                      <SwiperSlide>
                        <img src={val.imgurl} alt="sSSQW" />
                      </SwiperSlide>
                    );
                  })}
                <div className="navigation-sssliders">
                  <div ref={navigationPrevRef3} className="left-slider">
                    {/* Replace with your SVG ICON for Previous Slide */}
                    <i class="fa-solid fa-left-long"></i>
                  </div>
                  <div ref={navigationNextRef3} className="right-slider">
                    {/* Replace with your SVG ICON for Next Slide */}
                    <i class="fa-solid fa-right-long"></i>
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
          <div
            className="book-form-button-home "
            onClick={() => {
              setshowForm(true);
            }}
          >
            <span>
              Book Your Free Trial, Now<i class="fa-solid fa-right-long"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacementOpportunities;
