import React, { useEffect, useState } from "react";
import "./SingleCourcePage.scss";
import { addCourse_courseTypes } from "../../../../Redux/RedusersCompo/CourcesReducer";
import { useSelector, useDispatch } from "react-redux";

import ListCources from "../AllCourcesComponent/Components/ListCources";
import { getCourseTypesByCourseId } from "../../../../Redux/Actions/CourcesAction";
import { useParams } from "react-router-dom";
import Loading from "../../../../LoadingComponent/Loading";

const SingleCourcePage = () => {
  const courses = useSelector((state) => state.courseData.course_courseTypes);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { courseId } = useParams();
  // let course_Image1 = [];
  // let image = [];

  // if (courses) {
  //   course_Image1 = courses[0]?.course_Image;
  //   console.log(course_Image1);
  //   const imageData = course_Image1?.filter((image) => {
  //     return image.active_Status === "A";
  //   });

  //   if (imageData) {
  //     image = imageData[0];
  //   }
  // }

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getCourseTypesByCourseId(courseId);
      dispatch(addCourse_courseTypes(data));
      setIsLoading(false);
    };
    getData();
  }, [courseId, dispatch]);

  // const contants = () => {
  //   return (
  //     <>
  //       <h1>{`Certification In ${courses && courses[0]?.course_name}`}</h1>
  //     </>
  //   );
  // };

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="SingleCourcePage-main-container">
      <div className="SingleCourcePage-container">
        <div className="SingleCourcePage-content">
          {courses &&
            courses?.map((course, key) => {
              const courseName = course.course_name;
              if (
                course.course_active === "A" &&
                course.future_course === "D"
              ) {
                return (
                  <ListCources
                    coursesTypes={course.Course_type}
                    courseName={courseName}
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default SingleCourcePage;
