import { myaxios } from "../ApiBase";

export const getALLEvents = async () => {
  try {
    const responce = await myaxios.get(`/api/events`);
    return responce.data;
  } catch (error) {}
};

export const getEeventVideo = async (EventTypeId) => {
  try {
    const responce = await myaxios.get(`/api/eventTypes/${EventTypeId}`);
    return responce.data;
  } catch (error) {}
};

//juzz it jobs

export const getAllEvent = async () => {
  try {
    const responce = await myaxios.get(`/api/juzzItJobs`);
    return responce.data;
  } catch (error) {}
};

export const getJobDetails = async (jobId) => {
  try {
    const responce = await myaxios.get(`/api/juzzItJobs/${jobId}`);
    return responce.data;
  } catch (error) {}
};

// job applicants

export const addJuzzItJobApplycants = async (jobId, applicantData) => {
  try {
    const responce = await myaxios.post(
      `/api/domainJob/${jobId}/applicants`,
      applicantData
    );
    return responce.data;
  } catch (error) {}
};

//// landing page

export const getPageData = async (pageId) => {
  try {
    const responce = await myaxios.get(`/api/page/${pageId}`);
    return responce.data;
  } catch (error) {}
};

export const addLandingPAGEdETAILSDetails = async (
  landingpageId,
  landingPageData
) => {
  try {
    const responce = await myaxios.post(
      `/api/landingPages/${landingpageId}/applications`,
      landingPageData
    );
    return responce.data;
  } catch (error) {}
};
