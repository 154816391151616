import React, { useEffect, useState } from "react";
import "./ResetPassWord.scss";
// import { useNavigate } from "react-router-dom";
import { updatePassWordData } from "../../../../../../../Redux/Actions/MyCourseActions";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Loading from "../../../../../../../LoadingComponent/Loading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getStudentDataByToken } from "../../../../../../../Redux/Actions/AuthAction";
import { setStudentDataToStore } from "../../../../../../../Redux/RedusersCompo/AuthReducer";
import { useDispatch, useSelector } from "react-redux";
import SettingSideBar from "../SettingSideBar";

const isPasswordValid = (password) => {
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return passwordPattern.test(password);
};

const ResetPassWord = () => {
  // const navigate = useNavigate();

  const [oldPassWord, setOldPassword] = useState("");
  const [newPassWord, setNewPassWord] = useState("");
  const [conformPassWord, setConformPassWord] = useState("");
  const [showUpdateStatus, setshowUpdateStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const [passWordupdateMessage, setpassWordupdateMessage] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [conformPasswordError, setConformPasswordError] = useState("");
  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  // const userData = localStorage.getItem("userData");
  // let user = {};
  // if (userData) {
  //   user = JSON.parse(userData);
  // }

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    // Reset error messages
    setOldPasswordError("");
    setNewPasswordError("");
    setConformPasswordError("");

    // Validate old password
    if (!oldPassWord) {
      setOldPasswordError("Old password is required.");
      return;
    } else if (!isPasswordValid(oldPassWord)) {
      setOldPasswordError(
        "Old password must have at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long."
      );
      return;
    }

    // Validate new password
    if (!newPassWord) {
      setNewPasswordError("New password is required.");
      return;
    } else if (!isPasswordValid(newPassWord)) {
      setNewPasswordError(
        "New password must have at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long."
      );
      return;
    }

    // Validate confirm password
    if (conformPassWord !== newPassWord) {
      setConformPasswordError("Passwords do not match.");
      return;
    }

    setLoading(true);
    const passwordData = {
      old_password: oldPassWord,
      new_password: newPassWord,
    };
    const response = await updatePassWordData(
      userDetails && userDetails?.id,
      passwordData
    );
    setLoading(false);
    if (response?.successStatus === true) {
      setshowUpdateStatus(true);
    } else {
      setpassWordupdateMessage("InCorrect Old Password");
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleConformePasswordVisibility = () => {
    setShowConformPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleOldPasswordVisibility = () => {
    setShowOldPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="ResetPassWord-main-container">
      <div
        className={`MyProfileSetting-successfull-main-container ${
          showUpdateStatus ? "showUpdatedStatus" : ""
        }`}
      >
        <div className="MyProfileSetting-successfull-container">
          <h1>Detaild updated successfully</h1>
          <Button
            onClick={() => setshowUpdateStatus(false)}
            variant="contained"
          >
            ok
          </Button>
        </div>
      </div>

      <SettingSideBar />
      {/* 
      <div className="ResetPassWord-links-contant">
        <ul>
          <li onClick={() => navigate("/DashBoard/Myprofile/setting")}>
            Profile
          </li>
          <li
            className="active"
            onClick={() => navigate("/DashBoard/Myprofile/resetPassWord")}
          >
            PassWord
          </li>
          <li onClick={() => navigate("/DashBoard/Myprofile/socialLinks")}>
            social links
          </li>
        </ul>
      </div> */}
      <div className="ResetPassWord-form-content">
        <div className="ResetPassWord-form">
          {passWordupdateMessage && (
            <Typography className="error-message" color={"red"}>
              {passWordupdateMessage}
            </Typography>
          )}
          <form onSubmit={onSubmitHandler}>
            <TextField
              variant="outlined"
              label="Old PassWord"
              type={showOldPassword ? "text" : "password"}
              value={oldPassWord}
              onChange={(e) => setOldPassword(e.target.value)}
              error={!!oldPasswordError}
              helperText={oldPasswordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleOldPasswordVisibility}
                      edge="end"
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              variant="outlined"
              label="New password"
              type={showPassword ? "text" : "password"}
              value={newPassWord}
              onChange={(e) => setNewPassWord(e.target.value)}
              error={!!newPasswordError}
              helperText={newPasswordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Confirm Password"
              variant="outlined"
              type={showConformPassword ? "text" : "password"}
              value={conformPassWord}
              onChange={(e) => setConformPassWord(e.target.value)}
              error={!!conformPasswordError}
              helperText={conformPasswordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConformePasswordVisibility}
                      edge="end"
                    >
                      {showConformPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button className="button" type="submit" variant="contained">
              Update
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassWord;
