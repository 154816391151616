import React from "react";
import "./ListOfLiclassVodeos.scss";
//import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";
const ListOfLiveClassRecodedVideo = ({ videoContent }) => {
  // const options = { year: "numeric", month: "long", day: "numeric" };
  return (
    <div className="ListOfLiveClassRecodedVideo-main-contant">
      {videoContent &&
        videoContent?.map((val, key) => {
          if (val.activeStatus === "D") {
            return null;
          }
          return (
            <div className="AllLiveClassRecodedVideo-single-video-container">
              <NavLink to={`${val.recordedVideoId}`} className={"link"}>
                <div className="AllLiveClassRecodedVideo-single-video-details">
                  <h2>{val.vedioTopic}</h2>
                  <p>{val.videoDesc}</p>
                  {/* <p>{val.createdDate}</p> */}
                </div>
              </NavLink>
            </div>
          );
        })}
    </div>
  );
};

export default ListOfLiveClassRecodedVideo;
