import React, { useEffect } from "react";
import "./AllAssignments.scss";
import { useNavigate, useParams } from "react-router-dom";
import { getTopicAssignments } from "../../../../../../Redux/Actions/MyCourseActions";
import { Button } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setTopicAssignment } from "../../../../../../Redux/RedusersCompo/MyCourseReducer";
const AllAssignments = () => {
  const { topicId } = useParams();

  const topicAssignment = useSelector(
    (state) => state.mycourseData.topicAssignment
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const data = await getTopicAssignments(topicId);
      dispatch(setTopicAssignment(data));
    };
    getData();
  }, [dispatch, topicId]);

  const downloadForm = async (filename) => {
    const data = {
      path: filename,
    };

    try {
      const res = await axios.post(
        "http://localhost:8082/api/data/download",
        data,
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const navigate = useNavigate();

  return (
    <div className="AllAssignments-main-container">
      {topicAssignment &&
        topicAssignment.map((val, key) => (
          <div className="assignment-main-content" key={key}>
            <div className="assignment-content">
              <h1>
                <span>assignment {key + 1}: </span>
                {val.assignment_topic}
              </h1>

              <p>
                {" "}
                <i class="fa-solid fa-arrow-right"></i> {val.assignmant_desc}
              </p>
            </div>
            <div className="button-component">
              <Button
                variant="contained"
                className="button"
                onClick={() => {
                  downloadForm(val.file_location);
                }}
              >
                download
              </Button>
              <p
                onClick={() => {
                  navigate(`assignment/${val.assignment_id}`);
                }}
              >
                <i class="fa-solid fa-arrow-right"></i>
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AllAssignments;
