import React, { useEffect, useState } from "react";
import "./MyProfileSetting.scss";
// import { useNavigate } from "react-router-dom";
import {
  getStudentProfile,
  updateStudentData,
} from "../../../../../../Redux/Actions/MyCourseActions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { setstudentProfileUpdateData } from "../../../../../../Redux/RedusersCompo/MyCourseReducer";
import Loading from "../../../../../../LoadingComponent/Loading";
import { getStudentDataByToken } from "../../../../../../Redux/Actions/AuthAction";
import { setStudentDataToStore } from "../../../../../../Redux/RedusersCompo/AuthReducer";
import SettingSideBar from "./SettingSideBar";
const MyProfileSetting = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showUpdateStatus, setshowUpdateStatus] = useState(false);

  // const userData = localStorage.getItem("userData");
  // let user = {};
  // if (userData) {
  //   user = JSON.parse(userData);
  // }

  const userDetails = useSelector((state) => state.authdata.StudentData);
  const tokenData = localStorage.getItem("tokenData");

  useEffect(() => {
    const getData = async () => {
      if (tokenData) {
        if (!userDetails) {
          const data = await getStudentDataByToken(tokenData);
          dispatch(setStudentDataToStore(data));
        }
      }
    };
    getData();
  }, [dispatch, tokenData, userDetails]);

  const studentProfileUpdateData = useSelector(
    (state) => state.mycourseData.studentProfileUpdateData
  );

  const [name, setName] = useState("");

  // const [email, setEmail] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [GithubLink, setGithubLink] = useState("");

  useEffect(() => {
    if (studentProfileUpdateData) {
      setName(studentProfileUpdateData[0]?.student_name);
      setMobile_number(studentProfileUpdateData[0]?.mobile_Number);
      setAddress(studentProfileUpdateData[0]?.student_address);
      // setGithubLink(profileSocialLinks[0]?.github_link);
    }
  }, [studentProfileUpdateData]);

  const addProfileLinks = async () => {
    const socialLinks = {
      name: name,
      mobile_number: Number(mobile_number),
      address: address,
    };
    setIsLoading(true);
    const response = await updateStudentData(
      userDetails && userDetails?.id,
      socialLinks
    );
    setIsLoading(false);
    if (response?.status === true) {
      setshowUpdateStatus(true);
      const data = await getStudentProfile(userDetails && userDetails?.id);
      dispatch(setstudentProfileUpdateData(data));
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getStudentProfile(userDetails && userDetails?.id);
      dispatch(setstudentProfileUpdateData(data));
    };
    getData();
  }, [dispatch, userDetails]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="MyProfileSetting-main-container">
      <div
        className={`MyProfileSetting-successfull-main-container ${
          showUpdateStatus ? "showUpdatedStatus" : ""
        }`}
      >
        <div className="MyProfileSetting-successfull-container">
          <h1>Detaild updated successfully</h1>
          <Button
            onClick={() => setshowUpdateStatus(false)}
            variant="contained"
          >
            ok
          </Button>
        </div>
      </div>
      <SettingSideBar />

      {/* <div className="MyProfileSetting-links-contant">
        <ul>
          <li
            className="active"
            onClick={() => navigate("/DashBoard/Myprofile/setting")}
          >
            Profile
          </li>
          <li onClick={() => navigate("/DashBoard/Myprofile/resetPassWord")}>
            PassWord
          </li>
          <li onClick={() => navigate("/DashBoard/Myprofile/socialLinks")}>
            social links
          </li>
        </ul>
      </div> */}

      <div className="SocialLinks-main-content-container">
        <div className="SocialLinks-main-content">
          <ul>
            <li>
              <h1>Name</h1>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
              ></input>
            </li>
            {/* <li>
              <h1>Email</h1>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
              ></input>
            </li> */}
            <li>
              <h1>Mobile Number</h1>
              <input
                value={mobile_number}
                onChange={(e) => setMobile_number(e.target.value)}
                type="text"
              ></input>
            </li>
            <li>
              <h1>address</h1>
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                type="text"
              ></input>
            </li>
            {/* <li>
              <h1> Github</h1>
              <input type="text"></input>
            </li> */}
          </ul>
          <Button
            variant="contained"
            className="button"
            onClick={addProfileLinks}
          >
            update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MyProfileSetting;
