import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import "./EventForm.scss";
import { useNavigate } from "react-router-dom";
import { serntSignUpEventRequest } from "../../../../../../Redux/Actions/AuthAction";
import Loading from "../../../../../../LoadingComponent/Loading";
const EventForm = ({ setShowForm, imgData, EventType }) => {
  const [formData, setFormData] = useState({
    name: "",
    emeil: "",
    mobileNumber: "",
  });

  const [isloading, setLoading] = useState(false);
  const [errors, setError] = useState({
    name: "",
    emeil: "",
    mobileNumber: "",
  });

  const handleFormDataSubmition = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const handleFormSubmition = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const data = {
        EMAIL: formData.emeil,
        MOBILE_NUMBER: formData.mobileNumber,
        NAME: formData.name,
      };
      setLoading(true);
      const response = await serntSignUpEventRequest(data);
      setLoading(false);
      if (response?.status === true) {
        setFormData({
          name: "",
          emeil: "",
          mobileNumber: "",
        });
        navigate(
          `/Events/${EventType?.EVENT_TYPE_ID}/${EventType?.EVENT_TYPE_NAME}`
        );
      }
    } else {
      console.log("Form validation failed");
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: formData.name ? "" : "Name is required",
      emeil: /\S+@\S+\.\S+/.test(formData.emeil) ? "" : "Invalid email address",
      mobileNumber: /^\d{10}$/.test(formData.mobileNumber)
        ? ""
        : "Mobile must be 10 digits", // Modified to validate the topic
    };

    setError(newErrors);

    // Check if there are no errors
    return Object.values(newErrors).every((error) => error === "");
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="EventForm-main-container">
      <div className="EventForm-container">
        <img src={imgData} alt="slkmsksj"></img>
        <i
          class="fa-solid fa-x"
          onClick={() => {
            setShowForm();
          }}
        ></i>

        <form onSubmit={handleFormSubmition}>
          <TextField
            type="text"
            name="name"
            label="Enter Name"
            variant="standard"
            value={formData.name}
            onChange={handleFormDataSubmition}
            error={errors.name}
            helperText={errors.name}
          />
          <TextField
            type="text"
            name="emeil"
            label="Enter Email"
            variant="standard"
            value={formData.emeil}
            onChange={handleFormDataSubmition}
            error={errors.emeil}
            helperText={errors.emeil}
          />
          <TextField
            type="text"
            name="mobileNumber"
            label="Enter Mobile Number"
            variant="standard"
            value={formData.mobileNumbermobileNumber}
            onChange={handleFormDataSubmition}
            error={errors.mobileNumber}
            helperText={errors.mobileNumber}
          />
          <Button className="Button" type="submit" variant="contained">
            submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default EventForm;
