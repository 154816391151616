import React from "react";
import "./ApplicationSubmited.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const ApplicationSubmited = () => {
  const navigate = useNavigate();
  return (
    <div className="ApplicationSubmited-main-container">
      <div className="ApplicationSubmited-container">
        <h1>your application submited successfully</h1>
        <Button
          onClick={() => {
            navigate("/Cources");
          }}
          className="button"
          variant="outlined"
        >
          explore our courses
        </Button>
      </div>
    </div>
  );
};

export default ApplicationSubmited;
