import { myaxios } from "../ApiBase";

export const getAllMainTeamMember = async () => {
  try {
    const responce = await myaxios.get(`/api/student/mainTeamMembers`);
    return responce.data;
  } catch (error) {}
};

export const getAllCoreTeamMember = async () => {
  try {
    const responce = await myaxios.get(`/api/student/teamMembers`);
    return responce.data;
  } catch (error) {}
};
