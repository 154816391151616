import React from "react";
import "./FrequentlyAskedQuestions.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const FrequentlyAskedQuestions = ({ faqs, heading }) => {
  return (
    <div className="FrequentlyAskedQuestions-main-container">
      <div className="FrequentlyAskedQuestions-container">
        {heading === "NO" ? (
          <h1>Frequently Asked Questions</h1>
        ) : (
          <h1>{<h1>{heading}</h1>}</h1>
        )}

        <div className="FrequentlyAskedQuestions-Faqs-content">
          {faqs &&
            faqs?.map((val, key) => {
              return (
                <Accordion key={key} className="Accordion">
                  <AccordionSummary
                    id={`panel${key}-header`}
                    aria-controls={`panel${key}-content`}
                    expandIcon={<ExpandMoreIcon />}
                    className="Typography-head-bar"
                  >
                    <Typography variant="h6" className="Typography-head">
                      {key + 1}
                      {".   "}
                      {val.FAQ_HEADING}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="h6" className="Typography-desc">
                      {"    "}
                      <i class="fa-solid fa-angles-right"></i>
                      {"  "}
                      {val.FAQ_DESC}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
