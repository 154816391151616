import React, { useState } from "react";
import { TextField, Button, Container } from "@mui/material";
import "./DemoForm.scss";
import { serntSignUpDemoRequest } from "../../../Redux/Actions/AuthAction";
import { useNavigate } from "react-router-dom";
import Loading from "../../../LoadingComponent/Loading";

const DemoForm = (props) => {
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const newWarnings = {};

    if (!email) {
      newErrors.email = "Email is required";
      newWarnings.email = "Email is recommended";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      newWarnings.mobileNumber = "Mobile number is recommended";
    } else if (!/^\d{10}$/.test(mobileNumber)) {
      newErrors.mobileNumber = "Invalid mobile number (10 digits)";
    }

    setErrors(newErrors);
    setWarnings(newWarnings);

    return Object.keys(newErrors).length === 0;
  };

  const data = {
    EMAIL: email,
    MOBILE_NUMBER: mobileNumber,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        setLoading(true);
        const response = await serntSignUpDemoRequest(data);

        if (response?.status === true) {
          setMobileNumber("");
          setEmail("");
          // Additional success actions if needed
        }

        if (props.setsetOpen) {
          props.setsetOpen();
        }
        if (props.setsuccessSubmition) {
          props.setsuccessSubmition();
        }
        if (props.SETDisplay) {
          props.SETDisplay();
        }

        if (props && props.setNavigate) {
          // Call setNavigate
          props.setNavigate();
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
        // Handle error appropriately (e.g., display a message to the user)
        if (props.SETDisplay) {
          props.SETDisplay();
        }
        if (props && props.setNavigate) {
          // Call setNavigate
          props.setNavigate();
        }
      } finally {
        setLoading(false);
      }
    } else {
      // Handle form validation errors appropriately (e.g., display a message to the user)
    }
  };

  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }

  const handleClose = () => {
    if (props.SETDisplay) {
      props.SETDisplay();
    }
    if (props && props.setNavigate) {
      // Call setNavigate
      props.setNavigate();
    }
  };

  return (
    <div className="DemoForm-main-container">
      <Container className="Container" maxWidth="sm">
        <i className="fa-solid fa-x" onClick={handleClose}></i>
        <h3>Book a Free Demo, now!</h3>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            variant="filled"
            fullWidth
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            error={!!errors.email}
            helperText={errors.email || warnings.email}
          />
          <TextField
            label="Mobile Number"
            variant="filled"
            fullWidth
            type="tel"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            margin="normal"
            error={!!errors.mobileNumber}
            helperText={errors.mobileNumber || warnings.mobileNumber}
            className="input"
          />
          <Button
            type="submit"
            variant="contained"
            className="button"
            fullWidth
          >
            Submit
          </Button>
          <p>
            <i className="fa-solid fa-square-check"></i> By providing your
            contact details, you agree to our
            <span onClick={() => navigate("/TermsConditions")}>
              Terms of Use
            </span>{" "}
            &nbsp;&
            <span onClick={() => navigate("/PrivacyPolicy")}>
              Privacy Policy
            </span>
          </p>
        </form>
      </Container>
    </div>
  );
};

export default DemoForm;
