import React, { useEffect, useState } from "react";
import "./EnrolledLiveCoursePlacementsDetails.scss";
import { getliveCoursePlacementImages } from "../../../../../../../../Redux/Actions/MyCourseActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setenrolledLiveClassPlacementsImages } from "../../../../../../../../Redux/RedusersCompo/MyCourseReducer";
import Loading from "../../../../../../../../LoadingComponent/Loading";
const EnrolledLiveCoursePlacementsDetails = () => {
  const { placementId } = useParams();
  const placementImages = useSelector(
    (state) => state.mycourseData.enrolledLiveClassPlacementsImages
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getliveCoursePlacementImages(placementId);
      dispatch(setenrolledLiveClassPlacementsImages(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, placementId]);

  console.log(placementImages);

  const imagedata = placementImages.filter((img) => {
    return img.active_Status === "A";
  });

  let image = {};

  if (imagedata) {
    image = imagedata[0];
  }

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="EnrolledLiveCoursePlacementsDetails-main-container">
      <div className="EnrolledLiveCoursePlacementsDetails-container">
        <img src={image?.Image_URL} alt="dscdsa"></img>
      </div>
    </div>
  );
};

export default EnrolledLiveCoursePlacementsDetails;
