import React, { useEffect } from "react";
import "./RecentPlacementDetail.scss";
import { useParams } from "react-router-dom";
import { getRecentPlacementDetailsData } from "../../../../../../Redux/Actions/HomePageActions";
import { useDispatch, useSelector } from "react-redux";
import { setrecentPlacementDetails } from "../../../../../../Redux/RedusersCompo/HomePageReducer";
import HeaderApplyForm from "../../../../CourcesComponents/SingleCourceComponent/CourseTypeCompo/HeaderApplyForm";
import RecentPlacementProjectList from "./Componet/RecentPlacementProjectList";
import logo from "../../../../../../img/logo/juzz-it_logo.jpg";
import RecomendeCourses from "../../RecomendeCoursesComponent/RecomendeCourses";
const RecentPlacementDetail = () => {
  const { placementId } = useParams();
  const dispach = useDispatch();

  const placementDetails = useSelector(
    (state) => state.HomePageData.recentPlacementDetails
  );

  useEffect(() => {
    const getDate = async () => {
      const data = await getRecentPlacementDetailsData(placementId);
      dispach(setrecentPlacementDetails(data));
    };
    getDate();
  }, [dispach, placementId]);

  let image = {};

  if (placementDetails && placementDetails?.length > 0) {
    const imageData = placementDetails[0]?.images.filter((val) => {
      return val.active_status === "A";
    });
    if (imageData && imageData.length > 0) {
      image = imageData[0];
    }
  }

  let certificate = [];

  if (placementDetails && placementDetails?.length > 0) {
    const certificateData = placementDetails[0]?.certificate.filter((val) => {
      return val.active_Status === "A";
    });
    if (certificateData && certificateData.length > 0) {
      certificate = certificateData;
    }
  }

  // const options = { year: "numeric", month: "long", day: "numeric" };

  let qualificationData = [];

  if (
    placementDetails &&
    placementDetails.length > 0 &&
    placementDetails[0]?.acadamicQualification &&
    placementDetails[0]?.acadamicQualification.length > 0
  ) {
    qualificationData = [...placementDetails[0]?.acadamicQualification].sort(
      (a, b) => {
        return a.order - b.order;
      }
    );
  }

  return (
    <div className="RecentPlacementDetail-main-container">
      <div className="RecentPlacementDetail-container">
        <div className="RecentPlacementDetail-left-content">
          <HeaderApplyForm />
          <div className="RecentPlacementDetail-certificate">
            <p>Certificate recived </p>

            <div>
              {certificate &&
                certificate?.map((val, key) => {
                  return <img src={val.certificate_URL} alt="sx"></img>;
                })}
            </div>
          </div>
        </div>
        <div className="RecentPlacementDetail-right-content">
          <div className="RecentPlacementDetail-main-profileData">
            <div className="RecentPlacementDetail-profileData">
              <div className="RecentPlacementDetail-profileImage">
                <img
                  src={image && image.image_URL ? image.image_URL : logo}
                  alt="xcdas"
                ></img>
              </div>
              <div className="RecentPlacementDetail-data">
                <h1>{placementDetails && placementDetails[0]?.name}</h1>
                <p>
                  {" "}
                  <i class="fa-solid fa-user-tie"></i>
                  {placementDetails && placementDetails[0]?.position}
                </p>
              </div>
              <span
                className=" span"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    placementDetails && placementDetails[0]?.linkedin_Link,
                    "_blank"
                  );
                }}
              >
                connect at
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2Wb7G67EcR44qT3KQLlLzI1Fna_L2lPXfTI1sx8_z2w&s"
                  alt="linked-in-logo"
                />
              </span>
            </div>
            <div className="RecentPlacementDetail-main-coursedetails">
              <div className="RecentPlacementDetail-course-undertaken">
                <h1>Course UnderTaken</h1>
                <p>
                  <i class="fa-solid fa-bookmark"></i>{" "}
                  {placementDetails && placementDetails[0]?.CourseUndrTaken}
                </p>
              </div>
              <div className="RecentPlacementDetail-skills-learned">
                <h1>Skills Acquired at JUZZ-IT :</h1>
                <ul>
                  {placementDetails &&
                    placementDetails[0]?.skillsLearned?.map((val, key) => {
                      return (
                        <li>
                          <i class="fa-solid fa-circle-dot"></i>
                          {val.skill_name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <RecentPlacementProjectList
            projects={placementDetails && placementDetails[0]?.projects}
          />
          <div className="RecentPlacementDetail-main-acadamicqualification">
            <h2 className="All-components-heading"> academic qualification</h2>
            <ul>
              {qualificationData &&
                qualificationData?.map((val, key) => {
                  return (
                    <li>
                      <i class="fa-solid fa-circle-dot"></i>
                      <div className="RecentPlacementDetail-acadamicqualification">
                        <h4>{val.qualification}</h4>
                        <p>{val.qualification_from}</p>
                        <span>
                          {new Date(val.date).toLocaleDateString("en-US", {
                            year: "numeric",
                          })}
                        </span>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <RecomendeCourses />
    </div>
  );
};

export default RecentPlacementDetail;
