import React, { useState } from "react";
import "./AnAllRoundEffective.scss";
// import icon1 from "../../../../../img/Home/sss/icon1.2.png";
import icon2 from "../../../../../img/Home/sss/icon2.1.jpg";
import icon3 from "../../../../../img/Home/sss/icon3.1.jpg";
// import icon4 from "../../../../../img/Home/sss/icon4.1.png";
import icon5 from "../../../../../img/Home/sss/icon5.2.jpg";
// import icon6 from "../../../../../img/Home/sss/icon6.2.jpg";
import { Button } from "@mui/material";
import DemoForm from "../../../DemoFormCompo/DemoForm";

const AnAllRoundEffective = () => {
  const [showForm, setshowForm] = useState(false);
  const [successSubmition, setsuccessSubmition] = useState(false);
  return (
    <div className="AnAllRoundEffective-main-container">
      <div className={`demo-form ${showForm ? "desplayDemoform" : ""}`}>
        <DemoForm
          SETDisplay={() => setshowForm(!showForm)}
          setsuccessSubmition={() => setsuccessSubmition(true)}
        />
      </div>

      <div
        className={`sucessfully-submited-Request-main ${
          successSubmition ? "successSubmition" : ""
        }`}
      >
        <div className="sucessfully-submited-Request">
          <h1>We received your request. We will reach you soon.</h1>
          <Button
            variant="contained"
            onClick={() => setsuccessSubmition(false)}
          >
            Ok
          </Button>
        </div>
      </div>
      <div className="AnAllRoundEffective-container">
        <div className="All-components-heading">
          Secure your complimentary trial now – an all-encompassing and
          effective method to elevate your tech career.
        </div>
        <div className="all-component-heading-discription">
          <span>
            At JUZZ-IT, our core philosophy revolves around hands-on,
            experiential learning.
          </span>{" "}
          Engage in building software projects akin to the practices of the top
          1% of Tech Professionals.<span>Acquire the comprehensive skills</span>{" "}
          necessary to secure coveted positions as
          <span>
            {" "}
            Fullstack / Backend Developers /Data Science Engineer/Data analyst/
            Design Engineer /Manufacturing Engineer / Automotive Engineer /
            Energy Engineer Etc.
          </span>{" "}
        </div>
        <ul>
          <li>
            <img
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRivEBfn3jdL4m1jyDZagk_VGNzApkZPTr0vCy__e0yCRnheUtc"
              }
              alt="cas"
            ></img>
            <h5 className="componets-itam-heading">
              {" "}
              Master Tech Skills through Professional-style Learning
            </h5>
            <p className="all-component-description">
              Explore Full Stack Development, Data Science ,Hybrid Eelectric
              Vehicle and Embedded Electric Vehicle in Design and analysis
              Programs with our immersive project-based curriculum. Develop
              practical skills in a real-world context.
            </p>
          </li>
          <li>
            <img src={icon2} alt="cas"></img>
            <h5 className="componets-itam-heading">
              Real Tech Challenges, Real Work Experience
            </h5>
            <p className="all-component-description">
              Acquire practical work experience by solving challenges posed by
              leading tech companies like Tata Elxsi , Ola Electric ,Tech
              Mahindra ,Capegemini ,Infosys etc.
            </p>
          </li>
          <li>
            <img src={icon3} alt="cas"></img>
            <h5 className="componets-itam-heading">
              {" "}
              Real-Time Learning with Industry Veterans
            </h5>
            <p className="all-component-description">
              Engage in live, activity-based working sessions led by experienced
              mentors from esteemed companies such as Tata Elxsi , Ola Electric
              ,Tech Mahindra ,Capegemini ,Infosys etc.
            </p>
          </li>
          <li>
            <img
              src={
                "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRIpxLNrNFc6Z9OoqCd5Pmbudvf87vXFMytDyWoXT67yG9YPI0N"
              }
              alt="cas"
            ></img>
            <h5 className="componets-itam-heading">
              {" "}
              Achieve Ambitious Roles with Career Support
            </h5>
            <p className="all-component-description">
              Receive personalized guidance and support from JUZZ-IT career
              coaches to excel in every interview, strategically plan your
              career, and secure exciting development jobs.
            </p>
          </li>
          <li>
            <img src={icon5} alt="cas"></img>
            <h5 className="componets-itam-heading">
              Elevate Your Interview Presence with Tangible Proof
            </h5>
            <p className="all-component-description">
              Stand out by showcasing real proof of work through a JUZZ-IT
              verified project portfolio on GitHub. Impress recruiters at
              product-based companies with your demonstrated skills and hands-on
              experience beyond traditional certificates.
            </p>
          </li>
          <li>
            <img
              src={
                "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTB8voif-CaxwA7wbYuIfu9_tB3Ba2zdgHrMuY9nT19uiQ2MukB"
              }
              alt="cas"
            ></img>
            <h5 className="componets-itam-heading">
              Propel Your Career with Targeted Support
            </h5>
            <p className="all-component-description">
              Validate your learnings and showcase your skills with a
              distinguished work experience certificate, ensuring you stand out
              during interviews and make a lasting impression.
            </p>
          </li>
        </ul>
        <div
          className="book-form-button-home"
          onClick={() => {
            setshowForm(true);
          }}
        >
          <span>
            Book Your Free Trial, Now<i class="fa-solid fa-right-long"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AnAllRoundEffective;
