import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileImage: [],
  profileSocialLinks: [],
  studentProfileUpdateData: [],
  batchCount: [],
  mycourseData: [],
  allPlacements: [],
  classLinks: [],

  videoWatchedData: [],
  topicAssignment: [],
  assignmentData: {},
  assignmentSubmitedData: [],

  StudentProfile: [],

  myCourseTypeDetailS: [],
  enrolledLiveClassPlacementsImages: [],
  enrolledLiveClassRcordedVideoSubject: [],
  enrolledLiveClassRcordedVideoSubjectVodeo: [],
  enrolledLiveClassRcordedSingleVodeo: {},

  // enrolledCourses: [],
  // enrolledCoursesplacement: [],
  // enrolledCoursesClassLinks: [],
  // enrolledCoursesCourseType: [],

  ///
  enrolledRecodedCourses: [],
  enrolledRecodedCoursesPlacements: [],
  enrolledRecodedCoursesClassLinks: [],
  enrolledRecodedCoursesCourseTypaDetails: [],
  enrolledRecodedCoursesPlacementImages: [],
  enrolledRecodedCourseModules: [],
  emrolledRecodedCourseModuleVideos: [],
  emrolledRecodedCourseTopicVideos: [],

  //
  enrolledWorkShops: [],
  enrolledWorkShopsPlacements: [],
  enrolledWorkShopsClassLinks: [],
  enrolledWorkShopsDetails: [],
  enrolledWorkShopPlacementsImages: [],
};

const MyCourseReducer = createSlice({
  name: "MyCourse",
  initialState,
  reducers: {
    setassignmentData(state, action) {
      state.assignmentData = action.payload;
    },

    setassignmentSubmitedData(state, action) {
      state.assignmentSubmitedData = action.payload;
    },

    setbatchCount(state, action) {
      state.batchCount = action.payload;
    },

    setvideoWatchedData(state, action) {
      state.videoWatchedData = action.payload;
    },

    setTopicAssignment(state, action) {
      state.topicAssignment = action.payload;
    },

    setprofileSocialLinks(state, action) {
      state.profileSocialLinks = action.payload;
    },
    setstudentProfileUpdateData(state, action) {
      state.studentProfileUpdateData = action.payload;
    },

    setprofileImage(state, action) {
      state.profileImage = action.payload;
    },

    setAllPlacements(state, action) {
      state.allPlacements = action.payload;
    },
    setClassLinks(state, action) {
      state.classLinks = action.payload;
    },

    setmyCourseTypeDetailS(state, action) {
      state.myCourseTypeDetailS = action.payload;
    },
    setEnrolledCourses(state, action) {
      state.enrolledCourses = action.payload;
    },
    setEnrolledRecodedCourses(state, action) {
      state.enrolledRecodedCourses = action.payload;
    },
    setEnrolledWorkShops(state, action) {
      state.enrolledWorkShops = action.payload;
    },
    setenrolledRecodedCoursesPlacements(state, action) {
      state.enrolledRecodedCoursesPlacements = action.payload;
    },
    setenrolledRecodedCoursesClassLinks(state, action) {
      state.enrolledRecodedCoursesClassLinks = action.payload;
    },
    setenrolledWorkShopsPlacements(state, action) {
      state.enrolledWorkShopsPlacements = action.payload;
    },
    setenrolledWorkShopsClassLinks(state, action) {
      state.enrolledWorkShopsClassLinks = action.payload;
    },
    setenrolledWorkShopsDetails(state, action) {
      state.enrolledWorkShopsDetails = action.payload;
    },
    setenrolledRecodedCoursesCourseTypaDetails(state, action) {
      state.enrolledRecodedCoursesCourseTypaDetails = action.payload;
    },
    setenrolledLiveClassPlacementsImages(state, action) {
      state.enrolledLiveClassPlacementsImages = action.payload;
    },
    setenrolledRecodedCoursesPlacementImages(state, action) {
      state.enrolledRecodedCoursesPlacementImages = action.payload;
    },
    setenrolledWorkShopPlacementsImages(state, action) {
      state.enrolledWorkShopPlacementsImages = action.payload;
    },
    setenrolledLiveClassRcordedVideoSubject(state, action) {
      state.enrolledLiveClassRcordedVideoSubject = action.payload;
    },
    setenrolledLiveClassRcordedVideoSubjectVodeo(state, action) {
      state.enrolledLiveClassRcordedVideoSubjectVodeo = action.payload;
    },
    setenrolledRecodedCourseModules(state, action) {
      state.enrolledRecodedCourseModules = action.payload;
    },
    setemrolledRecodedCourseModuleVideos(state, action) {
      state.emrolledRecodedCourseModuleVideos = action.payload;
    },
    setStudentProfile(state, action) {
      state.StudentProfile = action.payload;
    },
    setenrolledLiveClassRcordedSingleVodeo(state, action) {
      state.enrolledLiveClassRcordedSingleVodeo = action.payload;
    },
    setemrolledRecodedCourseTopicVideos(state, action) {
      state.emrolledRecodedCourseTopicVideos = action.payload;
    },
  },
});

export const {
  setassignmentSubmitedData,
  setassignmentData,
  setTopicAssignment,
  setvideoWatchedData,
  setprofileImage,
  setbatchCount,
  setprofileSocialLinks,
  setstudentProfileUpdateData,
  setClassLinks,
  setAllPlacements,
  setmyCourseTypeDetailS,
  setEnrolledCourses,
  setEnrolledRecodedCourses,
  setEnrolledWorkShops,
  setenrolledRecodedCoursesPlacements,
  setenrolledRecodedCoursesClassLinks,
  setenrolledWorkShopsPlacements,
  setenrolledWorkShopsClassLinks,
  setenrolledWorkShopsDetails,
  setenrolledRecodedCoursesCourseTypaDetails,
  setenrolledLiveClassPlacementsImages,
  setenrolledRecodedCoursesPlacementImages,
  setenrolledWorkShopPlacementsImages,
  setenrolledLiveClassRcordedVideoSubject,
  setenrolledLiveClassRcordedVideoSubjectVodeo,
  setenrolledRecodedCourseModules,
  setemrolledRecodedCourseModuleVideos,
  setStudentProfile,
  setenrolledLiveClassRcordedSingleVodeo,
  setemrolledRecodedCourseTopicVideos,
} = MyCourseReducer.actions;

export default MyCourseReducer.reducer;
