import React, { useEffect, useState } from "react";
import "./SessionLinks.scss";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllClassLinks,
//   getRecodedStudentClassLinks,
// } from "../../../../../../../../Redux/Actions/MyCourseActions";

// import {
//   setClassLinks,
//   setenrolledRecodedCoursesClassLinks,
// } from "../../../../../../../../Redux/RedusersCompo/MyCourseReducer";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Loading from "../../../../../../LoadingComponent/Loading";
import { getRecodedStudentClassLinks } from "../../../../../../Redux/Actions/MyCourseActions";
import { setenrolledRecodedCoursesClassLinks } from "../../../../../../Redux/RedusersCompo/MyCourseReducer";

const EnrolledRecordedCoursesSessionLinks = () => {
  const { courseId, studentBatchId } = useParams();
  const classLinkDetails = useSelector(
    (state) => state.mycourseData.enrolledRecodedCoursesClassLinks
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getRecodedStudentClassLinks(studentBatchId);
      dispatch(setenrolledRecodedCoursesClassLinks(data));
      setIsLoading(false);
    };
    getData();
  }, [studentBatchId, dispatch]);
  if (isLoading) {
    <Loading />;
  }
  return (
    <div className="EnrolledRecordedCoursesSessionLinks-main-container">
      <div className="EnrolledLiveCourseDetails-heading">
        <ul>
          <li>
            {" "}
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/details`}
              className={"link"}
            >
              course details{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/sessionLinks`}
              className={"link"}
            >
              {" "}
              session links
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/placements`}
              className={"link"}
            >
              placements
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/DashBoard/MyRecodedCourse/${courseId}/${studentBatchId}/classes`}
              className={"link"}
            >
              classes
            </NavLink>
          </li>
        </ul>
      </div>

      <TableContainer
        component={Paper}
        className="BatchClassLink-table-container"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI NO</TableCell>
              <TableCell>CLASS TITLE</TableCell>
              <TableCell>SUBJECT NAME</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>CLASS TIME</TableCell>
              <TableCell>CLASS LINK</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classLinkDetails &&
              classLinkDetails?.map((val, key) => {
                const date = new Date(val.class_Date);
                const options = {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };
                const formattedDate = date.toLocaleDateString("en-US", options);
                return (
                  <TableRow key={key}>
                    <TableCell>{key + 1}</TableCell>
                    <TableCell>{val.class_Title}</TableCell>
                    <TableCell>{val.subject_Name}</TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>{val.class_Time}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          window.open(val.class_Link, "_blank");
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        link
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EnrolledRecordedCoursesSessionLinks;
