import React, { useEffect, useState } from "react";
// import "./EnrolledRecordedCoursesPlacementDetails.scss";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { setenrolledRecodedCoursesPlacementImages
import Loading from "../../../../../LoadingComponent/Loading";
import { setenrolledRecodedCoursesPlacementImages } from "../../../../../Redux/RedusersCompo/MyCourseReducer";
import { getenrolledRecodedStudentPlacementImage } from "../../../../../Redux/Actions/MyCourseActions";

const EnrolledRecordedCoursesPlacementDetails = () => {
  const { placementId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const placementImages = useSelector(
    (state) => state.mycourseData.enrolledRecodedCoursesPlacementImages
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getenrolledRecodedStudentPlacementImage(placementId);
      dispatch(setenrolledRecodedCoursesPlacementImages(data));
      setIsLoading(false);
    };
    getData();
  }, [dispatch, placementId]);

  const imagedata = placementImages?.filter((img) => {
    return img.image_active_Staute === "A";
  });

  let image = {};

  if (imagedata) {
    image = imagedata[0];
  }

  if (isLoading) {
    <Loading />;
  }

  return (
    <div className="EnrolledLiveCoursePlacementsDetails-main-container">
      <div className="EnrolledLiveCoursePlacementsDetails-container">
        <img src={image?.Image_URL} alt="sss"></img>
      </div>
    </div>
  );
};

export default EnrolledRecordedCoursesPlacementDetails;
