import authHeader from "./auth-header";
import { myaxios } from "../ApiBase";
export const getAllBlogsWithAllData = async () => {
  try {
    const responce = await myaxios.get(`/api/student/blogs`);
    return responce.data;
  } catch (error) {}
};

export const getBlogDetailsByBlogId = async (blogId) => {
  try {
    const responce = await myaxios.get(`/api/student/blogs/${blogId}/details`, {
      headers: authHeader(),
    });
    return responce.data;
  } catch (error) {}
};

export const addCommentsToBlog = async (studentId, blogId, commentData) => {
  try {
    const responce = await myaxios.post(
      `/blg/student/${studentId}/blogs/${blogId}/comments`,
      commentData,
      {
        headers: authHeader(),
      }
    );
    return responce.data;
  } catch (error) {}
};

export const getAllBlogComments = async (blogId) => {
  try {
    const responce = await myaxios.get(`/api/blogs/${blogId}/comments`);
    return responce.data;
  } catch (error) {}
};


