import React, { useState } from "react";
import "./DashBoard.scss";

import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { setAuthStatus } from "../../../../Redux/RedusersCompo/AuthReducer";
import { useDispatch } from "react-redux";

const DashBoard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDashBordBar, setShowDashBordBar] = useState(false);

  const tokenData = localStorage.getItem("tokenData");
  if (!tokenData) {
    navigate("/");
  }

  const logoutHandler = () => {
    localStorage.removeItem("tokenData");
    navigate("/");
    window.location.reload();
    dispatch(setAuthStatus(false));
  };

  return (
    <div className="DashBoard-main-container">
      <div className="DashBoard-container">
        <div className="DashBoard-header-main-bar">
          <div className="DashBoard-header-bar">
            <h4> Dashboard</h4>
            <i
              onClick={() => {
                setShowDashBordBar(true);
              }}
              class="fa-solid fa-bars"
            ></i>
          </div>
        </div>

        <div
          className={`myprofile-main-content-heading-content ${
            showDashBordBar ? "showDashBordBar" : ""
          }`}
        >
          <div className="x-icon">
            <i
              onClick={() => {
                setShowDashBordBar(false);
              }}
              class="fa-solid fa-x"
            ></i>
          </div>
          <ul>
            <NavLink
              to={"/DashBoard"}
              onClick={() => {
                setShowDashBordBar(false);
              }}
              className="link"
            >
              <li>Dashboard</li>
            </NavLink>
            <NavLink
              onClick={() => {
                setShowDashBordBar(false);
              }}
              to={"/DashBoard/MyProFile"}
              className="link"
            >
              <li>
                {" "}
                <span>my profile</span>
              </li>
            </NavLink>
            <NavLink
              onClick={() => {
                setShowDashBordBar(false);
              }}
              to={"/DashBoard/MyliveCourses"}
              className="link"
            >
              <li> Enrolled courses</li>
            </NavLink>
            <NavLink
              onClick={() => {
                setShowDashBordBar(false);
              }}
              to={"/DashBoard/Myprofile/setting"}
              className="link"
            >
              <li>setting</li>
            </NavLink>
            <li onClick={logoutHandler} className="logout">
              logout
            </li>
          </ul>
        </div>
      </div>
      <div className="myprofile-main-content-body">
        <Outlet />
      </div>
    </div>
  );
};

export default DashBoard;
