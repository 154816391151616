import React, { useState } from "react";
import "./NewCourseTypeProject.scss";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

// Import Swiper modules
import { Autoplay } from "swiper/modules";

import { useEffect } from "react";
const NewCourseTypeProject = (props) => {
  const [slidesPerView, setSlidesPerView] = useState(4);
  let sortedProjects = [];

  const heading = props.heading;

  if (props.Projectslist && props.Projectslist.length > 0) {
    sortedProjects = [...props.Projectslist].sort((a, b) => a.order - b.order);
  }

  useEffect(() => {
    const updateSlidesPerView = () => {
      // Adjust the logic based on your design requirements
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1200) {
        setSlidesPerView(4);
      } else if (screenWidth >= 992) {
        setSlidesPerView(3);
      } else if (screenWidth >= 768) {
        setSlidesPerView(2);
      } else if (screenWidth >= 500) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(1);
      }
    };

    // Update slides per view on initial load and window resize
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  return (
    <div className="NewCourseTypeProject-main-container">
      <div className="NewCourseTypeProject-container">
        {heading === "NO" ? <h1>Projects</h1> : <h1>{heading}</h1>}

        <div className="NewCourseTypeProject-main-contant">
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={10}
            modules={[Autoplay]}
            autoplay={{ delay: 3500 }}
            className="mySwiper"
          >
            {sortedProjects &&
              sortedProjects?.map((val, key) => {
                return (
                  <SwiperSlide key={key} className="SwiperSlide">
                    <div className="NewProjects-content" key={key}>
                      <h4 data-aos-duration="1200">
                        {key + 1}
                        {".  "}
                        {val.project_name}
                      </h4>
                      <p>{val.project_desc}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default NewCourseTypeProject;
