import { Button } from "@mui/material";
import "./HomeHeader.scss";
import React from "react";
// import ReactPlayer from "react-player";
import { useState } from "react";
import BikingForm from "./BikingFormCompo/BikingForm";

const HomeHeader = () => {
  const [demoSubmitionStatus, setDemoSubmitionStatus] = useState(false);

  return (
    <div className="HomeHeader-main-container">
      <div
        className={`domo-submition-main-successfully ${
          demoSubmitionStatus ? "demosubmitionSuccessfully" : ""
        }`}
      >
        <div className="domo-submition-successfully">
          <h5>WE RECIVED YOUR REQUEST WE WILL BE REACHING YOU SOON</h5>
          <Button onClick={() => setDemoSubmitionStatus(false)}>OK</Button>
        </div>
      </div>
      <div className="HomeHeader-container">
        <div className="HomeHeader-main-container">
          <div className="HomeHeader-top-main-container">
            <div className="HomeHeader-top-left-container">
              <p>
                <span>New!</span> Jobs from Top Companies
                <i class="fa-solid fa-arrow-right"></i>
              </p>
              <h1>
                Engineer Your Success:<br></br> Job-Ready Courses Await!{" "}
                <br></br>
                Craft Your Future:
              </h1>
              <h2>Engineering courses with Job Assistance!</h2>
              <ul>
                <li>
                  <i class="fa-solid fa-check"></i>Placements & Training at
                  JUZZ-IT.
                </li>
                <li>
                  <i class="fa-solid fa-check"></i>Secure a 11+ LPA Salary with
                  Us!
                </li>
                <li>
                  <i class="fa-solid fa-check"></i>250+ Companies Hiring
                </li>
              </ul>
            </div>
            <div className="HomeHeader-top-right-container">
              <BikingForm />
            </div>
            <div className="HomeHeader-top-bottom-contant">
              <ul>
                <li className="li1">
                  <span>126%</span>
                  <p>Avg. CTC Hike</p>
                </li>
                <li className="li2">
                  <span>Top 1%</span>
                  <p>Industry Instructors</p>
                </li>
                <li className="li3">
                  <span>900+</span>
                  <p>Placement Partners</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
